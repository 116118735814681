import React from "react";
import { Props } from "..";
import { UrlFieldType } from "../../../../contentstack/fields/url";

export interface SectionHowToPlayContentstackProps {
  championListUrl: UrlFieldType;
}

export const transformContentstackPropsToSectionProps = ({
  championListUrl,
  ...otherProps
}: SectionHowToPlayContentstackProps): Props => {
  return {
    ...otherProps,
    championListLink: {
      ...championListUrl,
      text: "discover-more-champions.action",
    },
  };
};
