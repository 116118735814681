import React from "react";
import { Props } from "..";
import { GameMode } from "../typings";
import { ImageAssetUrl } from "../../../../../contentstack/content-types/assets/typings";
import ComponentImage from "../../../../components/image";
import { VideoData } from "../../../../../contentstack/content-types/html5-videos/transforms/typings";
import { videoDataSourcesToVideoSources } from "../../../../../contentstack/content-types/html5-videos/transforms/video-data-to-video-component";
import { LinkFieldType } from "../../../../../contentstack/fields/link";

export interface SectionGameModesContentstackProps extends Pick<Props, "title" | "heading"> {
  gameModes: Array<
    Pick<GameMode, "id" | "headline" | "description" | "name" | "link"> & {
      backgroundImageUrl: ImageAssetUrl;
      thumbnailImageUrl: ImageAssetUrl;
      videos: VideoData[];
    }
  >;
}

export const transformContentstackPropsToSectionProps = ({
  gameModes,
  ...props
}: SectionGameModesContentstackProps): Props => {
  return {
    ...props,
    gameModes: gameModes.map(({ backgroundImageUrl, thumbnailImageUrl, videos, ...gameModeProps }) => {
      return {
        backgroundImage: <ComponentImage src={backgroundImageUrl} objectFit={"cover"} />,
        thumbnailImage: <ComponentImage src={thumbnailImageUrl} objectFit={"cover"} />,
        videoSources: videoDataSourcesToVideoSources(videos[0].sources),
        ...gameModeProps,
      };
    }),
  };
};
