import React from "react";
import { SectionNewsYoutubeProps, Video } from "..";
import ComponentImage from "../../../../components/image";
import { YoutubeVideoThumbnail } from "../../../../../generated/graphql";
import { UrlFieldType } from "../../../../../contentstack/fields/url";

export interface SectionNewsYoutubeContentstackProps extends Pick<SectionNewsYoutubeProps, "sectionTitle" | "title"> {
  url: UrlFieldType;
  videos: Array<
    Pick<Video, "id" | "title" | "description"> & {
      thumbnails: YoutubeVideoThumbnail[];
    }
  >;
}

export const transformContentstackPropsToSectionProps = ({
  url,
  videos,
  ...props
}: SectionNewsYoutubeContentstackProps): SectionNewsYoutubeProps => {
  return {
    ...props,
    link: url
      ? {
          ...url,
          text: "view-more.action",
        }
      : undefined,
    videos: videos.map(({ thumbnails, ...videoProps }) => {
      return {
        ...videoProps,
        image: <ComponentImage src={thumbnails[0].url} objectFit="cover" />,
      };
    }),
  };
};
