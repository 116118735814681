import styled from "styled-components";
import { ReactComponent as TwitchLogoSvg } from "./twitch-logo.svg";

export const TwitchLogo = styled(TwitchLogoSvg)<{ fill?: string }>`
  .st0 {
    fill-rule: evenodd;
    clip-rule: evenodd;
    fill: ${({ fill }) => fill || "#6959A6"};
  }
`;
