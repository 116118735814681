import React, { DOMElement, useEffect, useRef, useState } from "react";
import { COLOR_PRIMARY_DARK_GOLD, COLOR_OUTLINE_TRANSPARENT_WHITE } from "../../../../layouts/default/theme";

import { Champion } from "../typings";
import { Wrapper, ImageWrapper, Hex, DialRingWrapper, OuterRing, DialRing } from "./style";

import Image from "../../../../components/image";
import HexOutline from "../../../../components/hex-outline";

import { ReactComponent as OuterRingSvg } from "../assets/outer-ring.svg";
import { ReactComponent as DialRingSvg } from "../assets/dial-ring.svg";
import { useTicker } from "../../../../utils/hooks";

const StyledOuterRing = OuterRing.withComponent(OuterRingSvg);
const StyledDialRing = DialRing.withComponent(DialRingSvg);
const StyledHexOutline = Hex.withComponent(HexOutline);

interface Props {
  isInViewport: boolean;
  isAutoCycling: boolean;
  cycleDuration: number;
  cycleStartTime: number;
  activeChampion: Champion;
  champions: Champion[];
  testId?: string;
}

const ChampionAvatar: React.FC<Props> = ({
  isInViewport,
  isAutoCycling,
  cycleDuration,
  cycleStartTime,
  activeChampion,
  champions,
  testId,
}) => {
  const imageWrapperRef = useRef<HTMLDivElement>(null);
  const [hasEntered, setHasEntered] = useState<boolean>(false);

  // Animate in on first intersection
  useEffect(() => {
    if (isInViewport && !hasEntered) setHasEntered(true);
  }, [isInViewport]);

  return (
    <Wrapper className={`${hasEntered && "animate-enter"}`}>
      <ImageWrapper ref={imageWrapperRef}>
        {champions.map((champion, index) => (
          <Image
            key={champion.id}
            src={champion.assets.splash}
            className={champion === activeChampion ? "is-selected" : ""}
            testId={`${testId}:avatar-${index}`}
          />
        ))}
      </ImageWrapper>

      <StyledHexOutline
        circular
        isActive={isInViewport}
        countdown={isAutoCycling}
        countdownDuration={cycleDuration}
        countdownStartTime={cycleStartTime}
        strokeColor={COLOR_OUTLINE_TRANSPARENT_WHITE}
        accentColor={COLOR_PRIMARY_DARK_GOLD}
        accentThickness={3}
        transitionDuration={2000}
        transitionDelay={400}
      />

      <StyledOuterRing />

      <DialRingWrapper>
        <StyledDialRing />
      </DialRingWrapper>
    </Wrapper>
  );
};

export default ChampionAvatar;
