import { useIntl } from "gatsby-plugin-intl";
import React from "react";
import { Champion } from "../@types/index";
import ChampionComponent from "../components/champion";
import { ResponsiveWrapper, Message, List, Item } from "./style";

export interface Props {
  isVisible: boolean;
  isFirstTime: boolean;
  champions: Champion[];
  className?: string;
  testId?: string;
}

const Section: React.FC<Props> = ({ champions, className, isVisible, isFirstTime, testId }) => {
  const intl = useIntl();
  return (
    <ResponsiveWrapper className={className}>
      <Message active={champions.length <= 0}>
        {intl.formatMessage({ id: "champion-list.filter-result.no-champions-found" })}
      </Message>

      <List active={champions.length > 0}>
        {champions.map(({ id, ...props }, idx) => {
          // TODO 2019-10-23 jeremboo: Check with Andrej how to do it validated by TS
          const additionalProps = {
            isVisible,
            isFirstTime,
          };
          return <Item key={id} as={ChampionComponent} {...props} {...additionalProps} testId={`${testId}-${idx}`} />;
        })}
      </List>
    </ResponsiveWrapper>
  );
};

export default Section;
