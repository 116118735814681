import styled, { css, keyframes } from "styled-components";
import { rem, easing } from "../../../utils/style";
import {
  MEDIA_MOBILE_ALL,
  MEDIA_NOT_MOBILE,
  COLOR_BACKGROUND_DARK,
  COLOR_TEXT_WHITE,
  MAX_WIDTH,
  COLUMN_WIDTH,
} from "../../../layouts/default/theme";
import Video from "../../../components/video";
import { Section as NewsItemListSection } from "../../news-item-list/style";
import ComponentImage from "../../../components/image";

const MEDIA_SHIFT_DESKTOP = COLUMN_WIDTH * 2;

const simpleFadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

/**
 * NOTE 2019-10-09 jeremboo:
 * This piece of code manage style exeptions relative to other components.
 * For instance here, is that component is under the Header, some other margins have to be aplyed
 */
export const cssPullUpNewsItemListSection = css`
  ${NewsItemListSection} {
    @media ${MEDIA_NOT_MOBILE} {
      margin-top: -${MEDIA_SHIFT_DESKTOP}px;
      padding-top: ${MEDIA_SHIFT_DESKTOP}px;
    }
  }
`;

export const Section = styled.section`
  box-sizing: border-box;

  position: relative;

  min-height: ${rem(500)};
  padding: ${rem(COLUMN_WIDTH)};

  @media ${MEDIA_MOBILE_ALL} {
    height: ${rem(500)};
    padding: 0;
  }
`;

export const BackgroundWrapper = styled.div`
  @media ${MEDIA_MOBILE_ALL} {
    display: none;
  }

  overflow: hidden;

  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;

  width: 100%;

  background: ${COLOR_BACKGROUND_DARK};
`;

export const BackgroundVideo = styled(Video)`
  width: 100%;
  height: 100%;

  background: ${COLOR_BACKGROUND_DARK};
`;

export const ForegroundWrapper = styled.div`
  position: relative;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: center;

  max-width: ${rem(MAX_WIDTH - COLUMN_WIDTH * 2)};
  height: 55vh;
  min-height: ${rem(500)};
  margin: 0 auto;

  @media ${MEDIA_MOBILE_ALL} {
    height: ${rem(500)};

    > .hex {
      display: none;
    }
  }
`;

export const ForegroundVideoWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;

  background: ${COLOR_BACKGROUND_DARK};
`;

export const ForegroundVideo = styled(Video)`
  width: 100%;
  height: 100%;

  display: block;
  @media ${MEDIA_MOBILE_ALL} {
    display: none;
  }

  &.is-mobile {
    display: none;
    @media ${MEDIA_MOBILE_ALL} {
      display: block;
    }
  }
`;

export const ForegroundInner = styled.div`
  width: 100%;
`;

export const ForegroundRow = styled.div`
  width: 100%;
  margin-top: ${rem(10)};

  text-align: center;
`;

const LOGO_ASSET_RATIO = 1200 / 529;
export const Logo = styled.div`
  display: inline-block;

  position: relative;

  /* pointer-events: none; */

  width: 40vw;
  height: calc(40vw / ${LOGO_ASSET_RATIO});
  max-width: ${rem(600)};
  max-height: ${rem(600 / LOGO_ASSET_RATIO)};

  @media ${MEDIA_MOBILE_ALL} {
    width: 80vw;
    height: calc(80vw / ${LOGO_ASSET_RATIO});
  }
`;

export const LogoImage = styled(ComponentImage)`
  position: absolute;
  left: 0;
  top: 0;

  display: block;
  width: 100%;
  height: 100%;
  margin: 0;

  animation: ${simpleFadeIn} backwards 1s 0.5s ${easing.easeOutCubic};
`;
