import { Props } from "../.";

const data: Props = {
  title: "rewards.title",
  heading: "section.how-to-play.reward.heading",
  description: "section.how-to-play.reward.description",
  days: [
    {
      label: "day-1.title",
      description: "rewards.day-1.description",
    },
    {
      label: "day-2.title",
      description: "rewards.day-2.description",
    },
    {
      label: "day-3.title",
      description: "rewards.day-3.description",
    },
    {
      label: "day-4.title",
      description: "rewards.day-4.description",
    },
    {
      label: "day-5.title",
      description: "rewards.day-5.description",
    },
    {
      label: "day-6.title",
      description: "rewards.day-6.description",
    },
    {
      label: "day-7.title",
      description: "rewards.day-7.description",
    },
  ].map(({ ...props }, i) => {
    return {
      id: `day-${i + 1}`,
      ...props,
    };
  }),
  levels: [
    {
      label: "level-1.title",
      description: "rewards.level-1.description",
    },
    {
      label: "level-2.title",
      description: "rewards.level-2.description",
    },
    {
      label: "level-3.title",
      description: "rewards.level-3.description",
    },
    {
      label: "level-4.title",
      description: "rewards.level-4.description",
    },
    {
      label: "level-5.title",
      description: "rewards.level-5.description",
    },
    {
      label: "level-6.title",
      description: "rewards.level-6.description",
    },
    {
      label: "level-7.title",
      description: "rewards.level-7.description",
    },
    {
      label: "level-8.title",
      description: "rewards.level-8.description",
    },
    {
      label: "level-9.title",
      description: "rewards.level-9.description",
    },
    {
      label: "level-10.title",
      description: "rewards.level-10.description",
    },
  ].map(({ ...props }, i) => {
    return {
      id: `level-${i + 1}`,
      ...props,
    };
  }),
  labels: {
    daysToggleButton: "login-rewards.action",
    levelsToggleButton: "level-up.action",
  },
  tooltipMap: {
    assassins: {
      title: "assassins.title",
      desc: "assassins.description",
    },
    mages: {
      title: "mages.title",
      desc: "mages.description",
    },
    marksmen: {
      title: "marksmen.title",
      desc: "marksmen.description",
    },
    fighters: {
      title: "fighters.title",
      desc: "fighters.description",
    },
  },
};

export default data;
