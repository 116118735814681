import React from "react";

import SectionChampionRoles, { Props as SectionChampionRolesProps } from "../../sections/home/champion-roles";
import SectionChampionStyle from "../../sections/home/champion-style";

export interface Props {
  championListLink: SectionChampionRolesProps["championListLink"];
  playLink: SectionChampionRolesProps["playLink"];
}

const PageSectionGameIntro: React.FC<Props> = ({ championListLink, playLink }) => {
  return (
    <React.Fragment>
      <SectionChampionRoles championListLink={championListLink} playLink={playLink} />
      <SectionChampionStyle link={playLink} />
    </React.Fragment>
  );
};

export default PageSectionGameIntro;
