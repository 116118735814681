import React from "react";

import HexOutline from "../../../../components/hex-outline";

import { Wrapper, WrapperInner, WrapperInner2 } from "./style";
import { COLOR_PRIMARY_DARK_GOLD, COLOR_OUTLINE_LIGHT_GREY } from "../../../../layouts/default/theme";

interface Props {
  isInViewport: boolean;
  isAutoCycling?: boolean;
  cycleDuration?: number;
  cycleStartTime?: number;
  className?: string;
}

const RoleSelectorCircle: React.FC<Props> = ({
  children,
  isInViewport,
  isAutoCycling,
  cycleDuration,
  cycleStartTime,
  className,
}) => {
  return (
    <Wrapper className={className}>
      <WrapperInner>
        <WrapperInner2>
          <HexOutline
            isActive={isInViewport}
            circular
            countdown={isAutoCycling}
            countdownDuration={cycleDuration}
            countdownStartTime={cycleStartTime}
            strokeColor={COLOR_OUTLINE_LIGHT_GREY}
            accentColor={COLOR_PRIMARY_DARK_GOLD}
            accentThickness={3}
            transitionDuration={2500}
            transitionDelay={0}
          />
          {children}
        </WrapperInner2>
      </WrapperInner>
    </Wrapper>
  );
};

export default RoleSelectorCircle;
