import React from "react";
import Carousel from "../../../components/carousel";
import SectionTitle from "../../../components/section-title";
import { useInView } from "../../../utils/hooks";
import { Article, Item, List, ResponsiveWrapper } from "./style";
import { Article as ArticleType } from "./types";

export interface SectionNewsHighlightsListProps {
  sectionTitle?: string;
  articles: ArticleType[];
  className?: string;
}

const SectionNewsHighlightsList: React.FC<SectionNewsHighlightsListProps> = ({ sectionTitle, articles, className }) => {
  const [inViewRef, inView] = useInView({ triggerOnce: true, threshold: 0.5 });
  return (
    <ResponsiveWrapper className={className} ref={inViewRef}>
      <SectionTitle text={sectionTitle} displayOnMobile={true} testId='highlight'>
        <List>
          {articles.map((article, idx) => {
            return (
              <Item key={article.id}>
                <Article {...article} fadeInDelay={idx * 100} isVisible={inView} testId={`highlight:article-${idx}`}/>
              </Item>
            );
          })}
        </List>
        <List className={"is-carousel"}>
          <Carousel>
            {articles.map((article, idx) => {
              return (
                <Item key={article.id}>
                  <Article {...article} fadeInDelay={idx * 100} isVisible={inView} testId={`highlight:articlecarousel-${idx}`}/>
                </Item>
              );
            })}
          </Carousel>
        </List>
      </SectionTitle>
    </ResponsiveWrapper>
  );
};

export default SectionNewsHighlightsList;
