import React, { useState, useEffect, useRef, RefObject } from "react";
import { ease } from "../../../../utils/math";

import { COLOR_OUTLINE_EXTRA_TRANSPARENT_WHITE, COLOR_PRIMARY_BLUE } from "../../../../layouts/default/theme";

import { Chapter } from "../typings";
import CHAPTER_MAP from "../assets/chapter-map";

import Tooltipper, { TooltipMap } from "../../../../components/format/tooltipper";
import {
  TopicWrapper,
  TopicPagination,
  TopicList,
  TopicItem,
  TopicItemTitle,
  TopicItemDesc,
  TopicNavigation,
  TopicNavigationButton,
  Arrow,
} from "./style";

import HexOutline, { HexTransitionType } from "../../../../components/hex-outline";

import { ReactComponent as ArrowLeftSvg } from "../../../../assets/arrows/left.svg";
import { ReactComponent as ArrowRightSvg } from "../../../../assets/arrows/right.svg";
import { useIntl } from "gatsby-plugin-intl";

const ArrowLeft = Arrow.withComponent(ArrowLeftSvg);
const ArrowRight = Arrow.withComponent(ArrowRightSvg);

export interface Props {
  isInViewport: boolean;
  isActive: boolean;
  topics: Array<{ topicTitle: string; topicDesc: string }>;
  chapter: Chapter;
  activeTopicIndex: number;
  updateTopic: (chapter: Chapter, direction: number) => void;
  tooltipMap: TooltipMap;
  testId?: string;
}

const GuideTopic: React.FC<Props> = ({
  isInViewport,
  isActive,
  topics,
  chapter,
  activeTopicIndex,
  updateTopic,
  tooltipMap,
  testId,
}) => {
  const intl = useIntl();
  const [hasEntered, setHasEntered] = useState(false);
  const [isPrevHovering, setIsPrevHovering] = useState(false);
  const [isNextHovering, setIsNextHovering] = useState(false);

  const topicWrapperRef = useRef<any>(null);
  const topicListRef = useRef<any>(null);
  const refTemplate: RefObject<unknown>[] = [];
  topics.map(() => refTemplate.push(React.createRef()));
  const topicItemRefs = useRef<any>(refTemplate);

  useEffect(() => {
    if (isActive && !hasEntered) setHasEntered(true);
  }, [isActive]);

  return (
    <TopicWrapper
      ref={topicWrapperRef}
      className={`${hasEntered ? "is-active" : ""}`}
      chapterIndex={Object.keys(CHAPTER_MAP).indexOf(chapter)}
      data-testid={testId}
    >
      <HexOutline
        isActive={isInViewport && hasEntered}
        clipRightTop={20}
        strokeColor={COLOR_OUTLINE_EXTRA_TRANSPARENT_WHITE}
        accentColor={COLOR_OUTLINE_EXTRA_TRANSPARENT_WHITE}
        transition={HexTransitionType.GROW}
        transitionDuration={1200}
        transitionDelay={300}
      />

      <TopicPagination>{`${activeTopicIndex + 1} / ${topics.length}`}</TopicPagination>

      <TopicList ref={topicListRef}>
        {topics.map((item, index) => (
          <TopicItem ref={topicItemRefs.current[index]} key={item.topicTitle} activeIndex={activeTopicIndex}>
            <TopicItemTitle data-testid={`${testId}:title`}>{intl.formatMessage({ id: item.topicTitle })}</TopicItemTitle>
            <TopicItemDesc data-testid={`${testId}:description`}>
              <Tooltipper text={intl.formatMessage({ id: item.topicDesc })} tooltipMap={tooltipMap} />
            </TopicItemDesc>
          </TopicItem>
        ))}
      </TopicList>

      <TopicNavigation>
        <TopicNavigationButton
          // className={`${activeTopicIndex === 0 ? 'is-disabled' : ''}`}
          onClick={() => updateTopic(chapter, -1)}
          onMouseEnter={() => setIsPrevHovering(true)}
          onMouseLeave={() => setIsPrevHovering(false)}
        >
          <HexOutline
            isActive={isInViewport && hasEntered}
            clipLeftTop={8}
            strokeColor={COLOR_PRIMARY_BLUE}
            interactive
            transition={HexTransitionType.GROW}
            transitionDuration={700}
            transitionDelay={550}
            hovering={isPrevHovering /* && !(activeTopicIndex === 0) */}
            hoverDuration={300}
            hoverEase={ease.outExpo}
          />
          <ArrowLeft />
        </TopicNavigationButton>
        <TopicNavigationButton
          // className={`${activeTopicIndex === topics.length - 1 &&
          //   'is-disabled'}`}
          onClick={() => updateTopic(chapter, 1)}
          onMouseEnter={() => setIsNextHovering(true)}
          onMouseLeave={() => setIsNextHovering(false)}
        >
          <HexOutline
            isActive={isInViewport && hasEntered}
            clipRightBot={8}
            strokeColor={COLOR_PRIMARY_BLUE}
            interactive
            transition={HexTransitionType.GROW}
            transitionDuration={800}
            transitionDelay={600}
            hovering={isNextHovering /* && !(activeTopicIndex === topics.length - 1) */}
            hoverDuration={150}
            hoverEase={ease.inOutQuart}
          />
          <ArrowRight />
        </TopicNavigationButton>
      </TopicNavigation>
    </TopicWrapper>
  );
};

export default GuideTopic;
