import React from "react";
import styled from "styled-components";
import { components, OptionTypeBase } from "react-select";
import { ValueContainerProps } from "react-select/src/components/containers";
import { COLOR_PRIMARY_ORANGE } from "../../../../lib/layouts/default/theme";

import { ReactComponent as SvgLoupe } from "./assets/loupe.svg";

const LoupeIcon = styled.svg`
  width: 19px;
  height: 19px;

  .st0 {
    fill: ${COLOR_PRIMARY_ORANGE};
  }
  .st1 {
    fill: none;
    stroke: ${COLOR_PRIMARY_ORANGE};
    stroke-width: 1.5;
    stroke-miterlimit: 10;
  }
`;
export const StyledLoupeIcon = LoupeIcon.withComponent(SvgLoupe);

function GenericValueContainer<T extends OptionTypeBase, M extends boolean>(): React.FC<ValueContainerProps<T, M>> {
  return function GenericValueContainerComponent({ children, ...props }) {
    return (
      <components.ValueContainer {...props}>
        <StyledLoupeIcon />
        {children}
      </components.ValueContainer>
    );
  };
}
export default GenericValueContainer;
