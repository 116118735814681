import { useIntl } from "gatsby-plugin-intl";
import React, { useState } from "react";
import GiantTitle from "../../../components/giant-title";
import { LinkProps } from "../../../components/link";
import SectionTitle from "../../../components/section-title";
import ChampionRole from "../../../enums/champion-role";
import { useInView } from "../../../utils/hooks";
import roleMap from "./assets/role-map";
import BackgroundIcon, { IconMap } from "./background-icon";
import { ActionButton, ActionList, ResponsiveWrapper, RoleSelector, SectionInner } from "./style";

export interface Props {
  championListLink: LinkProps & {
    text?: string;
  };
  playLink: LinkProps & {
    text?: string;
  };
  intersectionRoot?: HTMLElement | null;
  className?: string;
}

const ChampionRolesComponent: React.FC<Props> = ({ championListLink, playLink, intersectionRoot, className }) => {
  const intl = useIntl();
  const [activeRole, setActiveRole] = useState<ChampionRole>(ChampionRole.ASSASSIN);

  const [intersectionRef, inView] = useInView({
    root: intersectionRoot,
    threshold: 0,
  });

  const iconMap = (Object.keys(roleMap) as ChampionRole[]).reduce<IconMap>((map, role) => {
    map[role] = roleMap[role].icon;
    return map;
  }, {} as any);
  return (
    <ResponsiveWrapper className={className} data-testid='championroles'>
      <SectionTitle text={intl.formatMessage({ id: "champions.title" })}>
        <SectionInner ref={intersectionRef}>
          <BackgroundIcon activeRole={activeRole} iconMap={iconMap} />

          <GiantTitle
            isActive={inView}
            text={intl.formatMessage({ id: "section.champion-roles.title" })}
            description={intl.formatMessage({ id: "section.champion-roles.description" })}
            transitionDelay={0}
            testId='championroles'
          />

          <ActionList>
            <ActionButton link={championListLink} variant={"secondary"} isActive={inView} transitionDelay={450} testId='championroles:morebutton'>
              {championListLink.text || intl.formatMessage({ id: "discover-more-champions.action" })}
            </ActionButton>
            <ActionButton link={playLink} isActive={inView} transitionDelay={520} testId='championroles:playbutton'>
              {playLink.text || intl.formatMessage({ id: "play-now.action" })}
            </ActionButton>
          </ActionList>

          <RoleSelector isInViewport={inView} activeRole={activeRole} setActiveRole={setActiveRole} roleMap={roleMap} testId='championroles'/>
        </SectionInner>
      </SectionTitle>
    </ResponsiveWrapper>
  );
};

export default ChampionRolesComponent;
