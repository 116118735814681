import React from "react";
import { COLOR_BACKGROUND_DARK } from "../../layouts/default/theme";

import { Wrapper, ImageWrapper, Image, Fade } from "./style";

interface Props {
  images: React.ReactElement[];
  activeImageIndex: number;
  isInViewport: boolean;
  fadeColorTop?: string;
  fadeColorBot?: string;
}

const CyclingImageBackground: React.FC<Props> = ({
  images,
  activeImageIndex,
  isInViewport,
  fadeColorTop = COLOR_BACKGROUND_DARK,
  fadeColorBot = COLOR_BACKGROUND_DARK,
}) => {
  return (
    <Wrapper>
      <ImageWrapper>
        {images.map((originalImage, index) => {
          const isActive = activeImageIndex === index;

          const image = React.cloneElement(originalImage, {
            lazy: !isInViewport || !isActive,
          });

          return (
            <Image key={index} className={`${isActive ? "is-active" : ""}`}>
              {image}
            </Image>
          );
        })}
      </ImageWrapper>
      <Fade className={"upper"} fadeColor={fadeColorTop} />
      <Fade className={"lower"} fadeColor={fadeColorBot} />
    </Wrapper>
  );
};

export default CyclingImageBackground;
