import React from "react";
import { components, OptionTypeBase } from "react-select";
import { OptionProps } from "react-select/src/components/Option";

import ChampionDifficultyRanking from "../../../enums/champion-difficulty-ranking";
import ChampionDifficultyIndicator from "../../indicator/champion-difficulty";

interface OptionType extends OptionTypeBase {
  value: ChampionDifficultyRanking;
  label: string;
}

const OptionChampionDifficulty: React.FC<OptionProps<OptionType, false>> = ({ data, ...props }) => {
  return (
    <components.Option data={data} {...props}>
      <ChampionDifficultyIndicator ranking={data.value} />
    </components.Option>
  );
};

export default OptionChampionDifficulty;
