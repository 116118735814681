import React from "react";

import TippyComponent from "@tippy.js/react";

import { Wrapper, TooltipWrapper, TooltipContent, TooltipTarget } from "./style";
import { useIntl } from "gatsby-plugin-intl";
const Tippy = TooltipWrapper.withComponent(TippyComponent);

interface ToolTipDefinition {
  title: string;
  desc: string;
}
export interface TooltipMap {
  [id: string]: ToolTipDefinition;
}

export interface TooltipperProps {
  text: string;
  tooltipMap: TooltipMap;
  className?: string;
}

const Tooltipper: React.FC<TooltipperProps> = ({ text, tooltipMap, className }) => {
  return <Wrapper className={className}>{convertLineBreakToSpans(convertTextToTooltipJsx(text, tooltipMap))}</Wrapper>;
};

interface ToolTipProps extends ToolTipDefinition {
  // TODO: refactor this whole file to be more compatible with intl.
  children?: any;
}
const ToolTipComponent: React.FC<ToolTipProps> = (props) => {
  const intl = useIntl();
  return (
    <Tippy
      // Use the following to debug:
      // trigger="manual"
      // visible={true}
      maxWidth={320}
      content={
        <TooltipContent>
          <h1>{intl.formatMessage({ id: props.title })}</h1>
          <p>{intl.formatMessage({ id: props.desc })}</p>
        </TooltipContent>
      }
    >
      {props.children}
    </Tippy>
  );
};
const convertTextToTooltipJsx = (text: string, tooltipMap: TooltipMap): Array<JSX.Element | string> => {
  const nodes = [];

  const regex = /\[(.*?)\]\s*\(\s*tooltip\s*:\s*(.*?)\s*\)/g;
  let lastIndex = 0;

  while (true) {
    const match = regex.exec(text);
    if (match === null) break;

    // pre text
    nodes.push(text.substr(lastIndex, match.index - lastIndex));

    const tooltipTargetText = match[1];
    const tooltipId = match[2];
    const tooltip = tooltipMap[tooltipId];

    if (tooltip) {
      // wrap tooltip target
      nodes.push(
        <ToolTipComponent key={match.index} title={tooltip.title} desc={tooltip.desc}>
          <TooltipTarget>{tooltipTargetText}</TooltipTarget>
        </ToolTipComponent>,
      );
    } else {
      // fallback to just showing text without tooltip
      // console.warn('Tooltip not found.', tooltipId)
      nodes.push(tooltipTargetText);
    }

    lastIndex = match.index + match[0].length;
  }

  nodes.push(text.substr(lastIndex));

  return nodes;
};

const convertLineBreakToSpans = (parts: Array<JSX.Element | string>): JSX.Element[] => {
  const paragraphs: JSX.Element[] = [];

  let paragraphInnerParts: Array<JSX.Element | string> = [];
  parts.forEach((part) => {
    if (typeof part !== "string") {
      paragraphInnerParts.push(part);
    } else {
      const partLines = part.split(/[\r\n]+/);
      if (partLines.length > 1) {
        partLines.slice(0, partLines.length - 1).forEach((partLine, i) => {
          paragraphInnerParts.push(partLine);
          paragraphs.push(createLineJsxElement(paragraphInnerParts, i + ""));
          paragraphInnerParts = [];
        });
      }

      paragraphInnerParts.push(partLines[partLines.length - 1]);
    }
  });

  if (paragraphInnerParts.length) {
    paragraphs.push(createLineJsxElement(paragraphInnerParts, "last"));
  }

  return paragraphs;
};

const createLineJsxElement = (children: Array<JSX.Element | string>, key: string) => {
  return (
    <React.Fragment key={key}>
      {" "}
      {React.createElement(
        "span",
        {
          className: "line",
        },
        children,
      )}
    </React.Fragment>
  );
};

export default Tooltipper;
