/**
 * Load script by inserting <script> into the page.
 * @param src Script url
 */
export default function loadScript(src: string): Promise<void> {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => resolve();
    document.body.appendChild(script);
  });
}
