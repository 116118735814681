import React, { useContext, useEffect, useRef, useState } from "react";
import ScrollElement from "../../../../contexts/scroll-element";
import Tween from "../../../../utils/classes/Tween";
import { useResize, useTickerWithScrollActivation } from "../../../../utils/hooks";
import { ease } from "../../../../utils/math";
import Intro from "../../components/intro";
import { ButtonWrapper, Container, IntroWrapper, TitleWrapper } from "./style";
import GiantTitle from "../../../../components/giant-title";
import Button from "../../../../components/button";
import { COLOR_OUTLINE_LIGHT_GREY } from "../../../../layouts/default/theme";
import { useIntl } from "gatsby-plugin-intl";

interface Props {
  isInViewport?: boolean;
  testId?: string;
}

const SCROLL_TO_POSITION = 0.8; // 80% of container height

const HeroText: React.FC<Props> = ({ isInViewport = false, testId }) => {
  const scrollElement = useContext(ScrollElement);
  const containerRef = useRef<any>(null);
  const titleRef = useRef<any>(null);
  const introRef = useRef<any>(null);
  const buttonRef = useRef<any>(null);

  const [containerHeight, setContainerHeight] = useState(0);
  const [isMs] = useState(() => {
    return typeof window !== "undefined" && document.documentElement.classList.contains("is-ms");
  });

  const [passive] = useState({
    introProgress: 0.0,
  });

  const generateScrollTween = () =>
    new Tween(
      1200,
      ease.inQuart,
      (progress) => {
        if (!scrollElement) return;

        const scrollPos = containerHeight * SCROLL_TO_POSITION * progress;

        isMs ? (scrollElement.scrollTop = scrollPos) : scrollElement.scrollTo(0, scrollPos);
      },
      () => {
        /* */
      },
    );

  const [scroll] = useState({ tween: generateScrollTween() });

  const onButtonClick = () => {
    scroll.tween.kill();
    scroll.tween = generateScrollTween();
    scroll.tween.start();
  };

  useResize(() => {
    setContainerHeight(containerRef.current.offsetHeight);
  }, [containerRef]);

  useEffect(() => {
    const introTween = new Tween(
      420.0,
      ease.inOutSine,
      (progress, value) => {
        passive.introProgress = progress;
      },
      () => {
        /* */
      },
    );
    introTween.start(1000);
  }, []);

  useTickerWithScrollActivation(
    containerRef.current,
    ({}, activation) => {
      if (!isInViewport) {
        return;
      }

      titleRef.current.style.transform = `translate3d(
        0,
        ${activation.out * -580.0}px,
        0)`;

      introRef.current.style.transform = `translate3d(
        0,
        ${activation.out * -450.0}px,
        0)`;

      buttonRef.current.style.transform = `translate3d(
        0,
        ${activation.out * -360.0}px,
        0)`;

      introRef.current.style.opacity = passive.introProgress;
    },
    [isInViewport, containerRef, titleRef, buttonRef],
    0.135, // easingFactor
  );
  const intl = useIntl();

  return (
    <Container ref={containerRef}>
      <TitleWrapper ref={titleRef}>
        <GiantTitle
          isActive={isInViewport}
          text={intl.formatMessage({ id: "section.how-to-play.title" })}
          transitionDelay={500}
          testId={testId}
        />
      </TitleWrapper>
      <IntroWrapper ref={introRef}>
        <Intro
          isInViewport
          transitionDelay={1000}
          transitionDuration={2000}
          description={intl.formatMessage({ id: "section.how-to-play.description" })}
          testId={testId}
        />
      </IntroWrapper>
      <ButtonWrapper ref={buttonRef}>
        <Button
          isActive={isInViewport}
          variant={"secondary"}
          onClick={onButtonClick}
          transitionDelay={400}
          hoverDuration={250}
          hoverEase={ease.inOutCubic}
          outlineColor={COLOR_OUTLINE_LIGHT_GREY}
          testId={`${testId}:button`}
        >
          {intl.formatMessage({ id: "lets-go.action" })}
        </Button>
      </ButtonWrapper>
    </Container>
  );
};

export default HeroText;
