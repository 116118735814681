import { useIntl } from "gatsby-plugin-intl";
import React, { useContext, useRef, useState } from "react";
import ButtonSmall from "../../../components/button-small";
import GiantTitle from "../../../components/giant-title";
import { LinkProps } from "../../../components/link";
import SectionTitle from "../../../components/section-title";
import { BREAKPOINT_DESKTOP_NARROW, COLOR_TEXT_WHITE } from "../../../layouts/default/theme";
import { ManagerContext } from "../../../managers";
import { useInView, useResize, useTicker } from "../../../utils/hooks";
import { lerpClamped } from "../../../utils/math";
import SceneBackdrop from "./scene-backdrop";
import { BackdropContainer, Button, Lockup, Section, SectionInner, Title, TitleGroup } from "./style";
import StyleSelector from "./style-selector";

const SectionWithTitle = Section.withComponent(SectionTitle);
const StyledGiantTitle = Title.withComponent(GiantTitle);
const StyledButtonSmall = Button.withComponent(ButtonSmall);

interface Props {
  link: LinkProps & {
    text?: string;
  };
  intersectionRoot?: HTMLElement | null;
}

const ChampionStyle: React.FC<Props> = ({ link, intersectionRoot }) => {
  const intl = useIntl();
  const { viewport, input } = useContext(ManagerContext);

  const titleGroupRef = useRef<any>(null);

  const [intersectionRef, inView] = useInView({
    root: intersectionRoot,
    threshold: 0,
  });

  const [isWiderLayout, setIsWiderLayout] = useState(false);
  // Not updated using setter, updated out of react state for performance reasons
  const [passive] = useState({ x: 0, y: 0 });

  useResize(({ width }) => {
    setIsWiderLayout(width > BREAKPOINT_DESKTOP_NARROW);
  });

  useTicker(() => {
    if (!inView) return;

    const { width, height } = viewport.latest;

    passive.x = isWiderLayout ? lerpClamped(passive.x, input.normal.x * 2 - 1, 0.04, 0.01) : 0;
    passive.y = isWiderLayout ? lerpClamped(passive.y, input.normal.y * 2 - 1, 0.04, 0.01) : 0;

    titleGroupRef.current.style.transform = `translate3d( ${width * 0.01 * -passive.x}px, ${
      height * 0.02 * -passive.y
    }px, 0 )`;
  }, [inView, titleGroupRef]);
  return (
    <SectionWithTitle
      text={intl.formatMessage({ id: "champion-skins.title" })}
      verticalAlign={"center"}
      textColor={COLOR_TEXT_WHITE}
      testId='championstyle'
    >
      <BackdropContainer>
        <SceneBackdrop isInViewport={inView}></SceneBackdrop>
      </BackdropContainer>

      <SectionInner ref={intersectionRef}>
        <Lockup>
          <TitleGroup ref={titleGroupRef} className={`${isWiderLayout ? "is-wider" : ""}`}>
            <StyledGiantTitle
              isActive={inView}
              text={intl.formatMessage({ id: "section.champion-style.title" })}
              toggleContrast={isWiderLayout}
              description={intl.formatMessage({ id: "section.champion-style.description" })}
              testId='championstyle'
            />

            <StyledButtonSmall link={link} isActive={inView} transitionDelay={850} testId='championstyle:playbutton'>
              {link.text || intl.formatMessage({ id: "play-now.action" })}
            </StyledButtonSmall>
          </TitleGroup>

          <StyleSelector isInViewport={inView} testId='championstyle'/>
        </Lockup>
      </SectionInner>
    </SectionWithTitle>
  );
};

export default ChampionStyle;
