import React, { useRef, useState, useEffect } from "react";
import { useInView, useTickerWithScrollActivation, useResize } from "../../../utils/hooks";
import {
  COLOR_OUTLINE_EXTRA_TRANSPARENT_WHITE,
  COLOR_TEXT_WHITE,
  BREAKPOINT_TABLET_ALL,
  COLOR_BACKGROUND_BLACK,
} from "../../../layouts/default/theme";
import { GameMode } from "./typings";
import { Wrapper, Intersection, Section, SectionInner, Dock, TitleGroup, Title, PlayNowButton } from "./style";
import SectionTitle from "../../../components/section-title";
import GiantTitle from "../../../components/giant-title";
import ButtonSmall from "../../../components/button-small";
import HexOutline, { HexTransitionType } from "../../../components/hex-outline";
import CyclingImageBackground from "../../../components/cycling-image-background";
import ModePreview from "./mode-preview";
import ModeDescription from "./mode-description";
import ModeSelector from "./mode-selector";
import { useIntl } from "gatsby-plugin-intl";

export interface Props {
  title?: string;
  heading?: string;
  gameModes: GameMode[];
  intersectionRoot?: HTMLElement | null;
}

const SectionWithTitle = Section.withComponent(SectionTitle);
const StyledButtonSmall = PlayNowButton.withComponent(ButtonSmall);
const StyledGiantTitle = Title.withComponent(GiantTitle);

const CYCLE_DURATION = 7000; // Currently the length of the placeholder videos

const PlayerModes: React.FC<Props> = ({ title, heading, gameModes, intersectionRoot }) => {
  const [intersectionRef, inView] = useInView({
    root: intersectionRoot,
    threshold: 0,
  });

  const wrapperRef = useRef<any>(null);

  const [isWiderLayout, setIsWiderLayout] = useState(false);
  const [activeMode, setActiveMode] = useState<GameMode>(gameModes[0]);

  const [hasInteracted, setHasInteracted] = useState(false);
  const [autoCycling, setAutoCycling] = useState(false);
  const [cycleStartTime, setCycleStartTime] = useState(0);

  useResize(({ width }) => {
    setIsWiderLayout(width > BREAKPOINT_TABLET_ALL);
  });

  useTickerWithScrollActivation(
    wrapperRef.current,
    ({ elapsed }, activation) => {
      if (autoCycling) {
        const mode = gameModes[Math.floor(((elapsed - cycleStartTime) / CYCLE_DURATION) % gameModes.length)];
        if (mode !== activeMode) setActiveMode(mode);
      }

      if (!inView) {
        return;
      }
    },
    [activeMode, autoCycling, cycleStartTime, inView, wrapperRef],
  );

  useEffect(() => {
    inView && !hasInteracted ? setAutoCycling(true) : setAutoCycling(false);
    setCycleStartTime(typeof window === "undefined" ? 0 : performance.now());
  }, [inView]);

  function onModeSelect(mode: GameMode) {
    setActiveMode(mode);
    setAutoCycling(false);
    setHasInteracted(true);
  }
  const intl = useIntl();
  return (
    <SectionWithTitle
      text={title || intl.formatMessage({ id: "game-modes.title" })}
      verticalAlign={"center"}
      textColor={COLOR_TEXT_WHITE}
      testId='gamemodes'
    >
      <Wrapper ref={wrapperRef}>
        <CyclingImageBackground
          isInViewport={inView}
          activeImageIndex={gameModes.indexOf(activeMode)}
          images={gameModes.map(({ backgroundImage }) => backgroundImage)}
          fadeColorTop={COLOR_BACKGROUND_BLACK}
          fadeColorBot={COLOR_BACKGROUND_BLACK}
        />

        <Intersection ref={intersectionRef} />

        <SectionInner>
          <Dock>
            <HexOutline
              isActive={inView}
              clipRightTop={isWiderLayout ? 35 : 0}
              strokeColor={COLOR_OUTLINE_EXTRA_TRANSPARENT_WHITE}
              accentColor={COLOR_OUTLINE_EXTRA_TRANSPARENT_WHITE}
              transition={HexTransitionType.WIRE}
              transitionDuration={2000}
              transitionDelay={550}
            />

            <TitleGroup>
              <StyledGiantTitle
                isActive={inView}
                text={heading || intl.formatMessage({ id: "section.game-modes.title" })}
                transitionDelay={0}
                toggleContrast
                testId='gamemodes'
              />
              <StyledButtonSmall link={activeMode.link} isActive={inView} transitionDelay={350} testId='gamemodes:playbutton'>
                {activeMode.link.text || intl.formatMessage({ id: "play-now.action" })}
              </StyledButtonSmall>
            </TitleGroup>

            <ModePreview
              gameModes={gameModes}
              isInViewport={inView}
              isAutoCycling={autoCycling}
              cycleDuration={CYCLE_DURATION}
              cycleStartTime={cycleStartTime}
              activeMode={activeMode}
              testId='gamemodes'
            />

            <ModeSelector gameModes={gameModes} isInViewport={inView} activeMode={activeMode} onSelect={onModeSelect} testId='gamemodes'/>

            <ModeDescription gameModes={gameModes} activeMode={activeMode} testId='gamemodes'/>
          </Dock>
        </SectionInner>
      </Wrapper>
    </SectionWithTitle>
  );
};

export default PlayerModes;
