import styled from "styled-components";
import { rem } from "../../../../utils/style";
import { MEDIA_MOBILE_ALL, COLOR_BACKGROUND_DARK, COLOR_BACKGROUND_BLACK } from "../../../../layouts/default/theme";

const movement = 70.0; // px
const heightRatio = (1100.0 / 1440.0) /* asset dimensions */ * 100.0;

export const Container = styled.div`
  position: absolute;
  bottom: 0;

  // overflow: hidden;

  width: 100%;
  height: 100%; //calc(${heightRatio}vw);

  // min-height: 100vh;

  perspective: 100px;
  perspective-origin: 50% 100%;
`;

export const LayerContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  @media ${MEDIA_MOBILE_ALL} {
    transform-origin: center bottom;
    transform: scale3d(1.5, 1.5, 1);
  }
`;

export const LayerWrapper = styled.div`
  position: absolute;

  left: -${movement * 0.5}px;
  bottom: 0;

  width: calc(100% + ${movement}px);
  height: calc(${heightRatio}vw + ${movement}px);

  will-change: transform;
`;

export const Layer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  > * {
    width: 100%;
    height: 100%;
  }

  &.gradient {
    &:after {
      content: "";

      background-image: linear-gradient(to bottom, transparent 50%, ${COLOR_BACKGROUND_DARK} 65%);

      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 105%;
    }
  }
`;

export const SceneFader = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: ${COLOR_BACKGROUND_BLACK};
  opacity: 0;
`;
