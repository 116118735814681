import React from "react";
import { SectionNewsLatestProps } from "..";
import { Article as ArticleType, Category as CategoryType } from "../types";
import { ImageAssetUrl } from "../../../../../contentstack/content-types/assets/typings";
import ComponentImage from "../../../../components/image";
import fallbackImageUrl from "../fixtures/articles/5.jpg";
import { UrlFieldType } from "../../../../../contentstack/fields/url";

export interface SectionNewsLatestContentstackProps extends Pick<SectionNewsLatestProps, "sectionTitle"> {
  articles: Array<
    Pick<ArticleType, "category" | "title" | "authors" | "text"> & {
      uid: string;
      date: string;
      link: UrlFieldType;
      imageUrl?: ImageAssetUrl;
    }
  >;
  categories: Array<
    Pick<CategoryType, "text"> & {
      uid: string;
      link: UrlFieldType;
      imageUrl: ImageAssetUrl;
    }
  >;
  linkUrl?: UrlFieldType;
}

export const transformContentstackPropsToSectionProps = ({
  articles,
  categories,
  linkUrl,
  ...props
}: SectionNewsLatestContentstackProps): SectionNewsLatestProps => {
  return {
    ...props,
    sectionTitle: "latest-news.title",
    articles: articles.map(({ uid, date, imageUrl, ...articleProps }) => {
      return {
        ...articleProps,
        id: uid,
        date: new Date(date),
        image: <ComponentImage src={imageUrl ? imageUrl : fallbackImageUrl} objectFit={"cover"} />,
      };
    }),
    categories: categories.map(({ uid, imageUrl, ...categoryProps }) => {
      return {
        ...categoryProps,
        id: uid,
        image: <ComponentImage src={imageUrl} objectFit={"cover"} />,
      };
    }),
    link: linkUrl
      ? {
          ...linkUrl,
          text: "view-latest-news.action",
        }
      : undefined,
  };
};
