import styled from "styled-components";
import { MEDIA_MOBILE_ALL } from "../../../../layouts/default/theme";

const movement = 84.0; // px

const heightRatio = (640.0 / 1440.0) /* asset dimensions */ * 100.0;

export const Container = styled.div`
  position: absolute;
  bottom: 0;

  width: 100%;
  height: 100%;

  perspective: 100px;
  perspective-origin: 50% 100%;

  pointer-events: none;
`;

export const LayerContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  @media ${MEDIA_MOBILE_ALL} {
    transform-origin: center bottom;
    transform: scale3d(1.5, 1.5, 1);
  }
`;

export const LayerWrapper = styled.div`
  position: absolute;

  left: -${movement * 0.5}px;
  bottom: 0;

  width: calc(100% + ${movement}px);
  height: calc(${heightRatio}vw + ${movement}px);

  will-change: transform;
`;

export const Layer = styled.div`
  position: relative;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 0%;

  width: 100%;
  height: 100%;
`;
