import { graphql } from "gatsby";

interface Nodes<T> {
  nodes: T[];
}
export interface PageTranslation {
  publish_details: { locale: string };
  meta_title: string;
  url: {
    url: string;
  };
}
export interface PageTranslationQueryResult {
  pageTranslations: Nodes<PageTranslation>;
}

export const PageTranslationsFragment = graphql`
  fragment PageTranslationsFragment on Query {
    # Find all versions of an entry with a given UID and in a different language.
    # needs $uid and $language parameters to be defined in the query.
    # Note that the default language "en" is explicitly ignored.
    pageTranslations: allContentstackLolComLandingPages(
      filter: { uid: { eq: $uid }, publish_details: { locale: { nin: ["en"] } } }
    ) {
      nodes {
        publish_details {
          locale
        }
        title
        url {
          url
        }
      }
    }
  }
`;

interface LanguageAlternate {
  hrefLang: string;
  href: string;
}

export const pageTranslationToMeta =
  (baseURL: string) =>
  (t: PageTranslation): LanguageAlternate => ({
    hrefLang: t.publish_details.locale,
    href: `${baseURL}${t.publish_details.locale}${t.url.url}`,
  });

/**
 * Convert page translation results into language alternate objects ready for SEO.
 * @param baseURL the base URL for the website.
 * @param pageTranslations
 * @returns
 */
export const pageTranslationsToAlternates = (
  baseURL: string,
  pageTranslations: PageTranslation[],
): LanguageAlternate[] =>
  pageTranslations.map(pageTranslationToMeta(baseURL)).concat({
    hrefLang: "x-default",
    href: `${baseURL}`,
  });
