import React from "react";
import { ChampionExplorerProps } from "..";
import ChampionLane from "../../../../enums/champion-lane";
import * as Assets from "../../../../assets/champion";
import ChampionRole from "../../../../enums/champion-role";

const defaultProps: ChampionExplorerProps = {
  title: "starter-champions.title",
  heading: "section.champion-explorer.heading",
  link: {
    text: "discover-more-champions.action",
    url: "https://na.leagueoflegends.com/",
    internal: false,
  },
  champions: [
    {
      id: "MissFortune",
      name: "champion.missfortune.name",
      title: "champion.missfortune.title",
      description: "champion.missfortune.description",
      difficulty: 1,
      lane: ChampionLane.BOTTOM,
      role: ChampionRole.MARKSMAN,
    },
    {
      id: "Lux",
      name: "champion.lux.name",
      title: "champion.lux.title",
      description: "champion.lux.description",
      difficulty: 5,
      lane: ChampionLane.MIDDLE,
      role: ChampionRole.MAGE,
    },
    {
      id: "Darius",
      name: "champion.darius.name",
      title: "champion.darius.title",
      description: "champion.darius.description",
      difficulty: 2,
      lane: ChampionLane.TOP,
      role: ChampionRole.FIGHTER,
    },
    {
      id: "Ahri",
      name: "champion.ahri.name",
      title: "champion.ahri.title",
      description: "champion.ahri.description",
      difficulty: 5,
      lane: ChampionLane.MIDDLE,
      role: ChampionRole.MAGE,
    },
    {
      id: "MasterYi",
      name: "champion.masteryi.name",
      title: "champion.masteryi.title",
      description: "champion.masteryi.description",
      difficulty: 4,
      lane: ChampionLane.JUNGLE,
      role: ChampionRole.ASSASSIN,
    },
    {
      id: "Garen",
      name: "champion.garen.name",
      title: "champion.garen.title",
      description: "champion.garen.description",
      difficulty: 5,
      lane: ChampionLane.TOP,
      role: ChampionRole.FIGHTER,
    },
    {
      id: "Sona",
      name: "champion.sona.name",
      title: "champion.sona.title",
      description: "champion.sona.description",
      difficulty: 6,
      lane: ChampionLane.BOTTOM,
      role: ChampionRole.SUPPORT,
    },
    {
      id: "Tristana",
      name: "champion.tristana.name",
      title: "champion.tristana.title",
      description: "champion.tristana.description",
      difficulty: 4,
      lane: ChampionLane.BOTTOM,
      role: ChampionRole.MARKSMAN,
    },
    {
      id: "Brand",
      name: "champion.brand.name",
      title: "champion.brand.title",
      description: "champion.brand.description",
      difficulty: 4,
      lane: ChampionLane.MIDDLE,
      role: ChampionRole.MAGE,
    },
    {
      id: "Ekko",
      name: "champion.ekko.name",
      title: "champion.ekko.title",
      description: "champion.ekko.description",
      difficulty: 8,
      lane: ChampionLane.JUNGLE,
      role: ChampionRole.ASSASSIN,
    },
  ].map((champion) => {
    const assetUrl = `https://ddragon.leagueoflegends.com/cdn/img/champion/splash/${champion.id}_0.jpg`;
    return {
      ...champion,
      assets: {
        avatar: assetUrl,
        splash: assetUrl,
      },
    };
  }),
};

export default defaultProps;
