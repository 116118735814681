import React from "react";
import { Props } from "..";
import { UrlFieldType } from "../../../../../contentstack/fields/url";

export interface SectionPlayerGuideContentstackProps {
  primaryLink: UrlFieldType;
  secondaryLink: UrlFieldType;
}

export const transformContentstackPropsToSectionProps = ({
  primaryLink,
  secondaryLink,
  ...otherProps
}: SectionPlayerGuideContentstackProps): Props => {
  return {
    ...otherProps,
    primaryLink,
    secondaryLink,
  };
};
