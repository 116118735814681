import React from "react";
import { Props } from "..";
import { transformContentstackPropsToSectionProps as transformPlayerGuide } from "../../../sections/home/player-guide/transforms/contentstack";
import { UrlFieldType } from "../../../../contentstack/fields/url";

export interface SectionPlayerGuideContentstackProps {
  primaryLink: UrlFieldType;
  secondaryLink: UrlFieldType;
}

export const transformContentstackPropsToSectionProps = (props: SectionPlayerGuideContentstackProps): Props => {
  return transformPlayerGuide(props);
};
