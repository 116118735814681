import styled, { keyframes } from "styled-components";
import { easing } from "../../../../utils/style";
import { MEDIA_MOBILE_ALL } from "../../../../layouts/default/theme";
import { cssLocalizedUppercase } from "../../../../components/css";
import ComponentImage from "../../../../components/image";

const MapAssetEntry = keyframes`
  from {
    opacity: 0;
    transform: translate3d( 0, 5%, 0 ) scale( 0.95, 0.95 );
  }
  to {  
    opacity: 1;
    transform: none;
  }
`;

export const Container = styled.div`
  max-width: 1500px;
  width: 100%;
`;

export const ContainerInner = styled.div`
  box-sizing: border-box;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* played around with numbers until entire ContainerInner2 is within ContainerInner boundary */
  padding: calc(230px - 17%) 10.5% 0;

  @media ${MEDIA_MOBILE_ALL} {
    padding-top: 50px;
  }
`;

export const ContainerInner2 = styled.div<{ cssHeight: string }>`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: ${({ cssHeight }) => cssHeight};
`;

export const MapCamera = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  @media ${MEDIA_MOBILE_ALL} {
    width: 120%;
    left: -10%;
    top: -15%;
  }
`;

export const layerTransform = (progress = 0): string => {
  // return `rotateX(-46deg) skewX(-21deg) translateZ(${progress + 'px'})`
  return `translateY(${progress + "px"})`;
};

const Layer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: ${layerTransform()};
`;

export const BackgroundLayer = styled(Layer)`
  opacity: 0;

  ${Container}.is-activated & {
    transform-origin: center top;
    animation: ${MapAssetEntry} forwards 3.2s 0.1s ${easing.easeOutQuart};
  }
`;

export const BackgroundLayerImage = styled(ComponentImage)`
  width: 100%;
  height: auto;
`;

export const ForegroundLayer = styled(Layer)`
  /* played around with numbers until layer overlaps exactly the map boundaries */
  top: 21%;
  left: 30%;
  width: 45%;
  height: 55%;
`;

export const MockLayerContent = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  ${cssLocalizedUppercase}
  letter-spacing: 0.1em;
  font-weight: bold;
  text-align: center;
`;
