import styled from "styled-components";
import { rem, easing } from "../../../../utils/style";
import { COLOR_TEXT_WHITE, COLUMN_WIDTH } from "../../../../layouts/default/theme";

import { MEDIA_COLUMN_LAYOUT } from "../style";
import {
  cssLocalizedFontFamilySerif,
  cssLocalizedLineHeight,
  cssLocalizedUppercase,
  cssLocalizedItalic,
} from "../../../../components/css";

export const Container = styled.div`
  @media ${MEDIA_COLUMN_LAYOUT} {
    position: relative;
    width: 100%;
    height: 240px;
  }
`;

export const Name = styled.h4`
  margin-top: 0;

  font-size: 30px;
  ${cssLocalizedFontFamilySerif}
  ${cssLocalizedItalic}
  ${cssLocalizedUppercase}

  @media ${MEDIA_COLUMN_LAYOUT} {
    font-size: 24px;
  }
`;

export const Title = styled.h5`
  margin-top: ${rem(5)};

  font-size: ${rem(12)};
  font-weight: 400;

  letter-spacing: 0.05em;
  ${cssLocalizedUppercase}
`;

export const SpecList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  @media ${MEDIA_COLUMN_LAYOUT} {
    display: flex;
    justify-content: center;
    margin-bottom: ${rem(30)};
  }
`;

export const SpecItem = styled.li`
  position: relative;
  display: inline-flex;
  align-items: center;

  margin: ${rem(20)} ${rem(20)} 0 0;

  &.difficulty {
    width: 100%;
    height: 22px;

    > span {
      font-size: ${rem(10)};
    }
  }

  @media ${MEDIA_COLUMN_LAYOUT} {
    flex-direction: column;
    margin: ${rem(20)} 0;

    &,
    &.difficulty {
      width: 25%;
      max-width: 140px;
    }
  }
`;

export const SpecItemLabel = styled.span`
  margin-right: ${rem(5)};

  font-size: ${rem(10)};
  font-weight: 600;
  letter-spacing: 0.05em;
  ${cssLocalizedUppercase}

  @media ${MEDIA_COLUMN_LAYOUT} {
    position: absolute;
    top: calc(100% + 8px);
    margin-right: 0;
  }
`;

export const DifficultyWrapper = styled.span`
  display: inline-block;
  width: 60px;
  margin-left: 5px;
`;

export const IconWrapper = styled.span`
  display: inline-block;
  width: 24px;
  margin-left: 5px;

  @media ${MEDIA_COLUMN_LAYOUT} {
    margin-left: 0;
  }
`;

export const Icon = styled.svg`
  display: inline-block;
  vertical-align: middle;
  width: 22px;
  height: 22px;
  fill: #b29a67;
`;

export const Description = styled.p`
  max-width: 300px;
  font-size: 14px;
  letter-spacing: 0.03em;
  ${cssLocalizedLineHeight(18 / 14)}

  @media ${MEDIA_COLUMN_LAYOUT} {
    display: none;
  }
`;

const BASE_DELAY = 0.1;

export const SpecsWrapper = styled.div`
  position: absolute;
  left: ${rem(COLUMN_WIDTH)};
  top: 50%;
  overflow: hidden;

  margin: -130px auto 0;

  color: ${COLOR_TEXT_WHITE};

  @media ${MEDIA_COLUMN_LAYOUT} {
    top: 0;
    left: 0;
    width: 100%;
    padding: 0;
    margin-top: 0;
    text-align: center;
    transform: none;
  }

  /* Spec animations */
  ${Name},
  ${Title},
  ${DifficultyWrapper},
  ${IconWrapper},
  ${Description} {
    position: relative;

    > span,
    > svg {
      position: absolute;
      left: 0;
      top: 0;

      width: 100%;

      pointer-events: none;
      opacity: 0;
      transition: opacity 0.3s ${easing.easeOutCubic}, transform 0.4s ${easing.easeInExpo};

      &.is-active {
        position: relative;
        pointer-events: auto;
        z-index: 1;
        opacity: 1;
        transform: none;

        transition: opacity 0.5s ${easing.easeOutQuart}, transform 0.5s ${easing.easeOutQuart};
      }
    }
  }

  ${Name} {
    > span {
      transform: translateX(6px);
      &.is-active {
        transition-delay: ${BASE_DELAY}s;
        transition-duration: 0.6s;
      }
    }
  }
  ${Title} {
    > span {
      transform: translateX(6px);
      &.is-active {
        transition-delay: ${BASE_DELAY + 0.06}s;
        transition-duration: 0.7s;
      }
    }
  }
  ${DifficultyWrapper} {
    > span {
      transform: translateX(4px);
      @media ${MEDIA_COLUMN_LAYOUT} {
        transform: translateY(4px);
      }
      &.is-active {
        transition-delay: ${BASE_DELAY + 0.09}s;
        transition-duration: 0.8s;
      }
    }
  }
  ${IconWrapper} {
    > svg {
      transform: translateY(4px);
      &.is-active {
        transition-delay: ${BASE_DELAY + 0.14}s;
        transition-duration: 0.9s;
      }
    }
  }
  ${Description} {
    > span {
      transform: translateY(4px);
      &.is-active {
        transition-delay: ${BASE_DELAY + 0.25}s;
        transition-duration: 1.1s;
      }
    }
  }
`;
