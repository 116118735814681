import { useIntl } from "gatsby-plugin-intl";
import React from "react";
import ArticleExcerpt from "../../../components/article-excerpt";
import Carousel from "../../../components/carousel";
import { LinkProps } from "../../../components/link";
import SectionTitle from "../../../components/section-title";
import { useInView } from "../../../utils/hooks";
import {
  ArticleItem,
  ArticleList,
  Category,
  CategoryItem,
  CategoryList,
  Content,
  Link,
  LinkContainer,
  Main,
  MobileHorizontalSectionTitle,
  ResponsiveWrapper,
} from "./style";
import { Article as ArticleType, Category as CategoryType } from "./types";

export interface SectionNewsLatestProps {
  sectionTitle?: string;
  articles: ArticleType[];
  categories: CategoryType[];
  link?: LinkProps & {
    text: string;
  };
  className?: string;
}

const ARTICLE_STAGING = 200;
const CATEGORY_DELAY = 750;
const CATEGORY_STAGING = 100;

const SectionNewsLatest: React.FC<SectionNewsLatestProps> = ({
  sectionTitle,
  articles,
  categories,
  link,
  className,
}) => {
  const intl = useIntl();
  const [inViewRef, inView] = useInView({ triggerOnce: true, threshold: 0.05 });
  return (
    <ResponsiveWrapper ref={inViewRef} className={inView ? "isVisible" : ""} data-testid="latestnews">
      <SectionTitle text={intl.formatMessage({ id: sectionTitle })} displayOnMobile={false}>
        <MobileHorizontalSectionTitle>{intl.formatMessage({ id: "categories.title" })}</MobileHorizontalSectionTitle>
        <Content>
          <Main>
            <ArticleList>
              {articles.map((article, idx) => {
                return (
                  <ArticleItem key={article.id}>
                    <ArticleExcerpt
                      {...article}
                      isVisible={inView}
                      animationDelay={idx * ARTICLE_STAGING}
                      testId={`latestnews:article-${idx}`}
                    />
                  </ArticleItem>
                );
              })}
            </ArticleList>

            {link && (
              <LinkContainer>
                <Link {...link} testId="latestnews:viewbutton">
                  {intl.formatMessage({ id: link.text })}
                </Link>
              </LinkContainer>
            )}
          </Main>

          <CategoryList>
            {categories.map((category, idx) => {
              return (
                <CategoryItem key={category.id} data-testid={`latestnews:category-${idx}`}>
                  <Category {...category} isVisible={inView} animationDelay={CATEGORY_DELAY + idx * CATEGORY_STAGING} />
                </CategoryItem>
              );
            })}
          </CategoryList>

          <CategoryList className={"is-carousel"}>
            <Carousel>
              {categories.map((category, idx) => {
                return (
                  <CategoryItem key={category.id} data-testid={`latestnews:mobilecategory-${idx}`}>
                    <Category
                      {...category}
                      isVisible={inView}
                      animationDelay={CATEGORY_DELAY + idx * CATEGORY_STAGING}
                    />
                  </CategoryItem>
                );
              })}
            </Carousel>
          </CategoryList>
        </Content>
      </SectionTitle>
    </ResponsiveWrapper>
  );
};

export default SectionNewsLatest;
