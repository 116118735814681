import styled from "styled-components";
import { rem } from "../../../../utils/style";
import {
  MEDIA_TABLET_SMALL,
  MEDIA_MOBILE_ALL,
  MEDIA_MOBILE_MEDIUM,
  MEDIA_DESKTOP_NARROW,
} from "../../../../layouts/default/theme";

export const Wrapper = styled.div`
  box-sizing: border-box;

  position: absolute;
  left: 0;
  top: 0;

  display: flex;
  -ms-flex-align: end; /* IE10 fix */
  align-items: flex-end;
  justify-content: center;

  width: 100%;
  height: 100%;

  padding-bottom: 24vw;

  @media ${MEDIA_TABLET_SMALL} {
    padding-bottom: 34%;
  }

  @media ${MEDIA_MOBILE_ALL} {
    padding-bottom: 44%;
  }

  // @media ${MEDIA_MOBILE_MEDIUM} {
  //   padding-bottom: 100%;
  // }
`;

export const Placement = styled.div`
  position: relative;

  width: 45vw;
  height: 45vw;
  max-width: ${rem(500)};
  max-height: ${rem(500)};

  @media ${MEDIA_DESKTOP_NARROW} {
    width: 34vw;
    height: 34vw;
  }

  @media ${MEDIA_TABLET_SMALL} {
    width: 50vw;
    height: 50vw;
  }
`;

export const HexWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;
`;
