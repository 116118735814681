import React, { useState, useEffect, useRef } from "react";
import { useTickerWithScrollActivation } from "../../utils/hooks";

import { COLOR_OUTLINE_DARK_GREY, COLOR_OUTLINE_WHITE } from "../../layouts/default/theme";
import { TwitchEmbedParams, TwitchEmbedPlayer } from "../../components/twitch/types";

import {
  ReponsiveWrapper,
  WrapperInner,
  GiantTitle,
  Info,
  InfoInner,
  TwitchLogo,
  InfoTitle,
  InfoText,
  InfoButton,
  VideoContainer,
  VideoInner,
  Twitch,
  HexOutlineWide,
  HexOutlineNarrow,
} from "./style";

import SectionTitle from "../../components/section-title";
import { HexTransitionType } from "../../components/hex-outline";
import { LinkProps } from "../../components/link";

export interface Props {
  giantTitle?: string;
  sectionTitle?: string;
  items: VideoItem[];
  className?: string;
}

export interface VideoItem extends Pick<TwitchEmbedParams, "channel" | "video"> {
  title: string;
  text: string;
  action: LinkProps & {
    text: string;
  };
}

const Section: React.FC<Props> = ({ sectionTitle, giantTitle, items }) => {
  const [activeItem, setActiveItem] = useState<VideoItem | null>(null);
  const [player, setPlayer] = useState<TwitchEmbedPlayer | null>(null);
  const [paused, setPaused] = useState<boolean>(true);
  const [playerInView, setPlayerInView] = useState(false);

  const sectionRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  // randomly select active item
  useEffect(() => {
    const index = Math.floor(Math.random() * items.length);
    setActiveItem(items[index]);
  }, []);

  // set player to muted by default
  useEffect(() => {
    if (!player) return;
    player.setMuted(true);
  }, [player]);

  // play only when within view
  useEffect(() => {
    if (!player) return;

    if (playerInView) {
      player.play();
    } else {
      player.pause();
    }
  }, [player, playerInView]);

  useTickerWithScrollActivation(
    sectionRef.current,
    ({}, activation) => {
      if (!wrapperRef.current) return;

      // Scrolled past, out of viewport
      if (activation.direct.out >= 1) {
        setPlayerInView(false);
      } else {
        // Scroll into viewport
        if (activation.direct.in >= 0.1) {
          wrapperRef.current.classList.add("is-activated");
          setPlayerInView(true);
          // Not yet scrolled into viewport
        } else {
          wrapperRef.current.classList.remove("is-activated");
          setPlayerInView(false);
        }
      }
    },
    [wrapperRef],
  );

  return (
    <ReponsiveWrapper ref={sectionRef} hasGiantTitle={!!giantTitle}>
      <SectionTitle text={sectionTitle}>
        {giantTitle ? <GiantTitle isActive={playerInView} text={giantTitle} transitionDelay={0} /> : null}

        <WrapperInner ref={wrapperRef}>
          <Info>
            <InfoInner>
              <TwitchLogo />
              {activeItem && (
                <React.Fragment>
                  <InfoTitle>{activeItem.title}</InfoTitle>
                  <InfoText>{activeItem.text}</InfoText>
                  <InfoButton link={activeItem.action} strokeColor={COLOR_OUTLINE_WHITE} fillColor={"transparent"}>
                    {activeItem.action.text}
                  </InfoButton>
                </React.Fragment>
              )}
            </InfoInner>
          </Info>

          <VideoContainer>
            <VideoInner>
              {activeItem && (
                <Twitch
                  lazy={!playerInView}
                  channel={activeItem.channel}
                  video={activeItem.video}
                  layout="video"
                  width="100%"
                  height="100%"
                  onEmbed={(embed) => {
                    embed.addEventListener("video.ready", () => {
                      setPlayer(embed.player);
                    });
                    embed.addEventListener("video.play", () => setPaused(false));
                    embed.addEventListener("video.pause", () => setPaused(true));
                  }}
                />
              )}
            </VideoInner>
          </VideoContainer>

          <HexOutlineWide
            isActive={playerInView}
            clipRightTop={20}
            offsetVertical={-1}
            offsetHorizontal={1}
            strokeColor={COLOR_OUTLINE_DARK_GREY}
            accentColor={COLOR_OUTLINE_DARK_GREY}
            transition={HexTransitionType.WIRE}
            transitionDuration={1500}
            transitionDelay={800}
          />

          <HexOutlineNarrow
            isActive={playerInView}
            clipRightTop={20}
            offsetVertical={1}
            offsetHorizontal={-1}
            strokeColor={COLOR_OUTLINE_DARK_GREY}
            accentColor={COLOR_OUTLINE_DARK_GREY}
            transition={HexTransitionType.WIRE}
            transitionDuration={1500}
            transitionDelay={800}
          />
        </WrapperInner>
      </SectionTitle>
    </ReponsiveWrapper>
  );
};

export default Section;
