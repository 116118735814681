import React, { useState, useContext, useRef } from "react";

import { useInView, useTickerWithScrollActivation, useTicker, useMedia } from "../../../utils/hooks";
import { mod } from "../../../utils/math";

import { COLOR_TEXT_WHITE } from "../../../layouts/default/theme";

import { Section, SectionStack, BodyInner, BodyWrapper, BodyDock, BodyDisplay, MEDIA_COLUMN_LAYOUT } from "./style";

import SectionTitle from "../../../components/section-title";
import StickyOffsetContext from "../../../contexts/sticky-offset";

import GuideChapter from "./guide-chapter";
import GuideTopic from "./guide-topic";
import GuideMap from "./guide-map";

import { ChapterMap, Chapter, Topic } from "./typings";
import CHAPTER_MAP from "./assets/chapter-map";
import TOOLTIP_MAP from "./assets/tooltip-map";
import { useIntl } from "gatsby-plugin-intl";

// Construct a data list of all topics to be rendered to the map
const ALL_TOPICS: Topic[] = [];
Object.keys(CHAPTER_MAP).forEach((chapter) => {
  const priorTopicCount = ALL_TOPICS.length;
  CHAPTER_MAP[chapter as keyof ChapterMap].topics.forEach((topic: Topic, index: number) => {
    const amended: Topic = {
      topicChapter: chapter as keyof ChapterMap,
      allTopicsIndex: index,
      priorTopicCount,
      ...topic,
    };
    ALL_TOPICS.push(amended);
  });
});

const CHAPTER_COUNT = Object.keys(CHAPTER_MAP).length;

export interface Props {
  stickyOffset?: number;
}

const Guide: React.FC<Props> = ({ stickyOffset }) => {
  const stickyOffsetContext = useContext(StickyOffsetContext);
  if (stickyOffset === undefined) {
    stickyOffset = stickyOffsetContext;
  }
  const [isMs] = useState(() => {
    return typeof window !== "undefined" && document.documentElement.classList.contains("is-ms");
  });

  const [activeChapter, setActiveChapter] = useState<Chapter>(() => Chapter.OBJECTIVES);
  const [isColumnLayout, setIsColumnLayout] = useState(false);
  const [isStuck, setIsStuck] = useState(false);

  const [activeTopicIndexes, setActiveTopicIndexes] = useState<number[]>([0, 0, 0, 0]);

  const updateTopicIndexForChapter = (chapter: Chapter, index: number) => {
    const indexes = [...activeTopicIndexes];
    indexes[Object.keys(CHAPTER_MAP).indexOf(chapter)] = index;
    setActiveTopicIndexes(indexes);
  };

  const updateTopicIndexByDirection = (chapter: Chapter, direction: number) => {
    const newIndex = mod(
      activeTopicIndexes[Object.keys(CHAPTER_MAP).indexOf(chapter)] + direction,
      CHAPTER_MAP[chapter].topics.length,
    );
    updateTopicIndexForChapter(chapter as keyof ChapterMap, newIndex);
  };

  useMedia(MEDIA_COLUMN_LAYOUT, (match) => {
    setIsColumnLayout(match || isMs);
  });

  const [intersectionRef, inView] = useInView({ threshold: 0 });
  const [hasEntered, setHasEntered] = useState(false);

  const objectivesRef = useRef<any>(null);
  const targetsRef = useRef<any>(null);
  const neutralsRef = useRef<any>(null);
  const positionsRef = useRef<any>(null);

  const [position] = useState({ fixed: 0, eased: 0 });

  // Calculate scroll position across section stack
  useTickerWithScrollActivation(
    objectivesRef.current,
    ({}, activation) => {
      if (!hasEntered && activation.direct.in >= 0.25) {
        setHasEntered(true);
      }
    },
    [objectivesRef],
  );
  useTickerWithScrollActivation(
    targetsRef.current,
    ({}, activation) => {
      position.fixed = activation.direct.in;
      position.eased = activation.in;
    },
    [targetsRef],
    0.06,
  );
  useTickerWithScrollActivation(
    neutralsRef.current,
    ({}, activation) => {
      position.fixed += activation.direct.in;
      position.eased += activation.in;
    },
    [positionsRef],
    0.06,
  );
  useTickerWithScrollActivation(
    positionsRef.current,
    ({}, activation) => {
      position.fixed += activation.direct.in;
      position.eased += activation.in;
    },
    [positionsRef],
    0.06,
  );

  // Apply scroll updates
  useTicker(() => {
    const activeChapterIndex = Math.round(position.fixed + 0.25);

    setActiveChapter(Object.keys(CHAPTER_MAP)[activeChapterIndex] as keyof ChapterMap);

    if (!isColumnLayout) {
      setIsStuck(position.fixed > 0 && position.fixed < CHAPTER_COUNT - 1);
    }
  }, [isColumnLayout]);

  const renderBodyDock = (chapter: Chapter) => (
    <BodyDock>
      <GuideChapter
        isActive={(hasEntered && chapter === activeChapter) || isMs}
        title={intl.formatMessage({ id: CHAPTER_MAP[chapter].chapterTitle })}
        desc={intl.formatMessage({ id: CHAPTER_MAP[chapter].chapterDesc })}
        testId={`guidechapter:${chapter.toLowerCase()}`}
      />
      <GuideTopic
        isInViewport={inView}
        isActive={(hasEntered && chapter === activeChapter) || isMs}
        topics={CHAPTER_MAP[chapter].topics}
        chapter={chapter}
        activeTopicIndex={activeTopicIndexes[Object.keys(CHAPTER_MAP).indexOf(chapter)]}
        updateTopic={updateTopicIndexByDirection}
        tooltipMap={TOOLTIP_MAP}
        testId={`guidetopic:${chapter.toLowerCase()}`}
      />
    </BodyDock>
  );

  const renderBodyDisplay = (isColumnInstance: boolean, chapter: Chapter, topics: Topic[]) =>
    (isColumnLayout && isColumnInstance) || (!isColumnLayout && !isColumnInstance) ? (
      <GuideMap
        isColumnInstance={isColumnInstance}
        isInViewport={inView}
        isStuck={isStuck}
        activeChapter={chapter}
        activeTopicIndex={activeTopicIndexes[Object.keys(CHAPTER_MAP).indexOf(chapter)]}
        updateTopic={updateTopicIndexForChapter}
        topics={topics}
      />
    ) : null;
  const intl = useIntl();
  return (
    <Section ref={intersectionRef} chapterCount={CHAPTER_COUNT}>
      <BodyWrapper className={"is-sticky-layout"} stickyOffset={stickyOffset}>
        <BodyInner>
          <BodyDisplay>{renderBodyDisplay(false, activeChapter, ALL_TOPICS)}</BodyDisplay>
        </BodyInner>
      </BodyWrapper>

      <SectionStack className={"is-first"} ref={objectivesRef}>
        <SectionTitle
          text={intl.formatMessage({ id: CHAPTER_MAP.OBJECTIVES.sectionTitle })}
          textColor={COLOR_TEXT_WHITE}
          verticalAlign={"top"}
        >
          <BodyWrapper>
            <BodyInner>
              {renderBodyDock(Chapter.OBJECTIVES)}
              <BodyDisplay>
                {renderBodyDisplay(true, Chapter.OBJECTIVES, CHAPTER_MAP[Chapter.OBJECTIVES].topics)}
              </BodyDisplay>
            </BodyInner>
          </BodyWrapper>
        </SectionTitle>
      </SectionStack>

      <SectionStack className={"is-second"} ref={targetsRef}>
        <SectionTitle
          text={intl.formatMessage({ id: CHAPTER_MAP.TARGETS.sectionTitle })}
          textColor={COLOR_TEXT_WHITE}
          verticalAlign={"top"}
        >
          <BodyWrapper>
            <BodyInner>
              {renderBodyDock(Chapter.TARGETS)}
              <BodyDisplay>{renderBodyDisplay(true, Chapter.TARGETS, CHAPTER_MAP[Chapter.TARGETS].topics)}</BodyDisplay>
            </BodyInner>
          </BodyWrapper>
        </SectionTitle>
      </SectionStack>

      <SectionStack ref={neutralsRef}>
        <SectionTitle
          text={intl.formatMessage({ id: CHAPTER_MAP.NEUTRALS.sectionTitle })}
          textColor={COLOR_TEXT_WHITE}
          verticalAlign={"top"}
        >
          <BodyWrapper>
            <BodyInner>
              {renderBodyDock(Chapter.NEUTRALS)}
              <BodyDisplay>
                {renderBodyDisplay(true, Chapter.NEUTRALS, CHAPTER_MAP[Chapter.NEUTRALS].topics)}
              </BodyDisplay>
            </BodyInner>
          </BodyWrapper>
        </SectionTitle>
      </SectionStack>

      <SectionStack ref={positionsRef}>
        <SectionTitle
          text={intl.formatMessage({ id: CHAPTER_MAP.POSITIONS.sectionTitle })}
          textColor={COLOR_TEXT_WHITE}
          verticalAlign={"top"}
        >
          <BodyWrapper>
            <BodyInner>
              {renderBodyDock(Chapter.POSITIONS)}
              <BodyDisplay>
                {renderBodyDisplay(true, Chapter.POSITIONS, CHAPTER_MAP[Chapter.POSITIONS].topics)}
              </BodyDisplay>
            </BodyInner>
          </BodyWrapper>
        </SectionTitle>
      </SectionStack>
    </Section>
  );
};

export default Guide;
