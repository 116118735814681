import React, { useState, useContext, useRef } from "react";
import { useTicker, useResize } from "../../../../utils/hooks";
import { clamp, distance, ease, lerpClamped } from "../../../../utils/math";
import { easing } from "../../../../utils/style";
import { ManagerContext } from "../../../../managers";

import { CircleWeighted, DiamondOuter, DiamondInner, DiamondAccent } from "../../../../assets/rings";
import { Plus } from "../../../../assets/symbols";

import { HotspotType } from "../typings";
import {
  HotspotWrapper,
  HotspotLens,
  HotspotLensOuter,
  HotspotLensInner,
  HotspotSpecimen,
  HotspotSpecimenInner,
  SvgInactiveRing,
  SvgActiveRingOuter,
  SvgActiveRingInner,
  SvgActiveRingAccent,
  SvgIcon,
} from "./style";

export interface Props {
  hasEntered: boolean;
  isStuck: boolean;
  isVisible: boolean;
  isActive: boolean;
  index: number;
  onClick: () => void;
  type: HotspotType;
  icon?: React.FC;
  asset?: string;
  x: number;
  y: number;
}

const POINTER_RANGE = 400; // In pixels

const defaultIcon = SvgIcon.withComponent(Plus);
const InactiveRing = SvgInactiveRing.withComponent(CircleWeighted);
const ActiveRingOuter = SvgActiveRingOuter.withComponent(DiamondOuter);
const ActiveRingInner = SvgActiveRingInner.withComponent(DiamondInner);
const ActiveRingAccent = SvgActiveRingAccent.withComponent(DiamondAccent);

const GuideMapHotspot: React.FC<Props> = ({
  hasEntered,
  isStuck,
  isVisible,
  isActive,
  index,
  onClick,
  type,
  icon,
  asset,
  x,
  y,
}) => {
  const IconElement = useRef(icon ? SvgIcon.withComponent(icon) : defaultIcon);
  const { viewport, input } = useContext(ManagerContext);
  const lensRef = useRef<any>(null);
  const specimenRef = useRef<any>(null);

  const [origin, setOrigin] = useState({ x: 0, y: 0 });
  // Not updated using setter, updated out of react state for performance reasons
  const [passive] = useState({ x: 0, y: 0 });

  useResize(() => {
    if (type === HotspotType.ICON) return;

    lensRef.current.style.transform = "none";
    const bounds = lensRef.current.getBoundingClientRect();
    setOrigin({
      x: bounds.left + bounds.width / 2,
      y: bounds.top + bounds.height / 2,
    });
  }, [lensRef, specimenRef, isStuck]);

  useTicker(() => {
    if (type === HotspotType.ICON) return;
    if (!isVisible) return;

    const { width, height } = viewport.latest;
    const { client, isTouch } = input.latest;

    const pointerX = (client.x - origin.x) / width;
    const pointerY = (client.y - origin.y) / height;
    const proximity = 1 - Math.min(distance(client.x, client.y, origin.x, origin.y), POINTER_RANGE) / POINTER_RANGE;

    passive.x = isTouch ? 0 : lerpClamped(passive.x, pointerX * ease.inQuart(proximity), 0.07, 0.0005);
    passive.y = isTouch ? 0 : lerpClamped(passive.y, pointerY * ease.inQuart(proximity), 0.07, 0.0005);

    lensRef.current.style.transform = `translate3d( ${width * 0.25 * passive.x}px, ${height * 0.25 * passive.y}px, 0 )`;

    specimenRef.current.style.transform = `translate3d( ${clamp(width * 0.3 * -passive.x, -20, 20)}px, ${clamp(
      height * 0.3 * -passive.y,
      -20,
      20,
    )}px, 0 )`;
  }, [isVisible, isActive, origin, lensRef, specimenRef]);

  return (
    <HotspotWrapper
      x={x}
      y={y}
      index={index}
      onClick={onClick}
      className={`
        ${hasEntered ? "has-entered" : ""} 
        ${isVisible ? "is-visible" : ""} 
        ${isActive ? "is-active" : ""} 
        ${type === HotspotType.ICON ? "is-icon" : ""}
      `}
    >
      <IconElement.current index={index} type={type} />
      <InactiveRing type={type} />
      <HotspotLens ref={lensRef}>
        <HotspotLensOuter>
          <HotspotLensInner>
            <HotspotSpecimen>
              <HotspotSpecimenInner ref={specimenRef} style={{ backgroundImage: `url(${asset})` }} />
            </HotspotSpecimen>
          </HotspotLensInner>
        </HotspotLensOuter>
        <ActiveRingOuter type={type} />
        <ActiveRingInner type={type} />
        <ActiveRingAccent type={type} />
      </HotspotLens>
    </HotspotWrapper>
  );
};

const MemoizedGudieMapHotspot = React.memo(GuideMapHotspot, (oldProps, newProps) => {
  return !(
    oldProps.hasEntered !== newProps.hasEntered ||
    oldProps.isStuck !== newProps.isStuck ||
    oldProps.isVisible !== newProps.isVisible ||
    oldProps.isActive !== newProps.isActive
  );
});

export default MemoizedGudieMapHotspot;
