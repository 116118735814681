import styled from "styled-components";
import { rem, easing } from "../../../../utils/style";
import { COLOR_BACKGROUND_LIGHT_GREY, MEDIA_MOBILE_ALL } from "../../../../layouts/default/theme";
import { SIDE_WIDTH_SMALL_IN_PX } from "../../../../components/section-title";

export const Wrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;

  width: ${rem(550)};
  height: ${rem(550)};
  margin: ${rem(-550 / 2)} 0 0 ${rem(-550 / 2)};

  @media ${MEDIA_MOBILE_ALL} {
    left: 0;
    top: 0;

    width: 100%;
    margin: 0;
  }
`;

export const Icon = styled.svg`
  position: absolute;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;

  fill: ${COLOR_BACKGROUND_LIGHT_GREY};

  opacity: 0;
  transition: opacity 0.4s ${easing.easeInOutCubic};

  &.is-active {
    opacity: 1;
    transition-duration: 1s;
    transition-delay: 0.2s;
  }

  @media ${MEDIA_MOBILE_ALL} {
    width: calc(100% + ${SIDE_WIDTH_SMALL_IN_PX * 2 + "px"});
    height: calc(100% + ${SIDE_WIDTH_SMALL_IN_PX * 2 + "px"});
    margin-left: ${-1 * SIDE_WIDTH_SMALL_IN_PX + "px"};
  }
`;
