import React, { useState, useEffect } from "react";
import { useInView, useTicker } from "../../../utils/hooks";
import {
  COLOR_OUTLINE_EXTRA_TRANSPARENT_WHITE,
  COLOR_TEXT_WHITE,
  COLOR_PRIMARY_BLUE,
} from "../../../layouts/default/theme";

import SectionTitle from "../../../components/section-title";
import ButtonSmall from "../../../components/button-small";
import HexOutline, { HexTransitionType } from "../../../components/hex-outline";
import GiantTitle from "../../../components/giant-title";

import ChampionAvatar from "./champion-avatar";
import ChampionSpecs from "./champion-specs";
import ChampionSelector from "./champion-selector";

import { Champion } from "./typings";
import { Section, SectionInner, Dock, Hex, TitleGroup, CtaButton, ChampionBackgroundImage } from "./style";
import CyclingImageBackground from "../../../components/cycling-image-background";
import { LinkProps } from "../../../components/link";
import { useIntl } from "gatsby-plugin-intl";

export interface ChampionExplorerProps {
  title: string;
  heading: string;
  link?: LinkProps & {
    text: string;
  };
  champions: Champion[];
  intersectionRoot?: HTMLElement | null;
  className?: string;
}

const StyledSectionTitle = SectionInner.withComponent(SectionTitle);
const StyledHexOutline = Hex.withComponent(HexOutline);
const StyledButtonSmall = CtaButton.withComponent(ButtonSmall);

const CYCLE_DURATION = 7000;

const ChampionExplorer: React.FC<ChampionExplorerProps> = ({
  title,
  heading,
  link,
  champions,
  intersectionRoot,
  className,
}) => {
  const [intersectionRef, inView] = useInView({
    root: intersectionRoot,
    threshold: 0.2,
  });

  const [activeChampion, setActiveChampion] = useState<Champion>(champions[0]);
  const [hasEntered, setHasEntered] = useState(false);
  const [hasInteracted, setHasInteracted] = useState(false);
  const [autoCycling, setAutoCycling] = useState(false);
  const [cycleStartTime, setCycleStartTime] = useState(0);

  useTicker(
    ({ elapsed }) => {
      if (autoCycling) {
        const champion = Object.values(champions)[
          Math.floor(((elapsed - cycleStartTime) / CYCLE_DURATION) % champions.length)
        ];
        if (champion.id !== activeChampion.id) setActiveChampion(champion);
      }
    },
    [activeChampion, autoCycling],
  );

  useEffect(() => {
    if (inView && !hasEntered) setHasEntered(true);
    inView && !hasInteracted ? setAutoCycling(true) : setAutoCycling(false);
    setCycleStartTime(typeof window === "undefined" ? 0 : performance.now());
  }, [inView]);

  function onChampionSelect(champion: Champion) {
    setActiveChampion(champion);
    setAutoCycling(false);
    setHasInteracted(true);
  }
  const intl = useIntl();
  return (
    <Section className={className} ref={intersectionRef} data-testid='meetchampions'>
      <CyclingImageBackground
        fadeColorTop={"transparent"}
        images={champions.map((champ, i) => (
          <ChampionBackgroundImage key={i} style={{ backgroundImage: `url(${champ.assets.splash})` }} />
        ))}
        activeImageIndex={champions.indexOf(activeChampion)}
        isInViewport={true}
      />

      <StyledSectionTitle
        text={intl.formatMessage({ id: title })}
        textColor={COLOR_TEXT_WHITE}
        verticalAlign={"center"}
      >
        <Dock>
          <StyledHexOutline
            isActive={hasEntered}
            clipRightTop={25}
            strokeColor={COLOR_OUTLINE_EXTRA_TRANSPARENT_WHITE}
            accentColor={COLOR_OUTLINE_EXTRA_TRANSPARENT_WHITE}
            transition={HexTransitionType.WIRE}
            transitionDuration={1500}
            transitionDelay={500}
          />

          <TitleGroup>
            <GiantTitle
              isActive={hasEntered}
              toggleContrast
              text={intl.formatMessage({ id: heading })}
              testId='meetchampions'
              // transitionDelay={200}
            />
          </TitleGroup>

          <ChampionAvatar
            isInViewport={inView}
            isAutoCycling={autoCycling}
            cycleDuration={CYCLE_DURATION}
            cycleStartTime={cycleStartTime}
            activeChampion={activeChampion}
            champions={champions}
            testId='meetchampions'
          />

          <ChampionSelector 
          isInViewport={inView} 
          activeChampionIndex={champions.indexOf(activeChampion)}
          champions={champions} 
          onSelect={onChampionSelect} 
          testId='meetchampions'
          />

          <ChampionSpecs champions={champions} activeChampion={activeChampion} testId='meetchampions:specs'>
            {link && (
              <StyledButtonSmall
                link={link}
                isActive={inView}
                strokeColor={COLOR_PRIMARY_BLUE}
                fillColor={"transparent"}
                testId='meetchampions:discoverbutton'
              >
                {intl.formatMessage({ id: link.text })}
              </StyledButtonSmall>
            )}
          </ChampionSpecs>
        </Dock>
      </StyledSectionTitle>
    </Section>
  );
};

export default ChampionExplorer;
export { default as defaultProps } from "./assets/default-props";
