import { SectionType } from "../../../../lib/templates/page/typings";
import { TemplateContextData, ContentstackSection } from "../templates/default/typings";
import { ContentstackAllLandingPagesQuery as Query } from "../../../../generated/graphql";
import { ImageAssetUrl } from "../../assets/typings";
import contentstackNodeToVideoData from "../../html5-videos/transforms/contentstack-node-to-video-data";
import notEmpty from "../../../utils/not-empty";
import ContentstackArticleClass from "../../articles/class";
import Social from "../../../../lib/enums/social";
import {
  modularBlockFeaturedNews,
  modularBlockFooterVideo,
  modularBlockHighlights,
  modularBlockNewsHeader,
  modularBlockPromo,
} from "./modular-blocks";
import contentstackSectionItemsToComponentSectionItems from "./contentstack-section-items-to-component-section-items";
import { isChampionRole } from "../../../../lib/enums/champion-role";
import { articleDataToArticle } from "../../categories/transforms/modular-blocks/filtered-news";
import { UrlFieldType } from "../../../fields/url";
import isInternalUrl from "../../../utils/is-internal-url";

const transform = (
  bcp47Locale: string,
  node: Query["all"]["edges"][0]["node"],
  configurationNode: Query["configuration"]["edges"][0]["node"],
  championNodes: Array<Query["allChampions"]["edges"][0]["node"]>,
  articleNodes: Array<Query["allArticles"]["edges"][0]["node"]>,
): TemplateContextData => {
  // transform sections
  const sections: ContentstackSection[] = node.modular_blocks
    .map((block) => blockToContentstackSection(bcp47Locale, block, configurationNode, championNodes, articleNodes))
    .filter((section: ContentstackSection | null): section is ContentstackSection => section !== null);

  // EXCEPTION: Use category intro section as intro for "Latest News" page.
  if (configurationNode.pages.latest_news[0] && node.uid === configurationNode.pages.latest_news[0].uid) {
    sections.unshift({
      type: SectionType.CATEGORY_INTRO,
      props: {
        title: node.title,
        blurb: node.meta_description,
      },
    });
  }

  return {
    uid: node.uid,
    title: node.title,
    description: node.meta_description,
    meta: {
      title: node.meta_title,
    },
    image: !node.meta_image
      ? null
      : {
          url: node.meta_image.url as ImageAssetUrl,
        },
    sections,
  };
};

export default transform;

const blockToContentstackSection = (
  bcp47Locale: string,
  section: Query["all"]["edges"][0]["node"]["modular_blocks"][0],
  configurationNode: Query["configuration"]["edges"][0]["node"],
  championNodes: Array<Query["allChampions"]["edges"][0]["node"]>,
  articleNodes: Array<Query["allArticles"]["edges"][0]["node"]>,
): ContentstackSection | null => {
  const keys = Object.keys(section) as Array<keyof Query["all"]["edges"][0]["node"]["modular_blocks"][0]>;

  for (const key of keys) {
    if (!section[key]) continue;

    switch (key) {
      case "footer_video":
        return TRANSFORM_MAP[key](section[key], {
          url: {
            url: configurationNode.urls.game_download,
            internal: false,
          },
        });
      case "game_modes_module":
        return TRANSFORM_MAP[key](section[key]);
      case "hardcoded_module":
        return TRANSFORM_MAP[key](bcp47Locale, section[key], configurationNode, championNodes, articleNodes);
      case "hero":
        return TRANSFORM_MAP[key](section[key]);
      case "featured_news":
        return TRANSFORM_MAP[key](section[key]);
      case "highlights":
        return TRANSFORM_MAP[key](section[key]);
      case "latest_news":
        return TRANSFORM_MAP[key](section[key], {
          viewMoreUrl: configurationNode.pages.latest_news[0]
            ? {
                url: configurationNode.pages.latest_news[0].url.url,
                internal: true,
              }
            : undefined,
        });
      case "popular_external":
        return TRANSFORM_MAP[key](section[key]);
      case "popular_internal":
        return TRANSFORM_MAP[key](section[key]);
      case "promo_module":
        return TRANSFORM_MAP[key](section[key]);
      case "social_divider":
        return TRANSFORM_MAP[key](section[key]);
      case "twitch_featured":
        return TRANSFORM_MAP[key](section[key]);
      case "two_column_featured":
        return TRANSFORM_MAP[key](section[key]);
      case "youtube_channel":
        return TRANSFORM_MAP[key](section[key]);

      default:
        console.warn(`Section not integrated. (section: ${key})`);
    }
  }

  return null;
};

const TRANSFORM_MAP = {
  footer_video: (
    section: Query["all"]["edges"][0]["node"]["modular_blocks"][0]["footer_video"],
    options: {
      url: UrlFieldType;
    },
  ): ContentstackSection | null => {
    if (!section) return null;

    const props = modularBlockFooterVideo(section, { url: options.url });
    if (!props) return null;

    return {
      type: SectionType.DOWNLOAD_GAME_BASIC,
      props,
    };
  },
  game_modes_module: (
    section: Query["all"]["edges"][0]["node"]["modular_blocks"][0]["game_modes_module"],
  ): ContentstackSection | null => {
    if (!section) return null;

    const gameModes = section.videos
      .map((gameMode, gameModeIndex) => {
        if (!gameMode.video) return null;
        if (!gameMode.background_image) return null;
        if (!gameMode.thumbnail_image) return null;

        const videos = gameMode.video
          .filter(notEmpty)
          .map((entry) => contentstackNodeToVideoData(entry))
          .filter(notEmpty);

        if (videos.length <= 0) return null;

        const url = gameMode.url ? gameMode.url : section.cta.href;

        return {
          id: `mode-${gameModeIndex}`,
          link: {
            url,
            text: section.cta.title ? section.cta.title : undefined,
            internal: isInternalUrl(url),
          },
          name: gameMode.title,
          headline: gameMode.subtitle,
          description: gameMode.description,
          backgroundImageUrl: gameMode.background_image.url as ImageAssetUrl,
          thumbnailImageUrl: gameMode.thumbnail_image.url as ImageAssetUrl,
          videos,
        };
      })
      .filter(notEmpty);

    if (gameModes.length <= 0) return null;

    return {
      type: SectionType.GAME_MODES,
      props: {
        title: section.label,
        heading: section.title,
        gameModes,
      },
    };
  },
  hardcoded_module: (
    bcp47Locale: string,
    section: Query["all"]["edges"][0]["node"]["modular_blocks"][0]["hardcoded_module"],
    configurationNode: Query["configuration"]["edges"][0]["node"],
    champions: Array<Query["allChampions"]["edges"][0]["node"]>,
    articleNodes: Array<Query["allArticles"]["edges"][0]["node"]>,
  ): ContentstackSection | null => {
    if (!section) return null;
    if (!section.hardcoded_module_selection) return null;
    if (!section.hardcoded_module_selection[0]) return null;

    const module_id = section.hardcoded_module_selection[0].module_id;

    switch (module_id) {
      case "all-news":
        if (!configurationNode.pages.champion_list[0]) return null;

        const articles = articleNodes.map((articleNode) => articleDataToArticle(articleNode)).filter(notEmpty);

        if (articles.length <= 0) return null;

        return {
          type: SectionType.CATEGORY_ARTICLE_LIST_CONTENTSTACK,
          props: {
            articles,
            articlesPerPage: 12,
            terms: [],
          },
        };

      case "section-champion-list":
        const localizedCompare = new Intl.Collator(bcp47Locale).compare;
        return {
          type: SectionType.CHAMPION_LIST,
          props: {
            champions: champions
              .sort((a, b) => localizedCompare(a.champion_name, b.champion_name))
              .map(({ uid, champion_name, champion_splash, difficulty, recommended_roles, url, champion }) => {
                return {
                  id: uid,
                  name: champion_name,
                  link: {
                    url,
                    internal: true,
                  },
                  difficulty,
                  splashImageUrl: champion_splash,
                  profileImageUrl:
                    champion && champion.profile_image && champion.profile_image.url
                      ? (champion.profile_image.url as ImageAssetUrl)
                      : undefined,
                  roles: recommended_roles
                    ? recommended_roles
                        .map((role) => {
                          if (isChampionRole(role)) return role;
                          return null;
                        })
                        .filter(notEmpty)
                    : [],
                };
              }),
          },
        };

      case "section-groups-how-to-play":
        if (!configurationNode.pages.champion_list[0]) return null;

        return {
          type: SectionType.HOW_TO_PLAY,
          props: {
            championListUrl: {
              url: configurationNode.pages.champion_list[0].url.url,
              internal: true,
            },
          },
        };

      case "section-groups-player-guide":
        if (!configurationNode.pages.how_to_play_guide[0]) return null;

        return {
          type: SectionType.PLAYER_GUIDE,
          props: {
            primaryLink: {
              url: configurationNode.pages.how_to_play_guide[0].url.url,
              internal: true,
            },
            secondaryLink: {
              url: configurationNode.urls.game_download,
              internal: false,
            },
          },
        };

      case "section-groups-game-intro":
        if (!configurationNode.pages.champion_list[0]) return null;

        return {
          type: SectionType.GAME_INTRO,
          props: {
            championListLink: {
              url: configurationNode.pages.champion_list[0].url.url,
              internal: true,
            },
            playLink: {
              url: configurationNode.urls.game_download,
              internal: false,
            },
          },
        };

      case "section-home-hero":
        return {
          type: SectionType.HOME_HERO,
          props: {
            link: {
              url: configurationNode.urls.game_download,
              internal: false,
            },
          },
        };
      default:
        console.warn(`Unsupported hardcoded module. (module_id: ${module_id})`);
        return null;
    }
  },
  hero: (section: Query["all"]["edges"][0]["node"]["modular_blocks"][0]["hero"]): ContentstackSection | null => {
    if (!section) return null;

    const props = modularBlockNewsHeader(section);
    if (!props) return null;

    return {
      type: SectionType.NEWS_HEADER,
      props,
    };
  },
  featured_news: (
    section: Query["all"]["edges"][0]["node"]["modular_blocks"][0]["featured_news"],
  ): ContentstackSection | null => {
    if (!section) return null;

    const props = modularBlockFeaturedNews(section);
    if (!props) return null;

    return {
      type: SectionType.NEWS_ITEM_LIST,
      props,
    };
  },
  highlights: (
    section: Query["all"]["edges"][0]["node"]["modular_blocks"][0]["highlights"],
  ): ContentstackSection | null => {
    if (!section) return null;

    const props = modularBlockHighlights(section);
    if (!props) return null;

    return {
      type: SectionType.NEWS_HIGHLIGHTS_LIST,
      props,
    };
  },
  latest_news: (
    section: Query["all"]["edges"][0]["node"]["modular_blocks"][0]["latest_news"],
    options: {
      viewMoreUrl?: UrlFieldType;
    },
  ): ContentstackSection | null => {
    if (!section) return null;

    return {
      type: SectionType.NEWS_LATEST,
      props: {
        articles: section.articles.map((articleData) => {
          const article = new ContentstackArticleClass(articleData);
          return {
            uid: article.uid,
            category: article.categoryTitle,
            title: article.title,
            link: {
              url: article.url,
              internal: article.internal,
            },
            authors: article.authors.map(({ title }) => title),
            date: article.publishDate.toISOString(),
            text: article.description,
            imageUrl: article.bannerUrl,
          };
        }),
        categories: section.category_selection
          .map((item) => {
            const catTag = item.category_selection[0];

            if (!catTag) return null;
            if (!item.category_thumbnail) return null;

            return {
              uid: catTag.uid,
              text: catTag.title,
              link: {
                url: catTag.url.url,
                internal: true,
              },
              imageUrl: item.category_thumbnail.url as ImageAssetUrl,
            };
          })
          .filter(notEmpty),
        linkUrl: options.viewMoreUrl,
      },
    };
  },
  popular_external: (
    section: Query["all"]["edges"][0]["node"]["modular_blocks"][0]["popular_external"],
  ): ContentstackSection | null => {
    if (!section) return null;

    return {
      type: SectionType.NEWS_POPULAR,
      props: {
        sectionTitle: section.label,
        articles: section.items
          .map(({ link, banner }) => {
            if (!banner) return null;

            return {
              url: {
                url: link.href,
                internal: isInternalUrl(link.href),
              },
              image: {
                uid: banner.uid,
                alt: link.title || undefined,
                url: banner.url as ImageAssetUrl,
                dimension:
                  banner.fields &&
                  banner.fields.dimension &&
                  banner.fields.dimension.width &&
                  banner.fields.dimension.height
                    ? {
                        width: banner.fields.dimension.width,
                        height: banner.fields.dimension.height,
                      }
                    : undefined,
              },
            };
          })
          .filter(notEmpty),
      },
    };
  },
  popular_internal: (
    section: Query["all"]["edges"][0]["node"]["modular_blocks"][0]["popular_internal"],
  ): ContentstackSection | null => {
    if (!section) return null;

    return {
      type: SectionType.NEWS_POPULAR,
      props: {
        sectionTitle: section.label,
        link: section.cta
          ? {
              url: section.cta.href,
              text: section.cta.title || section.cta.href,
              internal: isInternalUrl(section.cta.href),
            }
          : undefined,
        articles: section.items
          .map(({ banner: bannerEntry, content_entry }) => {
            const entry = content_entry[0];
            if (!entry) return null;

            let article;

            switch (entry.__typename) {
              case "Contentstack_articles":
                const cArticle = new ContentstackArticleClass(entry);

                const banner = bannerEntry ? bannerEntry : entry.banner ? entry.banner : undefined;

                if (!banner) return null;

                article = {
                  uid: cArticle.uid,
                  url: {
                    url: cArticle.url,
                    internal: cArticle.internal,
                  },
                  category: cArticle.categoryTitle,
                  title: cArticle.title,
                  image: {
                    uid: banner.uid,
                    url: banner.url as ImageAssetUrl,
                    dimension:
                      banner.fields &&
                      banner.fields.dimension &&
                      banner.fields.dimension.width &&
                      banner.fields.dimension.height
                        ? {
                            width: banner.fields.dimension.width,
                            height: banner.fields.dimension.height,
                          }
                        : undefined,
                  },
                };
                break;
            }

            return article;
          })
          .filter(notEmpty),
      },
    };
  },
  promo_module: (
    section: Query["all"]["edges"][0]["node"]["modular_blocks"][0]["promo_module"],
  ): ContentstackSection | null => {
    if (!section) return null;

    const props = modularBlockPromo(section);
    if (!props) return null;

    return {
      type: SectionType.PROMO,
      props,
    };
  },
  social_divider: (
    section: Query["all"]["edges"][0]["node"]["modular_blocks"][0]["social_divider"],
  ): ContentstackSection | null => {
    if (!section) return null;

    const icons = section.items
      .map((item) => {
        let type;
        switch (item.icon) {
          case "Discord":
            type = Social.DISCORD;
            break;
          case "Facebook":
            type = Social.FACEBOOK;
            break;
          case "Instagram":
            type = Social.INSTAGRAM;
            break;
          case "Reddit":
            type = Social.REDDIT;
            break;
          case "Twitter":
            type = Social.TWITTER;
            break;
          case "VK":
            type = Social.VK;
            break;
          case "Line":
            type = Social.LINE;
            break;
          case "YouTube":
            type = Social.YOUTUBE;
            break;
          case "Twitch":
            type = Social.TWITCH;
            break;
          default:
            return null;
        }
        return {
          type,
          link: item.url,
          target: "_blank",
        };
      })
      .filter(notEmpty);

    if (icons.length <= 0) return null;

    return {
      type: SectionType.NEWS_SOCIAL,
      props: {
        text: section.title,
        icons,
      },
    };
  },
  twitch_featured: (
    section: Query["all"]["edges"][0]["node"]["modular_blocks"][0]["twitch_featured"],
  ): ContentstackSection | null => {
    if (!section) return null;

    return {
      type: SectionType.TWITCH,
      props: {
        sectionTitle: section.label,
        giantTitle: section.title || undefined,
        items: section.twitch_streams.map(({ description, link, title: itemTitle, type, video_id }) => {
          return {
            channel: type === "Channel" ? video_id : undefined,
            video: type === "Video" ? video_id : undefined,
            title: itemTitle,
            text: description,
            link: {
              text: link.title ? link.title : link.href,
              url: link.href,
              internal: isInternalUrl(link.href),
            },
          };
        }),
      },
    };
  },
  youtube_channel: (
    section: Query["all"]["edges"][0]["node"]["modular_blocks"][0]["youtube_channel"],
  ): ContentstackSection | null => {
    if (!section) return null;
    if (!section.channel) return null;
    if (section.videos.length <= 0) return null;

    return {
      type: SectionType.NEWS_YOUTUBE,
      props: {
        sectionTitle: section.label || undefined,
        url: {
          url: `https://www.youtube.com/${
            section.channel.customUrl ? section.channel.customUrl : `channel/${section.channel.channelId}`
          }/`,
          internal: false,
        },
        title: section.name || section.channel.title,
        videos: section.videos.map((video) => {
          return {
            id: video.videoId,
            title: video.title,
            description: video.description,
            thumbnails: video.thumbnails,
          };
        }),
      },
    };
  },
  two_column_featured: (
    section: Query["all"]["edges"][0]["node"]["modular_blocks"][0]["two_column_featured"],
  ): ContentstackSection | null => {
    if (!section) return null;

    const items = contentstackSectionItemsToComponentSectionItems(section.items);

    // don't show section if empty
    if (items.length < 1) return null;

    return {
      type: SectionType.PROMO_LIST,
      props: {
        giantTitle: section.title || undefined,
        sectionTitle: section.label,
        promoItems: items.slice(0, 2),
      },
    };
  },
};
