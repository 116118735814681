import styled, { keyframes, css } from "styled-components";
import { rem, easing } from "../../../../utils/style";
import { MEDIA_MOBILE_ALL, COLOR_OUTLINE_TRANSPARENT_WHITE } from "../../../../layouts/default/theme";
import VideoComponent from "../../../../components/video";
import { PREVIEW_VIDEO_SIZE_REM } from "../style";

export const TRANSITION_DURATION_IN_MS = 500;

export const Wrapper = styled.div`
  position: absolute;
  left: calc((100% - ${PREVIEW_VIDEO_SIZE_REM}) / 2);
  top: ${rem(-260)};

  width: ${PREVIEW_VIDEO_SIZE_REM};
  height: ${PREVIEW_VIDEO_SIZE_REM};

  @media ${MEDIA_MOBILE_ALL} {
    left: calc(10vw - ${rem(10)});
    top: -40vw;

    width: 80vw;
    height: 80vw;
  }
`;

export const VideoContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;

  z-index: 1;

  width: 100%;
  height: 100%;

  overflow: hidden;
  border-radius: 50%;

  @media ${MEDIA_MOBILE_ALL} {
    /* Resolve border-radius + overflow in Android Chrome */
    transform: rotate(0.1deg);
  }
`;

export const Video = styled(VideoComponent)`
  position: absolute;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;

  overflow: hidden;
  border-radius: 50%;

  &.is-active {
    z-index: 1;
  }
`;

export const Hex = styled.div`
  z-index: 2;
`;

const ringEnter = keyframes`
  0% {
    opacity: 1;
    transform: rotate( 10deg ) scale( 0.98, 0.98 );
  }
  100% {
    opacity: 0;
    transform: none;
  }
`;

export const OuterRing = styled.svg`
  position: absolute;
  left: -2%;
  top: -2%;

  z-index: 2;

  width: 104%;
  height: 104%;

  fill: none;
  stroke: ${COLOR_OUTLINE_TRANSPARENT_WHITE};
  stroke-width: 0.25;

  opacity: 0;

  ${Wrapper}.animate-enter & {
    animation: ${ringEnter} forwards 2s 0s ${easing.easeInOutCubic};
  }
`;

const dialEnter = keyframes`
  0% {
    opacity: 0;
    transform: rotate( 10deg ) scale3d( 0.98, 0.98, 1 );
  }
  100% {
    opacity: 1;
    transform: none;
  }
`;

const dialRotate = keyframes`
  0% {
    transform: scale3d( 0.985, 0.985, 1 ) rotate( 0 );
  }
  50% {
    transform: scale3d( 1, 1, 1 ) rotate( 180deg );
  }
  100% {
    transform: scale3d( 0.985, 0.985, 1 ) rotate( 360deg );
  }
`;

export const DialRingWrapper = styled.div`
  overflow: hidden;

  position: absolute;
  left: -5%;
  top: -5%;

  z-index: 2;

  width: 110%;
  height: 110%;

  pointer-events: none;

  opacity: 0;

  ${Wrapper}.animate-enter & {
    animation: ${dialEnter} forwards 3s 1s ${easing.easeOutQuart};
  }
`;

export const DialRing = styled.svg`
  position: absolute;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;

  fill: ${COLOR_OUTLINE_TRANSPARENT_WHITE};

  animation: ${dialRotate} forwards infinite 180s linear;
`;
