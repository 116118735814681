import styled, { css } from "styled-components";
import { rem } from "../../../../utils/style";
import { COLOR_OUTLINE_LIGHT_GREY, MEDIA_MOBILE_ALL } from "../../../../layouts/default/theme";
import {
  cssBasicLayout as cssSelectorBasicLayout,
  DISTANCE_FROM_BOTTOM_TO_BASELINE as SELECTOR_DISTANCE_FROM_BOTTOM_TO_BASELINE,
} from "../../../../components/selector";

import RoleSelectorCircleComponent from "../role-selector-circle";
import RolePreviewComponent from "../role-preview";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const RoleWrapper = styled.nav`
  position: relative;
  flex: 2 1 150%;
  transform: translateY(calc(-50% + ${SELECTOR_DISTANCE_FROM_BOTTOM_TO_BASELINE}));

  @media ${MEDIA_MOBILE_ALL} {
    transform: none;
  }
`;

export const RoleSelectorCircle = styled(RoleSelectorCircleComponent)`
  flex: 1 1 140%;
`;

export const RolePreview = styled(RolePreviewComponent)`
  position: absolute;
  top: 135%;
  left: 50%;
  width: 80%;
  transform: translateX(-50%);
`;

export const cssColumnLayout = css`
  ${Wrapper} {
    display: block;
  }

  ${RoleWrapper} {
    ${cssSelectorBasicLayout}

    flex: 1 1 auto;
    border-top: 1px solid ${COLOR_OUTLINE_LIGHT_GREY};
    border-bottom: 1px solid ${COLOR_OUTLINE_LIGHT_GREY};
  }

  ${RoleSelectorCircle} {
    box-sizing: border-box;
    max-width: 500px;
    margin: 25px auto 0;
    padding: 0 20px;

    @media ${MEDIA_MOBILE_ALL} {
      margin-top: ${rem(40)};
      width: calc(100% - ${rem(20)});
    }
  }

  ${RolePreview} {
    display: none;
  }
`;
