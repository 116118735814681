import React, { ReactElement } from "react";
import Social from "../../../enums/social";
import { socialNameMap, socialSvgMap } from "../../../assets/social";
import {
  SocialContent,
  Icons,
  Link,
  Text,
  SocialIcon,
  StyledHexOutline,
  ResponsiveWrapper,
  SocialContentWrapper,
} from "./style";
import { useIntl } from "gatsby-plugin-intl";

export interface SectionNewsSocialProps {
  className?: string;
  text: string;
  icons: SocialIcon[];
}

export interface SocialIcon {
  type: Social;
  text?: string;
  link: string;
  target?: string;
}

const iconComponentMap = {
  [Social.DISCORD]: SocialIcon.withComponent(socialSvgMap[Social.DISCORD]),
  [Social.FACEBOOK]: SocialIcon.withComponent(socialSvgMap[Social.FACEBOOK]),
  [Social.INSTAGRAM]: SocialIcon.withComponent(socialSvgMap[Social.INSTAGRAM]),
  [Social.REDDIT]: SocialIcon.withComponent(socialSvgMap[Social.REDDIT]),
  [Social.TWITTER]: SocialIcon.withComponent(socialSvgMap[Social.TWITTER]),
  [Social.VK]: SocialIcon.withComponent(socialSvgMap[Social.VK]),
  [Social.LINE]: SocialIcon.withComponent(socialSvgMap[Social.LINE]),
  [Social.YOUTUBE]: SocialIcon.withComponent(socialSvgMap[Social.YOUTUBE]),
  [Social.TWITCH]: SocialIcon.withComponent(socialSvgMap[Social.TWITCH]),
};

const SectionNewsSocial: React.FC<SectionNewsSocialProps> = ({ className, text, icons }) => {
  const intl = useIntl();
  return (
    <ResponsiveWrapper className={className} data-testid='socials'>
      <SocialContentWrapper>
        <SocialContent>
          <Text data-testid='socials:title'>{text}</Text>
          <Icons>
            {icons &&
              icons.map((icon, index) => {
                const SelectedIcon = iconComponentMap[icon.type];
                return (
                  <Link
                    key={index}
                    href={icon.link}
                    target={icon.target}
                    aria-label={
                      icon.text
                        ? intl.formatMessage({ id: icon.text })
                        : intl.formatMessage({ id: socialNameMap[icon.type] })
                    }
                    data-testid={`socials:links-${index}`}
                  >
                    <SelectedIcon />
                  </Link>
                );
              })}
          </Icons>
        </SocialContent>
        <StyledHexOutline isActive clipRightTop={20} strokeColor={"#051C24"} fillColor={"#051C24"} />
      </SocialContentWrapper>
    </ResponsiveWrapper>
  );
};

export default SectionNewsSocial;
