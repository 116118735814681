import styled from "styled-components";
import { MEDIA_MOBILE_ALL } from "../../../../layouts/default/theme";
import { rem } from "../../../../utils/style";

const movement = 20.0; // px

const heightRatio = (954.0 / 1440.0) /* asset dimensions */ * 100.0;
const topOffset = 0; // `calc(100vh - ${heightRatio * 0.7}vw)`

export const Container = styled.div`
  position: relative;
  overflow: hidden;

  width: 100%;
  height: calc(${heightRatio}vw);

  perspective: 100px;
  perspective-origin: 50% 100%;

  opacity: 0;
`;

export const Layer = styled.div`
  position: absolute;

  left: -${movement * 0.5}px;
  // top: calc(${topOffset} - ${movement * 0.5}px);
  bottom: 0;
  // margin-bottom: 0; // set by JS onResize

  width: calc(100% + ${movement}px);
  height: calc(${heightRatio}vw + ${movement}px);

  // min-height: ${topOffset};

  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 0%;

  will-change: transform;

  &.particles {
    left: 0;
    top: 0;
    width: 100%;
    height: ${heightRatio}vw;
    min-height: 80vh;

    @media ${MEDIA_MOBILE_ALL} {
      min-height: ${heightRatio * 2}vw;
    }
  }
`;
