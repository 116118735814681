import React from "react";
import styled from "styled-components";
import { components, OptionTypeBase } from "react-select";
import { IndicatorProps } from "react-select/src/components/indicators";
import { COLOR_PRIMARY_ORANGE } from "../../../../lib/layouts/default/theme";
import { ReactComponent as SvgArrow } from "./assets/arrow-down.svg";

const ArrowIcon = styled.svg`
  width: 14px;
  height: 7px;
  color: ${COLOR_PRIMARY_ORANGE};

  .st0 {
    fill: none;
    stroke: currentColor;
    stroke-miterlimit: 10;
  }

  .st1 {
    fill: currentColor;
  }
`;

export const StyledArrowIcon = ArrowIcon.withComponent(SvgArrow);

function GenericDropdownIndicator<T extends OptionTypeBase, M extends boolean>(): React.FC<IndicatorProps<T, M>> {
  return function GenericDropdown({ ...props }) {
    if (!components.DropdownIndicator) return null;
    return (
      <components.DropdownIndicator {...props}>
        <StyledArrowIcon />
      </components.DropdownIndicator>
    );
  };
}

export default GenericDropdownIndicator;
