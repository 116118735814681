import styled, { keyframes } from "styled-components";
import { rem, easing } from "../../../utils/style";
import { COLOR_BACKGROUND_DARK, COLUMN_WIDTH, MAX_WIDTH, MEDIA_MOBILE_ALL } from "../../../layouts/default/theme";

export const Section = styled.section`
  position: relative;

  height: 100vh;

  /* Prevent 1px gap due to sub-pixel rounding */
  margin-top: -2px;

  background: ${COLOR_BACKGROUND_DARK};
`;

export const SectionWrapper = styled.div`
  position: absolute;

  width: 100%;

  pointer-events: none;

  html.is-ms-ie & {
    top: 0 !important;
    height: 100% !important;
  }
`;

export const SectionSticky = styled.div<{ stickyOffset?: number }>`
  position: sticky;
  top: ${({ stickyOffset }) => (stickyOffset || 0) + "px"};

  width: 100%;
  height: calc(100vh - ${({ stickyOffset }) => (stickyOffset || 0) + "px"});

  html.is-ms-ie & {
    position: absolute;
    top: auto;
    bottom: 0;
    height: 100vh;
  }
`;

export const Video = styled.video`
  position: absolute;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;

  opacity: 0;
  transform-origin: center top;
  will-change: transform, opacity;
  transition: opacity 0.5s ${easing.easeOutQuart}, transform 0.5s ${easing.easeOutQuint};

  ${SectionWrapper}.is-activated & {
    transition-duration: 0.8s, 0.5s;
    opacity: 1;
  }

  ${SectionWrapper}.is-leaving & {
    transition-duration: 0.8s, 1.4s;
    transform: scale3d(0.88, 0.88, 1);
  }

  @media ${MEDIA_MOBILE_ALL} {
    ${SectionWrapper}.is-leaving & {
      transform-origin: center 25%;
      transform: scale3d(0.9, 0.9, 1);
    }
  }

  html.is-ms-ie & {
    opacity: 1;
  }
`;

export const TextContainer = styled.div`
  position: absolute;

  bottom: 0;
  width: 100%;
  height: 100vh;

  /* transform: translate(0, 50%); */

  opacity: 0;
  transition: opacity 0.8s ${easing.easeOutQuart};

  ${SectionWrapper}.is-activated & {
    pointer-events: auto;

    opacity: 1;
  }

  @media ${MEDIA_MOBILE_ALL} {
    box-sizing: border-box;
    padding: 0 5%;
  }
`;

export const SectionInner = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  max-width: ${rem(MAX_WIDTH - COLUMN_WIDTH * 2)};
  margin: 0 auto;
`;

export const IntersectionCheckerContainer = styled.div`
  position: absolute;

  top: 0;
  bottom: 0;

  width: 100%;
`;

export const IntersectionChecker = styled.div`
  position: absolute;

  width: 100%;
  height: 100%;

  top: 0;
  left: 0;
`;
