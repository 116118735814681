import styled, { css } from "styled-components";
import { cssResponsiveListPullSideFn } from "../../../components/section-title";
import { default as ArticleComponent, layoutLarge, layoutOutline, layoutSimple } from "./article";

export const Wrapper = styled.div`
  padding: 4% 0;
  background-color: #f8f8f8;
`;

export const Content = styled.div`
  max-width: 1440px;
  margin: 0 auto;
`;

export const List = styled.ol`
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;

  display: none;
  &.is-carousel {
    display: block;
    overflow: hidden;
  }
`;

export const Item = styled.li`
  padding-right: 4%;
  width: 33.33333%;
  min-width: 220px;
  max-width: 600px;

  &:last-child {
    padding-right: 0;
  }
`;

export const Article = styled(ArticleComponent)``;

const BREAKPOINT_DESKTOP = 1000;
export const ResponsiveWrapper = styled(Wrapper)`
  @media (min-width: ${BREAKPOINT_DESKTOP + 1 + "px"}) {
    ${List} {
      justify-content: center;

      display: flex;
      &.is-carousel {
        display: none;
      }
    }

    ${Item} {
      flex: 1 1 auto;

      &:nth-child(1) {
        width: 60%;

        ${Article} {
          ${layoutLarge}
        }
      }

      &:nth-child(2),
      &:nth-child(3) {
        width: 20%;
        ${Article} {
          ${layoutSimple}
        }
      }
    }
  }

  @media (max-width: ${BREAKPOINT_DESKTOP + "px"}) {
    ${Article} {
      ${layoutOutline}
    }
  }

  ${cssResponsiveListPullSideFn({
    List,
    Item,
    breakpointLarge: 1200,
  })}
`;

export const cssPullNextSection = css`
  ${ResponsiveWrapper} {
    padding-bottom: 70px;

    ${List} {
      transform: translate(0, -40px);
    }
  }

  & + * {
    margin-top: -150px;
  }

  @media (max-width: ${BREAKPOINT_DESKTOP + "px"}) {
    ${ResponsiveWrapper} {
      ${List} {
        transform: none;
      }
    }

    & + * {
      margin-top: -110px;
    }
  }
`;
