import styled, { css } from "styled-components";
import { rem } from "../../utils/style";

import { MAX_WIDTH, MEDIA_MOBILE_ALL } from "../../layouts/default/theme";
import GiantTitleComponent from "../../components/giant-title";

export const Section = styled.section<{ hasGiantTitle?: boolean }>`
  height: auto;
  padding: ${rem(80)} 0;
`;

export const SectionInner = styled.div`
  max-width: ${MAX_WIDTH}px;
  margin: 0 auto;
`;

export const GiantTitle = styled(GiantTitleComponent)`
  margin-bottom: 65px;

  @media ${MEDIA_MOBILE_ALL} {
    margin-bottom: 40px;
  }
`;

export const ItemWrapper = styled.ul`
  position: relative;
  z-index: 1;

  width: 100%;
  max-width: 960px;
  margin: 10px auto 0;
  list-style: none;
  padding: 0;

  display: flex;
`;

export const Item = styled.li`
  transform: translate3d(0, 33vh, 0);

  > * {
    height: 100%;
  }
`;

export const rowLayout = css`
  ${ItemWrapper} {
    justify-content: center;
  }

  ${Item} {
    flex: 1 1 48%;
    min-width: 0;
    max-width: 460px;

    margin-left: 10px;
    margin-right: 10px;
  }
`;

export const columnLayout = css`
  ${ItemWrapper} {
    flex-direction: column;
  }

  ${Item} {
    flex: 0 1 100%;
    width: 100%;
    min-width: 0;
    margin-top: 30px;

    &:first-child {
      margin-top: 0;
    }
  }
`;

export const Wrapper = styled.section``;

export const BREAKPOINT_LAYOUT = 800;
export const ResponsiveWrapper = styled(Wrapper)`
  @media (min-width: ${BREAKPOINT_LAYOUT + 1}px) {
    ${rowLayout}
  }

  @media (max-width: ${BREAKPOINT_LAYOUT}px) {
    ${columnLayout}
  }
`;
