import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
`;

export const WrapperInner = styled.div`
  position: relative;
  width: 100%;
  padding-top: 100%;
`;

export const WrapperInner2 = styled.div`
  position: absolute;
  top: 0;
  right: 1px;
  bottom: 0;
  left: 0;
`;
