import styled, { css } from "styled-components";
import { rem } from "../../../utils/style";
import { fluidRange } from "polished";

import { MAX_WIDTH, MEDIA_MOBILE_ALL, BREAKPOINT_TABLET_ALL } from "../../../layouts/default/theme";

import ButtonSmall from "../../../components/button-small";
import RoleSelectorComponent, { cssColumnLayout } from "./role-selector";

import { SIDE_WIDTH_IN_PX, SIDE_WIDTH_SMALL_IN_PX, LAYOUT_BREAKPOINT } from "../../../components/section-title";
import riotLocaleToBcp47 from "../../../utils/riot-locale-to-bcp47";
import RiotLocale from "../../../enums/riot-locale";

export const Wrapper = styled.section``;

export const SectionInner = styled.div`
  position: relative;
  z-index: 1;

  max-width: ${MAX_WIDTH - SIDE_WIDTH_IN_PX * 2 + "px"};
  margin: 0 auto;
`;

export const Description = styled.p<{
  transitionDelay: number;
  isVisible: boolean;
}>`
  position: relative;
  max-width: 500px;
  text-align: center;
  margin: ${rem(12)} auto 0;
  font-size: 14px;
  letter-spacing: 0.03em;

  opacity: 0;
  transition: opacity 500ms ease-out ${({ transitionDelay }) => transitionDelay + "ms"};

  ${({ isVisible }) =>
    isVisible &&
    css`
      opacity: 1;
    `}
`;

export const ActionButton = styled(ButtonSmall)`
  margin: 8px;
  min-width: 220px;
  &:lang(${riotLocaleToBcp47(RiotLocale.th_TH)}) {
    font-size: 12px;
  }
`;

export const ActionList = styled.div`
  position: relative;
  z-index: 2;

  margin-top: ${rem(15)};
  text-align: center;

  @media ${MEDIA_MOBILE_ALL} {
    margin-top: ${rem(10)};
  }
`;

export const RoleSelector = styled(RoleSelectorComponent)``;

const BREAKPOINT_COLUMN_LAYOUT = BREAKPOINT_TABLET_ALL;

export const ResponsiveWrapper = styled(Wrapper)`
  /*
   * expand role selector width such baseline goes all the way to left side of wrapper
   * taking into account section title column width
   */
  @media (min-width: ${MAX_WIDTH + 1 + "px"}) {
    ${RoleSelector} {
      margin-left: calc(${-1 * SIDE_WIDTH_IN_PX + "px"} - (100vw - ${MAX_WIDTH + "px"}) / 2);
      width: calc(100% + ${SIDE_WIDTH_IN_PX + "px"} + (100vw - ${MAX_WIDTH + "px"}) / 2);
    }
  }

  @media (min-width: ${Math.max(LAYOUT_BREAKPOINT, BREAKPOINT_COLUMN_LAYOUT) + 1 + "px"}) and (max-width: ${MAX_WIDTH +
    "px"}) {
    ${RoleSelector} {
      /* expand role selector width such baseline goes all the way to left side of wrapper */
      margin-left: ${-1 * SIDE_WIDTH_IN_PX + "px"};
      width: calc(100% + ${SIDE_WIDTH_IN_PX + "px"});
    }
  }

  @media (min-width: ${BREAKPOINT_COLUMN_LAYOUT + 1 + "px"}) {
    padding: 140px 0 15vw;

    ${RoleSelector} {
      margin-top: -10%;

      ${fluidRange(
        {
          prop: "margin-top",
          fromSize: "-40px",
          toSize: "-130px",
        },
        BREAKPOINT_COLUMN_LAYOUT + 1 + "px",
        "1440px",
      )}
    }
  }

  @media (max-width: ${BREAKPOINT_COLUMN_LAYOUT + "px"}) {
    ${cssColumnLayout}

    padding-top: 50px;

    ${SectionInner} {
      padding-bottom: ${rem(150)};
    }

    ${RoleSelector} {
      margin-top: 25px;

      @media ${MEDIA_MOBILE_ALL} {
        margin-top: ${rem(16)};
      }
    }
  }

  @media (max-width: ${LAYOUT_BREAKPOINT + "px"}) {
    ${RoleSelector} {
      /* expand role selector width such baseline goes all the way to left side of wrapper */
      margin-left: ${-1 * SIDE_WIDTH_SMALL_IN_PX + "px"};
      margin-right: ${-1 * SIDE_WIDTH_SMALL_IN_PX + "px"};
      width: calc(100% + ${SIDE_WIDTH_SMALL_IN_PX * 2 + "px"});
    }
  }
`;
