import React from "react";
import { useInView } from "../../../utils/hooks";
import SectionTitle from "../../../components/section-title";

import { Article as ArticleType } from "./types";
import Article from "./article";
import { ResponsiveWrapper, Content, List, Item, LinkContainer, Link } from "./style";
import { LinkProps } from "../../../components/link";
import Carousel from "../../../components/carousel";
import { useIntl } from "gatsby-plugin-intl";

export interface SectionNewsPopularProps {
  sectionTitle?: string;
  articles: ArticleType[];
  link?: LinkProps & {
    text: string;
  };
  className?: string;
}

const SectionNewsPopular: React.FC<SectionNewsPopularProps> = ({ sectionTitle, articles, link, className }) => {
  const [inViewRef, inView] = useInView({ triggerOnce: true, threshold: 0.5 });
  const intl = useIntl();
  return (
    <ResponsiveWrapper ref={inViewRef} className={`${className} ${inView ? "isVisible" : ""}`}>
      <SectionTitle text={sectionTitle} displayOnMobile={true}>
        <Content>
          <List>
            <Carousel className={"carousel"}>
              {articles.map((article, idx) => {
                return (
                  <Item key={article.id} animationDelay={idx * 100}>
                    <Article article={article} />
                  </Item>
                );
              })}
            </Carousel>
          </List>

          {link && (
            <LinkContainer>
              <Link {...link}>{intl.formatMessage({ id: link.text || JSON.stringify(link) })}</Link>
            </LinkContainer>
          )}
        </Content>
      </SectionTitle>
    </ResponsiveWrapper>
  );
};

export default SectionNewsPopular;
