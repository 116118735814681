import styled, { css } from "styled-components";
import YouTubeComponent from "@u-wave/react-youtube";
import { animation, easing, hoverEffect } from "../../../utils/style";
import HexOutlineComponent from "../../../components/hex-outline";
import ArrowLink from "../../../components/arrow-link";
import { fluidRange } from "polished";
import {
  MAX_WIDTH,
  COLUMN_WIDTH,
  BREAKPOINT_MOBILE_ALL,
  COLOR_OUTLINE_LIGHT_GREY,
  COLOR_BACKGROUND_LIGHT_GREY,
  COLOR_PRIMARY_BLUE,
} from "../../../layouts/default/theme";
import {
  cssLocalizedLineHeight,
  cssLocalizedUppercase,
  cssLocalizedItalic,
  cssLocalizedFontFamilySansSerif,
  cssLocalizedFontFamilySerif,
} from "../../../components/css";

export const Wrapper = styled.section`
  margin: 4% 0;
`;

export const Content = styled.div`
  position: relative;
`;

export const FadeInWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  max-width: ${MAX_WIDTH - 2 * COLUMN_WIDTH + "px"};
  margin: 0 auto;
  box-sizing: border-box;

  ${animation.triggeredFadeIn(`${Wrapper}.isVisible &`, { delay: 1000 })}
`;

export const HexOutline = styled(HexOutlineComponent)``;

export const Main = styled.div``;

export const Aside = styled.aside``;

export const Title = styled.h1`
  margin: 0;
  ${cssLocalizedFontFamilySerif};
  ${cssLocalizedUppercase};
  font-weight: 800;
  ${cssLocalizedItalic}
  ${cssLocalizedLineHeight(1)}
`;

export const MainLinkContainer = styled.div``;

export const Link = styled(ArrowLink)``;

export const YouTubeContainer = styled.div`
  padding: 10px 0px;
`;

export const YouTubeContainerInner = styled.div`
  position: relative;
  width: 100%;
  padding-top: ${(9 / 16) * 100 + "%"};
`;

export const YouTube = styled(YouTubeComponent)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const YouTubeHexOutline = styled(HexOutlineComponent)`
  left: 10px;
  right: 10px;
  top: -10px;
  bottom: -10px;

  html.is-ms-10 & {
    /* pointer-events not supported on IE10 */
    display: none;
  }
`;

export const VideoTitle = styled.h2`
  ${cssLocalizedFontFamilySansSerif};
  margin-top: 20px;
  font-size: 18px;
`;

export const VideoDescription = styled.p`
  ${cssLocalizedFontFamilySansSerif};
  font-size: 14px;
  word-break: break-word;

  a {
    color: ${COLOR_PRIMARY_BLUE};
  }
`;

export const Info = styled.div``;

export const List = styled.ol`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const Item = styled.li``;

export const ItemAction = styled.button<{ selected: boolean }>`
  display: flex;
  align-items: center;

  box-sizing: border-box;
  width: 100%;
  padding: 20px 0px;
  border: 0;
  border-bottom: 1px solid ${COLOR_OUTLINE_LIGHT_GREY};
  background-color: transparent;
  text-align: left;

  ${hoverEffect.background()}

  ${({ selected }) =>
    selected &&
    css`
      background-color: ${COLOR_BACKGROUND_LIGHT_GREY};
    `}
`;

const IMAGE_WIDTH = 120;
export const ItemImage = styled.div`
  flex: 0 0 auto;
  width: ${IMAGE_WIDTH + "px"};
  height: ${(9 / 16) * IMAGE_WIDTH + "px"};

  > * {
    width: 100%;
    height: 100%;
  }
`;

export const ItemInfo = styled.div`
  flex: 1 1 100%;
`;

export const ItemTitle = styled.h3`
  ${cssLocalizedFontFamilySansSerif};
  margin: 0;
  font-size: 14px;
`;

export const ItemDescription = styled.p`
  margin-top: 5px;
  font-size: 14px;
`;

export const AsideLinkContainer = styled.div`
  margin-top: 10px;
  text-align: center;
`;

const rowLayout = css`
  ${FadeInWrapper} {
    padding: 4%;
  }

  ${Main} {
    flex: 1 1 auto;
  }

  ${VideoTitle},
  ${VideoDescription} {
    max-width: 460px;
  }

  ${Aside} {
    flex: 1 1 auto;
    max-width: 420px;
    min-width: 360px;
    margin-left: 5%;
  }

  ${List} {
    overflow: auto;
  }

  ${YouTubeContainer} {
    margin-top: 20px;
  }

  ${ItemInfo} {
    margin-left: 30px;
  }

  ${AsideLinkContainer} {
    display: none;
  }
`;

const columnLayout = css`
  ${FadeInWrapper} {
    flex-direction: column;
  }

  ${HexOutline} {
    display: none;
  }

  ${Aside} {
    margin-top: 20px;
  }

  ${MainLinkContainer} {
    display: none;
  }

  ${YouTubeContainer} {
    margin-top: 10px;
  }

  ${VideoTitle} {
    font-size: 16px;
  }

  ${Item} {
    &:first-child {
      border-top: 1px solid #c1c1c1;
    }

    /* hide all except first 3 items */
    &:not(:nth-child(-n + 3)) {
      display: none;
    }
  }

  ${ItemInfo} {
    margin: 0 4%;
  }
`;

const BREAKPOINT_DESKTOP = 1000;
const BREAKPOINT_MOBILE = BREAKPOINT_MOBILE_ALL;
export const ResponsiveWrapper = styled(Wrapper)`
  @media (min-width: ${BREAKPOINT_DESKTOP + 1 + "px"}) {
    ${rowLayout}

    ${Title} {
      ${fluidRange(
        {
          prop: "font-size",
          fromSize: "35px",
          toSize: "60px",
        },
        BREAKPOINT_DESKTOP + 1 + "px",
        "1440px",
      )}
    }

    ${List} {
      ${fluidRange(
        {
          prop: "height",
          fromSize: "420px",
          toSize: "800px",
        },
        BREAKPOINT_DESKTOP + 1 + "px",
        MAX_WIDTH + "px",
      )}

      html.is-ms-ie & {
        height: 100%;
        max-height: 800px;
      }
    }
  }

  @media (max-width: ${BREAKPOINT_DESKTOP + "px"}) {
    ${columnLayout}

    ${Title} {
      ${fluidRange(
        {
          prop: "font-size",
          fromSize: "30px",
          toSize: "40px",
        },
        "320px",
        BREAKPOINT_DESKTOP + "px",
      )}
    }
  }

  @media (max-width: ${BREAKPOINT_MOBILE + "px"}) {
    ${ItemAction} {
      padding: 4% 0;
    }
  }
`;
