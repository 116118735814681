import { easing } from "../../../../utils/style";
import React, { useState, useEffect, useRef } from "react";
import { COLOR_PRIMARY_DARK_GOLD, COLOR_OUTLINE_TRANSPARENT_WHITE } from "../../../../layouts/default/theme";

import { GameMode } from "../typings";
import {
  Wrapper,
  VideoContainer,
  Video,
  Hex,
  DialRingWrapper,
  OuterRing,
  DialRing,
  TRANSITION_DURATION_IN_MS,
} from "./style";

import HexOutline from "../../../../components/hex-outline";
import Reveal from "../../../../components/reveal";

import { ReactComponent as OuterRingSvg } from "../assets/outer-ring.svg";
import { ReactComponent as DialRingSvg } from "../assets/dial-ring.svg";

const StyledHexOutline = Hex.withComponent(HexOutline);
const StyledOuterRing = OuterRing.withComponent(OuterRingSvg);
const StyledDialRing = DialRing.withComponent(DialRingSvg);

interface Props {
  gameModes: GameMode[];
  isInViewport: boolean;
  isAutoCycling: boolean;
  cycleDuration: number;
  cycleStartTime: number;
  activeMode: GameMode;
  testId?: string;
}

const ModePreview: React.FC<Props> = ({
  gameModes,
  isInViewport = false,
  isAutoCycling,
  cycleDuration,
  cycleStartTime,
  activeMode,
  testId,
}) => {
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const [hasEntered, setHasEntered] = useState<boolean>(false);
  const [videos, setVideos] = useState<HTMLVideoElement[] | null>(null);

  // set video nodes
  useEffect(() => {
    if (!videoContainerRef.current) return;

    setVideos(Array.from(videoContainerRef.current.querySelectorAll<HTMLVideoElement>("video")));
  }, [videoContainerRef.current]);

  // Animate in on first intersection
  useEffect(() => {
    if (isInViewport && !hasEntered) setHasEntered(true);
  }, [isInViewport]);

  // Handle intersection changes
  useEffect(() => {
    if (!videos) return;

    const timeouts: number[] = [];

    videos.forEach((video) => {
      if (!isInViewport) {
        // pause videos when not in viewport
        video.pause();
      } else if (video.getAttribute("data-mode") !== activeMode.id) {
        // videos that are not for the current mode are paused after ensuring
        // they've fully transitioned out
        const timeout = window.setTimeout(() => {
          video.pause();
          timeouts.splice(timeouts.indexOf(timeout), 1);
        }, TRANSITION_DURATION_IN_MS);
        timeouts.push(timeout);
      } else {
        // play current video
        video.play();
      }
    });

    return () => {
      timeouts.forEach((timeout) => window.clearTimeout(timeout));
    };
  }, [isInViewport, videos, activeMode]);

  return (
    <Wrapper className={`${hasEntered && "animate-enter"}`}>
      <VideoContainer ref={videoContainerRef}>
        {gameModes.map((mode, index) => {
          const isActive = hasEntered && mode.id === activeMode.id;
          return (
            <Reveal
              key={mode.id}
              isActive={isActive}
              safariSimplified
              absolute
              showDir={"ne"}
              hideDir={"ne"}
              transitionTiming={easing.easeOutCubic}
              transitionDuration={1000}
              transitionDelay={200}
            >
              <Video
                className={`${isActive ? "is-active" : ""}`}
                objectFit={"cover"}
                objectPosition={"center center"}
                playsInline
                loop
                muted
                lazy={mode.id !== activeMode.id && !isInViewport}
                data-mode={mode.id}
                testId={`${testId}:video-${index}`}
              >
                {mode.videoSources}
              </Video>
            </Reveal>
          );
        })}
      </VideoContainer>

      <StyledHexOutline
        isActive={isInViewport}
        circular
        countdown={isAutoCycling}
        countdownDuration={cycleDuration}
        countdownStartTime={cycleStartTime}
        strokeColor={COLOR_OUTLINE_TRANSPARENT_WHITE}
        accentColor={COLOR_PRIMARY_DARK_GOLD}
        accentThickness={3}
        transitionDuration={2000}
        transitionDelay={400}
      />

      <StyledOuterRing />

      <DialRingWrapper>
        <StyledDialRing />
      </DialRingWrapper>
    </Wrapper>
  );
};

export default ModePreview;
