import { useIntl } from "gatsby-plugin-intl";
import React, { useContext, useRef, useState } from "react";
import SectionTitle from "../../../components/section-title";
import VideoComponent from "../../../components/video";
import StickyOffsetContext from "../../../contexts/sticky-offset";
import { BREAKPOINT_MOBILE_ALL, COLOR_TEXT_WHITE } from "../../../layouts/default/theme";
import { ManagerContext } from "../../../managers";
import { useInView, useResize, useTickerWithScrollActivation } from "../../../utils/hooks";
import { ease } from "../../../utils/math";
import Intro from "../components/intro";
import videoAssetMp4 from "./assets/what-is-league-of-legends.mp4";
import videoAssetWebm from "./assets/what-is-league-of-legends.webm";
import {
  IntersectionChecker,
  IntersectionCheckerContainer,
  Section,
  SectionInner,
  SectionSticky,
  SectionWrapper,
  TextContainer,
  Video,
} from "./style";

export interface Props {
  stickyOffset?: number;
  className?: string;
}

const StyledVideo = Video.withComponent(VideoComponent);
const StyledSectionTitle = TextContainer.withComponent(SectionTitle);

const About: React.FC<Props> = ({ stickyOffset, className }) => {
  const stickyOffsetContext = useContext(StickyOffsetContext);
  if (stickyOffset === undefined) {
    stickyOffset = stickyOffsetContext;
  }
  const intl = useIntl();
  const COPY = {
    sectionTitle: intl.formatMessage({ id: "about-the-game.title" }),
    title: intl.formatMessage({ id: "section.how-to-play.about.title" }),
    desc: intl.formatMessage({ id: "section.how-to-play.about.description" }),
  };

  const [isMs] = useState(() => {
    return (
      typeof window !== "undefined" &&
      (document.documentElement.classList.contains("is-ms-11") ||
        document.documentElement.classList.contains("is-ms-10"))
    );
  });

  const { viewport } = useContext(ManagerContext);

  const sectionRef = useRef<any>(null);
  const wrapperRef = useRef<any>(null);
  const videoRef = useRef<any>(null);
  const checkerRef = useRef<any>(null);
  const scrollRef = useRef<any>(null);
  const textRef = useRef<any>(null);

  const [intersectionRef, inView] = useInView({ threshold: 0.0 });

  useResize(() => {
    const { height } = viewport.latest;

    const sectionTop = sectionRef.current.offsetTop;

    // offset intersection checkers to bottom of screen in case of very narrow window
    const windowOffset = Math.min(sectionTop - height, 0.0);
    checkerRef.current.style.top = `${-windowOffset}px`;

    wrapperRef.current.style.top = `${-sectionTop}px`;
    wrapperRef.current.style.height = `${sectionTop + height}px`;
  }, [sectionRef, wrapperRef, scrollRef]);

  useTickerWithScrollActivation(
    scrollRef.current,
    ({}, activation) => {
      const activationPoint = viewport.latest.width <= BREAKPOINT_MOBILE_ALL ? 0.3 : 0.1;

      const activated = activation.direct.in >= activationPoint;
      const leaving = activation.direct.out >= 0.01;

      // Toggle section activation
      if (wrapperRef.current) {
        if (activated) {
          wrapperRef.current.classList.add("is-activated");
        } else {
          wrapperRef.current.classList.remove("is-activated");
        }
        if (leaving) {
          wrapperRef.current.classList.add("is-leaving");
        } else {
          wrapperRef.current.classList.remove("is-leaving");
        }
      }

      // Toggle video state
      if (videoRef.current) {
        if (activated && !leaving) {
          videoRef.current.play();
        } else {
          videoRef.current.pause();
        }
      }

      const { height } = viewport.latest;
      const translateY = ((1 - ease.inCubic(activation.in)) * 2 - 1) * 0.05 * height;

      if (!isMs) {
        textRef.current.style.transform = `translate3d(0, ${translateY}px, 0)`;
      }
    },
    [inView, sectionRef, wrapperRef, videoRef, scrollRef],
  );

  return (
    <Section ref={sectionRef} className={className} data-testid='about'>
      <IntersectionCheckerContainer ref={checkerRef}>
        <IntersectionChecker ref={intersectionRef} />
        <IntersectionChecker ref={scrollRef} />
      </IntersectionCheckerContainer>
      <SectionWrapper ref={wrapperRef}>
        <SectionSticky stickyOffset={stickyOffset}>
          <StyledVideo
            objectFit="cover"
            objectPosition="center center"
            lazy
            videoRef={videoRef}
            muted
            loop
            playsInline
            autoPlay
          >
            <source src={videoAssetWebm} type="video/webm" />
            <source src={videoAssetMp4} type="video/mp4" />
          </StyledVideo>

          <StyledSectionTitle text={COPY.sectionTitle} textColor={COLOR_TEXT_WHITE} verticalAlign={"center"}>
            <SectionInner>
              <Intro
                ref={textRef}
                isInViewport={inView}
                toggleContrast
                heading={COPY.title}
                description={COPY.desc}
                transitionDelay={200}
                transitionDuration={2400}
                testId='about'
              />
            </SectionInner>
          </StyledSectionTitle>
        </SectionSticky>
      </SectionWrapper>
    </Section>
  );
};

export default About;
