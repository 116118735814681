import { ChapterMap, HotspotType, Chapter } from "../typings";
import * as LaneIcons from "../../../../assets/lanes";
import * as RoleIcons from "../../../../assets/roles";
import * as hotspotAssets from "../guide-map/assets/hotspots";
import * as overlayAssets from "../guide-map/assets/overlays";

const chapterMap: ChapterMap = {
  [Chapter.OBJECTIVES]: {
    sectionTitle: "goal.title",
    chapterTitle: "section.how-to-play.guide.objectives.title",
    chapterDesc: "section.how-to-play.guide.objectives.desc",
    topics: [
      {
        topicTitle: "section.how-to-play.guide.objectives.topic-your-nexus.title",
        topicDesc: "section.how-to-play.guide.objectives.topic-your-nexus.desc",
        hotspots: [
          {
            type: HotspotType.ZOOM_BLUE,
            asset: hotspotAssets.yourNexus,
            x: 0.106,
            y: 0.865,
          },
        ],
      },
      {
        topicTitle: "section.how-to-play.guide.objectives.topic-enemy-nexus.title",
        topicDesc: "section.how-to-play.guide.objectives.topic-enemy-nexus.desc",
        hotspots: [
          {
            type: HotspotType.ZOOM_RED,
            asset: hotspotAssets.enemyNexus,
            x: 0.84,
            y: 0.07,
          },
        ],
      },
    ],
  },
  [Chapter.TARGETS]: {
    sectionTitle: "structures.title",
    chapterTitle: "section.how-to-play.guide.targets.title",
    chapterDesc: "section.how-to-play.guide.targets.desc",
    topics: [
      {
        topicTitle: "section.how-to-play.guide.targets.topic-turrets.title",
        topicDesc: "section.how-to-play.guide.targets.topic-turrets.desc",
        hotspots: [
          {
            type: HotspotType.ZOOM_RED,
            asset: hotspotAssets.turret,
            x: 0.625,
            y: 0.333,
          },
        ],
      },
      {
        topicTitle: "section.how-to-play.guide.targets.topic-inhibitors.title",
        topicDesc: "section.how-to-play.guide.targets.topic-inhibitors.desc",
        hotspots: [
          {
            type: HotspotType.ZOOM_RED,
            asset: hotspotAssets.inhibitor,
            x: 0.764,
            y: 0.148,
          },
        ],
      },
    ],
  },
  [Chapter.NEUTRALS]: {
    sectionTitle: "neutral-monsters.title",
    chapterTitle: "section.how-to-play.guide.neutrals.title",
    chapterDesc: "section.how-to-play.guide.neutrals.desc",
    topics: [
      {
        topicTitle: "section.how-to-play.guide.neutrals.topic-baron.title",
        topicDesc: "section.how-to-play.guide.neutrals.topic-baron.desc",
        hotspots: [
          {
            type: HotspotType.ZOOM_NEUTRAL,
            asset: hotspotAssets.baron,
            x: 0.398,
            y: 0.196,
          },
        ],
      },
      {
        topicTitle: "section.how-to-play.guide.neutrals.topic-drakes.title",
        topicDesc: "section.how-to-play.guide.neutrals.topic-drakes.desc",
        hotspots: [
          // {
          //   type: HotspotType.ZOOM_NEUTRAL,
          //   asset: hotspotAssets.example2,
          //   x: 0.325,
          //   y: 0.458,
          // },
          // {
          //   type: HotspotType.ZOOM_NEUTRAL,
          //   asset: hotspotAssets.example1,
          //   x: 0.567,
          //   y: 0.672,
          // },
          {
            type: HotspotType.ZOOM_NEUTRAL,
            asset: hotspotAssets.drakes,
            x: 0.705,
            y: 0.654,
          },
        ],
      },
    ],
  },
  [Chapter.POSITIONS]: {
    sectionTitle: "lane-positions.title",
    chapterTitle: "section.how-to-play.guide.positions.title",
    chapterDesc: "section.how-to-play.guide.positions.desc",
    topics: [
      {
        topicTitle: "section.how-to-play.guide.positions.topic-top.title",
        topicDesc: "section.how-to-play.guide.positions.topic-top.desc",
        hotspots: [
          {
            type: HotspotType.ICON,
            icon: LaneIcons.Top,
            x: 0.247,
            y: 0.077,
          },
        ],
        overlays: [
          {
            assets: [overlayAssets.top3x, overlayAssets.top2x, overlayAssets.top1x],
          },
        ],
      },
      {
        topicTitle: "section.how-to-play.guide.positions.topic-jungle.title",
        topicDesc: "section.how-to-play.guide.positions.topic-jungle.desc",
        hotspots: [
          {
            type: HotspotType.ICON,
            icon: LaneIcons.Jungle,
            x: 0.35,
            y: 0.415,
          },
        ],
        overlays: [
          {
            assets: [overlayAssets.jungle3x, overlayAssets.jungle2x, overlayAssets.jungle1x],
          },
        ],
      },
      {
        topicTitle: "section.how-to-play.guide.positions.topic-mid.title",
        topicDesc: "section.how-to-play.guide.positions.topic-mid.desc",
        hotspots: [
          {
            type: HotspotType.ICON,
            icon: LaneIcons.Mid,
            x: 0.529,
            y: 0.412,
          },
        ],
        overlays: [
          {
            assets: [overlayAssets.mid3x, overlayAssets.mid2x, overlayAssets.mid1x],
          },
        ],
      },
      {
        topicTitle: "section.how-to-play.guide.positions.topic-bot.title",
        topicDesc: "section.how-to-play.guide.positions.topic-bot.desc",
        hotspots: [
          {
            type: HotspotType.ICON,
            icon: LaneIcons.Bot,
            x: 0.917,
            y: 0.836,
          },
        ],
        overlays: [
          {
            assets: [overlayAssets.bot3x, overlayAssets.bot2x, overlayAssets.bot1x],
          },
        ],
      },
      {
        topicTitle: "section.how-to-play.guide.positions.topic-support.title",
        topicDesc: "section.how-to-play.guide.positions.topic-support.desc",
        hotspots: [
          {
            type: HotspotType.ICON,
            icon: RoleIcons.Support,
            x: 0.677,
            y: 0.929,
          },
        ],
        overlays: [
          {
            assets: [overlayAssets.support3x, overlayAssets.support2x, overlayAssets.support1x],
          },
        ],
      },
    ],
  },
};

export default chapterMap;
