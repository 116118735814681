import React, { ReactElement, useState } from "react";
import { ease } from "../../utils/math";
import { COLOR_OUTLINE_MEDIUM_GREY } from "../../layouts/default/theme";

import { Wrapper, Inner, Image, ImageInner, Text } from "./style";

import HexOutline from "../hex-outline";
import LinkComponent, { LinkProps } from "../link";

interface Props {
  isActive?: boolean;
  image: ReactElement;
  text: string;
  link?: LinkProps;
  index?: number;
  className?: string;
  onClick?: () => void;
  accentThickness?: number;
  transitionDelay?: number;
  testId?: string;
}

const Component: React.FC<Props> = ({
  isActive = true,
  image,
  text,
  link,
  index = 0,
  className,
  onClick,
  accentThickness = 2,
  transitionDelay = 0,
  testId,
}) => {
  const [isHovering, setIsHovering] = useState(false);

  const itemTransitionDuration = 1500 + index * 200;
  const itemTransitionDelay = transitionDelay + index * 100;

  return (
    <Wrapper className={className} onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
      <Inner as={link ? LinkComponent : onClick ? "button" : "div"} {...link} onClick={!link ? onClick : undefined}>
        <Image>
          <ImageInner data-testid={`${testId}:image`}>{image}</ImageInner>
        </Image>
        <Text data-testid={`${testId}:title`}>{text}</Text>
        <HexOutline
          isActive={isActive}
          clipRightTop={25}
          strokeColor={COLOR_OUTLINE_MEDIUM_GREY}
          accentColor={COLOR_OUTLINE_MEDIUM_GREY}
          accentThickness={accentThickness}
          transitionDuration={itemTransitionDuration}
          transitionDelay={itemTransitionDelay}
          interactive
          hovering={isHovering}
          hoverDuration={300}
          hoverEase={ease.outExpo}
        />
      </Inner>
    </Wrapper>
  );
};

export default Component;
