import React from "react";
import { Props } from "..";
import { LinkFieldType } from "../../../../contentstack/fields/link";

export interface SectionTwitchContentstackProps extends Pick<Props, "sectionTitle" | "giantTitle"> {
  items: Array<
    Pick<Props["items"][0], "channel" | "video" | "title" | "text"> & {
      link: LinkFieldType;
    }
  >;
}

export const transformContentstackPropsToSectionProps = ({
  items,
  ...otherProps
}: SectionTwitchContentstackProps): Props => {
  return {
    ...otherProps,
    items: items.map(({ link, ...otherItemProps }) => {
      return {
        ...otherItemProps,
        action: link,
      };
    }),
  };
};
