import React from "react";
import { SectionNewsPopularProps } from "..";
import { Article as ArticleType } from "../types";
import { ImageAsset } from "../../../../../contentstack/content-types/assets/typings";
import ComponentImage from "../../../../components/image";
import { UrlFieldType } from "../../../../../contentstack/fields/url";
import { LinkFieldType } from "../../../../../contentstack/fields/link";

export interface SectionNewsPopularContentstackProps extends Pick<SectionNewsPopularProps, "sectionTitle"> {
  link?: LinkFieldType;
  articles: Array<
    Pick<ArticleType, "category" | "title"> & {
      url: UrlFieldType;
      image: ImageAsset;
    }
  >;
}

export const transformContentstackPropsToSectionProps = ({
  link,
  articles,
  ...props
}: SectionNewsPopularContentstackProps): SectionNewsPopularProps => {
  return {
    ...props,
    link,
    articles: articles.map(({ url, image, ...articleProps }) => {
      return {
        ...articleProps,
        id: image.uid,
        link: url,
        image: <ComponentImage src={image.url} alt={image.alt} objectFit={"cover"} />,
        imageDimension: image.dimension,
      };
    }),
  };
};
