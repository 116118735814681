import React from "react";
import { Props } from "..";
import { UrlFieldType } from "../../../../contentstack/fields/url";

export interface SectionGameIntroContentstackProps {
  championListLink: UrlFieldType;
  playLink: UrlFieldType;
}

export const transformContentstackPropsToSectionProps = ({
  championListLink,
  playLink,
}: SectionGameIntroContentstackProps): Props => {
  return {
    championListLink,
    playLink,
  };
};
