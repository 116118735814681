import React, { useState, useEffect } from "react";
import { easing } from "../../../../utils/style";

import { ChapterWrapper, ChapterTitle, ChapterDesc } from "./style";

import Reveal from "../../../../components/reveal";

export interface Props {
  isActive: boolean;
  title: string;
  desc: string;
  testId?: string;
}

const GuideChapter: React.FC<Props> = ({ isActive, title, desc, testId }) => {
  const [hasEntered, setHasEntered] = useState(false);

  useEffect(() => {
    if (isActive && !hasEntered) setHasEntered(true);
  }, [isActive]);

  return (
    <ChapterWrapper className={`${hasEntered ? "is-active" : ""}`} data-testid={testId}>
      <ChapterTitle data-testid={`${testId}:title`}>
        <Reveal
          isActive={hasEntered}
          inline
          showDir={"se"}
          transitionTiming={easing.easeOutCubic}
          transitionDuration={1400}
          transitionDelay={0}
        >
          {title}
        </Reveal>
      </ChapterTitle>
      <ChapterDesc data-testid={`${testId}:description`}>{desc}</ChapterDesc>
    </ChapterWrapper>
  );
};

export default GuideChapter;
