import { useIntl } from "gatsby-plugin-intl";
import React, { Fragment, ReactElement, useCallback, useContext, useEffect, useRef, useState } from "react";
import { Controller as HexOutlineController } from "../../../components/hex-outline";
import { LinkProps } from "../../../components/link";
import SectionTitle from "../../../components/section-title";
import RiotLocaleContext from "../../../contexts/riot-locale";
import { useInView, useScrollTo } from "../../../utils/hooks";
import {
  Aside,
  AsideLinkContainer,
  Content,
  FadeInWrapper,
  HexOutline,
  Info,
  Item,
  ItemAction,
  ItemImage,
  ItemInfo,
  ItemTitle,
  Link,
  List,
  Main,
  MainLinkContainer,
  ResponsiveWrapper,
  Title,
  VideoDescription,
  VideoTitle,
  YouTube,
  YouTubeContainer,
  YouTubeContainerInner,
  YouTubeHexOutline,
} from "./style";

export interface SectionNewsYoutubeProps {
  sectionTitle?: string;
  title: string;
  videos: Video[];
  locale?: string;
  link?: LinkProps & {
    text: string;
    url: string;
  };
  className?: string;
}

export interface Video {
  id: string;
  image: ReactElement;
  title: string;
  description: string;
  url?: string;
}

const SectionNewsYoutube: React.FC<SectionNewsYoutubeProps> = ({
  sectionTitle,
  title,
  locale,
  videos,
  link,
  className,
}) => {
  const localeContext = useContext(RiotLocaleContext);
  if (locale === undefined) {
    locale = localeContext;
  }
  const intl = useIntl();
  const [paused, setPaused] = useState<boolean>(true);
  const [pausedInView, setPausedInView] = useState<boolean>(false);
  const [player, setPlayer] = useState<YT.Player | null>(null);
  const [currentVideo, setCurrentVideo] = useState<Video | null>(videos[0]);
  const [inViewRef, inView] = useInView({ triggerOnce: true, threshold: 0 });
  const [hexOutlineController, setHexOutlineController] = useState<HexOutlineController | null>(null);
  const videoRef = useRef<HTMLDivElement>(null);
  const [initialized, setInitialized] = useState<boolean>(false);

  // refresh hex outline when video changes, since the height of the component may have changed
  // due to difference in length of video title and description
  useEffect(() => {
    if (!hexOutlineController) return;
    hexOutlineController.refresh();
  }, [currentVideo, hexOutlineController]);

  // scroll to video when it changes
  useScrollTo(
    {
      ref: videoRef,
      offset: -40,
      threshold: 0.35,
    },
    [currentVideo],
  );

  const [inViewVideoRef, inViewVideo] = useInView();

  // play/pause video when in view
  useEffect(() => {
    // user manually paused video when in view, so don't restart it
    if (pausedInView) return;

    // start playing video when in view
    setPaused(!inViewVideo);
    if (player) player.pauseVideo();
  }, [player, inViewVideo]);

  // keep track when user manually paused video
  useEffect(() => {
    if (paused) {
      if (inViewVideo) {
        // paused by user while watching video
        setPausedInView(true);
      } else {
        // this was paused due to video coming out of view
      }
    } else {
      // started to play, so reset state
      setPausedInView(false);
    }
  }, [paused]);

  // autoplay video when it changes
  useEffect(() => {
    // don't run on initial render
    if (!initialized) return setInitialized(true);

    setPaused(false);
  }, [currentVideo]);

  // ensure video is playing when unpaused
  useEffect(() => {
    if (!player) return;
    const p = player;

    // try playing video as soon as unpaused
    if (!paused) p.playVideo();

    // ensure when video changes and unpaused state, video actually plays
    const listener = (e: YT.OnStateChangeEvent) => {
      if (!paused && e.data === YT.PlayerState.CUED) {
        e.target.playVideo();
      }
    };

    // add listener
    p.addEventListener("onStateChange", listener);
    return () => p.removeEventListener("onStateChange", listener);
  }, [player, paused]);

  // cue all videos once the player becomes available and update which video is being played
  // when current video changes
  useEffect(() => {
    if (!player) return;
    if (!currentVideo) return;

    // get video index
    let index = 0;
    videos.some((video, i) => {
      if (video.id === currentVideo.id) {
        index = i;
        return true;
      }
      return false;
    });

    // set playlist
    const currentIndex = player.getPlaylistIndex();
    if (currentIndex < 0 || currentIndex !== index) {
      player.cuePlaylist(
        videos.map((video) => video.id),
        index,
      );
    }
  }, [player, currentVideo]);

  // update state when video in player changes
  useEffect(() => {
    if (!player) return;
    const p = player;

    const listener = (e: YT.OnStateChangeEvent) => {
      const cuedVideoId = e.target.getPlaylist()[e.target.getPlaylistIndex()];
      const cuedVideo = videos.reduce((cuedVid: Video | null, v) => {
        if (cuedVid !== null) return cuedVid;
        return v.id === cuedVideoId ? v : null;
      }, null);

      setCurrentVideo(cuedVideo);
    };

    // add listener
    p.addEventListener("onStateChange", listener);
    return () => p.removeEventListener("onStateChange", listener);
  }, [player]);

  // memoized callbacks
  const onReadyVideo = useCallback<YT.PlayerEventHandler<YT.PlayerEvent>>((event) => setPlayer(event.target), []);
  const onPauseVideo = useCallback(() => setPaused(true), []);
  const onPlayingVideo = useCallback(() => setPaused(false), []);
  const onInitHexOutline = useCallback((controller) => setHexOutlineController(controller), []);
  // reuse link element
  const linkElement = !link ? null : <Link {...link}>{intl.formatMessage({ id: link.text })}</Link>;

  return (
    <ResponsiveWrapper ref={inViewRef} className={`${className} ${inView ? "isVisible" : ""}`} data-testid="youtube">
      <SectionTitle text={sectionTitle}>
        <Content>
          <HexOutline isActive={inView} transitionDelay={0} clipRightTop={30} onInit={onInitHexOutline} />

          <FadeInWrapper>
            <Main>
              <Title>{title}</Title>

              <MainLinkContainer>{linkElement}</MainLinkContainer>

              <YouTubeContainer ref={inViewVideoRef}>
                <YouTubeContainerInner ref={videoRef}>
                  <YouTube
                    playsInline={true}
                    width="100%"
                    height="100%"
                    muted={true}
                    onReady={onReadyVideo}
                    onPause={onPauseVideo}
                    onPlaying={onPlayingVideo}
                    lang={locale}
                    allowFullscreen={true}
                    annotations={false}
                    modestBranding={true}
                    showRelatedVideos={false}
                  />
                  <YouTubeHexOutline isActive />
                </YouTubeContainerInner>
              </YouTubeContainer>

              {currentVideo && (
                <Info>
                  <VideoTitle>{currentVideo.title}</VideoTitle>
                  <VideoDescription>{convertVideoDescriptionToJsx(currentVideo.description)}</VideoDescription>
                </Info>
              )}
            </Main>

            {videos.length <= 0 ? null : (
              <Aside>
                <List>
                  {videos.map((video) => (
                    <Item key={video.id}>
                      <ItemAction
                        onClick={() => setCurrentVideo(video)}
                        selected={currentVideo ? video.id === currentVideo.id : false}
                      >
                        <ItemImage>{video.image}</ItemImage>
                        <ItemInfo>
                          <ItemTitle>{video.title}</ItemTitle>
                        </ItemInfo>
                      </ItemAction>
                    </Item>
                  ))}
                </List>

                <AsideLinkContainer>{linkElement}</AsideLinkContainer>
              </Aside>
            )}
          </FadeInWrapper>
        </Content>
      </SectionTitle>
    </ResponsiveWrapper>
  );
};

export default SectionNewsYoutube;

const convertVideoDescriptionToJsx = (text: string): JSX.Element => {
  const nodes = [];

  const regex = /\n|https?:\/\/\S+|#\w+/gu;
  let lastIndex = 0;

  while (true) {
    const match = regex.exec(text);
    if (match === null) break;

    // pre text
    nodes.push(text.substr(lastIndex, match.index - lastIndex));

    const matchedText = match[0];

    if (matchedText === "\n") {
      // line break
      nodes.push(<br />);
    } else if (matchedText[0] === "#") {
      // hashtag
      const url = `https://www.youtube.com/results?search_query=${encodeURIComponent(matchedText)}`;
      nodes.push(
        <a href={url} target="_blank" rel="noreferrer">
          {matchedText}
        </a>,
      );
    } else {
      let url = matchedText;

      const lastChar = matchedText[matchedText.length - 1];
      if (lastChar === ".") {
        // exclude "." from url, assume it's part of regular text
        url = matchedText.substr(0, matchedText.length - 1);
      }

      // link
      nodes.push(
        <a href={url} target="_blank" rel="noreferrer">
          {url}
        </a>,
      );

      if (lastChar === ".") {
        nodes.push(lastChar);
      }
    }

    lastIndex = match.index + match[0].length;
  }

  nodes.push(text.substr(lastIndex));

  return (
    <>
      {nodes.map((node, i) => (
        <Fragment key={i}>{node}</Fragment>
      ))}
    </>
  );
};
