import { Props } from "..";
import {
  crispPoster,
  blurredPoster,
  mobilePoster,
  crispVideoSources,
  blurredVideoSources,
  mobileVideoSources,
} from "../assets";
import { UrlFieldType } from "../../../../../contentstack/fields/url";

export interface SectionHomeHeroContentstackProps {
  link: UrlFieldType;
}

export const transformContentstackPropsToSectionProps = (props: SectionHomeHeroContentstackProps): Props => {
  return {
    crispPoster,
    blurredPoster,
    mobilePoster,
    crispVideoSources,
    blurredVideoSources,
    mobileVideoSources,
    link: {
      ...props.link,
      text: "play-for-free.action",
    },
  };
};
