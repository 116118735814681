import styled, { css } from "styled-components";
import { rem, animation, hoverEffect } from "../../../../utils/style";
import { MEDIA_MOBILE_ALL } from "../../../../layouts/default/theme";

import CategoryComponent from "../../../../components/format/category";
import HexOutlineComponent from "../../../../components/hex-outline";
import {
  cssLocalizedFontFamilySansSerif,
  cssLocalizedFontFamilySerif,
  cssLocalizedItalic,
  cssLocalizedUppercase,
} from "../../../../components/css";

export const Wrapper = styled.a`
  padding: 4% 0;
  display: block;
  color: inherit;

  &:hover {
    text-decoration: none;
  }
`;

export const WrapperInner = styled.article`
  position: relative;
  display: flex;
  align-items: center;

  /* iOS 13 and Flickity showcase buggy behavior when events are bubbling up from children */
  html.is-safari & {
    @media ${MEDIA_MOBILE_ALL} {
      pointer-events: none;
    }
  }
`;

export const HexOutlineBackground = styled(HexOutlineComponent)``;
export const HexOutlineForeground = styled(HexOutlineComponent)``;

export const Image = styled.span<{ delay: number }>`
  position: relative;
  display: block;
  max-width: 120px;

  ${({ delay }) => animation.triggeredFadeIn(`${Wrapper}.isVisible &`, { delay })}
`;
export const ImageInner = styled.span`
  position: relative;
  display: block;
  padding-top: 100%;
  width: 100%;

  ${hoverEffect.scaleUp(Wrapper)}

  > * {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
  }
`;

export const Info = styled.span<{ delay: number }>`
  display: block;
  position: relative;
  box-sizing: border-box;
  flex: 1 1 auto;
  padding-left: 4%;

  ${({ delay }) => animation.triggeredFadeIn(`${Wrapper}.isVisible &`, { delay })}
`;

export const Category = styled(CategoryComponent)``;

export const Title = styled.h3`
  ${cssLocalizedFontFamilySansSerif};
  margin: 0;
`;

const LAYOUT_LARGE_MAX_IMAGE_WIDTH = 300;
export const LAYOUT_LARGE_CROP_X = 12;
export const LAYOUT_LARGE_CROP_Y = 7;

export const layoutLarge = css`
  ${WrapperInner} {
    padding: 10px 0;
  }

  ${HexOutlineBackground},
  ${HexOutlineForeground} {
    left: 10px;
  }

  ${Image} {
    flex: 1 1 45%;
    max-width: ${LAYOUT_LARGE_MAX_IMAGE_WIDTH + "px"};
    overflow: hidden; /* IE10 flexbox workaround - prevent inner image from overflowing container */
  }

  ${ImageInner} {
    padding-top: ${Math.round((LAYOUT_LARGE_CROP_Y / LAYOUT_LARGE_CROP_X) * 10000) / 100 + "%"};
  }

  ${Info} {
    padding-right: 4%;
  }

  ${Title} {
    margin-top: 10px;
    ${cssLocalizedFontFamilySerif}
    font-size: ${rem(26)};
    ${cssLocalizedItalic}
    font-weight: 800;
    letter-spacing: 0.7px;
    ${cssLocalizedUppercase}
  }
`;

const LAYOUT_SIMPLE_MIN_IMAGE_WIDTH = 70;
const LAYOUT_SIMPLE_MAX_IMAGE_WIDTH = 120;

export const layoutSimple = css`
  ${HexOutlineBackground},
  ${HexOutlineForeground} {
    display: none;
  }

  ${Image} {
    flex: 1 1 auto;
    width: 120px;
  }

  ${Title} {
    margin-top: 10px;
    font-size: 14px;
  }
`;

export const layoutOutline = css`
  ${WrapperInner} {
    padding: 3% 0;
  }

  ${HexOutlineBackground} {
    display: none;
  }

  ${HexOutlineForeground} {
    left: 3%;
  }

  ${Image} {
    flex: 1 1 50%;
    min-width: ${LAYOUT_SIMPLE_MIN_IMAGE_WIDTH + "px"};
    max-width: ${LAYOUT_SIMPLE_MAX_IMAGE_WIDTH + "px"};
  }

  ${Info} {
    flex: 1 1 75%;
    min-width: 0;
    padding-right: 4%;
  }

  ${Title} {
    font-size: 14px;
  }
`;

export const IMAGES_SIZES = [LAYOUT_LARGE_MAX_IMAGE_WIDTH, LAYOUT_SIMPLE_MAX_IMAGE_WIDTH];
