import React from "react";
import { Props } from "..";
import { ImageAssetUrl } from "../../../../contentstack/content-types/assets/typings";
import { Champion } from "../@types";
import ComponentImage from "../../../components/image";
import { UrlFieldType } from "../../../../contentstack/fields/url";
import ContentstackImage from "../../../../contentstack/components/image";

export interface SectionChampionListContentstackProps {
  champions: Array<
    Pick<Champion, "id" | "difficulty" | "name" | "roles"> & {
      link: UrlFieldType;
      splashImageUrl: string;
      profileImageUrl?: ImageAssetUrl;
    }
  >;
}

export const transformContentstackPropsToSectionProps = (props: SectionChampionListContentstackProps): Props => {
  return {
    champions: props.champions.map(({ splashImageUrl, profileImageUrl, ...championProps }) => {
      const image = profileImageUrl ? (
        <ContentstackImage alt="" src={profileImageUrl} objectFit="cover" responsiveSizes={[250]} />
      ) : (
        <ComponentImage alt="" src={splashImageUrl} objectFit="cover" />
      );

      return {
        ...championProps,
        image,
      };
    }),
  };
};
