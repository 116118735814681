import React from "react";

import { MapOverlay } from "./style";

export interface Props {
  isActive: boolean;
  assets: string[];
}

const GuideMapOverlay: React.FC<Props> = ({ isActive, assets }) => {
  return (
    <MapOverlay
      asset3x={assets[0]}
      asset2x={assets[1]}
      asset1x={assets[2]}
      className={`${isActive ? "is-active" : ""}`}
    />
  );
};

const MemoizedGudieMapOverlay = React.memo(GuideMapOverlay, (oldProps, newProps) => {
  return !(oldProps.isActive !== newProps.isActive);
});

export default MemoizedGudieMapOverlay;
