import { useIntl } from "gatsby-plugin-intl";
import React, { useState } from "react";
import { laneSvgMap, laneNameMap } from "../../../../assets/lanes";
import { roleSvgMap, roleNameMap } from "../../../../assets/roles";
import ChampionDifficultyIndicator from "../../../../components/indicator/champion-difficulty";

import { Champion } from "../typings";
import {
  SpecsWrapper,
  Name,
  Title,
  SpecList,
  SpecItem,
  SpecItemLabel,
  DifficultyWrapper,
  IconWrapper,
  Icon,
  Description,
  Container,
} from "./style";

interface ChampionSpecsProps {
  champions: Champion[];
  activeChampion: Champion;
  testId?: string;
}

const ChampionSpecs: React.FC<ChampionSpecsProps> = ({ champions, children, activeChampion, testId }) => {
  const [roleIcons] = useState(champions.map((champion) => Icon.withComponent(roleSvgMap[champion.role])));
  const [laneIcons] = useState(champions.map((champion) => Icon.withComponent(laneSvgMap[champion.lane])));

  // {champions.map((champion, index) => {
  //   const isActive = champion === activeChampion
  //   const RoleIcon = roleIcons[index]
  //   const LaneIcon = laneIcons[index]
  const intl = useIntl();
  return (
    <Container>
      <SpecsWrapper>
        <Name>
          {champions.map((champion, index) => (
            <span key={index} className={`${champion === activeChampion ? "is-active" : ""}`} data-testid={`${testId}:championname-${index}`}>
              {intl.formatMessage({ id: champion.name })}
            </span>
          ))}
        </Name>
        <Title>
          {champions.map((champion, index) => (
            <span key={index} className={`${champion === activeChampion ? "is-active" : ""}`} data-testid={`${testId}:championtitle-${index}`}>
              {intl.formatMessage({ id: champion.title })}
            </span>
          ))}
        </Title>
        <SpecList>
          <SpecItem className={"difficulty"}>
            <SpecItemLabel data-testid={`${testId}:difficultystring`}>{intl.formatMessage({ id: "champion-difficulty.title" })}</SpecItemLabel>
            <DifficultyWrapper>
              {champions.map((champion, index) => (
                <ChampionDifficultyIndicator
                  key={index}
                  className={`${champion === activeChampion ? "is-active" : ""}`}
                  level={champion.difficulty}
                  testId={`${testId}:difficulty-${index}`}
                />
              ))}
            </DifficultyWrapper>
          </SpecItem>

          <SpecItem>
            <SpecItemLabel data-testid={`${testId}:rolestring`}>{intl.formatMessage({ id: "champion-role.title" })}</SpecItemLabel>
            <IconWrapper>
              {champions.map((champion, index) => {
                const RoleIcon = roleIcons[index];
                return (
                  <RoleIcon
                    key={index}
                    className={`${champion === activeChampion ? "is-active" : ""}`}
                    aria-label={intl.formatMessage({ id: roleNameMap[champion.role] })}
                    data-testid={`${testId}:roleicon-${index}`}
                  />
                );
              })}
            </IconWrapper>
          </SpecItem>

          <SpecItem>
            <SpecItemLabel data-testid={`${testId}:lanestring`}>{intl.formatMessage({ id: "suggested-lane.title" })}</SpecItemLabel>
            <IconWrapper>
              {champions.map((champion, index) => {
                const LaneIcon = laneIcons[index];
                return (
                  <LaneIcon
                    key={index}
                    className={`${champion === activeChampion ? "is-active" : ""}`}
                    aria-label={intl.formatMessage({ id: laneNameMap[champion.lane] })}
                    data-testid={`${testId}:laneicon-${index}`}
                  />
                );
              })}
            </IconWrapper>
          </SpecItem>
        </SpecList>

        <Description>
          {champions.map((champion, index) => (
            <span key={index} className={`${champion === activeChampion ? "is-active" : ""}`} data-testid={`${testId}:championdescription-${index}`}>
              {intl.formatMessage({ id: champion.description })}
            </span>
          ))}
        </Description>

        {children}
      </SpecsWrapper>
    </Container>
  );
};

export default ChampionSpecs;
