import styled from "styled-components";
import { rem } from "../../../../utils/style";
import { MOBILE_MARGIN, COLUMN_WIDTH, MEDIA_MOBILE_ALL } from "../../../../layouts/default/theme";

export const Container = styled.div`
  overflow: hidden;

  position: absolute;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;
`;

export const TitleWrapper = styled.div`
  margin: 18vh ${COLUMN_WIDTH}px 0;

  @media ${MEDIA_MOBILE_ALL} {
    margin: 15vh ${MOBILE_MARGIN}px 0;
  }
`;

export const IntroWrapper = styled.div`
  margin: 0 ${COLUMN_WIDTH}px 1.5rem;
  opacity: 0;

  @media ${MEDIA_MOBILE_ALL} {
    margin: 0 ${MOBILE_MARGIN}px 1.5rem;
  }
`;

export const ButtonWrapper = styled.div`
  text-align: center;
`;
