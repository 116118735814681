import React from "react";
import SectionChampionExplorer, {
  defaultProps as championExplorerDefaultProps,
  ChampionExplorerProps,
} from "../../sections/how-to-play/champion-explorer";
import SectionHero from "../../sections/how-to-play/hero";
import SectionAbout from "../../sections/how-to-play/about";
import SectionGuide from "../../sections/how-to-play/guide";
import SectionLevel from "../../sections/how-to-play/level";
import SectionSeparatorHexLine from "../../sections/separator/hex-line";
import SectionHud, { copyProps as hudCopyProps } from "../../sections/how-to-play/hud";
import SectionReward, { defaultProps as defaultRewardProps } from "../../sections/how-to-play/reward";

export interface Props {
  championListLink?: ChampionExplorerProps["link"];
}

const PageSection: React.FC<Props> = ({ championListLink }) => {
  // const subnavItems = [
  //   {
  //     id: 'about',
  //     name: intl.formatMessage({id: "about.title" }),
  //     targetSelector: '.about',
  //   },
  //   {
  //     id: 'goal',
  //     name: intl.formatMessage({id: "goal.title" }),
  //     targetSelector: '.goal',
  //   },
  //   {
  //     id: 'structures',
  //     name: intl.formatMessage({id: "structures.title" }),
  //     targetSelector: '.structures',
  //   },
  //   {
  //     id: 'neutral-monsters',
  //     name: intl.formatMessage({id: "neutral-monsters.title" }),
  //     targetSelector: '.neutral-monsters',
  //   },
  //   {
  //     id: 'lane-positions',
  //     name: intl.formatMessage({id: "lane-positions.title" }),
  //     targetSelector: '.lane-positions',
  //   },
  //   {
  //     id: 'powering-up',
  //     name: intl.formatMessage({id: "powering-up.title" }),
  //     targetSelector: '.powering-up',
  //   },
  //   {
  //     id: 'skills-and-abilities',
  //     name: intl.formatMessage({id: "skills-and-abilities.title" }),
  //     targetSelector: '.skills-and-abilities',
  //   },
  //   {
  //     id: 'rewards',
  //     name: intl.formatMessage({id: "rewards.title" }),
  //     targetSelector: '.rewards',
  //   },
  //   {
  //     id: 'starter-champions',
  //     name: intl.formatMessage({id: "starter-champions.title" }),
  //     targetSelector: '.starter-champions',
  //   },
  // ]

  const championExplorerProps = {
    ...championExplorerDefaultProps,
    link: championListLink ? championListLink : championExplorerDefaultProps.link,
  };

  return (
    <React.Fragment>
      <SectionHero className="about" />

      <div>
        <SectionAbout />

        <SectionGuide />

        <SectionLevel className="powering-up" />
        <SectionSeparatorHexLine />
        <SectionHud className="skills-and-abilities" {...hudCopyProps} />
        <SectionReward className="rewards" {...defaultRewardProps} />
        <SectionChampionExplorer className="starter-champions" {...championExplorerProps} />

        {/* <Subnav items={subnavItems}></Subnav> */}
      </div>
    </React.Fragment>
  );
};

export default PageSection;
