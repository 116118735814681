import React, { ReactElement, useState, useRef, useContext } from "react";
import { useInView, useTickerWithScrollActivation } from "../../utils/hooks";
import { lerpClamped } from "../../utils/math";
import { ManagerContext } from "../../managers";

import { Section, SectionInner, ResponsiveWrapper, GiantTitle, ItemWrapper, Item } from "./style";

import PromoItem from "../../components/promo-item";
import SectionTitle from "../../components/section-title";
import { LinkProps } from "../../components/link";

export const SectionWithTitle = Section.withComponent(SectionTitle);

export interface PromoItem {
  text: string;
  image: ReactElement;
  link: LinkProps;
}

export interface Props {
  sectionTitle?: string;
  giantTitle?: string;
  promoItems: PromoItem[];
  promoItemZIndex?: number;
  className?: string;
}

const PromoList: React.FC<Props> = ({ sectionTitle, giantTitle, promoItems, promoItemZIndex = 10, className }) => {
  const { viewport } = useContext(ManagerContext);

  const itemWrapperRef = useRef<HTMLUListElement>(null);

  // Not updated using setter, updated out of react state for performance reasons
  const [offset] = useState({ left: 0, right: 0 });

  const [intersectionRef, inView, entry] = useInView({
    threshold: 0, // Intersecting at all
  });

  useTickerWithScrollActivation(
    itemWrapperRef.current,
    ({}, activation) => {
      if (!inView) return;
      if (!itemWrapperRef.current) return;

      const { width, height, scrollTop } = viewport.latest;
      const quickerActivation = Math.min(activation.direct.in * 2, 1);

      offset.left = lerpClamped(offset.left, quickerActivation, 0.15, 0.001);
      offset.right = lerpClamped(offset.right, quickerActivation, 0.1, 0.001);

      // const children = itemWrapperRef.current.querySelectorAll<HTMLLIElement>(
      //   Item.toString()
      // )
      const children: HTMLDivElement[] = Object.values(itemWrapperRef.current.children).map(
        (child) => child as HTMLDivElement,
      );

      if (children[0]) {
        children[0].style.transform = `translate3d( 0, ${height * 0.33 * (1 - offset.left)}px, 0 )`;
      }
      if (children[1]) {
        children[1].style.transform = `translate3d( 0, ${height * 0.33 * (1 - offset.right)}px, 0 )`;
      }
    },
    [inView, itemWrapperRef],
  );

  return (
    <SectionWithTitle text={sectionTitle} hasGiantTitle={!!giantTitle} testId='promosection'>
      <SectionInner ref={intersectionRef}>
        <ResponsiveWrapper className={className}>
          {giantTitle ? <GiantTitle isActive={inView} text={giantTitle} transitionDelay={0} testId='promosection'/> : null}

          <ItemWrapper ref={itemWrapperRef}>
            {promoItems.map(({ text, image: originalImage, link }, index) => {
              const image = React.cloneElement(originalImage, {
                lazy: !inView,
              });
              return (
                <Item
                  key={text + link.url}
                  style={{
                    zIndex: promoItemZIndex,
                  }}
                >
                  <PromoItem isActive={inView} text={text} image={image} link={link} index={index} testId={`promosection-${index}`}/>
                </Item>
              );
            })}
          </ItemWrapper>
        </ResponsiveWrapper>
      </SectionInner>
    </SectionWithTitle>
  );
};

export default PromoList;
