import React, { useState, useEffect, useContext, useRef } from "react";
import { useResize, useTicker } from "../../../../utils/hooks";
import { clamp, lerpClamped, ease } from "../../../../utils/math";
import { ManagerContext } from "../../../../managers";

import { MapOuter, MapCamera, MapPlane, MapAsset } from "./style";

import { Chapter, Topic, HotspotType } from "../typings";
import Hotspot from "../guide-map-hotspot";
import Overlay from "../guide-map-overlay";

export interface Props {
  isColumnInstance: boolean;
  isInViewport: boolean;
  isStuck: boolean;
  activeChapter: Chapter;
  activeTopicIndex: number;
  updateTopic: (chapter: Chapter, index: number) => void;
  topics: Topic[];
}

const GuideMap: React.FC<Props> = ({
  isColumnInstance,
  isInViewport,
  isStuck,
  activeChapter,
  activeTopicIndex,
  updateTopic,
  topics,
}) => {
  const { viewport, input } = useContext(ManagerContext);

  const cameraRef = useRef<any>(null);
  const planeRef = useRef<any>(null);

  const [baseZoomLevel, setBaseZoomLevel] = useState(1);
  const [hasEntered, setHasEntered] = useState(false);
  const [enterStartTime, setEnterStartTime] = useState(0);

  // Not updated using setter, updated out of react state for performance reasons
  const [eased] = useState({ x: 0, y: 0, scroll: 0 });

  function calculateBaseZoomLevel(camera: HTMLElement, plane: HTMLElement) {
    const cameraBounds = camera.getBoundingClientRect();
    const planeBounds = plane.getBoundingClientRect();
    const zoomLevel = cameraBounds.width / planeBounds.width;

    // Clamp between 30% to 100%
    return clamp(zoomLevel, 0.3, 1.0);
  }

  useEffect(() => {
    if (isInViewport && !hasEntered) {
      setHasEntered(true);
      setEnterStartTime(typeof window === "undefined" ? 0 : performance.now());
    }
  }, [isInViewport]);

  useResize(() => {
    setBaseZoomLevel(calculateBaseZoomLevel(cameraRef.current, planeRef.current));
    eased.scroll = viewport.latest.scroll;
  });

  useTicker(
    ({ elapsed }) => {
      if (!isInViewport) return;

      const DURATION = 1800;
      const DELAY = 200;
      const enterProgress = clamp((elapsed - (enterStartTime + DELAY)) / DURATION, 0, 1);

      const opacity = ease.outQuart(enterProgress);
      const scale = baseZoomLevel + (1 - ease.outQuart(enterProgress)) * -0.05;

      if (isColumnInstance) {
        cameraRef.current.style.transform = `scale3d( ${scale}, ${scale}, 1 )`;
      } else {
        const { normal } = input.latest;
        const { scroll, width, height } = viewport.latest;

        eased.x = lerpClamped(eased.x, normal.x * 2 - 1, 0.05, 0.02);
        eased.y = lerpClamped(eased.y, normal.y * 2 - 1, 0.05, 0.02);
        eased.scroll = lerpClamped(eased.scroll, scroll, 0.04, 0.0005);

        const translateX = width * 0.008 * -eased.x;
        const translateY = height * 0.008 * -eased.y + (1 - ease.outQuart(enterProgress)) * height * 0.1;
        // + (eased.scroll - scroll) * height * 0.8

        cameraRef.current.style.transform = `translate3d( ${translateX}px, ${translateY}px, 0 ) scale3d( ${scale}, ${scale}, 1 )`;
      }

      cameraRef.current.style.opacity = `${opacity}`;
    },
    [isInViewport, cameraRef, planeRef, baseZoomLevel, enterStartTime],
  );

  return (
    // <MapOuter className={`${hasEntered ? 'is-activated' : ''}`}>
    <MapOuter>
      <MapCamera ref={cameraRef} safariZoomLevel={baseZoomLevel}>
        <MapPlane ref={planeRef}>
          <MapAsset>
            {
              // Topics
              Object.values(topics).map(
                ({ overlays, topicChapter, allTopicsIndex = 0 }, topicIndex) =>
                  // Overlays
                  overlays &&
                  Object.values(overlays).map(({ assets }, index) => (
                    <Overlay
                      key={`overlay-${index}`}
                      assets={assets}
                      isActive={
                        (isColumnInstance && topicIndex === activeTopicIndex) ||
                        (topicChapter === activeChapter && allTopicsIndex === activeTopicIndex)
                      }
                    />
                  )),
              )
            }
          </MapAsset>
          {
            // Topics
            Object.values(topics).map(
              ({ hotspots, topicChapter, allTopicsIndex = 0, priorTopicCount = 0 }, topicIndex) =>
                // Hotspots
                Object.values(hotspots).map(({ ...config }) => {
                  return (
                    <Hotspot
                      key={allTopicsIndex}
                      index={allTopicsIndex}
                      hasEntered={hasEntered}
                      isStuck={isStuck}
                      isVisible={isColumnInstance || topicChapter === activeChapter}
                      isActive={
                        (isColumnInstance && topicIndex === activeTopicIndex) ||
                        (topicChapter === activeChapter && allTopicsIndex === activeTopicIndex)
                      }
                      onClick={() => updateTopic(activeChapter, topicIndex - priorTopicCount)}
                      {...config}
                    />
                  );
                }),
            )
          }
        </MapPlane>
      </MapCamera>
    </MapOuter>
  );
};

const MemoizedGuideMap = React.memo(GuideMap, (oldProps, newProps) => {
  return !(
    oldProps.isInViewport !== newProps.isInViewport ||
    oldProps.isStuck !== newProps.isStuck ||
    oldProps.activeChapter !== newProps.activeChapter ||
    oldProps.activeTopicIndex !== newProps.activeTopicIndex
  );
});

export default MemoizedGuideMap;
