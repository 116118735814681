import styled from "styled-components";
import { rem } from "../../utils/style";
import { cssLocalizedFontFamilySerif, cssLocalizedUppercase, cssLocalizedItalic } from "../css";

export const Wrapper = styled.article``;

export const Inner = styled.a`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  height: 100%;
  margin: 0 10px;
  padding: 10px 0;
  border: 0;
  background-color: transparent;

  &:hover {
    text-decoration: none;
  }
`;

export const Image = styled.div`
  position: relative;
  width: calc(100% + 20px);
  margin-left: -10px;
  padding-top: ${(9 / 16) * 100}%;
`;

export const ImageInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  * {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

export const Text = styled.div`
  flex: 1 1 auto;
  padding: 10%;
  margin: 0 -10px;
  text-align: center;
  word-wrap: break-word;
  background-color: #f6f6f6;

  color: #1a1d21;
  ${cssLocalizedFontFamilySerif}
  font-size: ${rem(30)};
  ${cssLocalizedUppercase}
  font-weight: 800;
  ${cssLocalizedItalic}
`;
