import styled from "styled-components";
import { rem } from "../../../utils/style";

import SectionTitleComponent from "../../../components/section-title";
import ImageComponent from "../../../components/image";

import { HudLayerType } from "./typings";
import {
  MEDIA_MOBILE_ALL,
  MEDIA_DESKTOP_NARROW,
  MAX_WIDTH,
  MEDIA_TABLET_AND_SMALLER,
  MOBILE_MARGIN,
  COLOR_OUTLINE_LIGHT_GREY,
  COLOR_BACKGROUND_BLACK,
} from "../../../layouts/default/theme";
import { cssLocalizedLineHeight } from "../../../components/css";

export const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  box-sizing: content-box;
  height: 350px;
  padding-bottom: 25vw;
  width: 100%;

  margin-top: 100px;

  background: linear-gradient(to bottom, transparent 80%, ${COLOR_BACKGROUND_BLACK} 85%);

  /* @media (min-width: ${MAX_WIDTH}px) {
    min-height: 70vw;
  }

  @media (min-width: ${MAX_WIDTH + 200}px) {
    min-height: 65vw;
  }

  @media (min-width: ${MAX_WIDTH + 400}px) {
    min-height: 60vw;
  } */

  @media ${MEDIA_TABLET_AND_SMALLER} {
    height: 500px;
  }

  @media ${MEDIA_MOBILE_ALL} {
    height: 100%;
    /* padding-bottom: 0; */
    /* padding-bottom: 150px;
    min-height: ${rem(660)};
    padding-bottom: 0;
    margin-top: 50px; */
  }
`;

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  @media ${MEDIA_MOBILE_ALL} {
    overflow: hidden;
  }
`;

export const BackgroundImage = styled(ImageComponent)`
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

export const SectionTitle = styled(SectionTitleComponent)`
  position: relative;
`;

export const Selector = styled.div`
  position: relative;
  max-width: 600px;
  margin: 40px auto 0;
  z-index: 1;

  @media ${MEDIA_TABLET_AND_SMALLER} {
    border-top: 1px solid ${COLOR_OUTLINE_LIGHT_GREY};
    border-bottom: 1px solid ${COLOR_OUTLINE_LIGHT_GREY};
  }

  @media ${MEDIA_MOBILE_ALL} {
    max-width: none;
    width: calc(100% + ${MOBILE_MARGIN * 2}px);
    margin-left: -${MOBILE_MARGIN}px;
    margin-left: -${MOBILE_MARGIN}px;
  }
`;

export const DescriptionList = styled.div`
  min-height: 100px;
`;

export const Description = styled.p`
  max-width: 560px;
  margin: 15px auto 0;
  text-align: center;
  font-size: 12px;
  ${cssLocalizedLineHeight(1.5)}
  letter-spacing: 0.1em;
  display: none;

  &.active {
    display: block;
  }
`;

export const HudContainer = styled.div`
  width: 100%;
  // overflow: auto;
`;

const HUD_MIN_WIDTH = 486; // 530
export const Hud = styled.div<{ maxWidth: number }>`
  max-width: ${({ maxWidth }) => maxWidth + "px"};
  min-width: ${HUD_MIN_WIDTH}px;
  margin: 30px auto 0;

  @media ${MEDIA_MOBILE_ALL} {
    margin-top: 10px;
  }

  @media (max-width: ${HUD_MIN_WIDTH + 100}px) {
    transition: transform 1s;
    &.option-0 {
      transform: translate3d(0, 0, 0);
    }
    &.option-1 {
      transform: translate3d(-50px, 0, 0);
    }
    &.option-2 {
      transform: translate3d(-100px, 0, 0);
    }
  }
  @media (max-width: 450px) {
    &.option-1 {
      transform: translate3d(-95px, 0, 0);
    }
    &.option-2 {
      transform: translate3d(-180px, 0, 0);
    }
  }
  @media (max-width: 400px) {
    &.option-1 {
      transform: translate3d(-115px, 0, 0);
    }
    &.option-2 {
      transform: translate3d(-195px, 0, 0);
    }
  }
`;

export const HudInner = styled.div<{ maxWidth: number; maxHeight: number }>`
  position: relative;
  width: 100%;
  padding-top: ${({ maxWidth, maxHeight }) => Math.round((maxHeight / maxWidth) * 10000) / 100 + "%"};
`;

export const HudLayerStatic = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;

  ${`&.layer-${HudLayerType.BASE}`} {
    z-index: 1;
  }

  ${`&.layer-${HudLayerType.ABILITES}`} {
    z-index: 3;
    left: 18.6%;
    top: 16.2%;
  }

  ${`&.layer-${HudLayerType.SPELLS}`} {
    z-index: 3;
    left: 58.2%;
    top: 18%;
  }

  ${`&.layer-${HudLayerType.ITEMS}`} {
    z-index: 3;
    left: 73.6%;
    top: 16.9%;
  }
`;

export const HudLayerInteractive = HudLayerStatic.withComponent(styled.button`
  padding: 0;
  border: 0;
  background-color: transparent;

  opacity: 0.3;
  transition: opacity 300ms;

  &:hover {
    opacity: 0.7;
  }

  &.active {
    opacity: 1;
  }
`);

export const LayerImage = styled.img`
  display: block;
  width: 100%;
  height: 100%;
`;
