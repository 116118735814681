import styled, { css } from "styled-components";
import { easing } from "../../utils/style";
import { COLOR_BACKGROUND_DARK } from "../../layouts/default/theme";

export const Wrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;

  z-index: 1;

  width: 100%;
  height: 100%;
`;

export const ImageWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  &::after {
    content: "";

    position: absolute;
    left: 0;
    top: 0;

    z-index: 2;

    width: 100%;
    height: 100%;
  }
`;

export const Image = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  opacity: 0;
  transition: opacity 0.3s 0.3s ${easing.easeInOutCubic};

  &.is-active {
    z-index: 1;

    opacity: 1;
    transition-delay: 0s;
  }

  * {
    width: 100%;
    height: 100%;
  }
`;

export const Fade = styled.div<{ fadeColor: string }>`
  position: absolute;
  left: 0;

  z-index: 2;

  width: 100%;
  height: 45%;

  transform: translateZ(0);

  &.upper {
    top: 0;

    background: linear-gradient(to top, transparent, ${(props) => props.fadeColor} 80%);
  }

  &.lower {
    bottom: 0;

    background: linear-gradient(to bottom, transparent, ${(props) => props.fadeColor} 80%);
  }
`;
