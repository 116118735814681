import styled from "styled-components";
import { easing } from "../../../../utils/style";

const BREAKPOINT_2X = 600;
const BREAKPOINT_3X = 1400;

export const MapOverlay = styled.div<{
  asset3x: string;
  asset2x: string;
  asset1x: string;
}>`
  position: absolute;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;

  pointer-events: none;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;

  background-image: url(${(props) => props.asset1x});

  @media only screen and (min-width: ${BREAKPOINT_2X}px) {
    background-image: url(${(props) => props.asset2x});
  }

  @media only screen and (min-width: ${BREAKPOINT_3X}px) {
    background-image: url(${(props) => props.asset3x});
  }

  opacity: 0;
  visibility: hidden;
  transition: opacity 0.8s ${easing.easeInOutQuart}, visibility 0s 0.8s;

  &.is-active {
    opacity: 1;
    visibility: visible;
    transition: opacity 1.6s 0.25s ${easing.easeInOutCubic}, visibility 0s 0s;
  }
`;
