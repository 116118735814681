import React, { useState } from "react";
import { COLOR_OUTLINE_MEDIUM_GREY } from "../../../../layouts/default/theme";

import {
  Wrapper,
  WrapperInner,
  HexOutlineBackground,
  HexOutlineForeground,
  Image,
  ImageInner,
  Info,
  Category,
  Title,
} from "./style";
import { Article as ArticleType } from "../types";
import LinkComponent from "../../../../components/link";

export interface SectionNewsHighlightsListProps extends ArticleType {
  className?: string;
  testId?: string
}

const SectionNewsHighlightsList: React.FC<SectionNewsHighlightsListProps> = ({
  category,
  image,
  link,
  title,
  className,
  isVisible = true,
  fadeInDelay = 0,
  testId,
}) => {
  const [isHovering, setIsHovering] = useState(false);
  return (
    <Wrapper
      {...link}
      className={`${className} ${isVisible ? "isVisible" : ""}`}
      as={LinkComponent}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      data-testid={testId}
    >
      <WrapperInner>
        <HexOutlineBackground
          isActive={isVisible}
          clipRightTop={15}
          fillColor="white"
          strokeColor="transparent"
          transitionDuration={1500}
          transitionDelay={fadeInDelay}
        />

        <Image delay={fadeInDelay + 800}>
          <ImageInner data-testid={`${testId}:image`}>{image}</ImageInner>
        </Image>

        <Info delay={fadeInDelay + 1000}>
          <Category data-testid={`${testId}:category`}>{category}</Category>
          <Title data-testid={`${testId}:title`}>{title}</Title>
        </Info>

        <HexOutlineForeground
          isActive={isVisible}
          clipRightTop={15}
          transitionDuration={1500}
          transitionDelay={fadeInDelay}
          interactive
          hovering={isHovering}
          hoverDuration={300}
          accentColor={COLOR_OUTLINE_MEDIUM_GREY}
        />
      </WrapperInner>
    </Wrapper>
  );
};

export default SectionNewsHighlightsList;
export { layoutLarge, layoutOutline, layoutSimple } from "./style";
