import React, { useState, useEffect, useContext, useCallback, useRef } from "react";
import { useTicker } from "../../../../utils/hooks";
import { lerpClamped } from "../../../../utils/math";
import { ManagerContext } from "../../../../managers";

import {
  Container,
  ContainerInner,
  ContainerInner2,
  MapCamera,
  BackgroundLayer,
  BackgroundLayerImage,
  ForegroundLayer,
  MockLayerContent,
  layerTransform,
} from "./style";
import mapAsset1440 from "./assets/player-guide-map-1440.png";
import mapAsset1024 from "./assets/player-guide-map-1024.png";
import mapAsset800 from "./assets/player-guide-map-800.png";
import mapAsset600 from "./assets/player-guide-map-600.png";

// tslint:disable-next-line: no-implicit-dependencies
// import mapAssetDimensions from 'image-dimensions-loader!./assets/map.png'
// import mapAssetDimensions from 'image-dimensions-loader!./assets/player-guide-map.png'
import { rgba } from "polished";
import { srcset } from "../../../../components/image";

const ASSET_WIDTH = 1440;
const ASSET_HEIGHT = 950;

export interface Controls {
  setProgress: (progress: number) => void;
}

export interface Props {
  isInViewport: boolean;
  className?: string;
  controlsRef?: (controls: Controls) => void;
  testId?: string;
}

const Map = React.forwardRef<HTMLDivElement, Props>(({ isInViewport, className, controlsRef, testId }, ref) => {
  const { viewport, input } = useContext(ManagerContext);

  const cameraRef = useRef<any>(null);
  const [passive] = useState({ x: 0, y: 0 });

  const [hasEntered, setHasEntered] = useState(false);

  useEffect(() => {
    if (!hasEntered && isInViewport) setHasEntered(true);
  }, [isInViewport]);

  const layersContainer = useCallback((node: HTMLDivElement) => {
    if (!controlsRef) return;
    if (!node) return;

    // get all layers
    const layerNodes = Array.from(node.querySelectorAll<HTMLDivElement>(ForegroundLayer.toString()));

    // expose layer manipilations functions
    // controlsRef({
    //   setProgress: (progress) => {
    //     // ensure progress value is not out of bounds
    //     if (progress < 0) progress = 0
    //     else if (progress > 1) progress = 1

    //     const distanceBetweenLayers = Math.min(
    //       viewport.latest.height * 0.2,
    //       150
    //     )

    //     layerNodes.forEach((layerNode, i) => {
    //       layerNode.style.transform = layerTransform(
    //         -(distanceBetweenLayers * (i + 1)) * progress
    //       )
    //     })
    //   },
    // })
  }, []);

  useTicker(
    ({ elapsed }) => {
      if (!isInViewport) return;

      const { normal, isTouch } = input.latest;
      const { width, height } = viewport.latest;

      passive.x = lerpClamped(passive.x, normal.x * 2 - 1, 0.05, 0.005);
      passive.y = lerpClamped(passive.y, normal.y * 2 - 1, 0.05, 0.005);
      const translateX = isTouch ? 0 : width * 0.008 * -passive.x;
      const translateY = isTouch ? 0 : height * 0.008 * -passive.y;

      cameraRef.current.style.transform = `translate3d( ${translateX}px, ${translateY}px, 0 )`;
    },
    [isInViewport, cameraRef],
  );

  const layers = [
    {
      text: "Nexus",
      color: rgba("#28cfcc", 0.5),
    },
    {
      text: "Turret & Dragons",
      color: rgba("#ceac2a", 0.5),
    },
    {
      text: "Lanes",
      color: rgba("#1721cb", 0.5),
    },
  ];

  return (
    <Container className={`${className} ${hasEntered ? "is-activated" : ""}`} ref={ref}>
      <ContainerInner>
        <ContainerInner2 cssHeight={(ASSET_HEIGHT / ASSET_WIDTH) * 100 + "%"} ref={layersContainer}>
          <MapCamera ref={cameraRef}>
            <BackgroundLayer>
              <BackgroundLayerImage
                src={mapAsset1440}
                lazy={!isInViewport}
                srcSet={srcset({
                  "600w": mapAsset600,
                  "800w": mapAsset800,
                  "1024w": mapAsset1024,
                  "1440w": mapAsset1440,
                })}
                testId={testId}
              />
            </BackgroundLayer>

            {/* {layers.map(({ text, color }, i) => (
                <ForegroundLayer key={text}>
                  <MockLayerContent style={{ backgroundColor: color }}>
                    {text}
                  </MockLayerContent>
                </ForegroundLayer>
              ))} */}
          </MapCamera>
        </ContainerInner2>
      </ContainerInner>
    </Container>
  );
});

export default Map;
