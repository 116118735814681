import React, { useState, useEffect } from "react";
import { easing } from "../../../../utils/style";
import { Wrapper, Row, Title, Description } from "./style";

import Reveal from "../../../../components/reveal";

export interface Props {
  isInViewport: boolean;
  heading?: string;
  description?: string;
  toggleContrast?: boolean;
  transitionDelay?: number;
  transitionDuration?: number;
  className?: string;
  testId?: string;
}

// const Section: React.FC<Props> = ({
const Section = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      isInViewport = true,
      heading,
      description,
      toggleContrast = false,
      transitionDelay = 0,
      transitionDuration = 2000,
      testId,
    },
    ref,
  ) => {
    const [hasEntered, setHasEntered] = useState(false);

    useEffect(() => {
      if (!hasEntered && isInViewport) {
        setHasEntered(true);
      }
    }, [isInViewport]);

    return (
      <Wrapper ref={ref} toggleContrast={toggleContrast}>
        {heading ? (
          <Row>
            <Reveal
              inline
              isActive={hasEntered}
              showDir={"se"}
              transitionDuration={transitionDuration}
              transitionDelay={transitionDelay}
              transitionTiming={easing.easeOutQuart}
            >
              <Title data-testid={`${testId}:title`}>{heading}</Title>
            </Reveal>
          </Row>
        ) : null}
        {description ? (
          <Row>
            <Reveal
              inline
              isActive={hasEntered}
              showDir={"nw"}
              transitionDuration={transitionDuration + 1000}
              transitionDelay={transitionDelay + 150}
              transitionTiming={easing.easeOutQuart}
            >
              <Description data-testid={`${testId}:description`}>{description}</Description>
            </Reveal>
          </Row>
        ) : null}
      </Wrapper>
    );
  },
);

Section.displayName = "Section";

export default Section;
