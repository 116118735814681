export enum Chapter {
  OBJECTIVES = "OBJECTIVES",
  TARGETS = "TARGETS",
  NEUTRALS = "NEUTRALS",
  POSITIONS = "POSITIONS",
}

export enum HotspotType {
  ZOOM_BLUE = "ZOOM_BLUE",
  ZOOM_RED = "ZOOM_RED",
  ZOOM_NEUTRAL = "ZOOM_NEUTRAL",
  ICON = "ICON",
}

export interface Topic {
  topicChapter?: Chapter;
  allTopicsIndex?: number;
  priorTopicCount?: number;
  topicTitle: string;
  topicDesc: string;
  hotspots: Array<{
    type: HotspotType;
    icon?: React.FC;
    asset?: string;
    x: number;
    y: number;
  }>;
  overlays?: Array<{
    assets: [string, string, string];
  }>;
}

export type ChapterMap = {
  [x in Chapter]: {
    sectionTitle: string;
    chapterTitle: string;
    chapterDesc: string;
    topics: Topic[];
  };
};
