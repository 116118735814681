import React from "react";
import { Item } from "..";
import experienceVideo720Webm from "./videos/experience-720.webm";
import experienceVideo720Mp4 from "./videos/experience-720.mp4";
import experienceVideo360Webm from "./videos/experience-360.webm";
import experienceVideo360Mp4 from "./videos/experience-360.mp4";
import goldVideo720Webm from "./videos/gold-720.webm";
import goldVideo720Mp4 from "./videos/gold-720.mp4";
import goldVideo360Webm from "./videos/gold-360.webm";
import goldVideo360Mp4 from "./videos/gold-360.mp4";

import shopImageSrc from "./shopkeeper.jpg";

const items: Item[] = [
  {
    videoSources: [
      <source
        key={0}
        src={experienceVideo720Webm}
        srcSet={`${experienceVideo360Webm} 360w, ${experienceVideo720Webm} 720w`}
        type="video/webm"
      />,
      <source
        key={1}
        src={experienceVideo720Mp4}
        srcSet={`${experienceVideo360Mp4} 360w, ${experienceVideo720Mp4} 720w`}
        type="video/mp4"
      />,
    ],
    title: "earn-experience.title",
    description: "section.how-to-play.level.earn-experience.description",
  },
  {
    videoSources: [
      <source
        key={0}
        src={goldVideo720Webm}
        srcSet={`${goldVideo360Webm} 360w, ${goldVideo720Webm} 720w`}
        type="video/webm"
      />,
      <source
        key={1}
        src={goldVideo720Mp4}
        srcSet={`${goldVideo360Mp4} 360w, ${goldVideo720Mp4} 720w`}
        type="video/mp4"
      />,
    ],
    title: "earn-gold.title",
    description: "section.how-to-play.level.earn-gold.description",
  },
  {
    isImageItem: true,
    imageSource: shopImageSrc,
    title: "shop.title",
    description: "shop.description",
  },
];

export default items;
