import React from "react";
import Select, { OptionTypeBase } from "react-select";
import { Props as SelectProps } from "react-select/src/Select";
import { Props as StateManagerProps } from "react-select/src/stateManager";
import { SelectComponentsConfig } from "react-select/src/components";

import DropdownIndicatorArrow from "./custom-components/dropdown-indicator-arrow";
import ValueContainerLoupe from "./custom-components/value-container-loupe";

import { Dropdown } from "./style";

const StyledSelect = Dropdown.withComponent(Select);

function GenericComponent<OptionType extends OptionTypeBase, IsMulti extends boolean = false>(): React.FC<
  StateManagerProps<OptionType, IsMulti> & SelectProps<OptionType>
> {
  // NOTE: initialize outside of react component to ensure components are not recreated on every state change
  const DropdownIndicator = DropdownIndicatorArrow<OptionType, IsMulti>();
  const ValueContainer = ValueContainerLoupe<OptionType, IsMulti>();

  const Component: React.FC<StateManagerProps<OptionType, IsMulti> & SelectProps<OptionType>> = ({
    className,
    isClearable = true,
    isSearchable = false,
    isBorderLeft = false,
    components,
    classNamePrefix = "dropdown",
    theme,
    ...controlProps
  }) => {
    if (theme !== undefined) {
      console.warn(`Warning:THEME::`, theme);
    }
    const customComponents: SelectComponentsConfig<OptionType, IsMulti> = {};
    if (!isSearchable) customComponents.DropdownIndicator = DropdownIndicator;
    if (isSearchable) customComponents.ValueContainer = ValueContainer;

    return (
      <StyledSelect
        // menuIsOpen={true}
        borderLeft={isBorderLeft}
        className={`${isSearchable ? "searchable" : ""} ${className || ""}`}
        classNamePrefix={classNamePrefix || ""}
        isClearable={isClearable}
        isSearchable={isSearchable}
        components={{ ...customComponents, ...components }}
        {...controlProps}
      />
    );
  };

  return Component;
}

export default GenericComponent;
export { prefixedClass as prefixedDropdownClass } from "./style";
