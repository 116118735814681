import { graphql, PageProps } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import { useFullURL } from "../../../../../lib/utils/hooks";
import { openGraphImageUrl } from "../../../../../lib/assets/share";
import { transformContentstackPropsToSectionProps as transformGameIntro } from "../../../../../lib/section-groups/game-intro/transforms/contentstack";
import { transformContentstackPropsToSectionProps as transformHowToPlay } from "../../../../../lib/section-groups/how-to-play/transforms/contentstack";
import { transformContentstackPropsToSectionProps as transformPlayerGuide } from "../../../../../lib/section-groups/player-guide/transforms/contentstack";
import { transformContentstackPropsToSectionProps as transformArticleList } from "../../../../../lib/sections/category/article-list/transforms/contentstack";
import { transformContentstackPropsToSectionProps as transformCategoryIntro } from "../../../../../lib/sections/category/intro/transforms/contentstack";
import { transformContentstackPropsToSectionProps as transformChampionList } from "../../../../../lib/sections/champion-list/transforms/contentstack";
import { transformContentstackPropsToSectionProps as transformDownloadApp } from "../../../../../lib/sections/download-app/transforms/contentstack";
import { transformContentstackPropsToSectionProps as transformDownloadGameBasic } from "../../../../../lib/sections/download-game-basic/transforms/contentstack";
import { transformContentstackPropsToSectionProps as transformGameModes } from "../../../../../lib/sections/home/game-modes/transforms/contentstack";
import { transformContentstackPropsToSectionProps as transformHomeHero } from "../../../../../lib/sections/home/hero/transforms/contentstack";
import { transformContentstackPropsToSectionProps as transformNewsItemList } from "../../../../../lib/sections/news-item-list/transforms/contentstack";
import { transformContentstackPropsToSectionProps as transformNewsHeader } from "../../../../../lib/sections/news/header/transforms/contentstack";
import { transformContentstackPropsToSectionProps as transformNewsHighlightsList } from "../../../../../lib/sections/news/highlights-list/transforms/contentstack";
import { transformContentstackPropsToSectionProps as transformNewsLatest } from "../../../../../lib/sections/news/latest/transforms/contentstack";
import { transformContentstackPropsToSectionProps as transformNewsPopular } from "../../../../../lib/sections/news/popular/transforms/contentstack";
import { transformContentstackPropsToSectionProps as transformNewsSocial } from "../../../../../lib/sections/news/social/transforms/contentstack";
import { transformContentstackPropsToSectionProps as transformNewsYoutube } from "../../../../../lib/sections/news/youtube/transforms/contentstack";
import { transformContentstackPropsToSectionProps as transformPromoList } from "../../../../../lib/sections/promo-list/transforms/contentstack";
import { transformContentstackPropsToSectionProps as transformPromo } from "../../../../../lib/sections/promo/transforms/contentstack";
import { transformContentstackPropsToSectionProps as transformTwitch } from "../../../../../lib/sections/twitch/transforms/contentstack";
import Template from "../../../../../lib/templates/page";
import { Section, SectionType } from "../../../../../lib/templates/page/typings";
import transform from "../../transforms/all-landing-pages-query-node-to-default-template-context-data";
import { ContentstackSection, TemplateContext } from "./typings";
import { PageTranslationQueryResult, pageTranslationsToAlternates } from "../../../../fragments/page-translations";

interface Edge<T> {
  node: T;
}
interface Edges<T> {
  edges: Edge<T>[];
}

interface Props extends PageProps {
  // TODO: Major refactor needed to bring sanity to these types.
  data: {
    all: Edges<any>;
    configuration: Edges<any>;
    allChampions: Edges<any>;
    allArticles: Edges<any>;
  } & PageTranslationQueryResult;
  pageContext: TemplateContext;
}

const byPublishedLocale =
  (locale: string) =>
  (edge: Edge<any>): boolean =>
    edge.node.publish_details.locale === locale;

const DefaultTemplate: React.FC<Props> = ({ data, pageContext }) => {
  const language = pageContext.language;
  const byContextLocale = byPublishedLocale(language);
  const node = data.all.edges.filter(byContextLocale)[0]?.node;
  const configurationNode = data.configuration.edges.filter(byContextLocale)[0]?.node;

  const championNodes = data.allChampions.edges.filter(byContextLocale).map((e: any) => e.node);
  const articleNodes = data.allArticles.edges.filter(byContextLocale).map((e: any) => e.node);
  const pageTranslations = data.pageTranslations.nodes;

  const defaultOpenGraphImage = useFullURL(openGraphImageUrl);
  const pageURL = useFullURL(`/${language}${node.url.url}`);
  const baseURL = useFullURL("/");

  // Validate that we got the data we need
  if (!node || !configurationNode || !championNodes || !articleNodes) {
    throw new Error(
      `Missing data for landing page! ${JSON.stringify({ node, configurationNode, championNodes, articleNodes })}`,
    );
  }
  // create download app section
  const { app_store_download_url, google_play_download_url } = configurationNode.league_friends_app;

  const downloadAppSections: ContentstackSection[] =
    app_store_download_url || google_play_download_url
      ? [
          {
            type: SectionType.DOWNLOAD_APP,
            props: {
              appleStoreDownloadLink: app_store_download_url
                ? {
                    url: app_store_download_url,
                    internal: false,
                  }
                : undefined,
              googleStoreDownloadLink: google_play_download_url
                ? {
                    url: google_play_download_url,
                    internal: false,
                  }
                : undefined,
            },
          },
        ]
      : [];
  const tData = transform(language, node, configurationNode, championNodes, articleNodes);
  tData.sections = [...tData.sections, ...downloadAppSections];
  const { uid, description, meta, image, sections } = tData;

  return (
    <React.Fragment>
      <GatsbySeo
        title={meta.title}
        description={description}
        openGraph={{
          type: "website",
          url: pageURL,
          title: meta.title,
          description,
          images: [{ url: defaultOpenGraphImage }],
        }}
        language={language}
        languageAlternates={pageTranslationsToAlternates(baseURL, pageTranslations)}
        // Note:
        // Twitter will read the og:title, og:image and og:description tags for
        // their card. gatsby-plugin-next-seo omits twitter:title, twitter:image
        // and twitter:description to avoid duplication.
        // TODO: Remove the duplicated twitterImageUrl file from this repository.
      />
      <Template id={uid} sections={dataSectionsToArticleSections(sections)} />
    </React.Fragment>
  );
};
export default DefaultTemplate;

const dataSectionsToArticleSections = (dataSections: ContentstackSection[]): Section[] => {
  return dataSections
    .map((csSection, index) => {
      let props;

      switch (csSection.type) {
        case SectionType.CATEGORY_ARTICLE_LIST_CONTENTSTACK:
          props = transformArticleList(csSection.props);
          break;

        case SectionType.CATEGORY_INTRO:
          props = transformCategoryIntro(csSection.props);
          break;

        case SectionType.CHAMPION_LIST:
          props = transformChampionList(csSection.props);
          break;

        case SectionType.DOWNLOAD_APP:
          props = transformDownloadApp(csSection.props);
          break;

        case SectionType.DOWNLOAD_GAME_BASIC:
          props = transformDownloadGameBasic(csSection.props);
          break;

        case SectionType.GAME_INTRO:
          props = transformGameIntro(csSection.props);
          break;

        case SectionType.GAME_MODES:
          props = transformGameModes(csSection.props);
          break;

        case SectionType.HOME_HERO:
          props = transformHomeHero(csSection.props);
          break;

        case SectionType.HOW_TO_PLAY:
          props = transformHowToPlay(csSection.props);
          break;

        case SectionType.NEWS_HEADER:
          props = transformNewsHeader(csSection.props);
          break;

        case SectionType.NEWS_HIGHLIGHTS_LIST:
          props = transformNewsHighlightsList(csSection.props);
          break;

        case SectionType.NEWS_ITEM_LIST:
          props = transformNewsItemList(csSection.props);
          break;

        case SectionType.NEWS_LATEST:
          props = transformNewsLatest(csSection.props);
          break;

        case SectionType.NEWS_POPULAR:
          props = transformNewsPopular(csSection.props);
          break;

        case SectionType.NEWS_SOCIAL:
          props = transformNewsSocial(csSection.props);
          break;

        case SectionType.NEWS_YOUTUBE:
          props = transformNewsYoutube(csSection.props);
          break;

        case SectionType.PLAYER_GUIDE:
          props = transformPlayerGuide(csSection.props);
          break;

        case SectionType.PROMO:
          props = transformPromo(csSection.props);
          break;

        case SectionType.PROMO_LIST:
          props = transformPromoList(csSection.props);
          break;

        case SectionType.TWITCH:
          props = transformTwitch(csSection.props);
          break;
      }

      if (!props) {
        console.warn(`Unable to transform section. (type: ${csSection.type})`);
        return null;
      }

      return {
        type: csSection.type,
        key: `section_${index}`,
        props,
      };
    })
    .filter((section): section is Section => section !== null);
};

export const query = graphql`
  # If the page query cache needs to change with context changes, then we need
  # $path and a query for contextChangeNotInvalidingQueryWorkaround
  # Since this page only uses locale and url, and those are essentially the query
  # cache key anyways, then we don't need to add this hack.
  #
  query ($language: String!, $url: String!, $uid: String!, $path: String!) {
    # use our workaround and alias null result to something short (_)
    # that doesn't expose what it does and saves few bytes
    _: contextChangeNotInvalidingQueryWorkaround(path: $path)

    #query ($language: String!, $url: String!, $uid: String!) {
    all: allContentstackLolComLandingPages(
      filter: { publish_details: { locale: { eq: $language } }, url: { url: { eq: $url } } }
    ) {
      edges {
        node {
          publish_details {
            locale
          }
          uid
          title
          meta_description
          meta_title
          url {
            url
          }
          meta_image {
            url
          }
          modular_blocks {
            hardcoded_module {
              hardcoded_module_selection {
                module_id
              }
            }
            game_modes_module {
              label
              title
              cta {
                href
                title
              }
              videos {
                url
                title
                subtitle
                description
                background_image {
                  url
                }
                thumbnail_image {
                  url
                }
                video {
                  video {
                    file {
                      url
                      content_type
                    }
                    height
                    width
                  }
                }
              }
            }
            footer_video {
              footer_videos_entry {
                video {
                  video {
                    file {
                      url
                      content_type
                    }
                    height
                    width
                  }
                }
                title
              }
            }
            twitch_featured {
              twitch_streams {
                description
                title
                type
                video_id
                link {
                  href
                  title
                }
              }
              label
              title
            }
            two_column_featured {
              title
              label
              items {
                title
                tag_entries {
                  ... on Contentstack_categories {
                    articles(limit: 2) {
                      ...ContentstackArticleExcerpt
                    }
                  }
                  ... on Contentstack_tags {
                    articles(limit: 2) {
                      ...ContentstackArticleExcerpt
                    }
                  }
                }
                image {
                  url
                  uid
                }
                content_entry {
                  __typename
                  ... on Contentstack_articles {
                    ...ContentstackArticleExcerpt
                  }
                }
              }
            }
            featured_news {
              label
              items {
                title
                image {
                  url
                  uid
                }
                description
                content_entry {
                  __typename
                  ... on Contentstack_articles {
                    ...ContentstackArticleExcerpt
                  }
                }
                tag_entries {
                  ... on Contentstack_tags {
                    articles(limit: 3) {
                      ...ContentstackArticleExcerpt
                    }
                  }
                  ... on Contentstack_categories {
                    articles(limit: 3) {
                      ...ContentstackArticleExcerpt
                    }
                  }
                }
              }
              cta {
                href
                title
              }
            }
            highlights {
              label
              items {
                title
                image {
                  url
                  uid
                }
                description
                content_entry {
                  __typename
                  ... on Contentstack_articles {
                    ...ContentstackArticleExcerpt
                  }
                }
                tag_entries {
                  ... on Contentstack_tags {
                    articles(limit: 3) {
                      ...ContentstackArticleExcerpt
                    }
                  }
                  ... on Contentstack_categories {
                    articles(limit: 3) {
                      ...ContentstackArticleExcerpt
                    }
                  }
                }
              }
            }
            latest_news {
              category_selection {
                category_selection {
                  __typename
                  ... on Contentstack_categories {
                    url {
                      url
                    }
                    title
                    uid
                  }
                  ... on Contentstack_tags {
                    url {
                      url
                    }
                    uid
                    title
                  }
                }
                category_thumbnail {
                  url
                  uid
                }
              }
              articles(limit: 5, locale: $language) {
                ...ContentstackArticleExcerpt
              }
            }
            popular_external {
              label
              items {
                banner {
                  uid
                  url
                  fields {
                    dimension {
                      height
                      width
                    }
                  }
                }
                link {
                  href
                  title
                }
              }
            }
            popular_internal {
              cta {
                href
                title
              }
              label
              items {
                content_entry {
                  __typename
                  ... on Contentstack_articles {
                    ...ContentstackArticleExcerpt
                  }
                  banner {
                    fields {
                      dimension {
                        height
                        width
                      }
                    }
                  }
                }
                banner {
                  uid
                  url
                  fields {
                    dimension {
                      height
                      width
                    }
                  }
                }
              }
            }
            youtube_channel {
              label
              name
              videos(limit: 10) {
                thumbnails {
                  height
                  size
                  url
                  width
                }
                title
                videoId
                description
              }
              channel {
                title
                channelId
                customUrl
              }
            }
            promo_module {
              promo_module_selection {
                uid
                promo_title
                subtitle
                description
                hide_title
                include_description
                promo_reference {
                  __typename
                  ... on Contentstack_articles {
                    ...ContentstackArticleExcerpt
                  }
                }
                promo_full_width_banner {
                  url
                  uid
                }
              }
            }
            social_divider {
              title
              items {
                icon
                url
              }
            }
            hero {
              content_entry {
                __typename
                ... on Contentstack_articles {
                  ...ContentstackArticleExcerpt
                }
              }
              title
              description
              tag_entries {
                ... on Contentstack_tags {
                  articles(limit: 1) {
                    ...ContentstackArticleExcerpt
                  }
                }
              }
              image {
                uid
                url
              }
            }
          }
        }
      }
    }
    configuration: allContentstackWebsiteConfiguration(filter: { publish_details: { locale: { eq: $language } } }) {
      edges {
        node {
          publish_details {
            locale
          }
          league_friends_app {
            app_store_download_url
            google_play_download_url
          }
          urls {
            game_download
          }
          pages {
            champion_list {
              url {
                url
              }
            }
            home {
              uid
              url {
                url
              }
            }
            how_to_play_guide {
              url {
                url
              }
            }
            news {
              url {
                url
              }
            }
            latest_news {
              uid
              url {
                url
              }
            }
          }
        }
      }
    }
    allChampions: allContentstackReadOnlyChampions(
      filter: { publish_details: { locale: { eq: $language } } }
      sort: { order: ASC, fields: champion_name }
    ) {
      edges {
        node {
          publish_details {
            locale
          }
          uid
          url
          champion_name
          champion_splash
          recommended_roles
          difficulty
          champion {
            profile_image {
              url
            }
          }
        }
      }
    }
    allArticles: allContentstackArticles(
      filter: { hide_from_lol_com: { eq: false }, publish_details: { locale: { eq: $language } } }
      limit: 200
      sort: { order: DESC, fields: date }
    ) {
      edges {
        node {
          publish_details {
            locale
          }
          ...ContentstackArticleExcerpt
          category {
            uid
          }
          article_tags {
            uid
          }
        }
      }
    }
    ...PageTranslationsFragment
  }
`;
