import styled, { css, keyframes } from "styled-components";
import { easing } from "../../../utils/style";
import {
  MEDIA_MOBILE_ALL,
  COLOR_PRIMARY_BLUE,
  MAX_WIDTH,
  COLOR_BACKGROUND_DARK,
  COLUMN_WIDTH,
  MEDIA_DESKTOP_NARROW,
  MEDIA_DESKTOP_AND_SMALLER,
} from "../../../layouts/default/theme";

import HexOutline from "../../../components/hex-outline";

import patternUrl from "./assets/pattern.png";
import { Plus, Minus } from "../../../assets/symbols";
import { cssLocalizedLineHeight, cssLocalizedUppercase } from "../../../components/css";

export const Wrapper = styled.section`
  position: relative;
  z-index: 1;

  padding: 120px 0;

  background-image: url(${patternUrl});
  background-repeat: repeat-x;

  @media ${MEDIA_MOBILE_ALL} {
    padding: 50px 0;
  }
`;

export const ContentContainer = styled.div`
  position: relative;
  display: flex;
`;

export const ContentContainerHexOutline = styled(HexOutline)`
  z-index: 1;
`;

export const AssetContainer = styled.div`
  position: relative;
`;

export const AssetList = styled.ol`
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;

  width: 100%;
  padding-top: ${(9 / 16) * 100 + "%"};
`;

export const AssetItem = styled.li`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* opacity: 0; */

  &.active {
    z-index: 1;
    /* opacity: 1; */

    /* transition: opacity 1s ${easing.easeInOutQuart}; */
  }

  > * {
    width: 100%;
    height: 100%;
  }
`;

export const Info = styled.div`
  /* In IE10 pointer-events: none is not supported, so the HexOutline 
    canvas sits above the accordions, blocking access. */
  html.is-ms-ie & {
    position: relative;
    z-index: 2;
  }
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 300px;

  margin: 0;
  padding: 20px 0;
  list-style: none;
`;

export const ListItem = styled.li`
  border-top: 1px solid #c1c1c1;
  padding: 30px;

  &:first-child {
    border-top: 0;
  }
`;

export const AssetHexOutline = styled(HexOutline)`
  z-index: 1;
`;

export const DetailsSummary = styled.summary`
  position: relative;

  cursor: pointer;
  ${cssLocalizedUppercase}
  letter-spacing: 0.08em;
  font-size: 14px;
  font-weight: bold;

  /* hide bullet created by browser */
  &:first-of-type {
    list-style-type: none;
  }
  &::-webkit-details-marker {
    display: none;
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

// HACK 2019-10-17 jeremboo: To animate the accordion, I had to not use the <details> tag
// export const Details = styled.details`
export const Details = styled.div<{ open: boolean }>`
  &[open] {
  }
`;

const ListItemIcon = css`
  position: absolute;
  left: -20px;
  top: 4px;

  width: 10px;
  height: 10px;

  fill: ${COLOR_PRIMARY_BLUE};

  transition: opacity 0.5s;
`;

const ListItemIconPlusSvg = styled.svg`
  ${ListItemIcon}

  opacity: 1;

  ${`${Details}[open]`} & {
    opacity: 0;
  }
`;

const ListItemIconMinusSvg = styled.svg`
  ${ListItemIcon}

  opacity: 0;

  ${`${Details}[open]`} & {
    opacity: 1;
  }
`;

export const ListItemIconPlus = ListItemIconPlusSvg.withComponent(Plus);
export const ListItemIconMinus = ListItemIconMinusSvg.withComponent(Minus);

export const DetailsSummaryText = styled.span`
  display: inline-block;
  vertical-align: middle;
`;

export const DetailsContent = styled.p`
  margin-top: 5px;
  font-size: 12px;
  letter-spacing: 0.1em;
  ${cssLocalizedLineHeight(1.5)}
  overflow: hidden;
  max-height: 0px;
  transition: max-height 0.5s ease-in-out;

  ${`${Details}[open]`} & {
    transition: max-height 0.5s ease-in-out;
    max-height: 150px;
    animation: ${fadeIn} 0.5s ${easing.easeInOutCubic};
  }
`;

const rowLayout = css`
  ${ContentContainer} {
    align-items: center;

    width: calc(100% - ${COLUMN_WIDTH * 2}px);
    max-width: ${MAX_WIDTH - COLUMN_WIDTH * 4}px;
    margin: 55px auto 0;

    @media ${MEDIA_DESKTOP_NARROW} {
      width: 100%;
    }
  }

  ${AssetContainer} {
    flex: 1 1 auto;
    min-width: 0;

    > *:last-child {
      display: none;
    }
  }

  ${Info} {
    box-sizing: border-box;
    flex: 0 1 500px;
    min-width: 420px;
    padding: 0 50px;
  }
`;

const columnLayout = css`
  ${ContentContainer} {
    flex-direction: column;

    > *:last-child {
      display: none;
    }
  }

  ${AssetContainer} {
    margin-top: 30px;
  }

  ${List} {
    justify-content: flex-start;
    height: 350px;
  }

  ${Info} {
    margin-top: 30px;
  }
`;

const BREAKPOINT_MOBILE = 1000;
const BREAKPOINT_DESKTOP = BREAKPOINT_MOBILE + 1;

export const ResponsiveWrapper = styled(Wrapper)`
  @media (min-width: ${BREAKPOINT_DESKTOP}px) {
    ${rowLayout}
  }

  @media (max-width: ${BREAKPOINT_MOBILE}px) {
    ${columnLayout}
  }
`;
