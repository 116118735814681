import styled, { css } from "styled-components";
import { fluidRange } from "polished";

import {
  MAX_WIDTH,
  COLUMN_WIDTH,
  MOBILE_MARGIN,
  BREAKPOINT_TABLET_ALL,
  BREAKPOINT_DESKTOP_NARROW,
  MEDIA_TABLET_AND_SMALLER,
  MEDIA_MOBILE_ALL,
  MEDIA_NOT_MOBILE,
  BREAKPOINT_MOBILE_LARGE,
} from "../../../layouts/default/theme";
import { easing, hoverEffect } from "../../../utils/style";
import ArrowLink from "../../../components/arrow-link";
import { cssResponsiveListPullSideFn } from "../../../components/section-title";
import { HorizontalTitle } from "../../../components/section-title/style";
import { default as CategoryComponent } from "./category";
import {
  rowLayout as articleExcerptRowLayout,
  columnLayout as articleExcerptColumnLayout,
} from "../../../components/article-excerpt";

export const Wrapper = styled.div``;

export const Content = styled.div`
  display: flex;
  max-width: ${MAX_WIDTH - COLUMN_WIDTH * 4}px;
  margin: 0 auto;
`;

export const MobileHorizontalSectionTitle = styled(HorizontalTitle)``;

export const Main = styled.div``;

export const ArticleList = styled.ol`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const ArticleItem = styled.li``;

export const CategoryList = styled.ol`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const CategoryItem = styled.li``;

export const Category = styled(CategoryComponent)``;

export const LinkContainer = styled.div`
  margin-top: 6%;
  border-top: 1px solid #c1c1c1;
  padding-top: 3%;
  text-align: right;
`;
export const Link = styled(ArrowLink)``;

const rowLayout = css`
  ${Main} {
    flex: 1 1 auto;
    width: 100%;
    min-width: 0;
    padding-right: 4%;
  }

  ${CategoryList} {
    position: relative;
    flex: 1 1 auto;
    align-self: flex-start;
    min-width: 300px;
    padding-left: 4%;
    padding-bottom: 4%;

    &.is-carousel {
      display: none;
    }

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      width: 1px;
      height: 100%;
      background-color: #c1c1c1;
      transform: scale(1, 0);
      transform-origin: top center;
      transition: transform 2s ${easing.easeInOutCubic} 0.25s;
    }
  }

  ${ArticleItem} {
    padding: 40px 0;

    /* ${hoverEffect.background()} */
  }

  ${CategoryItem} {
    margin-top: 20px;

    &:first-child {
      margin-top: 50px;
    }
  }
`;

const columnLayout = css`
  ${Content} {
    flex-direction: column;
  }

  ${CategoryList} {
    overflow: hidden;
    display: flex;
    order: 1;
    padding-bottom: 4%;

    display: none;
    &.is-carousel {
      display: block;
    }

    > div {
      /* Flickity */
      width: 100%;
    }
  }

  ${CategoryItem} {
    flex: 1 0 auto;
    width: 40%;
    min-width: 100px;
    max-width: 155px;
    margin-right: 12px;

    &:last-child {
      margin-right: 0;
    }
  }

  ${Main} {
    order: 2;

    margin-top: 4%;
    border-top: 1px solid #c1c1c1;
  }

  ${ArticleItem} {
    margin-top: ${MOBILE_MARGIN * 2}px;
  }
`;

export const ResponsiveWrapper = styled(Wrapper)`
  &.isVisible {
    ${CategoryList}::before {
      transform: scale(1, 1);
    }
  }

  ${fluidRange(
    ["margin-top", "margin-bottom"].map((prop) => ({
      prop,
      fromSize: "50px",
      toSize: "70px",
    })),
    BREAKPOINT_MOBILE_LARGE + 1 + "px",
    BREAKPOINT_TABLET_ALL + "px",
  )}

  @media (min-width: ${BREAKPOINT_TABLET_ALL + 1 + "px"}) {
    ${rowLayout}
  }

  @media (max-width: ${BREAKPOINT_DESKTOP_NARROW + 100}px) {
    ${CategoryList} {
      min-width: 23%;
    }
  }

  @media ${MEDIA_TABLET_AND_SMALLER} {
    ${columnLayout}
  }

  /* Article excerpt responsive layout */
  @media ${MEDIA_NOT_MOBILE} {
    ${articleExcerptRowLayout()}
  }
  @media ${MEDIA_MOBILE_ALL} {
    ${articleExcerptColumnLayout()}
  }

  ${cssResponsiveListPullSideFn({
    List: CategoryList,
    Item: CategoryItem,
    breakpointLarge: BREAKPOINT_TABLET_ALL,
  })}
`;
