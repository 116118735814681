import styled from "styled-components";
import { rem } from "../../../utils/style";
import {
  MEDIA_MOBILE_ALL,
  MEDIA_MOBILE_SMALL,
  MEDIA_TABLET_ALL,
  MEDIA_TABLET_SMALL,
  MEDIA_DESKTOP_AT_MAX_WIDTH,
  COLOR_BACKGROUND_DARK,
  COLUMN_WIDTH,
  MAX_WIDTH,
  MEDIA_MOBILE_TINY,
  MEDIA_MOBILE_LARGE,
  MEDIA_MOBILE_MEDIUM,
  BREAKPOINT_MOBILE_LARGE,
  MEDIA_DESKTOP_AND_SMALLER,
  COLOR_BACKGROUND_BLACK,
  COLOR_BACKGROUND_WHITE,
} from "../../../layouts/default/theme";

export const Section = styled.section`
  position: relative;
  box-sizing: border-box;

  height: 100vh;
  min-height: ${rem(1050)};
  max-height: 120vw;

  @media ${MEDIA_DESKTOP_AT_MAX_WIDTH} {
    min-height: ${rem(1220)};
  }

  @media ${MEDIA_TABLET_ALL} {
    min-height: ${rem(940)};
  }

  @media ${MEDIA_TABLET_SMALL} {
    min-height: ${rem(980)};
  }

  @media ${MEDIA_MOBILE_ALL} {
    overflow: hidden;
    max-height: 140vw;
  }

  @media ${MEDIA_MOBILE_ALL} {
    min-height: ${rem(690)};
  }

  @media ${MEDIA_MOBILE_LARGE} {
    min-height: ${rem(690)};
  }

  @media (min-width: 501px) and (max-width: ${BREAKPOINT_MOBILE_LARGE}px) {
    min-height: ${rem(800)};
  }

  @media (min-width: 450px) and (max-width: 500px) {
    min-height: ${rem(760)};
  }

  @media ${MEDIA_MOBILE_MEDIUM} {
    min-height: ${rem(640)};
  }

  @media ${MEDIA_MOBILE_SMALL} {
    min-height: ${rem(620)};
  }

  @media ${MEDIA_MOBILE_TINY} {
    min-height: ${rem(590)};
  }

  background-color: ${COLOR_BACKGROUND_DARK};
`;

export const BackdropContainer = styled.div`
  position: absolute;

  width: 100%;
  height: 100%;

  top: 0;
  left: 0;

  background-color: ${COLOR_BACKGROUND_WHITE};

  &::before,
  &::after {
    content: "";

    position: absolute;
    left: 0;
    bottom: 0;

    width: 100%;
  }

  &::before {
    z-index: 0;

    height: 120%;

    background: linear-gradient(to bottom, ${COLOR_BACKGROUND_WHITE} 0%, #b8f2ff 40%);

    @media ${MEDIA_MOBILE_ALL} {
      height: 100%;
    }
  }

  &::after {
    height: 25%;

    /* z-index: 1; */

    background: linear-gradient(to bottom, transparent 0%, ${COLOR_BACKGROUND_BLACK} 90%);
  }
`;

export const SectionInner = styled.div`
  position: relative;

  max-width: ${rem(MAX_WIDTH - COLUMN_WIDTH * 2)};
  height: 100%;
  margin: 0 auto;

  @media ${MEDIA_MOBILE_ALL} {
    padding: 0;
  }
`;

export const Lockup = styled.div`
  box-sizing: border-box;

  position: relative;

  width: 100%;
  height: 100%;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
`;

export const TitleGroup = styled.div`
  position: relative;
  z-index: 1;

  width: 100%;
  text-align: center;

  margin-top: ${rem(100)};

  @media ${MEDIA_MOBILE_ALL} {
    margin-top: ${rem(60)};
  }

  &.is-wider {
    position: absolute;
    left: calc(50% + 10vw);
    top: 30%;

    z-index: 1;

    width: 35vw;
    margin-top: 0;

    p {
      padding-left: 15%;
      padding-right: 15%;
    }

    @media (max-width: 1400px) {
      left: auto;
      right: 0;
      top: 33%;

      p {
        padding-left: 10%;
        padding-right: 10%;
      }
    }
  }
`;

export const Title = styled.h3`
  width: 100%;
`;

export const Button = styled.button`
  margin: ${rem(30)} 0 0;

  @media ${MEDIA_MOBILE_ALL} {
    margin-top: ${rem(20)};
  }
`;
