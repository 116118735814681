import React from "react";

import { GameMode } from "../typings";
import { Wrapper, Item, Headline, Desc } from "./style";

interface Props {
  gameModes: GameMode[];
  activeMode: GameMode;
  testId?: string;
}

const ModeDescription: React.FC<Props> = ({ gameModes, activeMode, testId }) => {
  return (
    <Wrapper>
      {gameModes.map((mode, index) => {
        const isActive = index === gameModes.indexOf(activeMode);
        return (
          <Item key={index} className={`${isActive ? "is-active" : ""}`} data-testid={`${testId}:gamemodecontainer-${index}`}>
            <Headline data-testid={`${testId}:gamemodetitle-${index}`}>{mode.headline}</Headline>
            <Desc data-testid={`${testId}:gamemodedescription-${index}`}>{mode.description}</Desc>
          </Item>
        );
      })}
    </Wrapper>
  );
};

export default ModeDescription;
