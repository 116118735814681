import styled from "styled-components";
import { rem, easing } from "../../../../utils/style";
import { MEDIA_TABLET_AND_SMALLER, COLOR_TEXT_WHITE } from "../../../../layouts/default/theme";
import { cssLocalizedLineHeight, cssLocalizedUppercase } from "../../../../components/css";

export const Wrapper = styled.div`
  position: relative;

  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  text-align: center;

  color: ${COLOR_TEXT_WHITE};

  @media ${MEDIA_TABLET_AND_SMALLER} {
    width: 100%;
  }
`;

export const Item = styled.div`
  position: absolute;
  left: 0;
  top: 0;

  width: 100%;

  &.is-active {
    z-index: 1;
  }

  @media ${MEDIA_TABLET_AND_SMALLER} {
    box-sizing: border-box;

    padding: 0 ${rem(20)};
  }
`;

export const Headline = styled.p`
  margin-top: ${rem(20)};

  font-size: ${rem(14)};
  font-weight: 600;
  letter-spacing: 0.07em;
  ${cssLocalizedUppercase}

  transform: translateY(-4px);
  opacity: 0;

  transition: transform 0.5s ${easing.easeInCubic}, opacity 0.5s ${easing.easeInCubic};

  ${Item}.is-active & {
    transform: none;
    opacity: 1;

    transition-delay: 0.8s;
    transition-duration: 0.7s;
    transition-timing-function: ${easing.easeOutQuart};
  }

  @media ${MEDIA_TABLET_AND_SMALLER} {
    font-size: ${rem(13)};
    letter-spacing: 0.15em;
  }
`;

export const Desc = styled.p`
  margin-top: ${rem(4)};

  font-size: ${rem(14)};
  letter-spacing: 0.08em;
  ${cssLocalizedLineHeight(1.6)}

  height: ${rem(14 * 1.4 * 2)};

  transform: translateY(6px);
  opacity: 0;

  transition: transform 0.55s ${easing.easeInCubic}, opacity 0.55s ${easing.easeInCubic};

  ${Item}.is-active & {
    transform: none;
    opacity: 1;

    transition-delay: 0.82s;
    transition-duration: 0.75s;
    transition-timing-function: ${easing.easeOutQuart};
  }

  @media ${MEDIA_TABLET_AND_SMALLER} {
    margin-top: ${rem(5)};

    font-size: ${rem(12)};
    letter-spacing: 0.1em;
  }
`;
