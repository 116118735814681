import React from "react";
import { SectionNewsHighlightsListProps } from "..";
import { ImageAssetUrl } from "../../../../../contentstack/content-types/assets/typings";
import { UrlFieldType } from "../../../../../contentstack/fields/url";
import ContentstackImage from "../../../../../contentstack/components/image";
import { LAYOUT_LARGE_CROP_X, LAYOUT_LARGE_CROP_Y, IMAGES_SIZES } from "../article/style";

export interface SectionNewsHighlightsListContentstackProps
  extends Pick<SectionNewsHighlightsListProps, "sectionTitle"> {
  articles: Array<
    Pick<SectionNewsHighlightsListProps["articles"][0], "id" | "category" | "title"> & {
      url: UrlFieldType;
      imageUrl: ImageAssetUrl;
    }
  >;
}

export const transformContentstackPropsToSectionProps = ({
  articles,
  ...props
}: SectionNewsHighlightsListContentstackProps): SectionNewsHighlightsListProps => {
  return {
    ...props,
    articles: articles.map(({ imageUrl, url, ...articleProps }) => {
      try {
        return {
          ...articleProps,
          link: url,
          image: (
            <ContentstackImage
              alt=""
              src={imageUrl}
              crop={`${LAYOUT_LARGE_CROP_X}:${LAYOUT_LARGE_CROP_Y}`}
              responsiveSizes={IMAGES_SIZES}
              objectFit={"cover"}
            />
          ),
        };
      } catch (err) {
        console.error(`Error rendering featured images for article ${url} (${articleProps.title}): ${err}`);
        throw err;
      }
    }),
  };
};
