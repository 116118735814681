import styled, { css } from "styled-components";
import { MEDIA_TABLET_AND_SMALLER, MEDIA_NOT_MOBILE, MEDIA_MOBILE_ALL } from "../../../../layouts/default/theme";
import { easing, animation, hoverEffect } from "../../../../utils/style";
import {
  cssLocalizedFontFamilySerif,
  cssLocalizedLineHeight,
  cssLocalizedUppercase,
  cssLocalizedItalic,
} from "../../../../components/css";

export const Wrapper = styled.a<{ "data-animation-delay": number }>`
  position: relative;
  display: block;
  height: 100px;
  color: inherit;
  font-size: 20px;
  box-sizing: border-box;
  padding: 15px;

  &:hover {
    text-decoration: none;
  }

  ${(options) =>
    animation.triggeredTranslateFadeIn("&.isVisible", {
      delay: options["data-animation-delay"],
    })}

  @media ${MEDIA_MOBILE_ALL} {
    ${(options) =>
      animation.triggeredTranslateFadeIn("&.isVisible", {
        delay: options["data-animation-delay"] - 650,
      })}
  }

  /* iOS 13 and Flickity showcase buggy behavior when events are bubbling up from children */
  html.is-safari & {
    @media ${MEDIA_MOBILE_ALL} {
      > * {
        pointer-events: none;
      }
    }
  }
`;

export const Name = styled.span<{ animationDelay: number }>`
  position: relative;
  display: block;
  width: 50%;
  ${cssLocalizedUppercase}
  font-weight: bold;
  color: white;
  ${cssLocalizedItalic}
  ${cssLocalizedLineHeight(1)}
  ${cssLocalizedFontFamilySerif}
  letter-spacing: 0.1em;

  ${({ animationDelay }) =>
    animation.triggeredTranslateFadeIn(`${Wrapper}.isVisible &`, {
      delay: animationDelay,
    })}

  @media ${MEDIA_MOBILE_ALL} {
    ${({ animationDelay }) =>
      animation.triggeredTranslateFadeIn(`${Wrapper}.isVisible &`, {
        delay: animationDelay - 600,
      })}
  }
`;

export const Text = styled.span`
  display: block;
  transform: translate3d(0, 0, 0);
  transition: transform 300ms ${easing.easeOutQuad};
`;

export const Image = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 25px;
    padding-top: 25px;
    background-color: white;
    transform: translate3d(50%, -50%, 0) rotate(45deg);
    will-change: transform;
    transition: transform 0.5s;
  }

  ${hoverEffect.scaleDown(Wrapper)}

  > * {
    display: block;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
  }
`;

export const ResponsiveWrapper = styled(Wrapper)`
  @media ${MEDIA_NOT_MOBILE} {
    &:hover {
      text-decoration: none;

      ${Image}::after {
        transform: translate3d(100%, -100%, 0) rotate(45deg);
      }

      ${Text} {
        transform: translate3d(10px, 0, 0);
      }
    }
  }

  @media ${MEDIA_TABLET_AND_SMALLER} {
    font-size: 14px;
    height: 95px;
    padding: 10px;

    ${Name} {
      width: 100%;
    }
  }
`;
