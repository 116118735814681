import React, { useState, useRef } from "react";
import { easing } from "../../../../utils/style";
import { BREAKPOINT_COLUMN } from "../style";

import { Champion } from "../typings";
import { Wrapper, List, Item, Label, Thumb, ItemHighlight, HighlightDiamond, HighlightLens } from "./style";

import Image from "../../../../components/image";
import ResponsiveSelector from "../../../../components/responsive-selector";
import { clamp } from "../../../../utils/math";
import { useIntl } from "gatsby-plugin-intl";

interface ChampionSelectorProps {
  isInViewport: boolean;
  activeChampionIndex: number;
  champions: Champion[];
  onSelect: (name: Champion) => void;
  testId?: string;
}

const ChampionSelector: React.FC<ChampionSelectorProps> = ({ 
  isInViewport = false,
  activeChampionIndex, 
  champions, 
  onSelect, 
  testId 
}) => {
  const [activeElementIndex, setActiveElementIndex] = useState(activeChampionIndex);
  const [activeElementIndexLocal] = useState({ value: 0 });
  const listRef = useRef<HTMLUListElement>(null);
  const intl = useIntl();

  const onItemClick = (champion: Champion) => {
    const newIndex = champions.indexOf(champion);
    setActiveElementIndex(newIndex);
    activeElementIndexLocal.value = newIndex;
    onSelect(champion);
  };

  const onSwipe = (direction: number) => {
    const clampedIndex = clamp(activeElementIndexLocal.value + direction, 0, champions.length - 1);
    setActiveElementIndex(clampedIndex);
    activeElementIndexLocal.value = clampedIndex;
    onSelect(champions[clampedIndex]);
  };

  return (
    <Wrapper>
      <ItemHighlight>
        <HighlightLens />
        <HighlightDiamond />
      </ItemHighlight>

      <ResponsiveSelector
        isInViewport={isInViewport}
        onSwipe={onSwipe}
        activeElementIndex={activeChampionIndex}
        verticalBreakpoint={BREAKPOINT_COLUMN}
        transitionDuration={800}
        transitionDelay={0}
        transitionTimingFunction={easing.easeOutQuart}
      >
        <List ref={listRef}>
          {champions.map((champion, index) => (
            <Item
              key={champion.id}
              onClick={() => onItemClick(champion)}
              className={`${index === activeChampionIndex ? "is-active" : ""}`}
              data-testid={`${testId}:championselector-${index}`}
            >
              <Thumb>
                <Image
                  src={champion.assets.avatar}
                  alt={intl.formatMessage({ id: champion.name })}
                  objectFit={"cover"}
                  objectPosition={"center center"}
                />
              </Thumb>
              <Label>{intl.formatMessage({ id: champion.name })}</Label>
            </Item>
          ))}
        </List>
      </ResponsiveSelector>
    </Wrapper>
  );
};

export default ChampionSelector;
