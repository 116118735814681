import React, { useState, useEffect, useContext, useRef } from "react";
import { useTickerWithScrollActivation } from "../../../../utils/hooks";
import { ease, lerpClamped } from "../../../../utils/math";
import { ManagerContext } from "../../../../managers";

import ChampionRole from "../../../../enums/champion-role";

import { COLOR_PRIMARY_DARK_GOLD, COLOR_OUTLINE_DARK_GREY } from "../../../../layouts/default/theme";
import { Wrapper, Placement, HexWrapper } from "./style";

import HexOutline from "../../../../components/hex-outline";

import StyleSelectorChampion from "../style-selector-champion";

interface Props {
  isInViewport: boolean;
  testId?: string;
}

const CYCLE_DURATION = 2000;

const StyleSelector: React.FC<Props> = ({ isInViewport = false, testId }) => {
  const { viewport, input } = useContext(ManagerContext);

  const wrapperContainerRef = useRef<any>(null);
  const hexContainerRef = useRef<any>(null);

  const [autoCycling, setAutoCycling] = useState(isInViewport);
  const [cycleStartTime, setCycleStartTime] = useState(0);

  // Not updated using setter, updated out of react state for performance reasons
  const [passive] = useState({ x: 0, y: 0 });

  // Watch for intersection changes
  useEffect(() => {
    isInViewport ? setAutoCycling(true) : setAutoCycling(false);
    setCycleStartTime(typeof window === "undefined" ? 0 : performance.now());
  }, [isInViewport]);

  useTickerWithScrollActivation(
    wrapperContainerRef.current,
    ({}, activation) => {
      if (!isInViewport) return;

      const { width, height } = viewport.latest;

      passive.x = lerpClamped(passive.x, input.normal.x * 2 - 1, 0.03, 0.01);
      passive.y = lerpClamped(passive.y, input.normal.y * 2 - 1, 0.03, 0.01);

      // Wrapper scroll activation
      wrapperContainerRef.current.style.transform = `translate3d(${passive.x * -4}px, ${
        passive.y * -4 + 0.42 * (activation.in * 20.0 + activation.out * 100.0)
      }px, 0)`;

      // Hex passive
      hexContainerRef.current.style.transform = `translate3d( ${width * 0.004 * passive.x}px, ${
        height * 0.005 * passive.y + height * -0.05 * ease.inSine(activation.out)
      }px, 0 )`;
    },
    [isInViewport, wrapperContainerRef, hexContainerRef],
  );

  return (
    <Wrapper ref={wrapperContainerRef}>
      <Placement>
        <HexWrapper ref={hexContainerRef}>
          <HexOutline
            isActive={isInViewport}
            diamond
            countdown={autoCycling}
            countdownDuration={CYCLE_DURATION}
            countdownStartTime={cycleStartTime}
            strokeColor={COLOR_OUTLINE_DARK_GREY}
            accentColor={COLOR_PRIMARY_DARK_GOLD}
            accentThickness={3}
          />
        </HexWrapper>

        <StyleSelectorChampion
          isAutoCycling={autoCycling}
          cycleDuration={CYCLE_DURATION}
          cycleStartTime={cycleStartTime}
          isInViewport={isInViewport}
          testId={testId}
        />
      </Placement>
    </Wrapper>
  );
};

export default StyleSelector;
