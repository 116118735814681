import React from "react";
import { useInView } from "../../../utils/hooks";

import { HeroWrapper, IntersectionChecker } from "./style";

import HeroScene from "./hero-scene";
import HeroText from "./hero-text";

interface Props {
  className?: string;
}

const Hero: React.FC<Props> = ({ className }) => {
  const [intersectionRef, inView] = useInView({
    threshold: 0,
  });

  return (
    <HeroWrapper className={className} data-testid='hero'>
      <IntersectionChecker ref={intersectionRef} />

      <HeroScene isInViewport={inView}></HeroScene>
      <HeroText isInViewport={inView} testId='hero'></HeroText>
    </HeroWrapper>
  );
};

export default Hero;
