import React, { useRef, useState } from "react";
import { useTickerWithScrollActivation } from "../../../../utils/hooks";
import { ease, lerpClamped } from "../../../../utils/math";

import { Container, LayerContainer, LayerWrapper, Layer, SceneFader } from "./style";

import Particles from "../../../../components/particles";
import { ParticleDirectionType } from "../../../../components/particles/index";

import { InputManagerInstance } from "../../../../managers";

import layerImage01 from "./assets/championstyle_01.png";
import layerImage02 from "./assets/championstyle_02.png";
import ComponentImage from "../../../../components/image";
// import layerImage03 from './assets/championstyle_03.png'

interface Props {
  isInViewport: boolean;
}

const SceneBackdrop: React.FC<Props> = ({ isInViewport = false }) => {
  const containerRef = useRef<any>(null);
  const sceneFaderRef = useRef<any>(null);

  // Not updated using setter, updated out of react state for performance reasons
  const [passive] = useState({
    pointerX: 0.0,
    pointerY: 0.0,
    layers: [
      // { ref: useRef<any>(null), perspective: -0.95, img: layerImage03 },
      { ref: useRef<any>(null), perspective: -0.5, img: layerImage02 },
      {
        ref: useRef<any>(null),
        perspective: 0.15,
        img: layerImage01,
        gradient: true,
      },
    ],
  });

  useTickerWithScrollActivation(
    containerRef.current,
    ({}, activation) => {
      if (!isInViewport) {
        return;
      }

      const targetPointerX = InputManagerInstance.normal.x * 2.0 - 1.0;
      const targetPointerY = InputManagerInstance.normal.y * 2.0 - 1.0;

      passive.pointerX = lerpClamped(passive.pointerX, targetPointerX, 0.06, 0.03);
      passive.pointerY = lerpClamped(passive.pointerY, targetPointerY, 0.06, 0.03);

      sceneFaderRef.current.style.opacity = ease.inCubic(Math.min(activation.direct.out * 1.5, 1));

      passive.layers.forEach((layer) => {
        layer.ref.current.style.transform = `translate3d(
        ${layer.perspective * passive.pointerX * -8.0}px,
        ${
          layer.perspective * passive.pointerY * -8.0 +
          (1.0 - layer.perspective) * (activation.in * 20.0 + activation.out * 60.0)
        }px,
        ${layer.perspective * activation.in * 4.0}px)`;
      });
    },
    [isInViewport, containerRef, sceneFaderRef],
    0.135, // easingFactor
  );

  return (
    <Container ref={containerRef}>
      <LayerContainer>
        {passive.layers.map((layer, index) => {
          return (
            layer.img && (
              <LayerWrapper ref={layer.ref} key={index}>
                <Layer className={layer.gradient ? "gradient" : ""}>
                  {layer.img && (
                    <ComponentImage lazy={!isInViewport} objectFit="cover" objectPosition="50% 0%" src={layer.img} />
                  )}
                </Layer>
              </LayerWrapper>
            )
          );
        })}
      </LayerContainer>
      <SceneFader ref={sceneFaderRef} />
      <Particles isActive={isInViewport} population={100.0} colors={["#bb23db"]} direction={ParticleDirectionType.UP} />
    </Container>
  );
};

export default SceneBackdrop;
