import React, { useRef, useState, useEffect, useContext } from "react";
import { useTickerWithScrollActivation, useResize } from "../../../../utils/hooks";
import { ease, lerpClamped } from "../../../../utils/math";
import { ManagerContext } from "../../../../managers";
import Tween from "../../../../utils/classes/Tween";

import { Container, Layer } from "./style";

import Particles from "../../../../components/particles";
import { ParticleDirectionType } from "../../../../components/particles/index";

import layerImage01 from "./assets/htp_hero_01.png";
import layerImage02 from "./assets/htp_hero_02.png";
import layerImage03 from "./assets/htp_hero_03.png";
import layerImage04 from "./assets/htp_hero_04.png";
import layerImage05 from "./assets/htp_hero_05.png";
import layerImage06 from "./assets/htp_hero_06.png";
import layerImageBG from "./assets/htp_hero_bg.png";

interface Props {
  isInViewport: boolean;
}

const HeroScene: React.FC<Props> = ({ isInViewport = false }) => {
  const { input } = useContext(ManagerContext);

  const containerRef = useRef<any>(null);
  const [initSize] = useState({ width: 0 });

  // Not updated using setter, updated out of react state for performance reasons
  const [passive] = useState({
    introProgress: 0.0,
    pointerX: 0.0,
    pointerY: 0.0,
    layers: [
      { ref: useRef<any>(null), perspective: -0.3, img: layerImageBG },
      { ref: useRef<any>(null), perspective: 0.2, img: layerImage06 },
      { ref: useRef<any>(null), perspective: 0.38, img: layerImage05 },
      { ref: useRef<any>(null), perspective: 0.5, img: layerImage04 },
      { ref: useRef<any>(null), perspective: 0.52, img: layerImage03 },
      { ref: useRef<any>(null), perspective: 2.0, img: null, static: true }, // particles
      { ref: useRef<any>(null), perspective: 0.65, img: layerImage02 },
      { ref: useRef<any>(null), perspective: 1.2, img: layerImage01 },
    ],
  });

  useEffect(() => {
    const introTween = new Tween(
      1800.0,
      ease.outQuart,
      (progress, value) => {
        passive.introProgress = progress;
        containerRef.current.style.opacity = progress;
      },
      () => {
        /* */
      },
    );
    introTween.start(1250);

    // setIntro(introTween)
  }, []);

  useTickerWithScrollActivation(
    containerRef.current,
    ({}, activation) => {
      if (!isInViewport) {
        return;
      }

      // intro transition
      const introProgress = 1.0 - passive.introProgress;

      const targetPointerX = input.normal.x * 2.0 - 1.0;
      passive.pointerX = lerpClamped(passive.pointerX, targetPointerX, 0.08, 0.02);

      // Don't apply Y cursor movement for touch
      if (!input.latest.isTouch) {
        const targetPointerY = input.normal.y * 2.0 - 1.0;
        passive.pointerY = lerpClamped(passive.pointerY, targetPointerY, 0.08, 0.02);
      }

      const verticalOffset = 1.0 - activation.out;

      passive.layers.forEach((layer) => {
        if (layer.static) return;

        layer.ref.current.style.transform = `translate3d(
        ${layer.perspective * passive.pointerX * -10.0}px,
        ${
          layer.perspective * passive.pointerY * -10.0 * verticalOffset +
          (1.0 - layer.perspective) * activation.out * 60.0 +
          layer.perspective * introProgress * 40.0 +
          introProgress * 200.0
        }px,
        ${layer.perspective * activation.out * 60.0}px)`;
      });
    },
    [isInViewport, containerRef],
    0.135, // easingFactor
  );

  useResize(({ width, height }) => {
    const SCALING_RATIO_THRESHOLD = 0.8;
    const BOTTOM_OFFSET_HEIGHT = height * -0.55;

    const ratio = width / height - SCALING_RATIO_THRESHOLD;
    const offset = Math.max(ratio, 0) * BOTTOM_OFFSET_HEIGHT;

    // Only update height when width changes to avoid mobile
    // bump when URL bar is scrolled away.
    if (width !== initSize.width) {
      containerRef.current.style.height = `${height - offset}px`;
    }

    initSize.width = width;
  });

  return (
    <Container ref={containerRef}>
      {passive.layers.map((layer, index) => {
        return layer.img ? (
          <Layer ref={layer.ref} key={index} style={{ backgroundImage: `url(${layer.img})` }} />
        ) : (
          <Layer ref={layer.ref} key={index} className={"particles"}>
            <Particles
              isActive={isInViewport}
              population={160.0}
              colors={["#cffffe", "#92f3ff", "#5ed3ff", "#469dff", "#1e48d3"]}
              direction={ParticleDirectionType.UP}
            />
          </Layer>
        );
      })}
    </Container>
  );
};

export default HeroScene;
