import styled from "styled-components";
import { rem } from "../../../../utils/style";

import {
  COLOR_TEXT_BLACK,
  COLOR_TEXT_WHITE,
  MEDIA_TABLET_ALL,
  MEDIA_MOBILE_ALL,
} from "../../../../layouts/default/theme";
import {
  cssLocalizedFontFamilySerif,
  cssLocalizedLineHeight,
  cssLocalizedUppercase,
  cssLocalizedItalic,
} from "../../../../components/css";

export const Wrapper = styled.div<{ toggleContrast: boolean }>`
  color: ${({ toggleContrast }) => (toggleContrast ? COLOR_TEXT_WHITE : COLOR_TEXT_BLACK)};
`;

export const Row = styled.div`
  text-align: center;
`;

export const Title = styled.h1`
  margin: 0;
  text-align: center;

  font-size: 60px;
  letter-spacing: 0.05em;
  ${cssLocalizedFontFamilySerif}
  ${cssLocalizedUppercase}
  ${cssLocalizedItalic}
  font-weight: 800;

  @media ${MEDIA_MOBILE_ALL} {
    font-size: 28px;
    ${cssLocalizedLineHeight(1)}
  }
`;

export const Description = styled.p`
  max-width: 660px;
  margin: 20px auto 0;
  text-align: center;
  font-size: ${rem(14)};
  letter-spacing: 0.08em;
  ${cssLocalizedLineHeight(1.6)}

  @media ${MEDIA_TABLET_ALL} {
    font-size: ${rem(12)};
    max-width: 550px;
  }

  @media ${MEDIA_MOBILE_ALL} {
    font-size: ${rem(12)};
    max-width: 400px;
  }
`;
