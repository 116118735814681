import styled from "styled-components";
import { rem, easing } from "../../../../utils/style";
import {
  COLOR_TEXT_WHITE,
  COLOR_TEXT_DARK_GREY,
  COLOR_PRIMARY_BLUE,
  COLOR_PRIMARY_BLUE_ACTIVE,
  COLUMN_WIDTH,
} from "../../../../layouts/default/theme";

import { MEDIA_COLUMN_LAYOUT } from "../style";
import { cssLocalizedLineHeight, cssLocalizedUppercase } from "../../../../components/css";

export const TopicWrapper = styled.section<{ chapterIndex: number }>`
  box-sizing: border-box;

  position: relative;

  width: 100%;
  padding: ${rem(COLUMN_WIDTH / 2)};
  margin-top: ${rem(COLUMN_WIDTH)};

  opacity: 0;
  transition: opacity 0.25s ${easing.easeOutQuart};

  &.is-active {
    opacity: 1;
    transition-duration: 1.2s;
    transition-delay: 0.45s;
  }

  @media ${MEDIA_COLUMN_LAYOUT} {
    width: calc(100% - ${rem(20)});
    padding: ${rem(20)} ${rem(15)};
    margin: ${rem(30)} ${rem(10)} 0;
  }

  @media ${MEDIA_COLUMN_LAYOUT} {
    width: calc(100% + ${rem(8 * 2)});
    padding: ${rem(20)} ${rem(13)};
    margin: ${rem(25)} ${rem(-8)} 0;
  }
`;

export const TopicPagination = styled.p`
  margin-top: 0;

  color: ${COLOR_TEXT_DARK_GREY};
  font-size: ${rem(12)};
  font-weight: 600;
`;

export const TopicList = styled.ul`
  overflow: hidden;

  white-space: nowrap;

  list-style: none;
  padding: 0;
  margin: 0;
`;

export const TopicItem = styled.li<{ activeIndex: number }>`
  box-sizing: content-box;

  white-space: normal;

  display: inline-block;
  vertical-align: top;
  width: 100%;
  padding-right: 10%;

  color: ${COLOR_TEXT_WHITE};

  transform: translate(${(props) => props.activeIndex * -100}%, 0);
  transition: transform 0.8s ${easing.easeOutQuart};
`;

export const TopicItemTitle = styled.h4`
  margin-top: ${rem(15)};

  font-size: ${rem(14)};
  letter-spacing: 0.05em;
  ${cssLocalizedUppercase}
`;

export const TopicItemDesc = styled.p`
  margin-top: ${rem(8)};

  font-size: ${rem(12)};
  ${cssLocalizedLineHeight(1.7)}
  letter-spacing: 0.05em;
`;

export const TopicNavigation = styled.nav`
  display: flex;

  @media ${MEDIA_COLUMN_LAYOUT} {
    justify-content: flex-end;
  }
`;

export const TopicNavigationButton = styled.button`
  /* Reset */

  appearance: none;
  padding: 0;
  margin: 0;
  border: 0;
  background: none;

  /* Style */

  position: relative;

  width: ${rem(40)};
  height: ${rem(32)};
  margin-top: ${rem(25)};
  margin-right: ${rem(5)};

  cursor: pointer;

  transition: opacity 0.2s ${easing.easeInOutCubic};

  &.is-disabled {
    opacity: 0.3;
    cursor: normal;
    pointer-events: none;
  }

  @media ${MEDIA_COLUMN_LAYOUT} {
    margin-top: ${rem(15)};
  }
`;

export const Arrow = styled.svg`
  display: block;
  width: 40%;
  height: 100%;
  margin: 0 30%;

  fill: ${COLOR_PRIMARY_BLUE};
  stroke: ${COLOR_PRIMARY_BLUE};

  transition: fill 0.2s ${easing.easeInOutCubic}, stroke 0.2s ${easing.easeInOutCubic};

  ${TopicNavigationButton}:hover & {
    fill: ${COLOR_PRIMARY_BLUE_ACTIVE};
    stroke: ${COLOR_PRIMARY_BLUE_ACTIVE};
  }
`;
