import styled from "styled-components";
import { rem } from "../../../utils/style";
import { COLOR_BACKGROUND_DARK } from "../../../layouts/default/theme";

export const HeroWrapper = styled.section`
  position: relative;
`;

export const IntersectionChecker = styled.div`
  position: absolute;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;
`;
