import React, { useRef, useEffect } from "react";

import ChampionRole from "../../../../enums/champion-role";
import { COLOR_OUTLINE_MEDIUM_GREY } from "../../../../layouts/default/theme";

import { RoleMap } from "../typings";

import { Wrapper, WrapperInner, VideoContainer, Video, Hex, VIDEO_TRANSITION_DURATION_IN_MS } from "./style";

import VideoComponent from "../../../../components/video";
import HexOutline from "../../../../components/hex-outline";

const StyledVideoComponent = Video.withComponent(VideoComponent);

export type VideoSourcesMap = {
  [x in ChampionRole]: React.ReactElement[];
};

interface Props {
  isInViewport: boolean;
  activeRole: ChampionRole;
  roleMap: RoleMap;
  videoSourcesMap: VideoSourcesMap;
  className?: string;
  testId?: string;
}

const StyledHexOutline = Hex.withComponent(HexOutline);

const getActiveRoleIndex = (activeRole: ChampionRole, videoSourcesMap: VideoSourcesMap) => {
  const keys = Object.keys(videoSourcesMap);
  for (let i = 0; i < keys.length; i++) {
    if (keys[i] === activeRole) return i;
  }
  throw Error(`Failed to get active role index. (activeRole ${activeRole})`);
};

const RolePreview: React.FC<Props> = ({ isInViewport = false, activeRole, roleMap, videoSourcesMap, className, testId }) => {
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const activeRoleIndex = getActiveRoleIndex(activeRole, videoSourcesMap);

  // Handle intersection changes
  useEffect(() => {
    if (!videoContainerRef.current) return;

    const videos: HTMLVideoElement[] = Array.from(videoContainerRef.current.querySelectorAll("video"));
    const timeoutIds: number[] = [];

    if (isInViewport) {
      videos.forEach((video, i) => {
        if (activeRoleIndex === i) {
          video.play();
        } else {
          timeoutIds.push(window.setTimeout(() => video.pause(), VIDEO_TRANSITION_DURATION_IN_MS));
        }
      });
    } else {
      videos.forEach((video) => video.pause());
    }

    return () => {
      timeoutIds.forEach((timeoutId) => window.clearTimeout(timeoutId));
    };
  }, [isInViewport, activeRole]);

  return (
    <Wrapper className={className}>
      <WrapperInner>
        <VideoContainer ref={videoContainerRef}>
          {(Object.keys(videoSourcesMap) as ChampionRole[]).map((key, i) => {
            const isActive = i === activeRoleIndex;

            return (
              <StyledVideoComponent
                lazy={!isInViewport || !isActive}
                className={`${key === activeRole ? "is-active" : ""}`}
                key={key}
                objectFit={"cover"}
                objectPosition={"center center"}
                playsInline
                loop
                muted
                poster={React.cloneElement(roleMap[key].videoPoster, {
                  lazy: !isInViewport || !isActive,
                })}
                testId={`${testId}:video-${i}`}
              >
                {videoSourcesMap[key]}
              </StyledVideoComponent>
            );
          })}
          <StyledHexOutline
            isActive={isInViewport}
            clipRightTop={40}
            offsetAmount={10}
            offsetHorizontal={-1}
            offsetVertical={1}
            strokeColor={COLOR_OUTLINE_MEDIUM_GREY}
            accentColor={COLOR_OUTLINE_MEDIUM_GREY}
          />
        </VideoContainer>
      </WrapperInner>
    </Wrapper>
  );
};

export default RolePreview;
