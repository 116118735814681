import styled from "styled-components";
import { rem, easing } from "../../../../utils/style";
import {
  COLOR_PRIMARY_BLUE,
  COLOR_TEXT_WHITE,
  COLOR_TEXT_BLACK,
  COLOR_PRIMARY_BLUE_ACTIVE,
  MEDIA_MOBILE_ALL,
  COLUMN_WIDTH,
} from "../../../../layouts/default/theme";
import { MEDIA_COLUMN_LAYOUT } from "../style";
import { cssLocalizedLineHeight, cssLocalizedUppercase } from "../../../../components/css";

export const ICON_SIZE = 110;

export const Wrapper = styled.div`
  z-index: 3;
  position: absolute;
  right: 0;
  top: 0;

  width: 20%;
  min-width: ${rem(320)};
  height: 100%;

  overflow: hidden;

  @media ${MEDIA_COLUMN_LAYOUT} {
    overflow: hidden;
    position: relative;

    width: 100%;
    height: ${ICON_SIZE}px;
    min-width: 0;
    padding-top: 16.5rem;
    padding-bottom: 56px;
  }

  @media ${MEDIA_MOBILE_ALL} {
    padding-top: calc(40vw + 2.5rem);
  }
`;

export const List = styled.ul`
  box-sizing: border-box;

  height: 100%;
  padding: 0;
  margin: 0;

  list-style: none;

  &::before,
  &::after {
    display: inline-block;
    width: calc(50% - ${ICON_SIZE / 2}px);
    height: calc(50% - ${ICON_SIZE / 2}px);
    content: "\\a0";
  }

  @media ${MEDIA_COLUMN_LAYOUT} {
    width: 100%;

    white-space: nowrap;
  }
`;

export const Item = styled.li`
  position: absolute;
  width: 100%;
  margin: 0;

  left: 0;
  top: 0;

  @media ${MEDIA_COLUMN_LAYOUT} {
    width: auto;
    display: inline-block;

    &.is-active {
      z-index: 1;
    }
  }
`;

export const Button = styled.button`
  width: 100%;
  box-sizing: border-box;
  position: relative;

  padding: 0;
  border: 0;
  background-color: transparent;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media ${MEDIA_COLUMN_LAYOUT} {
    display: inline-flex;
    flex-wrap: wrap;

    white-space: normal;
  }
`;

export const Label = styled.label`
  position: relative;

  width: 35%;
  margin-right: ${rem(10)};

  text-align: right;
  color: ${COLOR_TEXT_WHITE};
  font-size: ${rem(12)};
  ${cssLocalizedLineHeight(1.2)}
  letter-spacing: 0.15em;
  font-weight: 600;
  ${cssLocalizedUppercase}

  cursor: pointer;

  @media ${MEDIA_COLUMN_LAYOUT} {
    /* Only label inside ActiveStrip is shown on column layout */
    display: none;
  }
`;

export const Thumb = styled.div`
  position: relative;

  width: ${ICON_SIZE}px;
  height: ${ICON_SIZE}px;
  margin: 0 ${COLUMN_WIDTH}px 0 0;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto 115%;

  box-shadow: ${COLOR_PRIMARY_BLUE} 0 0 0 2px;

  transform-origin: left center;
  transform: scale(0.7, 0.7);

  transition: 0.3s box-shadow ${easing.easeOutQuart}, 1s 0.2s transform ${easing.easeOutQuart};

  ${Item}:hover & {
    box-shadow: ${COLOR_PRIMARY_BLUE_ACTIVE} 0 0 0 3px;
  }

  ${Item}.is-active & {
    box-shadow: ${COLOR_PRIMARY_BLUE} 0 0 0 3px;
    transform: none;
  }

  * {
    width: 100%;
    height: 100%;
  }

  @media ${MEDIA_COLUMN_LAYOUT} {
    margin: 0;

    transform-origin: center top;
  }
`;

export const ActiveStrip = styled.div`
  position: absolute;
  right: 0;
  top: 25%;

  width: 100%;
  height: 50%;

  @media ${MEDIA_COLUMN_LAYOUT} {
    top: 85%;
    right: -25%;
    width: 150%;
    height: 68px;
  }
`;

export const ActiveStripInner = styled.span`
  position: absolute;
  left: 0;
  top: 0;

  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  width: 100%;
  height: 100%;
  padding-right: ${ICON_SIZE + COLUMN_WIDTH}px;
  padding-left: ${COLUMN_WIDTH / 2}px;

  background: ${COLOR_PRIMARY_BLUE};

  ${Label} {
    width: auto;
    color: ${COLOR_TEXT_BLACK};
  }

  @media ${MEDIA_COLUMN_LAYOUT} {
    padding: 0;

    ${Label} {
      display: block;

      box-sizing: border-box;
      width: 100%;
      height: 100%;
      padding: 28px 20px 0;
      margin-right: 0;

      text-align: center;
    }
  }
`;
