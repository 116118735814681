import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";

import { useInView } from "../../../utils/hooks";

import Intro from "../components/intro";
import SelectorComponent from "../../../components/selector";
import Tooltipper, { TooltipMap } from "../../../components/format/tooltipper";

import SceneBackdrop from "./scene-backdrop";

import { HudLayerType } from "./typings";
import {
  Wrapper,
  Background,
  BackgroundImage,
  SectionTitle,
  Selector,
  DescriptionList,
  Description,
  HudContainer,
  Hud,
  HudInner,
  HudLayerInteractive,
  HudLayerStatic,
  LayerImage,
} from "./style";
import { SectionsHowToPlayHudAssetsQuery } from "../../../../generated/graphql";
import { clamp } from "../../../utils/math";
import { useIntl } from "gatsby-plugin-intl";
import { DefaultTheme, StyledComponent } from "styled-components";

const StyledSelector = Selector.withComponent(SelectorComponent);

interface Section {
  id: HudLayerType.ABILITES | HudLayerType.SPELLS | HudLayerType.ITEMS;
  name: string;
  description: string;
}

export interface Props {
  className?: string;
  title: string;
  heading: string;
  description: string;
  sections: Section[];
  tooltipMap: TooltipMap;
  intersectionRoot?: HTMLElement | null;
}

const SectionHud: React.FC<Props> = ({
  className,
  title,
  heading,
  description,
  sections,
  tooltipMap,
  intersectionRoot,
}) => {
  const intl = useIntl();
  const selectorOptions = sections.map(({ id, name }) => ({ id, text: intl.formatMessage({ id: name }) }));

  const [optionIndex, setOptionindex] = useState<number>(0);

  const [intersectionRef, inView] = useInView({
    root: intersectionRoot,
    threshold: 0,
  });

  const data: SectionsHowToPlayHudAssetsQuery = useStaticQuery(graphql`
    query SectionsHowToPlayHudAssets {
      allFile(filter: { relativePath: { glob: "lib/sections/how-to-play/hud/assets/hud/*.png" } }) {
        edges {
          node {
            childImageSharp {
              original {
                height
                src
                width
              }
            }
            name
          }
        }
      }
    }
  `);
  const hudImageNodes = data.allFile.edges.map(({ node }) => node);
  const baseNode = hudImageNodes.reduce((base, node) => {
    return node.name === HudLayerType.BASE ? node : base;
  }, hudImageNodes[0]);
  const baseWidth = baseNode.childImageSharp!.original!.width!;
  const baseHeight = baseNode.childImageSharp!.original!.height!;

  return (
    <Wrapper ref={intersectionRef} className={className} data-testid='abilities'>
      <Background>
        <SceneBackdrop isInViewport={inView}></SceneBackdrop>
      </Background>

      <SectionTitle text={intl.formatMessage({ id: title })}>
        <Intro
          isInViewport={inView}
          heading={intl.formatMessage({ id: heading })}
          description={intl.formatMessage({ id: description })}
          testId='abilities'
        />

        <StyledSelector
          options={selectorOptions}
          activeBulletColor="#d0a85c"
          activeTextColor="#010101"
          color="#bac2cc"
          activeOptionIndex={optionIndex}
          onSelectOption={(index) => setOptionindex(index)}
          onSwipe={(direction) => {
            setOptionindex((current) => clamp(current + direction, 0, selectorOptions.length - 1));
          }}
          testId='abilities'
        />

        <DescriptionList>
          {sections.map((section, index) => {
            return (
              <Description data-testid={`abilities:description-${index}`} key={section.id} className={section.id === sections[optionIndex].id ? "active" : ""}>
                <Tooltipper text={intl.formatMessage({ id: section.description })} tooltipMap={tooltipMap} />
              </Description>
            );
          })}
        </DescriptionList>

        <HudContainer>
          <Hud maxWidth={baseWidth} className={`option-${optionIndex}`}>
            <HudInner maxWidth={baseWidth} maxHeight={baseHeight}>
              {hudImageNodes.map((node) => {
                const name = node.name;
                const sectionIndex = getSectionIndexById(sections, name as HudLayerType);
                const section = sectionIndex !== null ? sections[sectionIndex] : null;

                // Explicit union type declaration to prevent error:
                // https://github.com/microsoft/TypeScript/issues/28631
                const Layer: StyledComponent<"div" | "button", DefaultTheme> = section
                  ? HudLayerInteractive
                  : HudLayerStatic;
                if (
                  !node.childImageSharp?.original?.height ||
                  !node.childImageSharp?.original?.width ||
                  !node.childImageSharp?.original?.src
                ) {
                  throw new Error(`Image missing properties: ${JSON.stringify(node)}`);
                }
                return (
                  <Layer
                    key={name}
                    className={`layer-${name} ${sections[optionIndex].id === name ? "active" : ""}`}
                    onClick={sectionIndex !== null ? () => setOptionindex(sectionIndex) : undefined}
                    style={{
                      height: (node.childImageSharp.original.height / baseHeight) * 100 + "%",
                      width: (node.childImageSharp.original.width / baseWidth) * 100 + "%",
                    }}
                  >
                    <LayerImage
                      src={node.childImageSharp.original.src}
                      alt={section ? intl.formatMessage({ id: section.name }) : undefined}
                    />
                  </Layer>
                );
              })}
            </HudInner>
          </Hud>
        </HudContainer>
      </SectionTitle>
    </Wrapper>
  );
};

function getSectionIndexById(sections: Section[], id: string): number | null {
  for (let i = 0; i < sections.length; i++) {
    if (sections[i].id === id) return i;
  }
  return null;
}

export default SectionHud;
export { default as copyProps } from "./assets/copy";
