import React, { useState, useEffect } from "react";
import { useTicker } from "../../../../utils/hooks";
import { easing } from "../../../../utils/style";

import ChampionRole from "../../../../enums/champion-role";

import { Wrapper, ChampionImageWrapper, ChampionImage } from "./style";
import Reveal from "../../../../components/reveal";

import {
  // assassinAsset,
  assassinSkinTwo,
  assassinSkinThree,
  assassinSkinFour,
} from "../../assets/champions";

type ChampionRoleMap = {
  [x in ChampionRole]: {
    assets: string[];
  };
};

interface Props {
  isAutoCycling: boolean;
  cycleDuration: number;
  cycleStartTime: number;
  isInViewport: boolean;
  testId?: string;
}

const assets = [
  // assassinAsset,
  assassinSkinTwo,
  assassinSkinThree,
  assassinSkinFour,
];

const SelectionChampion: React.FC<Props> = ({ isAutoCycling = false, cycleDuration, cycleStartTime, isInViewport, testId }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [angle] = useState({ i: 0 });

  useTicker(
    ({ elapsed }) => {
      if (isAutoCycling) {
        const newIndex = Math.floor(((elapsed - cycleStartTime) / cycleDuration) % assets.length);
        if (newIndex !== activeIndex) {
          setActiveIndex(newIndex);
          angle.i = (angle.i + 1) % 4;
        }
      }
    },
    [isAutoCycling, cycleStartTime, assets, activeIndex],
  );

  return (
    <Wrapper>
      <ChampionImageWrapper>
        {assets.map((asset, index) => {
          const isActive = index === activeIndex;
          return (
            <Reveal
              key={asset}
              absolute
              showDir={
                "ne"
                // angle.i === 0
                //   ? 'sw'
                //   : angle.i === 1
                //   ? 'se'
                //   : angle.i === 2
                //   ? 'ne'
                //   : 'nw'
              }
              hideDir={
                "ne"
                // angle.i === 0
                //   ? 'sw'
                //   : angle.i === 1
                //   ? 'se'
                //   : angle.i === 2
                //   ? 'ne'
                //   : 'nw'
              }
              isActive={isActive}
              transitionTiming={easing.easeOutCubic}
              transitionDuration={1800}
              transitionDelay={{ show: 0, hide: 0 }}
            >
              <ChampionImage
                className={`${isActive ? "is-active" : ""}`}
                src={asset}
                lazy={!isActive && !isInViewport}
                testId={`${testId}:image-${index}`}
              />
            </Reveal>
          );
        })}
      </ChampionImageWrapper>
    </Wrapper>
  );
};

export default SelectionChampion;
