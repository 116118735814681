import { HudLayerType } from "../typings";
import { Props } from "../.";

const copy: Props = {
  title: "abilities-spells-and-items.title",
  heading: "section.how-to-play.hud.title",
  description: "section.how-to-play.hud.description",
  sections: [
    {
      id: HudLayerType.ABILITES,
      name: "abilities.title",
      description: "abilities.description",
    },
    {
      id: HudLayerType.SPELLS,
      name: "summoner-spells.title",
      description: "summoner-spells.description",
    },
    {
      id: HudLayerType.ITEMS,
      name: "items.title",
      description: "items.description",
    },
  ],
  tooltipMap: {
    "passive-ability": {
      title: "passive-ability.title",
      desc: "passive-ability.description",
    },
    "basic-abilities": {
      title: "basic-abilities.title",
      desc: "basic-abilities.description",
    },
    "ultimate-ability": {
      title: "ultimate-ability.title",
      desc: "ultimate-ability.description",
    },
    flash: {
      title: "flash.title",
      desc: "flash.description",
    },
    teleport: {
      title: "teleport.title",
      desc: "teleport.description",
    },
    ignite: {
      title: "ignite.title",
      desc: "ignite.description",
    },
    smite: {
      title: "smite.title",
      desc: "smite.description",
    },
    trinkets: {
      title: "trinkets.title",
      desc: "trinkets.description",
    },
  },
};

export default copy;
