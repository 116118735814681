import React from "react";

import SectionCategoryArticleListWrapperContentstack from "../../sections/category/article-list/wrapper/contentstack";
import SectionCategoryIntro from "../../sections/category/intro";
import SectionChampionList from "../../sections/champion-list";
import SectionDownloadApp from "../../sections/download-app";
import SectionDownloadGameBasic from "../../sections/download-game-basic";
import SectionGameIntro from "../../section-groups/game-intro";
import SectionGameModes from "../../sections/home/game-modes";
import SectionPlayerGuide from "../../section-groups/player-guide";
import SectionHomeHero from "../../sections/home/hero";
import SectionHowToPlay from "../../section-groups/how-to-play";
import SectionNewsItemList from "../../sections/news-item-list";
import SectionNewsHighlightsList from "../../sections/news/highlights-list";
import SectionPromo from "../../sections/promo";
import SectionPromoList, { Props as SectionPromoListProps } from "../../section-groups/promo-list";
import SectionTwitch from "../../sections/twitch";
import SectionNewsHeader from "../../sections/news/header";
import SectionNewsLatest from "../../sections/news/latest";
import SectionNewsPopular from "../../sections/news/popular";
import SectionNewsSocial from "../../sections/news/social";
import SectionNewsYoutube from "../../sections/news/youtube";
import { ContentstackEmailForm } from "@riotgames/wwpub-components";

import { SectionType, Section as TemplateSection } from "./typings";
import { ProgressiveHydration } from "./style";

const SECTION_MAP: { [type in SectionType]: any } = {
  [SectionType.CATEGORY_ARTICLE_LIST_CONTENTSTACK]: SectionCategoryArticleListWrapperContentstack,
  [SectionType.CATEGORY_INTRO]: SectionCategoryIntro,
  [SectionType.CHAMPION_LIST]: SectionChampionList,
  [SectionType.DOWNLOAD_APP]: SectionDownloadApp,
  [SectionType.DOWNLOAD_GAME_BASIC]: SectionDownloadGameBasic,
  [SectionType.GAME_INTRO]: SectionGameIntro,
  [SectionType.GAME_MODES]: SectionGameModes,
  [SectionType.PLAYER_GUIDE]: SectionPlayerGuide,
  [SectionType.HOME_HERO]: SectionHomeHero,
  [SectionType.HOW_TO_PLAY]: SectionHowToPlay,
  [SectionType.NEWS_ITEM_LIST]: SectionNewsItemList,
  [SectionType.NEWS_HIGHLIGHTS_LIST]: SectionNewsHighlightsList,
  [SectionType.PROMO]: SectionPromo,
  [SectionType.PROMO_LIST]: SectionPromoList,
  [SectionType.TWITCH]: SectionTwitch,
  [SectionType.NEWS_HEADER]: SectionNewsHeader,
  [SectionType.NEWS_LATEST]: SectionNewsLatest,
  [SectionType.NEWS_POPULAR]: SectionNewsPopular,
  [SectionType.NEWS_SOCIAL]: SectionNewsSocial,
  [SectionType.NEWS_YOUTUBE]: SectionNewsYoutube,
  [SectionType.EMAIL_FORM]: ContentstackEmailForm,
};

interface Props {
  id: string;
  sections: TemplateSection[];
}

const Template: React.FC<Props> = ({ id, sections }) => {
  return (
    <React.Fragment>
      {sections.map(({ type, key, props }, sectionIndex) => {
        const CurrentSection = SECTION_MAP[type];
        if (!CurrentSection) {
          console.warn(`Unsupported section type. (type: ${type})`);
          return;
        }

        /**
         * TODO: remove progressive hydration?
         * Progressive hydration seems to be causing problems.
         */
        return (
          <ProgressiveHydration
            id={`${id}-${key}`}
            key={key}
            // force={sectionIndex < 2}
            className={[
              `type-${type}`,
              sections[sectionIndex + 1] ? `next-type-${sections[sectionIndex + 1].type}` : "next-type-empty",
              type === SectionType.PROMO_LIST && (props as SectionPromoListProps).giantTitle ? "has-giant-title" : null,
            ].join(" ")}
          >
            <CurrentSection {...props} />
          </ProgressiveHydration>
        );
      })}
    </React.Fragment>
  );
};

export default Template;
