import React from "react";

import { Article } from "../types";
import { ResponsiveWrapper, WrapperInner, Image, Category, Title } from "./style";
import LinkComponent from "../../../../components/link";

export interface SectionNewsPopularProps {
  article: Article;
  className?: string;
}

const SectionNewsPopular: React.FC<SectionNewsPopularProps> = ({
  article: { category, image, imageDimension, link, title },
  className,
}) => {
  return (
    <ResponsiveWrapper as={LinkComponent} className={className} {...link}>
      <WrapperInner>
        <Image
          style={{
            paddingTop: imageDimension
              ? Math.round((imageDimension.height / imageDimension.width) * 10000) / 100 + "%"
              : undefined,
          }}
        >
          {image}
        </Image>
        {category && <Category>{category}</Category>}
        {title && <Title>{title}</Title>}
      </WrapperInner>
    </ResponsiveWrapper>
  );
};

export default SectionNewsPopular;
