import styled, { css } from "styled-components";
import "tippy.js/dist/tippy.css";
import { cssLocalizedUppercase, cssLocalizedLineHeight } from "../../css";

export const Wrapper = styled.span``;

const TOOLTIP_BACKGROUND_COLOR = "#000913";
const TOOLTIP_BORDER_COLOR = "#0bc6e3";
export const TooltipWrapper = styled.div`
  &.tippy-tooltip {
    background-color: ${TOOLTIP_BACKGROUND_COLOR};
    background-clip: padding-box;
    border-radius: 0;
    border: 1px solid ${TOOLTIP_BORDER_COLOR};
    color: white;
    box-shadow: none;

    .tippy-content {
      padding: 15px;
    }

    > .tippy-backdrop {
      background-color: ${TOOLTIP_BACKGROUND_COLOR};
    }

    > .tippy-arrow {
      position: absolute;
      width: 7px;
      height: 7px;
    }

    > .tippy-arrow:after,
    > .tippy-arrow:before {
      content: "";
      position: absolute;
      z-index: -1;
    }

    &[data-placement^="top"] > .tippy-arrow {
      border-top-color: ${TOOLTIP_BACKGROUND_COLOR};
      bottom: -5px;
    }

    &[data-placement^="top"] > .tippy-arrow:after {
      border-top: 7px solid ${TOOLTIP_BACKGROUND_COLOR};
      bottom: 0;
    }

    &[data-placement^="top"] > .tippy-arrow:before {
      border-top: 7px solid ${TOOLTIP_BORDER_COLOR};
      bottom: -4px;
    }

    &[data-placement^="bottom"] > .tippy-arrow {
      border-bottom-color: ${TOOLTIP_BACKGROUND_COLOR};
    }

    &[data-placement^="bottom"] > .tippy-arrow:after {
      border-bottom: 7px solid ${TOOLTIP_BACKGROUND_COLOR};
      bottom: -7px;
    }

    &[data-placement^="bottom"] > .tippy-arrow:before {
      border-bottom: 7px solid ${TOOLTIP_BORDER_COLOR};
      bottom: -6px;
    }

    &[data-placement^="left"] > .tippy-arrow {
      border-left-color: ${TOOLTIP_BACKGROUND_COLOR};
    }

    &[data-placement^="left"] > .tippy-arrow:after {
      border-left: 7px solid ${TOOLTIP_BACKGROUND_COLOR};
      left: -7px;
    }

    &[data-placement^="left"] > .tippy-arrow:before {
      border-left: 7px solid ${TOOLTIP_BORDER_COLOR};
      left: -6px;
    }

    &[data-placement^="right"] > .tippy-arrow {
      border-right-color: ${TOOLTIP_BACKGROUND_COLOR};
    }

    &[data-placement^="right"] > .tippy-arrow:after {
      border-right: 7px solid ${TOOLTIP_BACKGROUND_COLOR};
      right: -7px;
    }

    &[data-placement^="right"] > .tippy-arrow:before {
      border-right: 7px solid ${TOOLTIP_BORDER_COLOR};
      right: -6px;
    }

    &[data-placement^="bottom"] > .tippy-arrow,
    &[data-placement^="top"] > .tippy-arrow {
      transform: translateX(-1px);
    }

    &[data-placement^="bottom"] > .tippy-arrow:after,
    &[data-placement^="bottom"] > .tippy-arrow:before,
    &[data-placement^="top"] > .tippy-arrow:after,
    &[data-placement^="top"] > .tippy-arrow:before {
      left: -7px;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
    }

    &[data-placement^="left"] > .tippy-arrow,
    &[data-placement^="right"] > .tippy-arrow {
      transform: translateY(-1px);
    }

    &[data-placement^="left"] > .tippy-arrow:after,
    &[data-placement^="left"] > .tippy-arrow:before,
    &[data-placement^="right"] > .tippy-arrow:after,
    &[data-placement^="right"] > .tippy-arrow:before {
      top: -7px;
      border-top: 7px solid transparent;
      border-bottom: 7px solid transparent;
    }
  }
`;

export const TooltipContent = styled.div`
  h1 {
    ${cssLocalizedUppercase}
    ${cssLocalizedLineHeight(1)}
    margin: 0;
    letter-spacing: 0.08em;
    font-size: 14px;
  }

  p {
    ${cssLocalizedLineHeight(1.5)}
    margin: 0.2em 0 0 0;
    color: #878a8c;
    font-size: 12px;
  }
`;

export const TooltipTarget = styled.span`
  color: #0bc6e3;
  border-bottom: 1px solid currentColor;
`;
