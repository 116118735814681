import styled from "styled-components";
import { rem, easing } from "../../../../utils/style";
import { COLOR_TEXT_WHITE, COLUMN_WIDTH, MEDIA_MOBILE_ALL, MOBILE_MARGIN } from "../../../../layouts/default/theme";

import { MEDIA_COLUMN_LAYOUT } from "../style";
import {
  cssLocalizedFontFamilySerif,
  cssLocalizedLineHeight,
  cssLocalizedItalic,
  cssLocalizedUppercase,
} from "../../../../components/css";

export const ChapterWrapper = styled.section`
  padding: 0 ${rem(COLUMN_WIDTH / 2)};

  color: ${COLOR_TEXT_WHITE};

  @media ${MEDIA_COLUMN_LAYOUT} {
    padding: 0 ${rem(5)};
  }
`;

export const ChapterTitle = styled.h3`
  width: 100%;
  margin-top: -9px; /* Align top to SectionTitle */

  font-size: ${rem(40)};
  ${cssLocalizedLineHeight(1)}
  letter-spacing: 0.05em;
  ${cssLocalizedItalic}
  ${cssLocalizedFontFamilySerif}
  font-weight: 800;
  ${cssLocalizedUppercase}

  @media ${MEDIA_COLUMN_LAYOUT} {
    font-size: ${rem(28)};
    margin-top: 0;
  }
`;

export const ChapterDesc = styled.p`
  margin-top: ${rem(10)};

  font-size: ${rem(12)};
  ${cssLocalizedLineHeight(1.7)}
  letter-spacing: 0.05em;

  opacity: 0;
  transition: opacity 0.2s 0.03s ${easing.easeOutQuart};

  ${ChapterWrapper}.is-active & {
    opacity: 1;
    transition-duration: 1.2s;
    transition-delay: 0.35s;
  }

  @media ${MEDIA_COLUMN_LAYOUT} {
    margin-top: 0;

    ${cssLocalizedLineHeight(1.4)}
  }
`;
