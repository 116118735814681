import styled, { css, keyframes } from "styled-components";
import { rem, easing } from "../../../../utils/style";
import {
  COLOR_PRIMARY_BLUE,
  COLOR_PRIMARY_RED,
  COLOR_OUTLINE_WHITE,
  COLOR_PRIMARY_GOLD,
  MEDIA_MOBILE_ALL,
} from "../../../../layouts/default/theme";

import { HotspotType } from "../typings";

const HOTSPOT_DIAMETER = 60;
const LENS_DIAMETER = 150;
const LENS_RING_DIAMETER = 190;
const LENS_OFFSET = (LENS_RING_DIAMETER - LENS_DIAMETER) / 2;

const entryAnimation = keyframes`
  from { 
    opacity: 0;
    transform: translateY( -30% );
  }
  to {
    opacity: 1;
    transform: translateY( 0 );
  }
`;

const entryAnimationMobile = keyframes`
  from { 
    opacity: 0;
    transform: translateY( -30% ) scale(1.5, 1.5);
  }
  to {
    opacity: 1;
    transform: translateY( 0 ) scale(1.5, 1.5);
  }
`;

export const HotspotWrapper = styled.div<{
  x: number;
  y: number;
  index: number;
}>`
  position: absolute;
  left: ${(props) => props.x * 100}%;
  top: ${(props) => props.y * 100}%;

  z-index: 1;

  width: ${HOTSPOT_DIAMETER}px;
  height: ${HOTSPOT_DIAMETER}px;
  margin: ${-HOTSPOT_DIAMETER / 2}px 0 0 ${-HOTSPOT_DIAMETER / 2}px;

  cursor: pointer;

  pointer-events: none;

  &.is-visible {
    pointer-events: auto;
  }

  opacity: 0;
  &.has-entered {
    opacity: 1;
    animation: ${entryAnimation} backwards ${(props) => 1.2 + props.index * 0.2}s ${(props) => 1.4 + props.index * 0.2}s
      ${easing.easeOutQuart};
  }

  @media ${MEDIA_MOBILE_ALL} {
    transform: scale(1.5, 1.5);
    &.has-entered {
      animation-name: ${entryAnimationMobile};
    }
  }
`;

export const HotspotLens = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  ${HotspotWrapper}.is-icon & {
    transform: translate3d(0, -150%, 0);
  }
`;

export const HotspotLensOuter = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;

  z-index: 1;

  overflow: hidden;
  border-radius: 50%;

  width: ${LENS_DIAMETER}px;
  height: ${LENS_DIAMETER}px;
  margin: ${-LENS_DIAMETER / 2}px 0 0 ${-LENS_DIAMETER / 2}px;

  pointer-events: none;

  ${HotspotWrapper}.is-active & {
    pointer-events: auto;
  }
`;

export const HotspotLensInner = styled.div`
  overflow: hidden;

  position: absolute;
  left: 0;
  top: 0;

  width: ${LENS_DIAMETER}px;
  height: ${LENS_DIAMETER}px;

  border-radius: 50%;
`;

export const HotspotSpecimen = styled.div`
  position: absolute;
  left: ${-LENS_OFFSET}px;
  top: ${-LENS_OFFSET}px;

  width: ${LENS_RING_DIAMETER}px;
  height: ${LENS_RING_DIAMETER}px;

  will-change: transform;

  opacity: 0;
  transform: rotate(3deg) scale(0.8, 0.8) translate3d(0, 0, 0);
  transition: opacity 0.2s ${easing.easeInOutQuart}, transform 0.6s ${easing.easeInOutQuart};

  ${HotspotWrapper}.is-active & {
    opacity: 1;
    transform: rotate(0deg) scale(1, 1) translate3d(0, 0, 0);
    transition: opacity 0.7s 0.35s ${easing.easeOutQuart}, transform 1.4s 0.35s ${easing.easeOutQuart};
  }
`;

export const HotspotSpecimenInner = styled.div`
  position: absolute;
  left: 0;
  top: 0;

  width: ${LENS_RING_DIAMETER}px;
  height: ${LENS_RING_DIAMETER}px;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
`;

export const SvgInactiveRing = styled.svg<{ type: HotspotType }>`
  position: absolute;
  left: -5%;
  top: -5%;

  width: 110%;
  height: 110%;

  /* fill: ${({ type }) =>
    type === HotspotType.ZOOM_RED
      ? COLOR_PRIMARY_RED
      : type === HotspotType.ZOOM_NEUTRAL
      ? COLOR_OUTLINE_WHITE
      : COLOR_PRIMARY_BLUE}; */
  fill: ${COLOR_OUTLINE_WHITE};

  opacity: 0;
  transform: scale(0.8, 0.8);
  transition: transform 0.6s ${easing.easeInOutQuart}, opacity 0.6s ${easing.easeInOutCubic};

  ${HotspotWrapper}.is-visible & {
    opacity: 1;
    transform: scale(0.9, 0.9);
  }

  ${HotspotWrapper}:hover & {
    transition: transform 0.3s ${easing.easeInOutQuart}, opacity 0.6s ${easing.easeInOutCubic};
    transform: scale(1, 1);
  }

  ${HotspotWrapper}.is-active & {
    opacity: 0;
    transform: rotate(179deg) translate3d(0, 6.5%, 0) scale(0.75, 0.75);
  }

  @media ${MEDIA_MOBILE_ALL} {
    left: -25%;
    top: -25%;
    width: 150%;
    height: 150%;
  }
`;

const ICON_RING_SCALE = 0.667;

const ActiveRingAsset = css<{ type: HotspotType }>`
  position: absolute;
  left: ${-(LENS_RING_DIAMETER - HOTSPOT_DIAMETER) / 2}px;
  top: ${-(LENS_RING_DIAMETER - HOTSPOT_DIAMETER) / 2}px;

  z-index: 1;

  width: ${LENS_RING_DIAMETER}px;
  height: ${LENS_RING_DIAMETER}px;
  margin-top: -7px;

  fill: ${({ type }) => (type === HotspotType.ZOOM_RED ? COLOR_PRIMARY_RED : COLOR_PRIMARY_BLUE)};

  pointer-events: none;

  opacity: 0;

  ${HotspotWrapper}.is-active & {
    opacity: 1;
  }

  ${HotspotWrapper}.is-icon & {
    left: ${-(LENS_RING_DIAMETER * ICON_RING_SCALE - HOTSPOT_DIAMETER) / 2}px;
    top: ${-(LENS_RING_DIAMETER * ICON_RING_SCALE - HOTSPOT_DIAMETER) / 2}px;
    width: ${LENS_RING_DIAMETER * ICON_RING_SCALE}px;
    height: ${LENS_RING_DIAMETER * ICON_RING_SCALE}px;
    fill: ${COLOR_PRIMARY_GOLD};
  }
`;

export const SvgActiveRingOuter = styled.svg<{ type: HotspotType }>`
  ${ActiveRingAsset}

  transform: rotate( 10deg ) scale( 1.05, 1.05 );
  transition: transform 0.3s ${easing.easeInOutQuint}, opacity 0.2s ${easing.easeInOutSine};

  ${HotspotWrapper}.is-active & {
    transition: transform 0.9s ${easing.easeInOutQuint}, opacity 0.6s ${easing.easeInOutSine};
    transform: none;
  }

  ${HotspotWrapper}.is-icon.is-active & {
    transition-delay: 0.47s;
  }
`;

export const SvgActiveRingInner = styled.svg<{ type: HotspotType }>`
  ${ActiveRingAsset}

  transform: rotate( 10deg ) scale( 0.95, 0.95 );
  transition: transform 0.3s ${easing.easeInOutQuint}, opacity 0.2s ${easing.easeInOutSine};

  ${HotspotWrapper}.is-active & {
    transition: transform 0.9s ${easing.easeInOutQuint}, opacity 0.6s ${easing.easeInOutSine};
    transform: none;
  }

  ${HotspotWrapper}.is-icon.is-active & {
    transition-delay: 0.47s;
  }
`;

export const SvgActiveRingAccent = styled.svg<{ type: HotspotType }>`
  ${ActiveRingAsset}

  transform: translate3d( 0, -8%, 0 );
  transition: transform 0.9s ${easing.easeOutExpo}, opacity 0.9s ${easing.easeOutExpo};

  ${HotspotWrapper}.is-active & {
    transition: transform 1.8s ${easing.easeOutQuint}, opacity 1.8s ${easing.easeOutQuint};
    transform: none;
    transition-delay: 0.35s;
  }

  ${HotspotWrapper}.is-icon.is-active & {
    transition-delay: 0.47s;
  }
`;

export const SvgIcon = styled.svg<{ index: number }>`
  position: absolute;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;
  margin-top: -4%;

  /* fill: ${({ type }) =>
    type === HotspotType.ZOOM_RED
      ? COLOR_PRIMARY_RED
      : type === HotspotType.ZOOM_NEUTRAL
      ? COLOR_OUTLINE_WHITE
      : COLOR_PRIMARY_BLUE}; */
  opacity: 0;
  fill: ${COLOR_OUTLINE_WHITE};

  transform: scale(0.25, 0.25);
  transition: transform 0.4s ${easing.easeInOutCubic}, opacity 0.4s ${easing.easeOutQuart};

  @media ${MEDIA_MOBILE_ALL} {
    transform: scale(0.33, 0.33);
  }

  ${HotspotWrapper}.is-icon & {
    opacity: 0;
    transform: scale(0.4, 0.4);
    transition: fill 1s ${easing.easeInOutCubic}, transform 1.5s ${easing.easeInOutCubic},
      opacity 0.6s ${easing.easeInOutCubic};
  }

  ${HotspotWrapper}.is-visible & {
    opacity: 1;
    transition-delay: ${(props) => 0.25 + 0.09 * props.index}s;
  }

  ${HotspotWrapper}.is-active & {
    /* fill: ${({ type }) =>
      type === HotspotType.ZOOM_RED
        ? COLOR_PRIMARY_RED
        : type === HotspotType.ZOOM_NEUTRAL
        ? COLOR_OUTLINE_WHITE
        : COLOR_PRIMARY_BLUE}; */
    opacity: 0;
    transform: scale(0, 0);
  }

  ${HotspotWrapper}.is-active.is-icon & {
    fill: ${COLOR_PRIMARY_GOLD};
    opacity: 1;
    transform: translateY(-150%) scale(0.7, 0.7);
    transition: fill 0.8s 0.4s ${easing.easeInOutQuart}, transform 1.2s ${easing.easeInOutQuart};
  }
`;
