import React, { useEffect, useState, useContext, useRef } from "react";
import { useResize, useTicker, useInView } from "../../utils/hooks";
import { InputManagerInstance, ManagerContext } from "../../managers";
import { easing } from "../../utils/style";
import { sign } from "../../utils/math";

import { ResponsiveSelectorWrapper } from "./style";

interface Props {
  isInViewport: boolean;
  children: React.ReactElement;
  activeElementIndex?: number;
  onSwipe?: (direction: number) => void;
  transitionDuration?: number;
  transitionDelay?: number;
  transitionTimingFunction?: string;
  verticalBreakpoint?: number;
}

const ResponsiveSelector: React.FC<Props> = ({
  isInViewport,
  children,
  activeElementIndex = 0,
  onSwipe = () => null,
  transitionDuration = 1000,
  transitionDelay = 0,
  transitionTimingFunction = easing.easeOutCubic,
  verticalBreakpoint = -1,
}) => {
  const [container, setContainer] = useState<HTMLElement>();
  const [containerWidth, setContainerWidth] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);
  const [elements, setElements] = useState<HTMLElement[]>([]);
  const [isHorizontal, setIsHorizontal] = useState(false);

  const [inViewForSwipeRef, inViewForSwipe] = useInView({ threshold: 0.5 });

  const { input, viewport } = useContext(ManagerContext);

  const childRef = useRef<HTMLElement>(null);
  const childrenWithRef = React.cloneElement(children, {
    ref: childRef,
  });

  useEffect(() => {
    if (!childRef.current) return;

    setContainer(childRef.current);
    setElements(Array.from(childRef.current.children) as HTMLElement[]);
  }, [childRef.current]);

  useResize(
    ({ width }) => {
      setIsHorizontal(width < verticalBreakpoint);

      if (!container) return;

      const { clientWidth, clientHeight } = container;
      setContainerWidth(clientWidth);
      setContainerHeight(clientHeight);
    },
    [container],
  );

  useEffect(() => {
    setAnimationPositions();
  }, [isInViewport, activeElementIndex, containerWidth, containerHeight]);

  useTicker(() => {
    const { isTouch, isDown, delta, travel } = input.latest;
    const { width } = viewport.latest;
    if (inViewForSwipe && isDown && isTouch) {
      // Detect swipes based on speed
      if (Math.abs(delta.x) > 0.03) {
        const direction = sign(-delta.x);
        InputManagerInstance.release();
        onSwipe(direction);
      }
      // Detect swipes based on distance travelled
      else if (Math.abs(travel.x) > width * 0.25) {
        const direction = sign(-travel.x);
        InputManagerInstance.release();
        onSwipe(direction);
      }
    }
  }, [inViewForSwipe]);

  const setAnimationPositions = () => {
    elements.forEach((element, index) => {
      // const offsetPos = `${index * 100}%`
      // const scrollPos = `${activeElementIndex * -100}%`

      // const xPos = isHorizontal
      //   ? `calc( ${xCenterPos} + ${offsetPos} + ${scrollPos} )`
      //   : '0%'
      // const yPos = isHorizontal
      //   ? '0%'
      //   : `calc( ${yCenterPos} + ${offsetPos} + ${scrollPos} )`

      const offsetPos = index * 100; // percentage
      const scrollPos = activeElementIndex * -100; // percentage

      // const leftPos = isHorizontal ? `${containerWidth / 2}px` : '0'
      // const topPos = isHorizontal ? '0' : `${containerHeight / 2}px`
      const topPos = isHorizontal ? "0" : `50%`;
      const leftPos = isHorizontal ? `50%` : "0";

      const xPos = isHorizontal
        ? `${offsetPos + scrollPos - 50}%` // Subtract 50% to position at center of container
        : "0%";
      const yPos = isHorizontal ? "0%" : `${offsetPos + scrollPos - 50}%`; // Subtract 50% to position at center of container

      element.style.left = leftPos;
      element.style.top = topPos;
      element.style.transition = `transform ${transitionDuration}ms ${transitionDelay}ms ${transitionTimingFunction}`;
      element.style.transform = `translate3d(${xPos}, ${yPos}, 0)`;
    });
  };

  return <ResponsiveSelectorWrapper ref={inViewForSwipeRef}>{childrenWithRef}</ResponsiveSelectorWrapper>;
};

export default ResponsiveSelector;
