import styled, { keyframes } from "styled-components";
import { rem, easing } from "../../../../utils/style";
import { COLOR_OUTLINE_TRANSPARENT_WHITE, MEDIA_MOBILE_ALL } from "../../../../layouts/default/theme";
import { MEDIA_COLUMN_LAYOUT } from "../style";

export const Wrapper = styled.div`
  position: absolute;

  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  width: 30vw;
  height: 30vw;

  max-width: ${rem(520)};
  max-height: ${rem(520)};

  @media ${MEDIA_COLUMN_LAYOUT} {
    width: 60vw;
    height: 60vw;

    position: relative;
    margin: 0;
    left: 0;
    transform: none;
  }

  @media ${MEDIA_MOBILE_ALL} {
    width: 80vw;
    height: 80vw;
  }
`;

export const ImageWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;

  z-index: 1;

  width: 100%;
  height: 100%;

  overflow: hidden;
  border-radius: 50%;

  @media ${MEDIA_MOBILE_ALL} {
    /* Resolve border-radius + overflow in Android Chrome */
    transform: rotate(0.1deg);

    html.is-safari & {
      transform: none;
    }
  }

  > img {
    position: absolute;
    opacity: 0;

    display: block;
    width: auto;
    height: 100%;
    /* Splash images always compose the champion on the right-third */
    margin: 0 0 0 -50%;

    transition: opacity 0.3s;

    &.is-selected {
      opacity: 1;
      z-index: 1;
    }
  }
`;

export const Hex = styled.div`
  z-index: 2;
`;

const ringEnter = keyframes`
  0% {
    opacity: 1;
    transform: rotate( 10deg ) scale( 0.98, 0.98 );
  }
  100% {
    opacity: 0;
    transform: none;
  }
`;

export const OuterRing = styled.svg`
  position: absolute;
  left: -2%;
  top: -2%;

  z-index: 2;

  width: 104%;
  height: 104%;

  fill: none;
  stroke: ${COLOR_OUTLINE_TRANSPARENT_WHITE};
  stroke-width: 0.25;

  opacity: 0;

  ${Wrapper}.animate-enter & {
    animation: ${ringEnter} forwards 2s 0s ${easing.easeInOutCubic};
  }

  @media ${MEDIA_COLUMN_LAYOUT} {
    transform: rotateZ(90deg);
  }
`;

const dialEnter = keyframes`
  0% {
    opacity: 0;
    transform: rotate( 10deg ) scale3d( 0.98, 0.98, 1 );
  }
  100% {
    opacity: 1;
    transform: none;
  }
`;

const dialRotate = keyframes`
  0% {
    transform: scale3d( 0.985, 0.985, 1 ) rotate( 0 );
  }
  50% {
    transform: scale3d( 1, 1, 1 ) rotate( 180deg );
  }
  100% {
    transform: scale3d( 0.985, 0.985, 1 ) rotate( 360deg );
  }
`;

export const DialRingWrapper = styled.div`
  overflow: hidden;

  position: absolute;
  left: -5%;
  top: -5%;

  width: 110%;
  height: 110%;

  pointer-events: none;

  opacity: 0;

  ${Wrapper}.animate-enter & {
    animation: ${dialEnter} forwards 3s 1s ${easing.easeOutQuart};
  }
`;

export const DialRing = styled.svg`
  position: absolute;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;

  fill: ${COLOR_OUTLINE_TRANSPARENT_WHITE};

  animation: ${dialRotate} infinite 180s linear;
`;
