import styled from "styled-components";
import { rem, easing } from "../../../../utils/style";
import { COLOR_TEXT_BLACK, MEDIA_MOBILE_ALL } from "../../../../layouts/default/theme";
import { cssLocalizedUppercase } from "../../../../components/css";

export const Wrapper = styled.div`
  position: absolute;
  left: -5%;
  top: 0;

  z-index: 1;

  width: 110%;
  height: 110%;

  @media ${MEDIA_MOBILE_ALL} {
    left: -5%;
    top: ${rem(20)};

    width: 110%;
    height: 110%;
  }
`;

export const ChampionImageWrapper = styled.div`
  position: relative;

  width: 100%;
  height: 100%;
`;

export const ChampionImage = styled.figure`
  position: absolute;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;
  margin: 0;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;

  * {
    width: 100%;
    height: 100%;
  }
`;

export const ChampionCaptionWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 100%;

  width: 100%;
  margin-top: ${rem(10)};

  text-align: center;

  @media ${MEDIA_MOBILE_ALL} {
    margin-top: 0;
  }
`;

export const ChampionCaption = styled.p`
  position: absolute;
  left: 0;
  top: 0;

  width: 100%;
  margin: 0;

  opacity: 0;
  transition: opacity 0.1s 0s ${easing.easeInOutCubic};

  &.is-active {
    opacity: 1;
    transition-duration: 0.7s;
  }
`;

export const ChampionCaptionName = styled.strong`
  display: block;

  color: ${COLOR_TEXT_BLACK};
  font-size: ${rem(14)};
  font-weight: bold;
  ${cssLocalizedUppercase}
  letter-spacing: 0.03em;
`;

export const ChampionCaptionTitle = styled.span`
  display: block;
  margin-top: ${rem(1)};

  color: ${COLOR_TEXT_BLACK};
  font-size: ${rem(14)};
  letter-spacing: 0.1em;
`;
