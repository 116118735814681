import React from "react";
import { useInView } from "../../../utils/hooks";
import GiantTitle from "../../../components/giant-title";
import SectionTitle from "../../../components/section-title";
import Button from "../../../components/button";
import { Section, ResponsiveWrapper, MapContainer, MapInner, Content, Actions, ActionButton } from "./style";
import { default as MapComponent, Controls } from "./map";
import { LinkProps } from "../../../components/link";
import { useIntl } from "gatsby-plugin-intl";

const StyledMap = MapInner.withComponent(MapComponent);
const StyledButton = ActionButton.withComponent(Button);

export interface Props {
  primaryLink: LinkProps & {
    text?: string;
  };
  secondaryLink: LinkProps & {
    text?: string;
  };
  mapRef?: React.RefObject<HTMLDivElement> | ((instance: HTMLDivElement | null) => void) | null;
  controlsRef?: (controls: Controls) => void;
  className?: string;
}

const PlayerGuide: React.FC<Props> = ({ primaryLink, secondaryLink, mapRef, controlsRef, className }) => {
  const [intersectionRef, inView] = useInView({
    threshold: 0.25,
  });
  const intl = useIntl();
  return (
    <Section ref={intersectionRef} className={className} data-testid='playerguide'>
      <SectionTitle text={intl.formatMessage({ id: "how-to-play.title" })} textColor="white" verticalAlign="center">
        <ResponsiveWrapper>
          <MapContainer>
            <StyledMap isInViewport={inView} ref={mapRef} controlsRef={controlsRef} testId='playerguide:image'/>
          </MapContainer>
          <Content>
            <GiantTitle
              isActive={inView}
              text={intl.formatMessage({ id: "section.player-guide.title" })}
              description={intl.formatMessage({ id: "section.player-guide.description" })}
              toggleContrast
              transitionDelay={100}
              testId='playerguide'
            />
            <Actions>
              <StyledButton
                link={primaryLink}
                isActive={inView}
                variant="secondary"
                transitionDelay={150}
                transitionDirection={"se"}
                testId='playerguide:getstartedbutton'
              >
                {primaryLink.text || intl.formatMessage({ id: "get-started.action" })}
              </StyledButton>
              <StyledButton link={secondaryLink} isActive={inView} transitionDelay={250} transitionDirection={"ne"} testId='playerguide:playbutton'>
                {secondaryLink.text || intl.formatMessage({ id: "play-for-free.action" })}
              </StyledButton>
            </Actions>
          </Content>
        </ResponsiveWrapper>
      </SectionTitle>
    </Section>
  );
};

export default PlayerGuide;
