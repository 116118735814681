import React, { useRef } from "react";
import { StyledComponent } from "styled-components";

import ChampionRole from "../../../../enums/champion-role";
import roleMap from "../assets/role-map";

import { Wrapper, Icon } from "./style";

export type IconMap = {
  [x in ChampionRole]: React.FunctionComponent<React.SVGProps<HTMLOrSVGElement>>;
};

interface Props {
  activeRole: ChampionRole;
  // TODO: convert iconMap to a list of valid roles.
  iconMap: IconMap;
}

const mappedIcons = (Object.keys(roleMap) as ChampionRole[]).reduce<IconMap>((map, role) => {
  map[role] = Icon.withComponent(roleMap[role].icon);
  return map;
}, {} as IconMap);

const BackgroundIcon: React.FC<Props> = ({ activeRole, iconMap }) => {
  const icons = (Object.keys(iconMap) as ChampionRole[]).map((key) => mappedIcons[key]);
  return (
    <Wrapper>
      {(Object.keys(iconMap) as ChampionRole[]).map((key, index) => {
        const RoleIcon = icons[index];
        return <RoleIcon className={`${key === activeRole ? "is-active" : ""}`} key={key} />;
      })}
    </Wrapper>
  );
};

export default BackgroundIcon;
