import React, { useState, useRef, useEffect, useContext } from "react";
import styled from "styled-components";
import { useTicker, useResize } from "../../utils/hooks";
import { calculateOffsetTop } from "../../utils/dom";

import ScrollElementContext from "../../contexts/scroll-element";

import { default as SectionPlayerGuide, Props as SectionPlayerGuideProps } from "../../sections/home/player-guide";
import { Controls } from "../../sections/home/player-guide/map";
import { ManagerContext } from "../../managers";
import { lerpClamped } from "../../utils/math";

export type Props = Omit<SectionPlayerGuideProps, "mapRef" | "progress">;

const PlayerGuide: React.FC<Props> = (props) => {
  const scrollElement = useContext(ScrollElementContext);
  const { viewport } = useContext(ManagerContext);

  const mapRef = useRef<HTMLDivElement>(null);
  const [controls, setControls] = useState<Controls | null>(null);

  const [upperBound, setUpperBound] = useState<number>(0);
  const [lowerBound, setLowerBound] = useState<number>(0);
  const [distance, setDistance] = useState<number>(0);

  // Not updated using setter, updated out of react state for performance reasons
  const [eased] = useState({ progress: 100 });

  useResize(
    ({ height }) => {
      if (!mapRef.current) return;
      if (!scrollElement) return;

      const offsetTop = calculateOffsetTop(mapRef.current, viewport.latest.scrollTop);

      setLowerBound(offsetTop - height * 0.6);
      setUpperBound(offsetTop - height * 0.2);
      setDistance(upperBound - lowerBound);
    },
    [mapRef.current],
  );

  useTicker(() => {
    if (!mapRef.current) return;
    if (!controls) return;

    const { scrollTop } = viewport.latest;
    // console.log(scrollTop)

    let newProgress: number;
    if (scrollTop < lowerBound) newProgress = 1;
    else if (scrollTop > upperBound) newProgress = 0;
    else {
      newProgress = (upperBound - scrollTop) / distance;
    }

    eased.progress = lerpClamped(eased.progress, newProgress, 0.1);

    // console.log(lowerBound, upperBound, scrollTop)

    controls.setProgress(eased.progress);
  }, [mapRef.current, controls, upperBound, lowerBound, distance]);

  return <SectionPlayerGuide mapRef={mapRef} controlsRef={setControls} {...props} />;
};

export default PlayerGuide;
