import React, { useEffect, useRef, useState } from "react";
import ResponsiveSelector from "../../../../components/responsive-selector";
import Reveal from "../../../../components/reveal";
import { useMedia } from "../../../../utils/hooks";
import { clamp } from "../../../../utils/math";
import { easeOutQuart } from "../../../../utils/style/easing";
import { BREAKPOINT_COLUMN } from "../style";
import { GameMode } from "../typings";
import { ActiveStrip, ActiveStripInner, Button, Item, Label, List, Thumb, Wrapper } from "./style";

interface Props {
  gameModes: GameMode[];
  isInViewport: boolean;
  activeMode: GameMode;
  onSelect: (name: GameMode) => void;
  testId?: string;
}

const ModeSelector: React.FC<Props> = ({ gameModes, isInViewport, activeMode, onSelect, testId }) => {
  const listRef = useRef<HTMLUListElement>(null);
  const [activeModeIndex, setActiveModeIndex] = useState(0);
  const [activeModeIndexLocal] = useState({ value: 0 });
  const [isColumnLayout, setIsColumnLayout] = useState(false);

  useMedia(`(max-width: ${BREAKPOINT_COLUMN}px)`, (match) => setIsColumnLayout(match));

  useEffect(() => {
    const newIndex = gameModes.indexOf(activeMode);
    setActiveModeIndex(newIndex);
    activeModeIndexLocal.value = newIndex;
  }, [activeMode]);

  const onSwipe = (direction: number) => {
    const clampedIndex = clamp(activeModeIndexLocal.value + direction, 0, gameModes.length - 1);
    onSelect(gameModes[clampedIndex]);
  };
  return (
    <Wrapper>
      <ResponsiveSelector
        isInViewport={isInViewport}
        onSwipe={onSwipe}
        verticalBreakpoint={BREAKPOINT_COLUMN}
        activeElementIndex={activeModeIndex}
        transitionDuration={1000}
      >
        <List ref={listRef}>
          {gameModes.map((gameMode, index) => {
            const isActive = gameMode.id === activeMode.id;

            return (
              <Item key={gameMode.id} className={`${isActive ? "is-active" : ""}`}>
                <Button onClick={() => onSelect(gameMode)} data-testid={`${testId}:gamemodebutton-${index}`}>
                  <Label>{gameMode.name}</Label>
                  <ActiveStrip>
                    <Reveal
                      isActive={isActive}
                      absolute
                      transitionDuration={isColumnLayout ? { show: 800, hide: 400 } : { show: 1200, hide: 800 }}
                      transitionDelay={isColumnLayout ? { show: 400, hide: 0 } : 0}
                      transitionTiming={easeOutQuart}
                      showDir={isColumnLayout ? "nw" : "nw"}
                      hideDir={isColumnLayout ? "nw" : "ne"}
                    >
                      <ActiveStripInner>
                        <Label>{gameMode.name}</Label>
                      </ActiveStripInner>
                    </Reveal>
                  </ActiveStrip>
                  <Thumb>
                    {React.cloneElement(gameMode.thumbnailImage, {
                      lazy: !isInViewport,
                    })}
                  </Thumb>
                </Button>
              </Item>
            );
          })}
        </List>
      </ResponsiveSelector>
    </Wrapper>
  );
};

export default ModeSelector;
