import { useIntl } from "gatsby-plugin-intl";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { HexTransitionType } from "../../../components/hex-outline";
import Image from "../../../components/image";
import Reveal from "../../../components/reveal";
import SectionTitle from "../../../components/section-title";
import Video from "../../../components/video";
import { COLOR_OUTLINE_DARK_GREY } from "../../../layouts/default/theme";
import { useInView } from "../../../utils/hooks";
import { easing } from "../../../utils/style";
import Intro from "../components/intro";
import items from "./assets/items";
import {
  AssetContainer,
  AssetHexOutline,
  AssetItem,
  AssetList,
  ContentContainer,
  ContentContainerHexOutline,
  Details,
  DetailsContent,
  DetailsSummary,
  DetailsSummaryText,
  Info,
  List,
  ListItem,
  ListItemIconMinus,
  ListItemIconPlus,
  ResponsiveWrapper,
} from "./style";

export interface Item {
  isImageItem?: boolean;
  imageSource?: string;
  videoSources?: Array<ReactElement<React.SourceHTMLAttributes<HTMLSourceElement>>>;
  title: string;
  description: string;
}

export interface Props {
  className?: string;
}

const SectionLevel: React.FC<Props> = ({ className }) => {
  const [currentItemIndex, setCurrentItemIndex] = useState<number>(0);
  const [intersectionRef, inView] = useInView();
  const [hasEntered, setHasEntered] = useState(false);

  const AssetListRef = useRef<HTMLOListElement>(null);
  const [videos, setVideos] = useState<HTMLVideoElement[]>([]);

  useEffect(() => {
    let vids: HTMLVideoElement[] = [];
    if (AssetListRef.current) {
      vids = Array.from(AssetListRef.current.querySelectorAll<HTMLVideoElement>("video"));
    }
    setVideos(vids);
  }, [AssetListRef.current]);

  useEffect(() => {
    if (!hasEntered && inView) {
      setHasEntered(true);
    }
  }, [inView]);

  useEffect(() => {
    videos.forEach((video, i) => {
      const active = i === currentItemIndex;
      if (active && inView) {
        video.play();
      } else {
        video.pause();
      }
    });
  }, [videos, currentItemIndex, inView]);
  const intl = useIntl();

  return (
    <ResponsiveWrapper className={className} ref={intersectionRef} data-testid='powerup'>
      <SectionTitle text={intl.formatMessage({ id: "powering-up.title" })}>
        <Intro
          isInViewport={inView}
          heading={intl.formatMessage({ id: "section.how-to-play.level.title" })}
          description={intl.formatMessage({ id: "section.how-to-play.level.description" })}
          testId='powerup'
        />

        <ContentContainer>
          <AssetContainer>
            <AssetList ref={AssetListRef}>
              {items.map((item, i) => {
                const isActive = i === currentItemIndex;
                return (
                  <Reveal
                    key={item.title}
                    isActive={hasEntered && isActive}
                    absolute
                    showDir={"ne"}
                    hideDir={"ne"}
                    transitionTiming={easing.easeInOutQuart}
                    transitionDuration={1500}
                    transitionDelay={0}
                  >
                    <AssetItem className={isActive ? "active" : ""}>
                      {item.isImageItem && item.imageSource ? (
                        <Image lazy={!isActive && !inView} src={item.imageSource} data-testid={`powerup:image-${i}`}/>
                      ) : item.videoSources ? (
                        <Video lazy={!isActive && !inView} loop playsInline muted testId={`powerup-${i}`}>
                          {item.videoSources}
                        </Video>
                      ) : null}
                    </AssetItem>
                  </Reveal>
                );
              })}
            </AssetList>
            <AssetHexOutline
              isActive={inView}
              clipRightTop={20}
              offsetVertical={1}
              offsetHorizontal={-1}
              strokeColor={COLOR_OUTLINE_DARK_GREY}
              accentColor={COLOR_OUTLINE_DARK_GREY}
              transition={HexTransitionType.WIRE}
              transitionDuration={1000}
              transitionDelay={500}
            />
          </AssetContainer>

          <Info>
            <List>
              {items.map((item, i) => {
                return (
                  <ListItem key={item.title}>
                    <Details open={i === currentItemIndex}>
                      <DetailsSummary
                        onClick={(e) => {
                          e.preventDefault();
                          setCurrentItemIndex(i);
                        }}
                      >
                        <ListItemIconPlus />
                        <ListItemIconMinus />
                        <DetailsSummaryText data-testid={`powerup:title-${i}`}>{intl.formatMessage({ id: item.title })}</DetailsSummaryText>
                      </DetailsSummary>
                      <DetailsContent data-testid={`powerup:description-${i}`}>{intl.formatMessage({ id: item.description })}</DetailsContent>
                    </Details>
                  </ListItem>
                );
              })}
            </List>
          </Info>
          <ContentContainerHexOutline
            isActive={inView}
            clipRightTop={40}
            offsetVertical={1}
            offsetHorizontal={-1}
            strokeColor={COLOR_OUTLINE_DARK_GREY}
            accentColor={COLOR_OUTLINE_DARK_GREY}
            transition={HexTransitionType.WIRE}
            transitionDuration={2000}
            transitionDelay={500}
          />
        </ContentContainer>
      </SectionTitle>
    </ResponsiveWrapper>
  );
};

export default SectionLevel;
