import React from "react";
import styled from "styled-components";
import SectionPromoList, { Props as PromoListProps } from "../../sections/promo-list";

export type Props = PromoListProps;

const StyledSectionPromoList = styled(SectionPromoList)`
  & + * {
    margin-top: -65px;
  }
`;

export default StyledSectionPromoList;
