import styled from "styled-components";
import { rem, animation } from "../../utils/style";
import { cssLocalizedLineHeight } from "../../components/css";

export const Wrapper = styled.section`
  padding: 70px 0 140px 0;
`;

export const Introduction = styled.p`
  max-width: 460px;
  padding: 0 10%;
  margin: 12px auto 0;
  font-size: ${rem(14)};
  text-align: center;
  letter-spacing: 0.08em;
  ${cssLocalizedLineHeight(1.6)}

  ${animation.fadeIn({ delay: 400 })}
`;

export const Body = styled.div`
  margin-top: 75px;
`;
