import styled, { css } from "styled-components";
import { COLUMN_WIDTH, MAX_WIDTH, MEDIA_DESKTOP_AT_MAX_WIDTH, MEDIA_MOBILE_ALL } from "../../layouts/default/theme";
import { rem, easing } from "../../utils/style";
import { fluidRange } from "polished";

import GiantTitleComponent from "../../components/giant-title";
import ButtonSmall from "../../components/button-small";
import TwitchComponent from "../../components/twitch";
import HexOutlineComponent from "../../components/hex-outline";

import { TwitchLogo as TwitchLogoAsset } from "./assets";
import {
  cssLocalizedFontFamilySerif,
  cssLocalizedLineHeight,
  cssLocalizedUppercase,
  cssLocalizedItalic,
} from "../../components/css";

export const Wrapper = styled.section<{ hasGiantTitle?: boolean }>`
  padding: ${rem(80)} 0;
`;

export const GiantTitle = styled(GiantTitleComponent)`
  margin-bottom: 65px;

  @media ${MEDIA_MOBILE_ALL} {
    margin-bottom: 40px;
  }
`;

export const WrapperInner = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;

  max-width: ${MAX_WIDTH - COLUMN_WIDTH * 6 + "px"};
  margin: 0 auto;

  /* transform: translate3d(0, 100%, 0);
  transition: transform 1.6s ${easing.easeOutQuart};

  &.is-activated {
    transform: none;
  } */
`;

export const HexOutlineWide = styled(HexOutlineComponent)``;

export const HexOutlineNarrow = styled(HexOutlineComponent)``;

export const Info = styled.div`
  padding: 4%;
  background-color: #9146ff;
  color: white;

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const InfoInner = styled.div``;

export const TwitchLogo = styled(TwitchLogoAsset)`
  width: 60px;
  height: 21px;
`;

export const InfoTitle = styled.h1`
  font-size: 20px;
  ${cssLocalizedLineHeight(1)}
  ${cssLocalizedFontFamilySerif}
  ${cssLocalizedUppercase}
  ${cssLocalizedItalic}
  letter-spacing: 0.05em;
`;

export const InfoText = styled.p`
  font-size: 14px;
  letter-spacing: 0.2em;
`;

export const InfoButton = styled(ButtonSmall)`
  margin-top: 20px;
`;

export const VideoContainer = styled.div`
  position: relative;
`;

export const VideoInner = styled.div`
  width: 100%;
  padding-top: ${100 / (16 / 9)}%;
`;

export const Twitch = styled(TwitchComponent)`
  background-color: black;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const rowLayout = css`
  ${WrapperInner} {
    margin: 0 ${rem(COLUMN_WIDTH * 3)};

    @media ${MEDIA_DESKTOP_AT_MAX_WIDTH} {
      margin: 0 auto;
    }
    @media only screen and (max-width: 1600px) {
      margin: 0 ${rem(COLUMN_WIDTH * 3)};
    }
    @media only screen and (max-width: 1400px) {
      margin: 0 ${rem(COLUMN_WIDTH)};
    }
    @media only screen and (max-width: 1200px) {
      margin: 0 auto;
    }
  }

  ${Info} {
    flex: 0 1 20%;
    min-width: 250px;
  }

  ${InfoTitle} {
    font-size: 40px;
  }

  ${VideoContainer} {
    flex: 1 1 auto;
  }

  ${HexOutlineNarrow} {
    display: none;
  }
`;

const columnLayout = css`
  ${WrapperInner} {
    flex-direction: column;
  }

  ${Info} {
    order: 2;
    padding: calc(2% + 30px) 25px;
  }

  ${InfoInner} {
    text-align: center;
  }

  ${VideoContainer} {
    order: 1;
  }

  ${VideoInner} {
  }

  ${HexOutlineWide} {
    display: none;
  }
`;

const LARGE_BREAKPOINT = 1000;
export const ReponsiveWrapper = styled(Wrapper)`
  html.is-ms-10 & {
    ${HexOutlineWide},
    ${HexOutlineNarrow} {
      /* event-pointers not supported on IE10 */
      display: none;
    }
  }

  @media ${`(min-width: ${LARGE_BREAKPOINT + 1 + "px"})`} {
    ${rowLayout}
  }

  @media ${`(max-width: ${LARGE_BREAKPOINT + "px"})`} {
    ${columnLayout}
  }

  ${TwitchLogo} {
    ${fluidRange(
      {
        prop: "width",
        fromSize: "70px",
        toSize: "80px",
      },
      "320px",
      "1440px",
    )}
    ${fluidRange(
      {
        prop: "height",
        fromSize: "24px",
        toSize: "27px",
      },
      "320px",
      "1440px",
    )}
  }

  ${InfoTitle} {
    ${fluidRange(
      {
        prop: "font-size",
        fromSize: "20px",
        toSize: "40px",
      },
      "320px",
      "1440px",
    )}
  }
`;
