import React from "react";
import styled from "styled-components";
import { components } from "react-select";
import { SingleValueProps } from "react-select/src/components/SingleValue";

import ChampionDifficultyRanking from "../../../enums/champion-difficulty-ranking";
import ChampionDifficultyIndicator from "../../indicator/champion-difficulty";

interface OptionType {
  value: ChampionDifficultyRanking;
  label: string;
}

const SingleValueChampionDifficulty: React.FC<SingleValueProps<OptionType>> = ({ children, data, ...props }) => {
  return (
    <components.SingleValue data={data} {...props}>
      <ChampionDifficultyIndicator ranking={data.value} />
    </components.SingleValue>
  );
};

export default SingleValueChampionDifficulty;
