import React, { useRef, useState, useEffect, useContext } from "react";
import { useTickerWithScrollActivation, useInView, useResize } from "../../../../utils/hooks";
import { ease, lerpClamped } from "../../../../utils/math";
import Tween from "../../../../utils/classes/Tween";

import { Container, LayerContainer, LayerWrapper, Layer } from "./style";

import { ManagerContext } from "../../../../managers";

import layerImage1 from "./assets/hud-1.png";
// import layerImage2 from './assets/hud-2.png'
import layerImage2 from "./assets/hud-2.jpg";

interface Props {
  isInViewport: boolean;
}

const SceneBackdrop: React.FC<Props> = ({ isInViewport = false }) => {
  const { input, viewport } = useContext(ManagerContext);
  const containerRef = useRef<any>(null);

  // const [containerHeight, setContainerHeight] = useState(100.0)

  // Not updated using setter, updated out of react state for performance reasons
  const [passive] = useState({
    pointerX: 0.0,
    pointerY: 0.0,
    layers: [
      {
        ref: useRef<any>(null),
        perspective: -0.3,
        img: layerImage2,
      },
      {
        ref: useRef<any>(null),
        perspective: 0,
        img: layerImage1,
        gradient: true,
      },
      // {
      //   ref: useRef<any>(null),
      //   perspective: 0,
      //   img: layerImage01,
      //   gradient: true,
      // },
    ],
  });

  useTickerWithScrollActivation(
    containerRef.current,
    ({}, activation) => {
      if (!isInViewport) return;

      const { width, height } = viewport.latest;

      const targetPointerX = input.normal.x * 2.0 - 1.0;
      const targetPointerY = input.normal.y * 2.0 - 1.0;

      passive.pointerX = lerpClamped(passive.pointerX, targetPointerX, 0.04, 0.02);
      passive.pointerY = lerpClamped(passive.pointerY, targetPointerY, 0.04, 0.02);

      // const activationValue = (activation.in + activation.out) * 0.5

      // const verticalOffset = 1.0 - activationValue

      passive.layers.forEach((layer) => {
        layer.ref.current.style.transform = `translate3d(
        ${layer.perspective * passive.pointerX * -8.0}px,
        ${
          layer.perspective * passive.pointerY * -40.0 +
          layer.perspective * (1 - activation.in) * Math.min(width, height) * 0.4 +
          layer.perspective * activation.out * Math.min(width, height) * -0.4
        }px,
        ${-2 + layer.perspective * activation.in * 4.0}px)`;
      });
    },
    [isInViewport, containerRef],
    0.135, // easingFactor
  );

  useResize((props) => {
    // const SCALING_RATIO_THRESHOLD = 0.8
    // const BOTTOM_OFFSET_HEIGHT = props.height * -0.55
    // const ratio = props.width / props.height - SCALING_RATIO_THRESHOLD
    // const offset = ratio * BOTTOM_OFFSET_HEIGHT
    // containerRef.current.style.height = `${props.height - offset}px`
    // setContainerHeight(props.height - offset)
  });

  return (
    <Container ref={containerRef}>
      <LayerContainer>
        {passive.layers.map((layer, index) => {
          // const gradient = (layer.gradient) ? (<GradientLayer></GradientLayer>) : '';
          return (
            <LayerWrapper ref={layer.ref} key={index}>
              <Layer style={{ backgroundImage: `url(${layer.img})` }} className={layer.gradient ? "gradient" : ""} />
            </LayerWrapper>
          );
        })}
      </LayerContainer>
    </Container>
  );
};

export default SceneBackdrop;
