import { SectionCategoryArticleListWrapperContentstackProps } from "../../sections/category/article-list/wrapper/contentstack";
import { SectionCategoryIntroProps } from "../../sections/category/intro";
import { Props as SectionChampionListProps } from "../../sections/champion-list";
import { Props as SectionDownloadAppProps } from "../../sections/download-app";
import { Props as SectionDownloadGameBasicProps } from "../../sections/download-game-basic";
import { Props as SectionGameIntroProps } from "../../section-groups/game-intro";
import { Props as SectionGameModesProps } from "../../sections/home/game-modes";
import { Props as SectionPlayerGuideProps } from "../../section-groups/player-guide";
import { Props as SectionHomeHeroProps } from "../../sections/home/hero";
import { Props as SectionHowToPlayProps } from "../../section-groups/how-to-play";
import { Props as SectionNewsItemListProps } from "../../sections/news-item-list";
import { SectionNewsHighlightsListProps } from "../../sections/news/highlights-list";
import { Props as SectionPromoProps } from "../../sections/promo";
import { Props as SectionPromoListProps } from "../../section-groups/promo-list";
import { Props as SectionTwitchProps } from "../../sections/twitch";
import { SectionNewsHeaderProps } from "../../sections/news/header";
import { SectionNewsLatestProps } from "../../sections/news/latest";
import { SectionNewsPopularProps } from "../../sections/news/popular";
import { SectionNewsSocialProps } from "../../sections/news/social";
import { SectionNewsYoutubeProps } from "../../sections/news/youtube";
import { EmailFormContentstackProps } from '@riotgames/wwpub-components'

export enum SectionType {
  CATEGORY_ARTICLE_LIST_CONTENTSTACK = "category_article_list_contentstack",
  CATEGORY_INTRO = "category_intro",
  CHAMPION_LIST = "champion_list",
  DOWNLOAD_APP = "download_app",
  DOWNLOAD_GAME_BASIC = "download_game_basic",
  GAME_INTRO = "game_intro",
  GAME_MODES = "game_modes",
  PLAYER_GUIDE = "player_guide",
  HOME_HERO = "home_hero",
  HOW_TO_PLAY = "how_to_play",
  NEWS_ITEM_LIST = "news_item_list",
  NEWS_HIGHLIGHTS_LIST = "news_highlights_list",
  PROMO = "promo",
  PROMO_LIST = "promo_list",
  TWITCH = "twitch",
  NEWS_HEADER = "news_header",
  NEWS_LATEST = "news_latest",
  NEWS_POPULAR = "news_popular",
  NEWS_SOCIAL = "news_social",
  NEWS_YOUTUBE = "news_youtube",
  EMAIL_FORM = 'email_form',
}

export type Section =
  | {
      key: string;
      type: SectionType.CATEGORY_ARTICLE_LIST_CONTENTSTACK;
      props: SectionCategoryArticleListWrapperContentstackProps;
    }
  | {
      key: string;
      type: SectionType.CATEGORY_INTRO;
      props: SectionCategoryIntroProps;
    }
  | {
      key: string;
      type: SectionType.CHAMPION_LIST;
      props: SectionChampionListProps;
    }
  | {
      key: string;
      type: SectionType.DOWNLOAD_APP;
      props: SectionDownloadAppProps;
    }
  | {
      key: string;
      type: SectionType.DOWNLOAD_GAME_BASIC;
      props: SectionDownloadGameBasicProps;
    }
  | { key: string; type: SectionType.GAME_INTRO; props: SectionGameIntroProps }
  | { key: string; type: SectionType.GAME_MODES; props: SectionGameModesProps }
  | {
      key: string;
      type: SectionType.PLAYER_GUIDE;
      props: SectionPlayerGuideProps;
    }
  | { key: string; type: SectionType.HOME_HERO; props: SectionHomeHeroProps }
  | { key: string; type: SectionType.HOW_TO_PLAY; props: SectionHowToPlayProps }
  | {
      key: string;
      type: SectionType.NEWS_ITEM_LIST;
      props: SectionNewsItemListProps;
    }
  | {
      key: string;
      type: SectionType.NEWS_HIGHLIGHTS_LIST;
      props: SectionNewsHighlightsListProps;
    }
  | { key: string; type: SectionType.PROMO; props: SectionPromoProps }
  | { key: string; type: SectionType.PROMO_LIST; props: SectionPromoListProps }
  | { key: string; type: SectionType.TWITCH; props: SectionTwitchProps }
  | {
      key: string;
      type: SectionType.NEWS_HEADER;
      props: SectionNewsHeaderProps;
    }
  | {
      key: string;
      type: SectionType.NEWS_LATEST;
      props: SectionNewsLatestProps;
    }
  | {
      key: string;
      type: SectionType.NEWS_POPULAR;
      props: SectionNewsPopularProps;
    }
  | {
      key: string;
      type: SectionType.NEWS_SOCIAL;
      props: SectionNewsSocialProps;
    }
  | {
      key: string;
      type: SectionType.NEWS_YOUTUBE;
      props: SectionNewsYoutubeProps;
    }
  | {
    key: string
    type: SectionType.EMAIL_FORM
    props: EmailFormContentstackProps
  };
