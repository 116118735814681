import styled from "styled-components";
import { hoverEffect } from "../../../../utils/style";

import CategoryComponent from "../../../../components/format/category";
import { MEDIA_NOT_MOBILE, MEDIA_MOBILE_ALL } from "../../../../layouts/default/theme";

export const Wrapper = styled.a`
  display: block;
  color: inherit;
`;

export const WrapperInner = styled.article`
  /* iOS 13 and Flickity showcase buggy behavior when events are bubbling up from children */
  html.is-safari & {
    @media ${MEDIA_MOBILE_ALL} {
      pointer-events: none;
    }
  }
`;

export const Image = styled.span`
  display: block;
  position: relative;
  overflow: hidden;
  padding-top: 125%;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 13%;
    padding-top: 13%;
    background-color: white;
    transform: translate3d(50%, -50%, 0) rotate(45deg);
    will-change: transform;
    transition: transform 0.5s;
  }

  ${hoverEffect.scaleDown(Wrapper)}

  > * {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
  }
`;

export const Category = styled(CategoryComponent)`
  margin-top: 0.8em;
`;

export const Title = styled.h3`
  margin: 0.4em 0 0 0;
  font-size: 14px;
  letter-spacing: 0.05em;
`;

export const ResponsiveWrapper = styled(Wrapper)`
  &:hover {
    text-decoration: none;
  }

  @media ${MEDIA_NOT_MOBILE} {
    &:hover {
      ${Image}::after {
        transform: translate3d(100%, -100%, 0) rotate(45deg);
      }
    }
  }
`;
