import styled, { keyframes } from "styled-components";
import { rem, easing } from "../../../../utils/style";

import { Base3x, Base2x, Base1x } from "./assets";
import { MEDIA_MOBILE_ALL } from "../../../../layouts/default/theme";

const BREAKPOINT_2X = 600;
const BREAKPOINT_3X = 1400;

export const MapOuter = styled.figure`
  width: 100%;
  height: 100%;
  margin: 0;
`;

/* Transformations applied to pan/zoom the map */
export const MapCamera = styled.div<{ safariZoomLevel: number }>`
  position: relative;

  width: 100%;
  height: 100%;

  opacity: 0;

  @media ${MEDIA_MOBILE_ALL} {
    /* Set initial scale to the minimum of 30% */
    transform: scale(0.3, 0.3);
  }

  html.is-safari & {
    transform: ${(props) => `scale3d( ${props.safariZoomLevel}, ${props.safariZoomLevel}, 1 ) !important`};
  }
`;

export const MapPlane = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;

  /* Derived by hand to suit map asset, representative of the map's play area at middle res (2x) */
  width: 1028px;
  height: 640px;

  transform: translate(-50%, -50%);
`;

export const MapAsset = styled.div`
  position: absolute;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;

  background-image: url(${Base1x});

  @media only screen and (min-width: ${BREAKPOINT_2X}px) {
    background-image: url(${Base2x});
  }

  @media only screen and (min-width: ${BREAKPOINT_3X}px) {
    background-image: url(${Base3x});
  }

  /* Png asset dimsensions at middle res (2x) */
  width: 2000px;
  height: 1244px;
  /* Align the map play-space to the MapPlane at middle res (2x) */
  left: -517px;
  top: -182px;
`;
