import styled, { css } from "styled-components";
import { animation } from "../../../utils/style";
import HexOutline from "../../../components/hex-outline";
import { prefixedDropdownClass } from "../../../components/dropdown";
import { COLOR_PRIMARY_ORANGE } from "../../../layouts/default/theme";
import { cssLocalizedUppercase } from "../../../components/css";

export const Wrapper = styled.section`
  ${animation.fadeIn({ delay: 500 })}
`;

export const Nav = styled.nav`
  margin: 0 2%;
`;

export const NavInner = styled.div`
  position: relative;
  display: block;
  margin: 0 auto;
  max-width: 1400px;
  box-sizing: border-box;
`;

export const NavOutline = styled(HexOutline)`
  right: 2px;
`;

export const NavContent = styled.div`
  box-sizing: border-box;
  height: 50px;
  /* padding: 1px; account for the hex outline */
  position: relative;
  display: flex;
  align-items: stretch;
`;

const NavSide = styled.div`
  position: relative;
  flex: 0 1 auto;
  display: flex;
  align-items: center;

  > * {
    width: 100%;
  }
`;

export const NavSearch = styled(NavSide)`
  min-width: 138px;
`;

export const NavDifficulty = styled(NavSide)`
  min-width: 172px;
`;

export const RoleContainer = styled.div`
  flex: 1 0 auto;
`;

export const RoleList = styled.ul`
  height: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
`;

export const RoleItem = styled.li`
  display: inline-block;
  font-size: 10px;
  height: 100%;
`;

export const RoleButton = styled.button<{ selected: boolean }>`
  position: relative;
  display: block;
  height: 100%;
  padding: 0 10px;
  border: 0;
  background-color: transparent;
  box-sizing: border-box;
  letter-spacing: 0.1em;

  font-weight: 600;
  ${cssLocalizedUppercase}
  color: #cacaca;

  transition: color 300ms;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 3px;
    background-color: ${COLOR_PRIMARY_ORANGE};
    transform: scale(0, 1);
    transition: transform 300ms;
  }

  &:hover {
    color: black;
    &::after {
      transform: scale(0.2, 1);
    }
  }

  ${({ selected }) =>
    selected &&
    css`
      color: black;
      &::after {
        transform: scale(1, 1) !important;
      }
    `}
`;

const layoutHex = css`
  .role-dropdown {
    display: none;
  }
`;

const layoutFlat = css`
  ${Nav} {
    margin: 0;
  }

  ${NavInner} {
    background-color: white;
    border: 1px solid #c1c1c1;
    border-left: 0;
    border-right: 0;
  }

  ${NavOutline} {
    display: none;
  }

  ${NavContent} {
    height: 35px;
    padding: 0px;
  }

  ${NavSide} {
    flex: 1 1 auto;
  }

  ${RoleContainer} {
    display: flex;
    align-items: center;
  }

  ${RoleList} {
    display: none;
  }
  .role-dropdown {
    flex: 1 1 auto;
    align-items: center;
  }
`;

const layoutFlatWithCollapsedSearch = css`
  ${NavSearch} {
    flex: 0 1 auto;
    height: 100%;
    background-color: white;

    &.active {
      z-index: 1;
      position: absolute;
      width: 100%;
      border-right: 0;
    }

    &:not(.active) ${prefixedDropdownClass("dropdown", "placeholder")} {
      display: none;
    }
  }

  ${NavSearch} {
    min-width: auto;
  }

  ${NavDifficulty} {
    min-width: auto;

    /* HACK 2019-10-23 jeremboo: hide the dropdown left line */
    & > div::before {
      display: none;
    }
  }
`;

const BREAKPOINT_LARGE = 1000;
const BREAKPOINT_SMALL = 500;
export const ResponsiveWrapper = styled(Wrapper)<{ stickyOffset?: number }>`
  position: sticky;
  z-index: 5;

  @media (min-width: ${BREAKPOINT_LARGE + 1 + "px"}) {
    ${layoutHex}
    top: ${({ stickyOffset }) => (stickyOffset || 0) + 10 + "px"};
  }

  @media (max-width: ${BREAKPOINT_LARGE + "px"}) {
    ${layoutFlat}
    top: ${({ stickyOffset }) => (stickyOffset || 0) + "px"};
  }

  @media (max-width: ${BREAKPOINT_SMALL + "px"}) {
    ${layoutFlatWithCollapsedSearch}
  }
`;
