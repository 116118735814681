import styled from "styled-components";
import { easing } from "../../../../utils/style";

export const VIDEO_TRANSITION_DURATION_IN_MS = 300;

export const Wrapper = styled.div`
  width: 100%;
`;

export const WrapperInner = styled.div`
  position: relative;
  width: 100%;
  padding-top: 56.25%;
`;

export const VideoContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const Video = styled.video`
  position: absolute;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;

  opacity: 0;
  transition: opacity ${VIDEO_TRANSITION_DURATION_IN_MS + "ms"} ${VIDEO_TRANSITION_DURATION_IN_MS + "ms"}
    ${easing.easeInOutCubic};

  &.is-active {
    z-index: 1;

    opacity: 1;
    transition-delay: 0ms;
  }
`;

export const Hex = styled.div`
  z-index: 1;
`;
