import RiotLocale from "../enums/riot-locale";
import LogoAsset1200 from "../assets/logo/logo-1200.png";
import vietnameseLogo from "../assets/logo/logo-vi-vn.png";
import chineseLogo from "../assets/logo/logo-zh-tw.png";

export default function getGameLogo(locale: string): string {
  let gameLogo;

  switch (locale) {
    case RiotLocale.vi_VN:
      gameLogo = vietnameseLogo;
      break;
    case RiotLocale.zh_TW:
      gameLogo = chineseLogo;
      break;
    default:
      gameLogo = LogoAsset1200;
      break;
  }

  return gameLogo;
}
