import styled, { css } from "styled-components";
import { rem } from "../../../utils/style";
import {
  COLOR_BACKGROUND_DARK,
  MAX_WIDTH,
  COLUMN_WIDTH,
  MEDIA_DESKTOP_AT_MAX_WIDTH,
} from "../../../layouts/default/theme";

export const BREAKPOINT_COLUMN = 1000;
export const BREAKPOINT_ROW = BREAKPOINT_COLUMN + 1;
export const MEDIA_COLUMN_LAYOUT = `(max-width: ${BREAKPOINT_COLUMN}px)`;
export const MEDIA_ROW_LAYOUT = `(min-width: ${BREAKPOINT_ROW}px)`;

const SCROLL_BUFFER_VH = 25;
export const Section = styled.section<{ chapterCount: number }>`
  position: relative;

  height: ${(props) => props.chapterCount * 100}vh;
  /* Add buffer at top to add extra scrollable space */
  padding-top: ${SCROLL_BUFFER_VH}vh;

  /* Prevent 1px gap due to sub-pixel rounding */
  margin-top: -2px;

  background: ${COLOR_BACKGROUND_DARK};

  @media ${MEDIA_COLUMN_LAYOUT} {
    height: auto;
    padding-top: 0;
  }
  html.is-ms & {
    height: ${(props) => props.chapterCount * 100}vh;
    padding-top: 0;
  }
`;

export const SectionStack = styled.div`
  position: relative;

  box-sizing: border-box;
  height: 100vh;
  padding-top: 10vh;
  overflow: hidden;

  &.is-first {
    position: absolute;
    left: 0;
    top: ${SCROLL_BUFFER_VH}vh;

    width: 100%;
  }

  &.is-second {
    margin-top: ${SCROLL_BUFFER_VH}vh;
  }

  @media ${MEDIA_COLUMN_LAYOUT} {
    height: auto;
    padding-top: 0;

    &.is-first {
      position: relative;
      top: 0;
    }
    &.is-second {
      margin-top: 0;
    }
  }

  html.is-ms & {
    &.is-first {
      position: relative;
      top: 0;
    }
    &.is-second {
      margin-top: 0;
    }
  }
`;

export const BodyWrapper = styled.div<{ stickyOffset?: number }>`
  box-sizing: border-box;

  position: relative;
  top: 0;

  width: 100%;
  height: 100vh;

  @media ${MEDIA_COLUMN_LAYOUT} {
    height: auto;
  }

  &.is-sticky-layout {
    overflow: hidden;
    position: sticky;
    z-index: 1;
    margin-top: ${-SCROLL_BUFFER_VH}vh;

    top: ${({ stickyOffset }) => (stickyOffset || 0) + "px"};
    height: calc(100vh - ${({ stickyOffset }) => (stickyOffset || 0) + "px"});

    @media ${MEDIA_COLUMN_LAYOUT} {
      display: none;
    }
    html.is-ms & {
      display: none;
    }
  }
`;

export const BodyInner = styled.div`
  position: relative;

  display: flex;
  align-items: flex-start;

  width: calc(100% - ${rem(COLUMN_WIDTH)});
  max-width: ${rem(MAX_WIDTH)};
  height: 100%;
  padding-left: ${rem(COLUMN_WIDTH)};

  @media ${MEDIA_DESKTOP_AT_MAX_WIDTH} {
    margin: 0 calc((100vw - ${rem(MAX_WIDTH)}) / 2);
  }

  @media ${MEDIA_COLUMN_LAYOUT} {
    flex-wrap: wrap;
    align-content: flex-start;

    width: 100%;
    padding-top: 40vw;
    padding-left: 0;
    padding-bottom: ${rem(60)};
  }
`;

export const BodyDock = styled.div`
  position: relative;
  z-index: 1;

  width: ${rem(COLUMN_WIDTH * 7)};

  @media ${MEDIA_COLUMN_LAYOUT} {
    order: 1;

    width: 100%;
    margin-top: 45vw;
  }
`;

export const BodyDisplay = styled.div`
  width: calc(100% - ${rem(COLUMN_WIDTH * 9)});
  min-height: 100%;
  padding-top: 45vh;
  margin-left: ${rem(COLUMN_WIDTH * 8)};

  display: none;
  ${BodyWrapper}.is-sticky-layout & {
    display: block;
  }

  @media ${MEDIA_COLUMN_LAYOUT} {
    display: block;
    ${BodyWrapper}.is-sticky-layout & {
      display: none;
    }

    order: 0;

    box-sizing: border-box;

    width: 80%;
    min-height: 0;
    padding-top: 0;
    margin: 0 auto;
  }

  html.is-ms & {
    display: block;
    ${BodyWrapper}.is-sticky-layout & {
      display: none;
    }

    position: absolute;
    right: 0;
    top: 0;
    width: 100vh;
    max-width: 70%;
    padding-top: 30vh;
  }
`;
