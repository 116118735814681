import styled from "styled-components";
import ProgressiveHydrationUtil from "../../utils/progressive-hydration";
import { cssPullNextSection } from "../../sections/news/highlights-list/style";
import { SectionType } from "./typings";
import { cssPullUpNewsItemListSection as cssPullUpNewsItemListSectionAfterHomeHero } from "../../sections/home/hero/style";
import { cssPullUpNewsItemListSection as cssPullUpNewsItemListSectionAfterNewsHeader } from "../../sections/news/header/style";
import { SectionWithTitle as SectionPromoListSectionWithTitle } from "../../sections/promo-list";

export const ProgressiveHydration = styled.div`
  &.type-${SectionType.NEWS_HIGHLIGHTS_LIST}.next-type-${SectionType.CHAMPION_LIST} {
    ${cssPullNextSection}
  }

  &.type-${SectionType.HOME_HERO} + .type-${SectionType.NEWS_ITEM_LIST} {
    ${cssPullUpNewsItemListSectionAfterHomeHero}
  }

  &.type-${SectionType.NEWS_HEADER} + .type-${SectionType.NEWS_ITEM_LIST} {
    ${cssPullUpNewsItemListSectionAfterNewsHeader}
  }

  &.type-${SectionType.TWITCH} + .type-${SectionType.PROMO_LIST}:not(.has-giant-title) {
    ${SectionPromoListSectionWithTitle} {
      padding-top: 0;
    }
  }
`;
