import styled from "styled-components";
import { StyledArrowIcon } from "./custom-components/dropdown-indicator-arrow";
import { StyledLoupeIcon } from "./custom-components/value-container-loupe";

import { COLOR_OUTLINE_LIGHT_GREY, MEDIA_MOBILE_ALL } from "../../layouts/default/theme";
import { cssLocalizedUppercase } from "../css";

export const prefixedClass = (prefix: string, component: string, state?: string) => {
  return `.${prefix}__${component}${state ? "--" + state : ""}`;
};

export const Dropdown = styled.div<{
  classNamePrefix: string;
  borderLeft: boolean;
}>`
  width: 100%;
  height: 100%;
  font-size: 10px;
  letter-spacing: 0.1em;
  font-weight: 600;
  ${cssLocalizedUppercase}

  /* control */

  ${({ classNamePrefix }) => prefixedClass(classNamePrefix, "control")} {
    cursor: pointer;
    height: 100%;
    min-height: 0;
    padding: 0 30px;
    border: 0;
    box-shadow: none; /* hide active border */
    background-color: transparent;
    justify-content: center;

    &::before {
      content: "";
      position: absolute;
      top: 10px;
      bottom: 10px;
      ${({ borderLeft }) => (borderLeft ? "left: 0" : "right: 0")};
      width: 1px;
      background-color: ${COLOR_OUTLINE_LIGHT_GREY};
      transition: top 250ms, bottom 250ms;
    }
  }

  ${({ classNamePrefix }) => prefixedClass(classNamePrefix, "single-value")} {
    position: relative;
    display: flex;
    transform: none;
    max-width: 100%;
    margin-right: 10px;
  }

  ${({ classNamePrefix }) => prefixedClass(classNamePrefix, "value-container")} {
    padding: 0;
    display: flex;
    flex: none;
  }

  ${({ classNamePrefix }) => prefixedClass(classNamePrefix, "placeholder")} {
    position: static;
    top: auto;
    color: black;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0;
    transform: none;
    transition: color 250ms;
  }

  ${({ classNamePrefix }) => prefixedClass(classNamePrefix, "indicator-separator")} {
    display: none;
  }

  ${({ classNamePrefix }) => prefixedClass(classNamePrefix, "clear-indicator")} {
    padding: 0;
    background-color: #d5d5d5;
    border-radius: 50%;
    transform: translate(-6px, 0);
    transition: background-color 300ms;

    &:hover {
      background-color: #b2b2b2;
    }

    svg {
      width: 11px;
      height: 11px;
      fill: white;
      stroke: white;
    }
  }

  ${({ classNamePrefix }) => prefixedClass(classNamePrefix, "dropdown-indicator")} {
    padding: 4px;
    transition: transform 250ms;
  }

  ${({ classNamePrefix }) => prefixedClass(classNamePrefix, "option")} {
    cursor: pointer;

    padding: 10px 30px;
    transition: background-color 300ms;

    &:active {
      background-color: transparent;
    }
  }

  ${({ classNamePrefix }) => prefixedClass(classNamePrefix, "input")} {
    input {
      text-transform: inherit;
      letter-spacing: inherit;
      font-weight: inherit;
    }
  }

  ${({ classNamePrefix }) => prefixedClass(classNamePrefix, "control", "menu-is-open")} {
    ${StyledArrowIcon} {
      color: #b2b2b2;
      transform: rotate(180deg);
    }
  }

  /* menu */

  ${({ classNamePrefix }) => prefixedClass(classNamePrefix, "menu")} {
    left: 0px;
    right: 1px;
    width: auto;
    margin-top: -10px;
    border-radius: 0;
    box-shadow: none;
    border: 1px solid ${COLOR_OUTLINE_LIGHT_GREY};
    border-top: 0;
  }

  ${({ classNamePrefix }) => prefixedClass(classNamePrefix, "menu-list")} {
    border-radius: 0;
    padding: 0;
  }

  ${({ classNamePrefix }) => prefixedClass(classNamePrefix, "option", "is-focused")} {
    background-color: #f4f4f4;
  }

  ${({ classNamePrefix }) => prefixedClass(classNamePrefix, "option", "is-selected")} {
    background-color: #006680;
  }

  /* search */
  &.searchable {
    ${StyledLoupeIcon} {
      position: absolute;
      left: 5px;
      top: 0;
      transition: left 250ms;
    }

    ${({ classNamePrefix }) => prefixedClass(classNamePrefix, "value-container")} {
      padding-left: 30px;

      html.is-ms-ie & {
        min-width: 150px;
      }
    }

    ${({ classNamePrefix }) => prefixedClass(classNamePrefix, "dropdown-indicator")} {
      display: none;
    }

    /* search selected */
    ${({ classNamePrefix }) => prefixedClass(classNamePrefix, "control", "menu-is-open")},
    ${({ classNamePrefix }) => prefixedClass(classNamePrefix, "control", "is-focused")} {
      ${({ classNamePrefix }) => prefixedClass(classNamePrefix, "placeholder")} {
        position: absolute;
        color: #cacaca;

        html.is-ms-ie & {
          display: none;
        }
      }
      ${({ classNamePrefix }) => prefixedClass(classNamePrefix, "input")} {
        margin-left: -4px;
      }
      ${StyledLoupeIcon} {
        left: 0;
      }
      input {
        min-width: 47.34px !important;
      }
    }
  }

  /* hover */
  &:hover
    ${({ classNamePrefix }) => prefixedClass(classNamePrefix, "control")},
    ${({ classNamePrefix }) => prefixedClass(classNamePrefix, "control", "is-focused")},
    ${({ classNamePrefix }) => prefixedClass(classNamePrefix, "control", "menu-is-open")} {
    &::before {
      top: 0;
      bottom: 0;
    }

    ${StyledLoupeIcon} {
      left: 0;
    }

    ${({ classNamePrefix }) => prefixedClass(classNamePrefix, "dropdown-indicator")} {
      transform: translate(5px, 0px);
    }
  }

  /* Responsive */

  @media ${MEDIA_MOBILE_ALL} {
    ${({ classNamePrefix }) => prefixedClass(classNamePrefix, "control")} {
      padding: 0 15px;
    }
  }
`;
