import { TooltipMap } from "../../../../components/format/tooltipper";

const tooltipMap: TooltipMap = {
  minions: {
    title: "minions.title",
    desc: "minions.description",
  },
  fountain: {
    title: "fountain.title",
    desc: "fountain.description",
  },
  shop: {
    title: "shop.title",
    desc: "shop.description",
  },
  "fog-of-war": {
    title: "fog-of-war.title",
    desc: "fog-of-war.description",
  },
  "super-minions": {
    title: "super-minions.title",
    desc: "super-minions.description",
  },
  "infernal-drake": {
    title: "infernal-drake.title",
    desc: "infernal-drake.description",
  },
  "cloud-drake": {
    title: "cloud-drake.title",
    desc: "cloud-drake.description",
  },
  "mountain-drake": {
    title: "mountain-drake.title",
    desc: "mountain-drake.description",
  },
  "ocean-drake": {
    title: "ocean-drake.title",
    desc: "ocean-drake.description",
  },
  "elder-dragon": {
    title: "elder-dragon.title",
    desc: "elder-dragon.description",
  },
};

export default tooltipMap;
