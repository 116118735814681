import React from "react";

import { Category } from "../types";
import { ResponsiveWrapper, Name, Text, Image } from "./style";
import LinkComponent from "../../../../components/link";
import { useIntl } from "gatsby-plugin-intl";

export interface CategoryProps extends Category {
  className?: string;
  isVisible?: boolean;
  animationDelay?: number;
}

const SectionNewsLatest: React.FC<CategoryProps> = ({
  text,
  link,
  image,
  className,
  isVisible = true,
  animationDelay = 0,
}) => {
  return (
    <ResponsiveWrapper
      {...link}
      className={`${className} ${isVisible ? "isVisible" : ""}`}
      as={link ? LinkComponent : "span"}
      data-animation-delay={animationDelay}
    >
      <Image>{image}</Image>
      <Name animationDelay={animationDelay + 200}>
        <Text>{text}</Text>
      </Name>
    </ResponsiveWrapper>
  );
};

export default SectionNewsLatest;
