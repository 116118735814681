import styled, { keyframes } from "styled-components";
import { rgba } from "polished";
import {
  MEDIA_MOBILE_ALL,
  MEDIA_TABLET_SMALL,
  COLOR_TEXT_DARK_GREY,
  COLOR_OUTLINE_DARK,
  MEDIA_TABLET_AND_SMALLER,
  MOBILE_MARGIN,
  COLUMN_WIDTH,
  MAX_WIDTH,
  MEDIA_DESKTOP_AT_MAX_WIDTH,
  COLOR_BACKGROUND_BLACK,
} from "../../../layouts/default/theme";

import SelectorComponent from "../../../components/selector";
import {
  OptionBulletContainer as SelectorOptionBulletContainer,
  Baseline as SelectorBaseline,
  Option as SelectorOption,
  OptionText as SelectorOptionText,
} from "../../../components/selector/style";
import { SIDE_WIDTH_SMALL_IN_PX, SIDE_WIDTH_IN_PX } from "../../../components/section-title/style";
import { Anchor as BouttonSmallAnchor } from "../../../components/button-small/style";
import { rem, easing } from "../../../utils/style";
import { cssLocalizedLineHeight } from "../../../components/css";

export const Wrapper = styled.section`
  position: relative;
  z-index: 1;

  width: 100%;
  padding-top: 190px;
  padding-bottom: 210px;

  /* Prevent 1px gap due to sub-pixel rounding */
  margin-top: -2px;

  color: white;

  background: ${COLOR_BACKGROUND_BLACK};

  @media ${MEDIA_MOBILE_ALL} {
    padding-top: 120px;
    padding-bottom: 150px;
  }
`;

export const SectionInner = styled.div`
  position: relative;

  width: 100%;
  max-width: ${MAX_WIDTH - COLUMN_WIDTH * 4}px;
  margin: 0 auto;

  @media ${MEDIA_TABLET_SMALL} {
    padding-bottom: 30px;
  }

  @media ${MEDIA_MOBILE_ALL} {
    width: calc(100% + 20px);
    margin: 0 -10px;
  }
`;

export const Hex = styled.div`
  z-index: 2;
`;

export const Title = styled.h1`
  top: -56px;
  margin-bottom: -56px;

  @media ${MEDIA_MOBILE_ALL} {
    top: -31px;
    margin-bottom: -31px;
  }

  strong {
    font-size: 70px;

    @media ${MEDIA_MOBILE_ALL} {
      font-size: 28px;
    }
  }
`;

export const DisplayToggle = styled.div`
  position: relative;
  z-index: 2;

  margin-top: 25px;
  text-align: center;

  ${BouttonSmallAnchor} {
    margin: 5px 10px;
  }

  @media ${MEDIA_MOBILE_ALL} {
    margin: 0 20px;
    margin-top: 25px;

    ${BouttonSmallAnchor} {
      margin: 5px;
    }
  }
`;

export const SelectorContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: -16px;

  z-index: 2;

  width: 100%;

  @media ${MEDIA_TABLET_AND_SMALLER} {
    bottom: 0;
  }
`;

export const Selector = styled(SelectorComponent)`
  position: absolute;
  left: 0;
  bottom: 0;

  width: 100%;

  pointer-events: none;
  opacity: 0;

  transition: opacity 0.4s 0s ${easing.easeInOutCubic};

  &.is-active {
    pointer-events: auto;
    z-index: 1;
    opacity: 1;
    transition-delay: 0.2s;
  }

  ${SelectorOption} {
    min-width: 80px;

    ${SelectorOptionText} {
      transform: translateY(16px);
      transition: color 0.6s, transform 0.6s ${easing.easeInOutQuart};
    }
    &.is-active {
      ${SelectorOptionText} {
        transform: none;
      }
    }
  }

  ${SelectorBaseline} {
    background-color: transparent;
  }
  ${SelectorOptionBulletContainer} {
    &::before,
    &::after {
      background-color: transparent;
    }
  }

  @media ${MEDIA_TABLET_AND_SMALLER} {
    width: 100%;
    margin-left: 0;
    margin-right: 0;

    border-top: 1px solid ${COLOR_OUTLINE_DARK};
    /* border-bottom: 1px solid ${COLOR_OUTLINE_DARK}; */

    ${SelectorOption} {
      ${SelectorOptionText} {
        transform: none;
      }
    }
  }
`;

export const DescContainer = styled.div`
  position: absolute;
  left: 0;
  top: 100%;

  z-index: 2;

  width: 100%;
  margin: 35px auto 0;

  @media ${MEDIA_TABLET_AND_SMALLER} {
    margin-top: 20px;
  }
`;

export const Description = styled.p`
  position: absolute;
  left: 0;
  top: 0;

  box-sizing: border-box;
  width: 100%;
  margin-top: 0;
  padding: 0 20px;

  text-align: center;
  font-size: ${rem(14)};
  letter-spacing: 0.08em;
  ${cssLocalizedLineHeight(1.6)}

  pointer-events: none;
  opacity: 0;
  transform: translate3d(0, 20%, 0);
  transition: transform 0.6s ${easing.easeInCubic}, opacity 0.6s ${easing.easeInCubic};

  &.is-active {
    pointer-events: auto;
    opacity: 1;
    transform: none;
    z-index: 1;

    transition-delay: 0.6s;
    transition-duration: 1.2s;
    transition-timing-function: ${easing.easeOutQuart};
  }

  .line {
    display: block;
  }

  @media ${MEDIA_MOBILE_ALL} {
    font-size: ${rem(10)};
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  z-index: 1;
  margin: -30px auto 0;
  max-width: 1200px;
  width: 100%;

  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ${Math.round((560 / 960) * 10000) / 100 + "%"};
  }

  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    pointer-events: none;
    opacity: 0;
    transform: translate3d(0, -2%, 0);
    transition: transform 0.6s 0.05s ${easing.easeInCubic}, opacity 0.6s 0.05s ${easing.easeInCubic};

    &.is-active {
      z-index: 1;
      pointer-events: auto;
      opacity: 1;
      transform: none;

      transition-delay: 0.65s;
      transition-duration: 1.6s;
      transition-timing-function: ${easing.easeOutQuart};
    }
  }

  @media ${MEDIA_TABLET_SMALL} {
    margin-top: 0;
  }

  @media ${MEDIA_MOBILE_ALL} {
  }
`;

export const ResponsiveWrapper = styled(Wrapper)``;
