import React, { useState, useEffect } from "react";
import { useTicker } from "../../../../utils/hooks";

import ChampionRole from "../../../../enums/champion-role";
import SelectorComponent, { Option as SelectorComponentOption } from "../../../../components/selector";

import RoleSelectorChampion from "../role-selector-champion";
import { VideoSourcesMap } from "../role-preview";

import { RoleMap } from "../typings";
import { Wrapper, RoleWrapper, RoleSelectorCircle, RolePreview } from "./style";
import { clamp } from "../../../../utils/math";
import { useIntl } from "gatsby-plugin-intl";

interface RoleSelectorProps {
  className?: string;
  isInViewport: boolean;
  activeRole: ChampionRole;
  setActiveRole: React.Dispatch<React.SetStateAction<ChampionRole>>;
  roleMap: RoleMap;
  testId: string;
}

const CYCLE_DURATION = 6000;

const RoleSelector: React.FC<RoleSelectorProps> = ({ className, isInViewport, activeRole, setActiveRole, roleMap, testId }) => {
  const [hasInteracted, setHasInteracted] = useState(false);
  const [autoCycling, setAutoCycling] = useState(false);
  const [cycleStartTime, setCycleStartTime] = useState(0);
  const intl = useIntl();

  const selectorOptions = Object.values(ChampionRole).map((role: ChampionRole) => ({
    id: role,
    text: intl.formatMessage({ id: roleMap[role].role }),
    icon: ((Asset) => <Asset />)(roleMap[role].icon),
  }));

  const getIndexByRole = (role: ChampionRole) => {
    for (let i = 0; i < selectorOptions.length; i++) {
      if (selectorOptions[i].id === role) return i;
    }
    return 0;
  };

  const onSelectOption = (index: number, option: SelectorComponentOption) => {
    setActiveRole(option.id as ChampionRole);
    setAutoCycling(false);
    setHasInteracted(true);
  };

  const onSwipe = (direction: number) => {
    setActiveRole((current: ChampionRole) => {
      const keys = Object.keys(ChampionRole);
      const activeIndex = Object.values(ChampionRole).indexOf(current);
      const newIndex = clamp(activeIndex + direction, 0, keys.length - 1);
      return selectorOptions[newIndex].id as ChampionRole;
    });
    setAutoCycling(false);
    setHasInteracted(true);
  };

  useTicker(
    ({ elapsed }) => {
      if (autoCycling) {
        const index = Math.floor(((elapsed - cycleStartTime) / CYCLE_DURATION) % Object.values(ChampionRole).length);
        if (selectorOptions[index].id !== activeRole) {
          setActiveRole(selectorOptions[index].id);
        }
      }
    },
    [autoCycling, activeRole, cycleStartTime],
  );

  // Watch for intersection changes
  useEffect(() => {
    isInViewport && !hasInteracted ? setAutoCycling(true) : setAutoCycling(false);
    setCycleStartTime(typeof window === "undefined" ? 0 : performance.now());
  }, [isInViewport]);

  const videoSourcesMap = (Object.keys(roleMap) as ChampionRole[]).reduce<VideoSourcesMap>((map, role) => {
    map[role] = roleMap[role].videoSources;
    return map;
  }, {} as any);

  return (
    <Wrapper className={className}>
      <RoleWrapper>
        <SelectorComponent
          options={selectorOptions}
          activeBulletColor="#c39031"
          activeOptionIndex={getIndexByRole(activeRole)}
          activeTextColor="#010101"
          color="#bac2cc"
          onSelectOption={onSelectOption}
          onSwipe={onSwipe}
          testId={testId}
          optionListAfter={
            <RolePreview
              isInViewport={isInViewport}
              activeRole={activeRole}
              roleMap={roleMap}
              videoSourcesMap={videoSourcesMap}
              testId={testId}
            />
          }
        />
      </RoleWrapper>

      <RoleSelectorCircle
        isInViewport={isInViewport}
        isAutoCycling={autoCycling}
        cycleDuration={CYCLE_DURATION}
        cycleStartTime={cycleStartTime}
      >
        <RoleSelectorChampion activeRole={activeRole} roleMap={roleMap} isInViewport={isInViewport} testId={testId}/>
      </RoleSelectorCircle>
    </Wrapper>
  );
};

export default RoleSelector;
export { cssColumnLayout } from "./style";
