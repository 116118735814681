import styled from "styled-components";
import { rem } from "../../../utils/style";
import {
  COLOR_BACKGROUND_DARK,
  MEDIA_MOBILE_ALL,
  COLUMN_WIDTH,
  MAX_WIDTH,
  MOBILE_MARGIN,
  MEDIA_DESKTOP_AT_MAX_WIDTH,
} from "../../../layouts/default/theme";

export const BREAKPOINT_COLUMN = 1200;
export const MEDIA_COLUMN_LAYOUT = `(max-width: ${BREAKPOINT_COLUMN}px)`;

export const Section = styled.section`
  position: relative;
  padding: 70px 0;

  background: ${COLOR_BACKGROUND_DARK};

  @media ${MEDIA_MOBILE_ALL} {
    padding: ${rem(40)} 0;

    user-select: none;
  }
`;

export const SectionInner = styled.div`
  margin: 0 auto;
`;

export const Dock = styled.div`
  max-width: ${rem(MAX_WIDTH - COLUMN_WIDTH * 4)};

  margin: 0 auto;

  z-index: 1;

  box-sizing: border-box;

  position: relative;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: 52vw;
  min-height: 720px;
  max-height: 900px;

  @media ${MEDIA_COLUMN_LAYOUT} {
    height: auto;
    min-height: 0;
    max-height: none;
    flex-direction: column;
    padding-top: 40px;
    padding-bottom: 0;
  }

  @media ${MEDIA_MOBILE_ALL} {
    width: calc(100% + ${MOBILE_MARGIN * 2}px);
    margin-left: ${-MOBILE_MARGIN}px;
    margin-right: ${-MOBILE_MARGIN}px;
  }

  // Resolves Flexbox vertical positioning issue when using min-heigh in IE.
  // See https://github.com/philipwalton/flexbugs/issues/231
  &:after {
    display: block;
    content: "";
    min-height: inherit;
    font-size: 0;
  }
`;

export const Hex = styled.div`
  @media ${MEDIA_MOBILE_ALL} {
    display: none;
  }
`;

export const TitleGroup = styled.div`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-100%);

  display: flex;
  justify-content: center;

  width: 100%;
  margin-top: calc(-15vw - 40px);

  z-index: 4;

  h1 {
    strong {
      font-size: 70px;
    }
  }

  @media ${MEDIA_DESKTOP_AT_MAX_WIDTH} {
    margin-top: ${-520 / 2 - 40}px;
  }

  @media ${MEDIA_COLUMN_LAYOUT} {
    position: relative;
    top: 0;
    margin-top: 0;
    margin-bottom: 45px;
    transform: none;
  }

  @media ${MEDIA_MOBILE_ALL} {
    margin-bottom: 30px;

    h1 {
      strong {
        font-size: 28px;
      }
    }
  }
`;

export const CtaButton = styled.button`
  margin-top: ${rem(30)};

  @media ${MEDIA_MOBILE_ALL} {
    margin-top: ${rem(0)};
  }
`;
export const ChampionBackgroundImage = styled.div`
  position: absolute;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;

  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;

  &::after {
    content: "";

    position: absolute;
    left: 0;
    top: 0;

    width: 100%;
    height: 100%;

    background: ${COLOR_BACKGROUND_DARK};
    opacity: 0.75;
  }
`;
