import React from "react";

import ComponentImage from "../../../../components/image";
import { VideoSource } from "../../../../components/video";

import crispPosterUrl from "./hero.jpg";
import crispVideoUrlMp4 from "./hero.mp4";
import crispVideoUrlWebm from "./hero.webm";
import blurredPosterUrl from "./hero-blurred.jpg";
import blurredVideoUrlMp4 from "./hero-blurred.mp4";
import blurredVideoUrlWebm from "./hero-blurred.webm";
import mobilePosterUrl from "./hero-mobile.jpg";
import mobileVideoUrlMp4 from "./hero-mobile.mp4";
import mobileVideoUrlWebm from "./hero-mobile.webm";

export const crispPoster = <ComponentImage src={crispPosterUrl} objectFit={"cover"} />;
export const blurredPoster = <ComponentImage src={blurredPosterUrl} objectFit={"cover"} />;
export const mobilePoster = <ComponentImage src={mobilePosterUrl} objectFit={"cover"} />;

export const crispVideoSources: VideoSource[] = [
  <source key={0} src={crispVideoUrlWebm} type="video/webm" />,
  <source key={1} src={crispVideoUrlMp4} type="video/mp4" />,
];

export const blurredVideoSources: VideoSource[] = [
  <source key={0} src={blurredVideoUrlWebm} type="video/webm" />,
  <source key={1} src={blurredVideoUrlMp4} type="video/mp4" />,
];

export const mobileVideoSources: VideoSource[] = [
  <source key={0} src={mobileVideoUrlWebm} type="video/webm" />,
  <source key={1} src={mobileVideoUrlMp4} type="video/mp4" />,
];
