import styled from "styled-components";
import { animation } from "../../../utils/style";
import ArrowLink from "../../../components/arrow-link";
import { cssResponsiveListPullSideFn } from "../../../components/section-title";
import { BASE_WIDTH } from "../../../layouts/default/theme";

export const Wrapper = styled.div`
  padding: 4% 0;
`;

export const Content = styled.div`
  max-width: ${BASE_WIDTH}px;
  margin: 0 auto;
`;

export const List = styled.ol`
  overflow: hidden;
  list-style: none;
  margin: 0;
  padding: 0;

  .carousel {
    width: 100%;
  }
`;

export const Item = styled.li<{ animationDelay: number }>`
  width: 240px;
  padding-right: 4%;

  ${({ animationDelay }) =>
    animation.triggeredTranslateFadeIn(`${Wrapper}.isVisible &`, {
      delay: animationDelay,
    })}

  &:last-child {
    padding-right: 0;
  }
`;

export const LinkContainer = styled.div`
  padding-top: 3%;
  text-align: right;
`;

export const Link = styled(ArrowLink)`
  ${animation.triggeredTranslateFadeIn(`${Wrapper}.isVisible &`, {
    delay: 1000,
    duration: 1000,
  })}
`;

export const ResponsiveWrapper = styled(Wrapper)`
  ${cssResponsiveListPullSideFn({
    List,
    Item,
    breakpointLarge: 1400,
  })}
`;
