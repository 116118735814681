import React, { useState, useEffect } from "react";
import ChampionDifficultyRanking from "../../../enums/champion-difficulty-ranking";
import ChampionRole from "../../../enums/champion-role";
import { Champion } from "../@types/index";
import Dropdown from "../../../components/dropdown";
import OptionChampionDifficulty from "../../../components/dropdown/custom-components/option-champion-difficulty";
import SingleValueChampionDifficulty from "../../../components/dropdown/custom-components/single-value-champion-difficulty";

import { levelToRanking } from "../../../components/indicator/champion-difficulty";

import {
  ResponsiveWrapper,
  Nav,
  NavInner,
  NavOutline,
  NavContent,
  NavSearch,
  RoleContainer,
  RoleList,
  RoleItem,
  RoleButton,
  NavDifficulty,
} from "./style";
import { useIntl } from "gatsby-plugin-intl";

interface ChampionRoleOption {
  value: ChampionRole;
  label: string;
}

const CHAMPION_ROLE_OPTIONS: ChampionRoleOption[] = [
  {
    value: ChampionRole.ASSASSIN,
    label: "champion-role.assassin.plural",
  },
  {
    value: ChampionRole.FIGHTER,
    label: "champion-role.fighter.plural",
  },
  { value: ChampionRole.MAGE, label: "champion-role.mage.plural" },
  {
    value: ChampionRole.MARKSMAN,
    label: "champion-role.marksman.plural",
  },
  {
    value: ChampionRole.SUPPORT,
    label: "champion-role.support.plural",
  },
  { value: ChampionRole.TANK, label: "champion-role.tank.plural" },
];

interface ChampionDifficultyOption {
  value: ChampionDifficultyRanking;
  label: string;
}

const CHAMPION_DIFFICULTY_OPTIONS: ChampionDifficultyOption[] = [
  {
    value: ChampionDifficultyRanking.LOW,
    label: "champion-difficulty.ranking.low",
  },
  {
    value: ChampionDifficultyRanking.MEDIUM,
    label: "champion-difficulty.ranking.medium",
  },
  {
    value: ChampionDifficultyRanking.HIGH,
    label: "champion-difficulty.ranking.high",
  },
];

interface ChampionOption {
  value: Champion;
  label: string;
}

// NOTE: initialize outside of react component to ensure components are not recreated on every state change
const SearchDropdown = Dropdown<ChampionOption>();
const RoleDropdown = Dropdown<ChampionRoleOption>();
const DifficultyDropdown = Dropdown<ChampionDifficultyOption>();

export interface Props {
  champions: Champion[];
  activeChampions: Champion[];
  onSelectActiveChampions: (champions: Champion[]) => void;
  stickyOffset?: number;
  className?: string;
  testId?: string;
}

const Section: React.FC<Props> = ({ champions, activeChampions, onSelectActiveChampions, stickyOffset, className, testId }) => {
  const championOptions: ChampionOption[] = champions.map((champion) => {
    return { value: champion, label: champion.name };
  });

  const [searchValue, setSearchValue] = useState<ChampionOption | null>(null);
  const [roleValue, setRoleValue] = useState<ChampionRoleOption | null>(null);
  const [difficultyValue, setDifficultyValue] = useState<ChampionDifficultyOption | null>(null);

  useEffect(() => {
    let champs: Champion[] = champions;
    if (searchValue) {
      champs = [searchValue.value];
    }

    if (roleValue) {
      champs = champs.filter((champ) => champ.roles.indexOf(roleValue.value) > -1);
    }

    if (difficultyValue) {
      // TODO: validate the change in levelToRanking didn't break this.
      champs = champs.filter((champ) => levelToRanking(champ.difficulty) === difficultyValue.value);
    }

    onSelectActiveChampions(champs);
  }, [searchValue, roleValue, difficultyValue]);

  const [searchDropdownFocused, setSearchDropdownFocused] = useState<boolean>(false);
  const intl = useIntl();
  return (
    <ResponsiveWrapper className={className} stickyOffset={stickyOffset}>
      <Nav>
        <NavInner>
          <NavOutline isActive clipLeftTop={10} clipRightBot={10} fillColor="white" strokeColor={"#c1c1c1"} />

          <NavContent>
            <NavSearch className={searchDropdownFocused || searchValue ? "active" : undefined}>
              <SearchDropdown
                isSearchable={true}
                options={championOptions}
                value={searchValue}
                defaultValue={null}
                onChange={(v?: readonly ChampionOption[] | ChampionOption | null) => {
                  const value: ChampionOption | null = v instanceof Array ? v[0] : v || null;
                  setSearchValue(value);
                }}
                placeholder={intl.formatMessage({ id: "search.action" })}
                noOptionsMessage={() => intl.formatMessage({ id: "search.message.no-champions-found" })}
                onFocus={() => {
                  console.log("focus");
                  setSearchDropdownFocused(true);
                }}
                onBlur={() => {
                  console.log("blur");
                  setSearchDropdownFocused(false);
                }}
              />
            </NavSearch>

            <RoleContainer>
              {/* large screen */}
              <RoleList>
                {[{ value: null, label: "champion-role.all.short" }, ...CHAMPION_ROLE_OPTIONS].map((option, index) => (
                  <RoleItem key={option.value || ""}>
                    <RoleButton
                      selected={roleValue ? option.value === roleValue.value : option.value === null}
                      onClick={() => setRoleValue(option.value ? option : null)}
                      data-testid={`${testId}:button-${index}`}
                    >
                      {intl.formatMessage({ id: option.label })}
                    </RoleButton>
                  </RoleItem>
                ))}
              </RoleList>

              {/* small screen */}
              <RoleDropdown
                className="role-dropdown"
                options={CHAMPION_ROLE_OPTIONS.map((option) => ({
                  value: option.value,
                  label: intl.formatMessage({ id: option.label }),
                }))}
                value={roleValue}
                onChange={(v?: readonly ChampionRoleOption[] | ChampionRoleOption | null) => {
                  const value: ChampionRoleOption | null = v instanceof Array ? v[0] : v || null;
                  setRoleValue(value);
                }}
                placeholder={intl.formatMessage({ id: "champion-role.all.long" })}
              />
            </RoleContainer>

            <NavDifficulty>
              <DifficultyDropdown
                isBorderLeft
                options={CHAMPION_DIFFICULTY_OPTIONS.map((o) => ({
                  ...o,
                  label: intl.formatMessage({ id: o.label }),
                }))}
                value={difficultyValue}
                onChange={(v?: readonly ChampionDifficultyOption[] | ChampionDifficultyOption | null) => {
                  const value: ChampionDifficultyOption | null = v instanceof Array ? v[0] : v || null;
                  setDifficultyValue(value);
                }}
                placeholder={intl.formatMessage({ id: "champion-difficulty.all-difficulties" })}
                components={{
                  Option: OptionChampionDifficulty,
                  SingleValue: SingleValueChampionDifficulty,
                }}
              />
            </NavDifficulty>
          </NavContent>
        </NavInner>
      </Nav>
    </ResponsiveWrapper>
  );
};

export default Section;
