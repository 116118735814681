import React, { useRef, useEffect, useState } from "react";

import loadScript from "../../utils/load-script";
import { TwitchWindow, Twitch, TwitchEmbed, TwitchEmbedParams, TwitchEmbedPlayer } from "./types";

import { Wrapper } from "./style";

declare const window: TwitchWindow;

export const TWITCH_EMBED_SCRIPT_URL = "https://embed.twitch.tv/embed/v1.js";

export interface Props extends TwitchEmbedParams {
  lazy?: boolean;
  onEmbed?: (embed: TwitchEmbed) => void;
  className?: string;
}

let twitchPromise: Promise<Twitch> | null = null;
function fetchTwitch(): Promise<Twitch> {
  if (!twitchPromise) {
    twitchPromise = loadScript(TWITCH_EMBED_SCRIPT_URL).then(() => {
      if (!window.Twitch) return Promise.reject();
      return Promise.resolve(window.Twitch);
    });
  }

  return twitchPromise;
}

const Component: React.FC<Props> = ({ lazy = false, className, onEmbed, channel, video, ...embedProps }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [player, setPlayer] = useState<TwitchEmbedPlayer | null>(null);
  video = video ? fixVideo(video) : video;

  // use state to remember once twitch has been set to load
  const [load, setLoad] = useState<boolean>(!lazy);
  if (!lazy && !load) setLoad(true);

  // initialize embed
  useEffect(() => {
    if (typeof window === "undefined") return;
    if (!load) return;

    let active = true;

    fetchTwitch().then((twitch) => {
      if (!active) return;
      if (!ref.current) return;

      const twitchEmbedParams: TwitchEmbedParams = { ...embedProps };
      if (channel) twitchEmbedParams.channel = channel;
      if (video) twitchEmbedParams.video = video;

      const embed = new twitch.Embed(ref.current, twitchEmbedParams);
      if (onEmbed) onEmbed(embed);

      embed.addEventListener("video.ready", () => {
        setPlayer(fixPlayer(embed.player));
      });
    });

    return () => {
      active = false;
    };
  }, [load]);

  useEffect(() => {
    if (!player) return;
    if (channel && player.getChannel() !== channel) player.setChannel(channel);
  }, [player, channel]);

  return <Wrapper className={className} ref={ref} />;
};

function fixVideo(video: string) {
  if (!video) return video;

  // fix setVideo @see https://discuss.dev.twitch.tv/t/interactive-frame-wont-load-vod/10784/3
  // add "v" prefix if one isn't specified
  return video.substr(0, 1) !== "v" ? `v${video}` : video;
}

function fixPlayer(player: TwitchEmbedPlayer): TwitchEmbedPlayer {
  // fix setMuted @see https://discuss.dev.twitch.tv/t/twitch-player-embed-muted-not-setting/13246
  // TODO: Why is this funciton being called with a undefined player?
  if (!player) {
    return player;
  }
  player.setMuted = function (value: boolean) {
    this._sendCommand("setMuted", [value]);
  };

  const _setVideo = player.setVideo;
  player.setVideo = function (video: string) {
    _setVideo.call(this, fixVideo(video));
  };

  return player;
}

export default Component;
