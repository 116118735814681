import styled from "styled-components";
import { rem } from "../../../utils/style";
import {
  MEDIA_MOBILE_ALL,
  COLOR_BACKGROUND_DARK,
  MAX_WIDTH,
  COLUMN_WIDTH,
  MEDIA_TABLET_ALL,
} from "../../../layouts/default/theme";

export const BREAKPOINT_COLUMN = 1400;
export const MEDIA_COLUMN_LAYOUT = `(max-width: ${BREAKPOINT_COLUMN}px)`;
export const PREVIEW_VIDEO_SIZE_PX = 520;
export const PREVIEW_VIDEO_SIZE_REM = rem(PREVIEW_VIDEO_SIZE_PX);

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const Intersection = styled.div`
  position: absolute;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
`;

export const Section = styled.section`
  position: relative;

  width: 100%;
  height: auto;

  background: ${COLOR_BACKGROUND_DARK};
`;

export const SectionInner = styled.div`
  position: relative;

  max-width: ${rem(MAX_WIDTH - COLUMN_WIDTH * 2)};
  padding-bottom: ${rem(160)};
  margin: 0 auto;

  @media ${MEDIA_MOBILE_ALL} {
    padding-bottom: ${rem(100)};
  }
`;

export const Dock = styled.div`
  position: relative;

  z-index: 2;

  width: 100%;
  padding-top: ${rem(110)};
  padding-bottom: ${rem(120)};
  margin-top: ${rem(260 + 180)};

  @media ${MEDIA_COLUMN_LAYOUT} {
    box-sizing: border-box;
    padding: ${rem(40)};
    padding-bottom: ${rem(120)};
  }

  @media ${MEDIA_MOBILE_ALL} {
    width: calc(100% + ${rem(20)});
    padding: 0 0 ${rem(140)};
    margin-top: calc(40vw + ${rem(250)});
    margin-left: ${rem(-10)};
  }
`;

export const TitleGroup = styled.div`
  display: block;
  text-align: center;

  width: 30%;
  height: 100%;

  @media ${MEDIA_COLUMN_LAYOUT} {
    position: absolute;
    left: 0;
    top: calc(-${PREVIEW_VIDEO_SIZE_PX / 2}px - 15rem);

    width: 100%;
    height: auto;
  }

  @media ${MEDIA_MOBILE_ALL} {
    top: calc(-40vw - ${rem(150)});
  }

  @media ${MEDIA_TABLET_ALL} {
    top: calc(-${PREVIEW_VIDEO_SIZE_PX / 2}px - 12rem);
  }
`;

export const Title = styled.h3`
  width: 100%;
`;

export const PlayNowButton = styled.button`
  padding-left: ${rem(50)};
  padding-right: ${rem(50)};
  margin-top: ${rem(30)};

  @media ${MEDIA_MOBILE_ALL} {
    margin-top: ${rem(20)};
  }
`;
