import React from "react";
import { Props, PromoItem } from "../.";
import { ImageAssetUrl } from "../../../../contentstack/content-types/assets/typings";
import ContentstackImage from "../../../../contentstack/components/image";
import { UrlFieldType } from "../../../../contentstack/fields/url";
import { sizes } from "../../../components/image";
import { COLUMN_WIDTH } from "../../../layouts/default/theme";
import { BREAKPOINT_LAYOUT } from "../style";

export interface SectionPromoListContentstackProps extends Pick<Props, "sectionTitle" | "giantTitle"> {
  promoItems: Array<
    Pick<PromoItem, "text"> & {
      uid: string;
      url: UrlFieldType;
      imageUrl: ImageAssetUrl;
    }
  >;
}

export const transformContentstackPropsToSectionProps = ({
  promoItems,
  ...otherProps
}: SectionPromoListContentstackProps): Props => {
  return {
    ...otherProps,
    promoItems: promoItems.map(({ imageUrl, url, ...promoItemsProps }) => {
      const MAX_WIDTH = 460;

      return {
        ...promoItemsProps,
        image: (
          <ContentstackImage
            src={imageUrl}
            objectFit="cover"
            responsiveSizes={[
              { width: MAX_WIDTH },
              { width: MAX_WIDTH * 2 },
              { width: MAX_WIDTH * 3 },
              { width: MAX_WIDTH * 3 },
            ]}
            sizes={sizes({
              [`calc(100vw - ${COLUMN_WIDTH * 2 + "px"})`]: `(max-width: ${BREAKPOINT_LAYOUT + "px"})`,
              "460px": null,
            })}
          />
        ),
        link: url,
      };
    }),
  };
};
