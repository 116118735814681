import React, { useState, useRef, useContext } from "react";
import GiantTitle from "../../components/giant-title";
import { useScrollTo } from "../../utils/hooks";
import StickyOffsetContext from "../../contexts/sticky-offset";

import List from "./list";
import Nav from "./nav";

import { Champion } from "./@types/index";
import { Wrapper, Introduction, Body } from "./style";
import { useIntl } from "gatsby-plugin-intl";

export interface Props {
  champions: Champion[];
  stickyOffset?: number;
  className?: string;
}

const Section: React.FC<Props> = ({ champions, stickyOffset, className }) => {
  const defaultStickyOffset = useContext(StickyOffsetContext);
  if (stickyOffset === undefined) {
    stickyOffset = defaultStickyOffset;
  }
  const bodyRef = useRef<HTMLDivElement>(null);
  const [activeChampions, setActiveChampions] = useState<Champion[]>(champions);
  const [isTransition, setIsTransition] = useState<boolean>(false);
  const [isFirstTime, setIsFirstTime] = useState<boolean>(true);
  const [activeListChampions, setActiveListChampions] = useState<Champion[]>(activeChampions);

  useScrollTo(
    {
      ref: bodyRef,
      offset: -10,
      threshold: 0.35,
      doneCallback: () => {
        // After the first interaction, remove the firstTime
        if (isFirstTime) setIsFirstTime(false);

        // Start a transition effect
        if (!isTransition) {
          setIsTransition(true);
          setTimeout(() => {
            setActiveListChampions(activeChampions);
            setIsTransition(false);
          }, 300);
        }
      },
    },
    [activeChampions],
  );
  const intl = useIntl();
  return (
    <Wrapper className={className} data-testid='hero'>
      <GiantTitle text={intl.formatMessage({ id: "section.champion-list.title" })} transitionDelay={200} testId='hero'/>

      <Introduction data-testid='hero:description'>{intl.formatMessage({ id: "section.champion-list.description" })}</Introduction>

      <Body ref={bodyRef}>
        <Nav
          champions={champions}
          activeChampions={activeChampions}
          onSelectActiveChampions={setActiveChampions}
          stickyOffset={stickyOffset}
          testId='navigation'
        />

        <List isFirstTime={isFirstTime} isVisible={!isTransition} champions={activeListChampions} testId='list'/>
      </Body>
    </Wrapper>
  );
};

export default Section;
