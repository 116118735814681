import styled, { keyframes } from "styled-components";
import { easing, rem } from "../../../../utils/style";
import {
  COLOR_PRIMARY_GOLD,
  COLOR_TEXT_WHITE,
  COLUMN_WIDTH,
  COLOR_OUTLINE_WHITE,
  MAX_WIDTH,
  COLOR_OUTLINE_EXTRA_TRANSPARENT_WHITE,
  COLOR_BACKGROUND_DARK,
  MEDIA_MOBILE_ALL,
} from "../../../../layouts/default/theme";
import { MEDIA_COLUMN_LAYOUT } from "../style";
import { ReactComponent as DiamondsSvg } from "../../../../assets/symbols/diamonds.svg";
import { cssLocalizedLineHeight, cssLocalizedUppercase } from "../../../../components/css";

const ICON_SIZE = 100;
const ICON_BORDER_PX = 2;
const ICON_INACTIVE_SCALE = 0.5;
const MAX_CONTAINER_WIDTH = MAX_WIDTH - COLUMN_WIDTH * 4;
const MAX_AVATAR_WIDTH = 520;
const LABEL_MARGIN = rem(10);
const COLUMN_LAYOUT_SPACING = rem(COLUMN_WIDTH);
const COLUMN_MODE_VERTICAL_MARGIN = "50px";
const HIGHLIGHT_PADDING = 8;
const HIGHLIGHT_DIAMETER = ICON_SIZE + HIGHLIGHT_PADDING * 2;

export const Wrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;

  width: 20%;
  height: 100%;

  z-index: 5;

  @media ${MEDIA_COLUMN_LAYOUT} {
    position: relative;
    width: 100%;
    height: ${ICON_SIZE}px;
    margin: calc(${COLUMN_MODE_VERTICAL_MARGIN} + 5px) 0 ${COLUMN_MODE_VERTICAL_MARGIN};
  }

  @media ${MEDIA_MOBILE_ALL} {
    margin-bottom: ${rem(20)};
  }
`;

export const List = styled.ul`
  box-sizing: border-box;
  position: relative;
  overflow: hidden;

  height: 100%;
  padding: ${COLUMN_LAYOUT_SPACING} 0;
  margin: 0;

  list-style: none;
`;

export const Item = styled.li`
  box-sizing: border-box;

  position: absolute;

  /* Set in ResponsiveSelector JS */
  left: 0;
  top: 0;

  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;

  will-change: transform;

  cursor: pointer;

  @media ${MEDIA_COLUMN_LAYOUT} {
    width: auto;
  }

  &.is-active {
    @media ${MEDIA_COLUMN_LAYOUT} {
      margin: 0;
    }
  }

  &:not(:last-child):after {
    content: "\\a0";
    width: 1px;
    height: ${ICON_SIZE}px;
    position: absolute;
    left: ${rem(ICON_SIZE / 2)};
    top: 50%;
    background: ${COLOR_OUTLINE_EXTRA_TRANSPARENT_WHITE};
    transform: translateX(-50%);

    @media ${MEDIA_COLUMN_LAYOUT} {
      left: 50%;
      right: auto;
      width: ${ICON_SIZE}px;
      height: 1px;
      transform: none;
    }
  }
`;

export const Label = styled.label`
  position: relative;

  width: 100%;
  margin-right: ${LABEL_MARGIN};
  flex-shrink: 1;

  text-align: left;
  color: ${COLOR_TEXT_WHITE};
  font-size: ${rem(12)};
  ${cssLocalizedLineHeight(1.2)}
  letter-spacing: 0.15em;
  font-weight: 600;
  ${cssLocalizedUppercase}

  cursor: pointer;

  opacity: 1;
  transition: opacity 1.5s;

  ${Item}.is-active & {
    opacity: 0;
    transition-duration: 0.3s;
  }

  @media ${MEDIA_COLUMN_LAYOUT} {
    display: none;
  }
`;

export const Thumb = styled.figure`
  position: relative;

  z-index: 1;

  box-sizing: border-box;
  padding: 0;
  margin: 0;
  flex-shrink: 0;

  width: ${rem(ICON_SIZE)};
  height: ${rem(ICON_SIZE)};

  background-repeat: no-repeat;
  background-position: 75% top;
  background-size: auto 100%;
  background-color: ${COLOR_BACKGROUND_DARK};

  border: solid ${COLOR_OUTLINE_WHITE} 1px;

  transform-origin: center;
  will-change: transform;
  transform: scale3d(${ICON_INACTIVE_SCALE}, ${ICON_INACTIVE_SCALE}, 1);

  overflow: hidden;
  border-radius: 50%;

  transition: transform 0.5s ${easing.easeInOutQuart};

  ${Item}.is-active & {
    transition-delay: 0.1s;
    transition-duration: 0.7s;
    transition-timing-function: ${easing.easeOutCubic};
    transform: scale3d(1, 1, 1);
  }

  > img {
    position: absolute;
    left: 0;
    top: 0;
    width: auto;
    height: 100%;
    /* Splash images always compose the champion on the right-third */
    margin: 0 0 0 -50%;

    opacity: 0.5;
    transition: opacity 0.2s ${easing.easeInOutCubic};

    ${Item}.is-active &, ${Item}:hover & {
      opacity: 1;
    }
  }
`;

const AVATAR_DIAMETER = "30vw";
const AVATAR_MAX_DIAMETER = rem(520);
const HIGHLIGHT_DIAMOND_ICON_SIZE = 18;

export const ItemHighlight = styled.div`
  position: absolute;
  z-index: 1;

  background: ${COLOR_PRIMARY_GOLD};

  right: calc(100% + ${HIGHLIGHT_PADDING}px);
  top: 50%;

  height: ${ICON_BORDER_PX}px;
  /*
    ( section width - avatar diameter ) / 2
    - 100% of wrapper width
    - Diamond icon size
    - 20px margin
  */
  width: calc(
    ((100vw - ${COLUMN_WIDTH * 2}px) - ${AVATAR_DIAMETER}) / 2 - 100% - ${HIGHLIGHT_DIAMOND_ICON_SIZE}px - 20px
  );

  @media (min-width: 1680px) {
    /*
      ( section width - avatar diameter ) / 2
      - 100% of wrapper width
      - Diamond icon size
      - 20px margin
    */
    width: calc(
      ((${MAX_CONTAINER_WIDTH}px) - ${AVATAR_MAX_DIAMETER}) / 2 - 100% - ${HIGHLIGHT_DIAMOND_ICON_SIZE}px - 15px
    );
  }

  @media ${MEDIA_COLUMN_LAYOUT} {
    top: calc(-${COLUMN_MODE_VERTICAL_MARGIN} + ${HIGHLIGHT_DIAMOND_ICON_SIZE}px + 6px);
    left: 50%;
    width: ${ICON_BORDER_PX}px;
    height: calc(${COLUMN_MODE_VERTICAL_MARGIN} - ${HIGHLIGHT_DIAMOND_ICON_SIZE}px - ${HIGHLIGHT_PADDING}px - 6px);
    transform: translateX(-50%);
  }
`;

export const HighlightDiamond = styled(DiamondsSvg)`
  position: absolute;
  display: block;

  height: 20px;
  width: 12px;

  left: -12px;
  top: ${ICON_BORDER_PX / 2}px;

  transform: translateY(-50%) rotateZ(90deg);

  .st0 {
    fill: none;
    stroke: ${COLOR_PRIMARY_GOLD};
  }

  @media ${MEDIA_COLUMN_LAYOUT} {
    right: auto;
    left: -5px;
    top: -${HIGHLIGHT_DIAMOND_ICON_SIZE}px;
    transform: none;
  }
`;

export const HighlightLens = styled.div`
  display: block;
  position: absolute;
  content: "\\a0";

  z-index: 1;

  box-sizing: border-box;

  width: ${HIGHLIGHT_DIAMETER}px;
  height: ${HIGHLIGHT_DIAMETER}px;

  left: 100%;
  top: ${-HIGHLIGHT_DIAMETER / 2}px;

  border-radius: 50%;
  border: ${ICON_BORDER_PX}px solid ${COLOR_PRIMARY_GOLD};

  @media ${MEDIA_COLUMN_LAYOUT} {
    top: 100%;
    right: auto;
    left: ${ICON_BORDER_PX / 2}px;
    transform: translate(-50%, 0%);
  }
`;
