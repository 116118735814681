import React, { ReactElement, useState, useEffect, useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";

import { useInView, useResize } from "../../../utils/hooks";
import SectionTitle from "../../../components/section-title";
import GiantTitle from "../../../components/giant-title";
import ButtonSmall from "../../../components/button-small";
import HexOutline, { HexTransitionType } from "../../../components/hex-outline";
import Tooltipper, { TooltipMap } from "../../../components/format/tooltipper";
import Image from "../../../components/image";

import {
  ResponsiveWrapper,
  SectionInner,
  Hex,
  Title,
  DisplayToggle,
  Selector,
  DescContainer,
  Description,
  ImageContainer,
  SelectorContainer,
} from "./style";
import { SectionsHowToPlayRewardAssetsQuery, File } from "../../../../generated/graphql";
import { COLOR_OUTLINE_EXTRA_TRANSPARENT_WHITE, COLOR_PRIMARY_ORANGE } from "../../../layouts/default/theme";
import { clamp } from "../../../utils/math";
import { useIntl } from "gatsby-plugin-intl";
import { Helmet } from "react-helmet";

const StyledGiantTitle = Title.withComponent(GiantTitle);
const StyledHexOutline = Hex.withComponent(HexOutline);

enum Display {
  DAYS,
  LEVELS,
}

interface DisplayValue {
  id: string;
  label: string;
  description: string;
}

export interface Props {
  title: string;
  heading: string;
  description: string;
  days: DisplayValue[];
  levels: DisplayValue[];
  labels: {
    daysToggleButton: string;
    levelsToggleButton: string;
  };
  tooltipMap: TooltipMap;
  className?: string;
}

const Section: React.FC<Props> = ({ title, heading, days, levels, labels, tooltipMap, className }) => {
  const [display, setDisplay] = useState<Display>(Display.DAYS);
  const [activeOptionIndexDays, setActiveOptionIndexDays] = useState<number>(0);
  const [activeOptionIndexLevels, setActiveOptionIndexLevels] = useState<number>(0);

  const titleRef = useRef<HTMLElement>(null);
  const [titleNameWidth, setTitleNameWidth] = useState(0);

  const [hasEntered, setHasEntered] = useState(false);
  const [inViewRef, inView] = useInView();

  useResize(() => {
    if (titleRef.current) setTitleNameWidth(titleRef.current.offsetWidth);
  }, [titleRef]);

  useEffect(() => {
    if (inView && !hasEntered) setHasEntered(true);
  }, [inView]);

  const data: SectionsHowToPlayRewardAssetsQuery = useStaticQuery(graphql`
    query SectionsHowToPlayRewardAssets {
      allFile(filter: { relativePath: { glob: "lib/sections/how-to-play/reward/assets/(day|level)/*" } }) {
        edges {
          node {
            childImageSharp {
              original {
                height
                src
                width
              }
            }
            name
          }
        }
      }
    }
  `);

  const activeImageName =
    display === Display.DAYS ? `day-${activeOptionIndexDays + 1}` : `level-${activeOptionIndexLevels + 1}`;

  const imageNodes = data.allFile.edges.map(({ node }) => node);
  const activeImageNode = imageNodes.reduce<Pick<File, "name"> | null>((activeNode, node) => {
    if (node.name === activeImageName) {
      return node;
    }
    return activeNode;
  }, null);
  const intl = useIntl();
  return (
    <ResponsiveWrapper className={className} ref={inViewRef} data-testid='rewards'>
      <SectionTitle text={intl.formatMessage({ id: title })} textColor="white">
        <SectionInner>
          <StyledHexOutline
            isActive={hasEntered}
            strokeColor={COLOR_OUTLINE_EXTRA_TRANSPARENT_WHITE}
            accentColor={COLOR_OUTLINE_EXTRA_TRANSPARENT_WHITE}
            transition={HexTransitionType.WIRE}
            transitionDuration={1500}
            transitionDelay={500}
            maskTop={titleNameWidth}
          />

          <StyledGiantTitle
            isActive={hasEntered}
            titleRef={titleRef}
            text={intl.formatMessage({ id: heading })}
            toggleContrast
            testId='rewards'
          />

          <DisplayToggle>
            <ButtonSmall
              isActive={hasEntered}
              variant="secondary"
              toggled={display === Display.DAYS}
              onClick={() => setDisplay(Display.DAYS)}
              testId='rewards:loginbutton'
            >
              {intl.formatMessage({ id: labels.daysToggleButton })}
            </ButtonSmall>

            <ButtonSmall
              isActive={hasEntered}
              variant="secondary"
              toggled={display === Display.LEVELS}
              onClick={() => setDisplay(Display.LEVELS)}
              testId='rewards:levelbutton'
            >
              {intl.formatMessage({ id: labels.levelsToggleButton })}
            </ButtonSmall>
          </DisplayToggle>

          <ImageContainer>
            {imageNodes.map((node, index) => {
              const isActive = activeImageNode && activeImageNode.name === node.name;
              return (
                <Image
                  key={node.name!}
                  className={isActive ? "is-active" : ""}
                  lazy={!inView}
                  src={node.childImageSharp!.original!.src!}
                  testId={`rewards:image-${index}`}
                />
              );
            })}
          </ImageContainer>

          <SelectorContainer>
            <Selector
              className={display === Display.DAYS ? "is-active" : ""}
              key={Display.DAYS}
              activeBulletColor={COLOR_PRIMARY_ORANGE}
              activeOptionIndex={activeOptionIndexDays}
              activeTextColor="white"
              color="#5d6166"
              options={days.map(({ id, label: text }) => ({ id, text: intl.formatMessage({ id: text }) }))}
              onSelectOption={(index) => setActiveOptionIndexDays(index)}
              onSwipe={(direction) => {
                setActiveOptionIndexDays((current) => clamp(current + direction, 0, days.length - 1));
              }}
              showOptionLines
              testId='rewards:login'
            />
            <Selector
              className={display === Display.LEVELS ? "is-active" : ""}
              key={Display.LEVELS}
              activeBulletColor={COLOR_PRIMARY_ORANGE}
              activeOptionIndex={activeOptionIndexLevels}
              activeTextColor="white"
              color="#5d6166"
              options={levels.map(({ id, label: text }) => ({ id, text: intl.formatMessage({ id: text }) }))}
              onSelectOption={(index) => setActiveOptionIndexLevels(index)}
              onSwipe={(direction) => {
                setActiveOptionIndexLevels((current) => clamp(current + direction, 0, levels.length - 1));
              }}
              showOptionLines
              testId='rewards:level'
            />
          </SelectorContainer>

          <DescContainer>
            {days.map((item, index) => (
              <Description
                key={`day-${index}`}
                className={`${display === Display.DAYS && index === activeOptionIndexDays ? "is-active" : ""}`}
                data-testid={`rewards:logindescription-${index}`}
              >
                <Tooltipper text={intl.formatMessage({ id: item.description })} tooltipMap={tooltipMap} />
              </Description>
            ))}

            {levels.map((item, index) => (
              <Description
                key={`level-${index}`}
                className={`${display === Display.LEVELS && index === activeOptionIndexLevels ? "is-active" : ""}`}
                data-testid={`rewards:leveldescription-${index}`}
              >
                <Tooltipper text={intl.formatMessage({ id: item.description })} tooltipMap={tooltipMap} />
              </Description>
            ))}
          </DescContainer>
        </SectionInner>
      </SectionTitle>
    </ResponsiveWrapper>
  );
};

export default Section;
export { default as defaultProps } from "./assets/default-props";
