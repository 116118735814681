import styled, { css } from "styled-components";
import { MEDIA_MOBILE_ALL, COLOR_BACKGROUND_BLACK } from "../../../layouts/default/theme";
import { rem } from "../../../utils/style";

import lowerBackgroundPattern from "./assets/lower-bg-pattern.png";
import { SIDE_WIDTH_SMALL_IN_PX } from "../../../components/section-title";

export const Section = styled.section`
  position: relative;
  box-sizing: border-box;

  height: auto;
  min-height: 800px;
  padding-bottom: ${rem(150)};

  background-color: ${COLOR_BACKGROUND_BLACK};
  color: white;

  @media ${MEDIA_MOBILE_ALL} {
    padding-bottom: ${rem(80)};
    min-height: 0;
  }

  &::after {
    content: "";

    position: absolute;
    left: 0;
    top: 100%;

    width: 100%;
    height: 460px;

    background-image: url(${lowerBackgroundPattern});
    background-repeat: repeat-x;
    background-position: left top;
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;

  max-width: 1300px;
  margin: 0 auto;

  display: flex;
  align-items: center;
`;

export const MapContainer = styled.div`
  flex: 1 1 auto;
  min-width: 0;
`;

export const MapInner = styled.div`
  width: 200%;
  margin: 10% 0 0 -50%;
`;

export const Content = styled.div`
  z-index: 1;
  text-align: center;
`;

export const Actions = styled.div`
  margin-top: 25px;
  > * {
    margin: 12px;
  }
`;

export const ActionButton = styled.div`
  min-width: ${rem(172)};
`;

const rowLayout = css`
  ${MapContainer} {
  }
  ${Content} {
  }
  ${MapInner} {
  }
`;

const columnLayout = css`
  flex-wrap: wrap;

  ${MapContainer} {
    width: 100%;

    /* manage overflow */
    overflow: hidden;
    margin: ${-1 * SIDE_WIDTH_SMALL_IN_PX + "px"};
    padding: ${SIDE_WIDTH_SMALL_IN_PX + "px"} 0;

    /* Map */
    > div {
      width: 120%;
      margin-left: -10%;
      margin-top: 0;
    }
  }

  ${Content} {
    width: 100%;
  }
`;

const BREAKPOINT_COLUMN = 1200;
const BREAKPOINT_ROW = BREAKPOINT_COLUMN + 1;

export const ResponsiveWrapper = styled(ContentContainer)`
  @media (max-width: ${BREAKPOINT_COLUMN}px) {
    ${columnLayout}
  }
  @media (min-width: ${BREAKPOINT_ROW}px) {
    ${rowLayout}
  }
`;
