import styled from "styled-components";
import { rem, easing } from "../../../../utils/style";
import { MEDIA_MOBILE_ALL } from "../../../../layouts/default/theme";
import ComponentImage from "../../../../components/image";

export const Wrapper = styled.div`
  position: absolute;
  left: 0;
  top: ${rem(70)};

  z-index: 1;

  width: 100%;
  height: 100%;
`;

export const ChampionImageWrapper = styled.div`
  position: absolute;
  left: -22%;
  top: calc(-22% - ${rem(50)});

  width: 144%;
  height: 144%;

  @media ${MEDIA_MOBILE_ALL} {
    left: -45%;
    top: calc(-45% - ${rem(50)});

    width: 190%;
    height: 190%;
  }
`;

export const ChampionImage = styled(ComponentImage)`
  position: absolute;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;
`;
