import React, { useEffect, useState } from "react";

import ChampionRole from "../../../../enums/champion-role";

import {
  Wrapper,
  ChampionImageWrapper,
  ChampionImage,
  ChampionCaptionWrapper,
  ChampionCaption,
  ChampionCaptionName,
  ChampionCaptionTitle,
} from "./style";

import Reveal from "../../../../components/reveal";
import { easing } from "../../../../utils/style";
import { useIntl } from "gatsby-plugin-intl";

type ChampionRoleMap = {
  [x in ChampionRole]: {
    image: React.ReactElement;
    championName: string;
    championTitle: string;
  };
};

interface Props {
  activeRole: ChampionRole;
  roleMap: ChampionRoleMap;
  isInViewport: boolean;
  testId?: string;
}

const RoleSelectorChampion: React.FC<Props> = ({ activeRole, roleMap, isInViewport, testId }) => {
  const [prevActiveRole] = useState({ index: 0, flipY: false });
  const intl = useIntl();

  useEffect(() => {
    prevActiveRole.index = Object.keys(roleMap).indexOf(activeRole);
    prevActiveRole.flipY = Math.random() > 0.5;
  }, [activeRole]);

  return (
    <Wrapper>
      <ChampionImageWrapper>
        {(Object.keys(roleMap) as ChampionRole[]).map((key, index) => {
          const forward = Object.keys(roleMap).indexOf(activeRole) > prevActiveRole.index;
          const flip = prevActiveRole.flipY;

          const isActive = key === activeRole;
          const image = React.cloneElement(roleMap[key].image, {
            lazy: !isActive && !isInViewport,
          });

          return (
            <Reveal
              key={key}
              absolute
              showDir={forward ? (flip ? "ne" : "se") : flip ? "nw" : "sw"}
              hideDir={forward ? (flip ? "ne" : "se") : flip ? "nw" : "sw"}
              isActive={isActive}
              transitionTiming={{
                show: easing.easeOutCubic,
                hide: easing.easeOutCubic,
              }}
              transitionDuration={{ show: 1400, hide: 1200 }}
              transitionDelay={{ show: 200, hide: 0 }}
            >
              <ChampionImage className={`${isActive ? "is-active" : ""}`} data-testid={`${testId}:championimage-${index}`}>{image}</ChampionImage>
            </Reveal>
          );
        })}
      </ChampionImageWrapper>
      <ChampionCaptionWrapper>
        {(Object.keys(roleMap) as ChampionRole[]).map((key, index) => (
          <ChampionCaption className={`${key === activeRole ? "is-active" : ""}`} key={key}>
            <ChampionCaptionName data-testid={`${testId}:championname-${index}`}>{intl.formatMessage({ id: roleMap[key].championName })}</ChampionCaptionName>
            <ChampionCaptionTitle data-testid={`${testId}:championtitle-${index}`}>{intl.formatMessage({ id: roleMap[key].championTitle })}</ChampionCaptionTitle>
          </ChampionCaption>
        ))}
      </ChampionCaptionWrapper>
    </Wrapper>
  );
};

export default RoleSelectorChampion;
